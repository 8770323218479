<template>
  <div class="Registers">
    <div class="SumRegisters">
      <button
        class="ItemSumRegisters"
        @click="activeTable"
        name="customer"
        style="border: 3px ridge #403b74"
      >
        <span class="titleCountRegisters"> Clientes Cadastrados </span>
        <div>
          <b-icon-people-fill></b-icon-people-fill>

          <span class="registersCount">
            {{ this.counter.customersCount }}
          </span>
        </div>
      </button>
      <button
        class="ItemSumRegisters"
        @click="activeTable"
        name="supplier"
        style="border: 3px ridge #3e66ae"
      >
        <span class="titleCountRegisters"> Fornecedores Cadastrados </span>
        <div>
          <b-icon-truck></b-icon-truck>
          <span class="registersCount"> {{ this.counter.providerCount }} </span>
        </div>
      </button>
      <button
        class="ItemSumRegisters"
        @click="activeTable"
        name="products"
        style="border: 3px ridge #48b865"
      >
        <span class="titleCountRegisters"> Produtos Cadastrados </span>
        <div>
          <b-icon-handbag></b-icon-handbag>
          <span class="registersCount"> {{ this.counter.productCount }} </span>
        </div>
      </button>
      <button
        class="ItemSumRegisters"
        @click="activeTable"
        name="employee"
        style="border: 3px ridge #f2a83e"
      >
        <span class="titleCountRegisters"> Funcionários Cadastrados </span>
        <div>
          <b-icon-person-lines-fill></b-icon-person-lines-fill>
          <span class="registersCount"> {{ this.counter.employeeCount }} </span>
        </div>
      </button>
      <button
        class="ItemSumRegisters"
        @click="activeTable"
        name="service"
        style="border: 3px ridge #222124"
      >
        <span class="titleCountRegisters"> Serviços Cadastrados </span>
        <div>
          <b-icon-shop></b-icon-shop>
          <span class="registersCount"> {{ this.counter.servicesCount }} </span>
        </div>
      </button>
    </div>
    <div class="ContainerBtnRegister">
      <b-button
        class="BtnRegister"
        style="background: #403b74"
        name="customer"
        @click="handleModals"
      >
        <b-icon-plus scale="1.5"></b-icon-plus>
        Novo Cliente</b-button
      >
      <b-button
        class="BtnRegister"
        style="background: #3e66ae"
        @click="handleModals"
        name="supplier"
      >
        <b-icon-plus scale="1.5"></b-icon-plus>
        Novo Fornecedor</b-button
      >

      <b-button
        class="BtnRegister"
        style="background: #48b865"
        @click="handleModals"
        name="products"
      >
        <b-icon-plus scale="1.5"></b-icon-plus>
        Novo Produto</b-button
      >

      <b-button
        class="BtnRegister"
        style="background: #f2a83e"
        name="employee"
        @click="handleModals"
      >
        <b-icon-plus scale="1.5"></b-icon-plus>
        Novo Funcionário</b-button
      >

      <b-button
        class="BtnRegister"
        style="background: #222124"
        name="service"
        @click="handleModals"
      >
        <b-icon-plus scale="1.5"></b-icon-plus>
        Novo Serviço</b-button
      >
    </div>
    <CustomerTable v-if="this.tableActive.customer" />
    <ProductsTable v-if="this.tableActive.products" />
    <SupplierTable
      v-if="this.tableActive.supplier"
      @supplierLenght="supplierLenght = $event"
    />
    <EmployeeTable v-if="this.tableActive.employee" />
    <ServiceTable v-if="this.tableActive.service" />

    <div :class="sizeModalRegister">
      <div :class="modalContainer" v-if="colapseRegister">
        <b-button class="closeModalRegister" @click="modalHeight"> X </b-button>
        <Customer class="cardContentModal" v-if="this.typeRegister.customer" />

        <Employee class="cardContentModal" v-if="this.typeRegister.employee" />

        <Products class="cardContentModal" v-if="this.typeRegister.products" />

        <Supplier class="cardContentModal" v-if="this.typeRegister.supplier" />

        <Service class="cardContentModal" v-if="this.typeRegister.service" />
      </div>
    </div>
  </div>
</template>
<script>
import Customer from "../Customer/Customer.vue";
import Employee from "../Employee/Employee.vue";
import Products from "../Products/Products.vue";
import Supplier from "../Supplier/Supplier.vue";
import CustomerTable from "./CustomerTable.vue";
import ProductsTable from "./ProductsTable.vue";
import SupplierTable from "./SupplierTable.vue";
import EmployeeTable from "./EmployeeTable.vue";
import ServiceTable from "./ServiceTable.vue";
import Service from "../ProvisionService/Service.vue";

import { mapMutations, mapState } from "vuex";
import serviceRegisters from "../../services/serviceRegisters";

export default {
  components: {
    Customer,
    Employee,
    Products,
    Supplier,
    Service,
    CustomerTable,
    ProductsTable,
    SupplierTable,
    EmployeeTable,
    ServiceTable,
  },

  data() {
    return {
      counter: {},
      tableActive: {
        customer: true,
        supplier: false,
        employee: false,
        products: false,
        service: false,
      },
      supplierLenght: 0,
    };
  },
  methods: {
    ...mapMutations([
      "ALTER_SIZE_MODAL_REGISTER",
      "ALTER_TYPE_REGISTER",
      "SET_COLAPSE_REGISTER",
      "ALTER_MODAL_CONTAINER_REGISTER",
      "SET_DATA_SEARCH_FROM_CUSTOMER",
      "SET_DATA_SEARCH_FROM_EMPLOYEE",
      "SET_DATA_SEARCH_FROM_PRODUCT",
      "SET_DATA_SEARCH_FROM_SUPPLIER",
      "SET_DATA_SEARCH_FROM_SERVICE",
    ]),

    async activeTable(event) {
      const button = event.currentTarget;
      const name = button.name;
      this.tableActive = {
        customer: false,
        supplier: false,
        employee: false,
        products: false,
        service: false,
        [name]: true,
      };
    },

    async countAll() {
      try {
        this.counter = await serviceRegisters.countAll();
      } catch (error) {
        console.log(error.response);
      }
    },

    async modalHeight() {
      this.SET_COLAPSE_REGISTER(!this.colapseRegister);
      this.colapseRegister
        ? this.ALTER_SIZE_MODAL_REGISTER("ModalGeneral")
        : this.ALTER_SIZE_MODAL_REGISTER("disable");
      this.modalContainer
        ? this.ALTER_MODAL_CONTAINER_REGISTER("modalContainer")
        : this.ALTER_MODAL_CONTAINER_REGISTER("contentModalDisable");

      if (!this.colapseRegister && this.sizeModalRegister === "disable") {
        this.SET_DATA_SEARCH_FROM_CUSTOMER({});
        this.SET_DATA_SEARCH_FROM_EMPLOYEE({});
        this.SET_DATA_SEARCH_FROM_PRODUCT({});
        this.SET_DATA_SEARCH_FROM_SUPPLIER({});
        this.SET_DATA_SEARCH_FROM_SERVICE({});
      }
      this.countAll();
    },

    async handleModals(event) {
      const name = event.target.name;
      this.ALTER_TYPE_REGISTER(name);

      this.modalHeight();
    },
  },
  computed: {
    ...mapState({
      sizeModalRegister: (state) => state.sizeModalRegister,
      typeRegister: (state) => state.typeRegister,
      colapseRegister: (state) => state.colapseRegister,
      modalContainer: (state) => state.modalContainer,
    }),
  },
  created() {
    this.SET_COLAPSE_REGISTER(false);
    this.SET_DATA_SEARCH_FROM_CUSTOMER({});
    this.SET_DATA_SEARCH_FROM_EMPLOYEE({});
    this.SET_DATA_SEARCH_FROM_PRODUCT({});
    this.SET_DATA_SEARCH_FROM_SUPPLIER({});
    this.SET_DATA_SEARCH_FROM_SERVICE({});
    this.ALTER_SIZE_MODAL_REGISTER("disable");
    this.ALTER_MODAL_CONTAINER_REGISTER("contentModalDisable");
    this.countAll();
  },
};
</script>
<style scoped>
.Registers {
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  width: calc(100% - 100px);
  height: 100vh;
  font-size: 13px;
}

.ContainerBtnRegister {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(227, 227, 227);
}

.BtnRegister {
  width: 18%;
  border: none;
  height: 30px;
  border-radius: 3px;
  font-size: 13px;
  box-shadow: 0px 12px 4px -10px rgba(142, 142, 142, 0.78);
}

.SumRegisters {
  display: flex;
  height: 90px;
  width: 100%;
  margin-top: 70px;
  justify-content: space-evenly;
  align-items: center;
}

.ItemSumRegisters {
  width: 18%;
  height: 60px;
  border-radius: 5px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 12px 4px -10px rgba(142, 142, 142, 0.78);
}

.ItemSumRegisters:hover {
  background: rgba(245, 245, 245, 0.546);
}
.ItemSumRegisters:focus {
  background: rgba(245, 245, 245, 0.764);
}

.ModalGeneral {
  position: fixed !important;
  z-index: 900;
  top: 53px;
  left: 61px;
  height: calc(100% - 53px);
  max-height: calc(100% - 53px);
  overflow: auto;
  width: calc(100% - 62px);
  background: #ffff;
  transition: all 0.7s ease-in-out;
}

.cardContentModal {
  width: 85vw;
  margin-top: 20px;
  margin-left: 5vw;
}

.disable {
  position: absolute;
  width: calc(100% - 62px);
  height: 0;
  left: 61px;
  top: 100vh;
  background: #b0b0b000;
  visibility: hidden;
  transition: all 0.7s ease-in-out;
}

.closeModalRegister {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  z-index: 900;
  font-size: 18px;
  height: 30px;
  background: white;
  border: none;
  color: rgb(6, 6, 6);
}

.closeModalRegister:hover {
  background: whitesmoke;
  color: red;
}

.closeModalRegister:focus {
  background: red;
  border: none;
  box-shadow: none;
}

.contentModalDisable {
  display: none;
  background: white;
  opacity: 0;
}

.modalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.titleCountRegisters {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .titleCountRegisters {
    font-size: 10px;
  }
  .ItemSumRegisters {
    gap: 0px;
    width: 19%;
    justify-content: space-between;
  }
  .Registers {
    margin-left: 60px;
    width: calc(100% - 60px);
  }
  .BtnRegister {
    width: 19%;
    font-size: 10px;
    height: 25px;
  }
  .ContainerBtnRegister {
    margin-top: 0px;
    padding-bottom: 20px;
  }
  .SumRegisters {
    height: 74px;
  }
}

@media screen and (max-width: 700px) {
  .cardContentModal {
    width: 100vw;
    margin-top: 30px;
    margin-left: 0vw;
  }

  .Registers {
    margin-left: 0px;
    width: 100%;
    height: 100vh;
    font-size: 11px;
  }

  .SumRegisters {
    margin: 55px 0 10px 0;
  }

  .ItemSumRegisters {
    width: 19%;
    height: 70px;
    gap: 0px;
    font-size: 10px;
  }

  .ContainerBtnRegister {
    margin-top: 0px;
  }

  .BtnRegister {
    width: 19%;
    height: 60px;
    font-size: 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .ModalGeneral {
    top: 53px;
    left: 0;
    width: 100%;
  }

  .disable {
    width: 100%;
    height: 0;
    left: 0;
  }
}
</style>