import api from "./axios";

class ServiceAccountReceiptBill {
  async save(receiptBill) {
    const { data } = await api.post("/receipt-bill", receiptBill)
    return data.id;
  }

  async update(receiptBill) {
    await api.put(`/receipt-bill/${receiptBill.id}`, receiptBill);
  }

  async delete(id) {
    await api.delete(`/receipt-bill/${id}`);
  }

  async findById(id) {
    const { data } = await api.get(`/receipt-bill/${id}`);
    return data;
  }

  async fillComobobox() {
    const { data } = await api.get(`/receipt-bill/combobox`);
    return data;
  }
}

export default new ServiceAccountReceiptBill()