<template>
  <div class="containerGeralMdfe">
    <div class="title">
      <b-icon-file-earmark-medical></b-icon-file-earmark-medical>
      <span> Manifesto Eletrônico </span>
    </div>
    <div class="rowContainer">
      <span>
        <b-icon-person-lines-fill></b-icon-person-lines-fill> Dados Gerais</span
      >
      <div class="rowContent" style="justify-content: flex-start !important">
        <div class="selectPlus" style="width: 25% !important">
          <label class="fixedSelect2">Ambiente</label>
          <b-form-select
            :class="`borderInput shadow-none ${isInvalidInput}`"
            value-field="value"
            text-field="text"
            v-model="dadosMdfe.ambiente"
            :options="ambiente"
            @blur.native="clearInvalidInput"
          >
          </b-form-select>
        </div>
        <div class="selectPlus" style="width: 25% !important">
          <label class="fixedSelect2">Emitente</label>
          <b-form-select
            :class="`borderInput shadow-none ${isInvalidInput}`"
            value-field="value"
            text-field="text"
            v-model="dadosMdfe.emitente"
            :options="emitente"
            @blur.native="clearInvalidInput"
            ref="emitente"
            disabled
          >
          </b-form-select>
        </div>
      </div>
    </div>
    <div class="rowContainer">
      <span>
        <b-icon-person-lines-fill></b-icon-person-lines-fill> Informações Estado
        da Carga</span
      >
      <div class="rowContent">
        <div class="input-group">
          <input
            v-model="cep_carregamento"
            required
            class="input borderInput"
          />
          <label class="user-label"> CEP </label>
          <button class="bcsCep" @click="fetchCepLoading(cep_carregamento)">
            <b-spinner
              v-if="spinGeneral"
              small
              label="Small Spinner"
            ></b-spinner>
            buscar
          </button>
        </div>
        <div class="input-group">
          <input
            v-model="dadosMdfe.uf_carregamento"
            disabled
            ref="uf_carregamento"
            :class="`input borderInput ${isInvalidInput}`"
            @blur="clearInvalidInput"
          />
          <label class="user-label-disabled"> Estado Carga </label>
        </div>
        <div class="input-group">
          <input
            v-model="carregamento.nome_municipio"
            disabled
            class="input borderInput"
          />
          <label class="user-label-disabled"> Cidade Carga </label>
        </div>
        <div class="input-group">
          <input
            v-model="carregamento.codigo_municipio"
            disabled
            class="input borderInput"
          />
          <label class="user-label-disabled"> Código IBGE </label>
        </div>
      </div>
    </div>
    <div class="rowContainer">
      <span>
        <b-icon-person-lines-fill></b-icon-person-lines-fill> Informações Estado
        de Descarga</span
      >
      <div class="rowContent">
        <div class="input-group">
          <input
            required
            v-model="cep_descarregamento"
            class="input borderInput"
          />
          <label class="user-label"> CEP </label>
          <button
            class="bcsCep"
            @click="fetchCepUnloading(cep_descarregamento)"
          >
            <b-spinner
              v-if="spinGeneral"
              small
              label="Small Spinner"
            ></b-spinner>
            buscar
          </button>
        </div>
        <div class="input-group">
          <input
            v-model="dadosMdfe.uf_descarregamento"
            disabled
            ref="uf_descarregamento"
            :class="`input borderInput ${isInvalidInput}`"
            @blur="clearInvalidInput"
          />
          <label class="user-label-disabled"> Estado Descarga </label>
        </div>
        <div class="input-group">
          <input
            v-model="descarregamento.nome_municipio"
            disabled
            class="input borderInput"
          />
          <label class="user-label-disabled"> Cidade Descarga </label>
        </div>
        <div class="input-group">
          <input
            v-model="descarregamento.codigo_municipio"
            disabled
            class="input borderInput"
          />
          <label class="user-label-disabled"> Código IBGE </label>
        </div>
      </div>
      <div class="rowContent">
        <div class="input-group-key">
          <input
            v-model="chaves"
            ref="chaves"
            :class="`input borderInput ${isInvalidInput}`"
            @blur="clearInvalidInput"
          />
          <label class="user-label-disabledKey"> Chave de Referência </label>
          <button class="addKey" @click="insertKey">Adicionar</button>
        </div>
      </div>
      <div class="contentKey">
        <div class="contentPercurso">
          <ul class="uf-list">
            <li
              v-for="(item, index) in descarregamento.documentos_fiscais"
              :key="index"
            >
              {{ item.chave }}
              <b-icon-x-circle @click="removeKey(index)" class="iconPercurso" />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <b-card-text class="mt-3">
      <div>
        <b-navbar toggleable class="cardDadosMdfe">
          <b-navbar-toggle
            target="navbar-toggle-collapse-motorista"
            id="dadosMdfeStyle"
            class="tamanhoCards"
          >
            <template #default="{ expanded }">
              <div class="tamanhoBotaoOpenCardFinaly">
                <div>
                  <b-icon
                    v-if="expanded"
                    icon="dash-square-fill"
                    style="color: #00395d"
                  ></b-icon>
                  <b-icon v-else icon="plus-square-fill" style="color: #00395d">
                  </b-icon>
                </div>
                <div style="margin-left: 10px; font-size: 13px">
                  Dados de Transporte
                </div>
              </div>
            </template>
          </b-navbar-toggle>

          <b-collapse
            id="navbar-toggle-collapse-motorista"
            class="col-md-12 col-sm-12 col-lg-12 col-xl-12"
            is-nav
          >
            <b-navbar-nav>
              <b-card
                class="mt-2 cardDadosMdfeBordaDupli"
                style="height: 100px"
              >
                <div class="rowContent" style="align-items: center">
                  <div class="input-group">
                    <input
                      v-model="dadosMdfe.rodoviario.rntrc"
                      class="input borderInput"
                      placeholder="Informar 8 caracteres"
                    />
                    <label class="user-label"> RNTRC </label>
                  </div>
                  <InputSelect
                    label="Motorista"
                    :items="conductor"
                    ref="refMotorista"
                    valueField="id"
                    textField="nome"
                    :dataSelect.sync="dadosMdfe.idCondutor"
                    :openCreateModal="() => openModals('modalConductor')"
                  />
                  <InputSelect
                    label="Veiculos"
                    :items="vehicle"
                    ref="refVeiculos"
                    valueField="id"
                    textField="placa"
                    :dataSelect.sync="dadosMdfe.rodoviario.idVeiculo"
                    :openCreateModal="() => openModals('modalVehicle')"
                  />
                  <div class="selectPlus">
                    <label class="fixedSelect2">UF Percurso</label>
                    <b-form-select
                      class="borderInput shadow-none"
                      value-field="value"
                      text-field="text"
                      :options="ufs"
                      v-model="uniqueRoute"
                      ref="uf"
                    >
                    </b-form-select>
                  </div>
                  <button class="addRoute" @click="insertRoute">
                    Adicionar
                  </button>
                </div>
                <div class="contentRoute">
                  <div class="contentPercurso">
                    <ul class="uf-list">
                      <li
                        v-for="(item, index) in dadosMdfe.percurso"
                        :key="index"
                      >
                        {{ item }}
                        <b-icon-x-circle
                          @click="removeItem(index)"
                          class="iconPercurso"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </b-card>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </b-card-text>
    <b-card-text class="mt-3" style="margin-bottom: 7rem">
      <div>
        <b-navbar toggleable class="cardDadosMdfe">
          <b-navbar-toggle
            target="navbar-toggle-collapse-carga"
            id="dadosMdfeStyle"
            class="tamanhoCards"
          >
            <template #default="{ expanded }">
              <div class="tamanhoBotaoOpenCardFinaly">
                <div>
                  <b-icon
                    v-if="expanded"
                    icon="dash-square-fill"
                    style="color: #00395d"
                  ></b-icon>
                  <b-icon v-else icon="plus-square-fill" style="color: #00395d">
                  </b-icon>
                </div>
                <div style="margin-left: 10px; font-size: 13px">
                  Dados Carga
                </div>
              </div>
            </template>
          </b-navbar-toggle>

          <b-collapse
            id="navbar-toggle-collapse-carga"
            class="col-md-12 col-sm-12 col-lg-12 col-xl-12"
            is-nav
          >
            <b-navbar-nav>
              <b-card class="mt-2 cardDadosMdfeBordaDupli">
                <div class="rowContent rowContentShippingCompany">
                  <div class="input-group inputShippingCompanyMd">
                    <b-form-input
                      class="input borderInput"
                      :value="maskToKg(dadosMdfe.peso_bruto)"
                      @input="handleGrossWeight($event)"
                    />
                    <label class="user-label"> Peso Bruto </label>
                  </div>

                  <div class="selectPlus">
                    <label class="fixedSelect2">Unidade</label>
                    <b-form-select
                      :class="`borderInput shadow-none ${isInvalidInput}`"
                      value-field="value"
                      text-field="text"
                      v-model="dadosMdfe.unidade"
                      :options="unidade"
                      @blur.native="clearInvalidInput"
                      ref="unidade"
                    >
                    </b-form-select>
                  </div>

                  <InputMoney
                    label="Valor Total da Carga"
                    type="text"
                    :dataInput.sync="dadosMdfe.valor_carga"
                    ref="valor"
                  />
                </div>
              </b-card>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </b-card-text>

    <div class="rowContentFooter">
      <div class="btnsContainerFooterInit">
        <b-button class="btnsSaveNeutral" @click="clear"
          >Novo Manifesto Eletrônico</b-button
        >
        <b-button
          @click="sendMdfe"
          :disabled="loadingToSend"
          v-if="
            dadosMdfe.id &&
            (dadosMdfe.response === '' || dadosMdfe.response === null)
          "
          class="btnsSave"
          >Emitir
          <b-spinner
            v-if="loadingToSend"
            small
            label="Small Spinner"
            class="loadingToSend"
          ></b-spinner
        ></b-button>

        <b-button
          @click="updateOrSaveMdfe"
          v-if="dadosMdfe.response === '' || dadosMdfe.response === null"
          class="btnsSave"
          >{{ dadosMdfe.id ? "Atualizar" : "Salvar" }}</b-button
        >
      </div>
    </div>
    <ModalConductor @getNewConductor="getAllConductor" />
    <ModalVehicle @getNewVehicle="getAllVehicle" />
  </div>
</template>
<script>
import mixinMdfe from "../../mixins/Mdfe/Mdfe";

export default {
  mixins: [mixinMdfe],
};
</script>

<style scoped>
.containerGeralMdfe {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 12px;
  margin-bottom: 100px;
}

.isInvalidInput:focus {
  border: solid 1px rgba(204, 21, 21, 0.583) !important;
  transition: ease-in all 0.1s;
  box-shadow: 0 0 2px 1px rgba(204, 21, 21, 0.583) !important;
}

.cardDadosMdfe {
  padding-bottom: 15px !important;
  border-radius: 5px !important;
  border: 1px solid rgba(232, 231, 231, 0.788);
  background-color: #f9f9f94d !important;
  padding: 0px 0px 0px 0px !important;
  border-radius: 5px !important;
  width: 100%;
}

.cardDadosMdfeBorda {
  border-radius: 5px !important;
  margin-top: 25px !important;
  margin-bottom: 20px;
}

.cardDadosMdfeBordaDupli {
  border-radius: 5px !important;
  margin-top: 25px !important;
  margin-bottom: 20px;
}

.tamanhoCards {
  width: 100%;
  display: flex;
}

.dropdown-menu {
  display: none;
  font-size: 12px;
  color: #212529;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu li {
  font-size: 12px;
  width: 100% !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  display: flex;
  text-align: center;
  list-style: none;
  border: none;
  height: 20px;
}

.loadingToSend {
  position: absolute;
  right: 10px;
}

.tamanhoBotaoOpenCard {
  color: black;
  font-size: 16px;
  height: 30px;
  display: flex;
  align-items: center;
}

.tamanhoBotaoOpenCardFinaly {
  color: black;
  font-size: 16px;
  height: 30px;
  display: flex;
  align-items: center;
}

.iconCadTransportadora {
  display: flex;
  justify-content: space-between;
}

.btnCadTransportadora {
  cursor: pointer;
}

#dadosMdfeStyle {
  border: none !important;
}

.form-control-sm {
  height: 28px;
  padding: 0.25rem 0.25rem;
  font-size: 12px;
  line-height: 1.3;
  border-radius: 0.2rem;
}

.custom-select-sm {
  height: 28px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 12px;
}

.form-group {
  margin-bottom: 0.2rem;
}

span {
  font-weight: bold;
  cursor: context-menu;
}

.user-label {
  position: absolute;
  left: 4px;
  font-size: 11px;
  bottom: 7px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  z-index: 10;
}

.user-label-disabled {
  position: absolute;
  left: 10px;
  font-size: 9px;
  bottom: 22px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  background-color: #ffffff;
}

.user-label-disabledKey {
  position: absolute;
  left: -4px;
  font-size: 12px;
  bottom: 5px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  background-color: #ffffff;
}

.input {
  font-size: 11px !important;
  width: 100%;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
}

.selectPlus {
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 9px;
  bottom: 24px;
  left: 11px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: white;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 11px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: none;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.borderInput {
  font-size: 12px !important;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px !important;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  box-shadow: none !important;
  border: 1px solid #505050;
}

.title {
  font-size: 16px;
  color: rgb(95, 95, 95);
  margin-bottom: 30px;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.bcsCep {
  display: flex;
  height: 30px;
  position: absolute;
  right: 0px;
  font-size: 11px;
  z-index: 2;
  cursor: pointer;
  color: black;
  border-radius: 5px;
  border: none;
  background: #c3c3c3;
  align-items: center;
}

.rowContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 10px;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}

.content-form-btn {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.form-btn {
  width: 40%;
  height: 24px;
  margin-top: 35px;
}

.form-btn:hover {
  border: 1px solid #00395d;
  color: #00395d;
  background: whitesmoke;
}

.form-btn {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background: #fbfbfb;
  border: 1px solid #00395d;
  border-radius: 3px;
  width: 10%;
  box-shadow: 0px 1px 1px 0px black;
}

.addRoute:hover {
  border: 1px solid #00395d;
  color: #00395d;
  background: whitesmoke;
}

.addRoute {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  height: 20px;
  background: #d0d0d0;
  border: none;
  border-radius: 3px;
}

.addKey:hover {
  border: 1px solid #00395d;
  background: whitesmoke;
}

.addKey {
  display: flex;
  height: 30px;
  position: absolute;
  right: 0px;
  font-size: 11px;
  z-index: 2;
  cursor: pointer;
  color: black;
  border-radius: 5px;
  border: none;
  background: #c3c3c3;
  align-items: center;
}

.contentRoute {
  position: absolute;
  right: 10px;
  height: 70px;
  width: 29%;
  background: whitesmoke;
  border-radius: 8px;
  padding: 10px;
}

.contentKey {
  width: 50%;
  background: whitesmoke;
  border-radius: 8px;
  padding: 10px;
}

.uf-list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
  gap: 10px;
}

.uf-list li {
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  position: relative;
  background-color: #cbcbcb;
}

.iconPercurso {
  position: absolute;
  color: #c93b3b;
  top: -5px;
  background: whitesmoke;
  right: -3px;
  border-radius: 50%;
}

.rowContentFooter {
  display: flex;
  width: 80vw;
  padding: 15px 10px 30px 10px;
  justify-content: space-between;
  gap: 10px;
  height: 60px;
  bottom: 0;
  background: #f9f9f9;
  position: fixed;
  z-index: 8000;
}

.btnsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.closeModal {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
  color: rgb(255, 153, 153);
  cursor: pointer;
  border: 1px solid rgb(238, 238, 238);
  padding: 2px 3px;
  border-radius: 4px;
}

.closeModal:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}

.btnsContainerFooterInit {
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.btnsSave {
  border-radius: 5px;
  background-color: #00395d;
  width: 24%;
  border: none;
  color: white;
  font-size: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnsSaveNeutral {
  border-radius: 5px;
  background-color: #ececec;
  width: 24%;
  border: 1px solid rgb(222, 221, 221);
  color: rgb(0, 0, 0);
  font-size: 12px;
}

.input-group {
  width: 30%;
}

.input-group-key {
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 1rem 0 1rem;
}

.card {
  border: none !important;
}

.inputNameShippingCompany {
  width: 70%;
}

@media screen and (max-width: 800px) {
  .rowContentShippingCompany {
    flex-wrap: wrap;
    justify-content: start;
  }

  .inputNameShippingCompany {
    width: 100%;
  }

  .inputShippingCompanyMd {
    width: 48.82%;
    margin-top: 10px;
  }
}

@media screen and (max-width: 700px) {
  .rowContent {
    flex-direction: column;
  }
  .selectPlus {
    width: 100%;
  }
  .input-group {
    width: 100% !important;
  }
  .rowContentFooter {
    display: flex;
    width: 100vw !important;
    padding: 5px;
    gap: 5px;
    height: 60px;
    bottom: 0;
  }
  .btnsSave {
    width: 100px;
    font-size: 11px;
  }
  .btnsSaveNeutral {
    width: 100px;
    font-size: 11px;
  }
}
</style>