<template>
  <div style="max-height: 100px">
    <b-modal id="modalColors" centered hide-header>
      <template #modal-footer>
        <div style="width: 100%; display: flex; justify-content: space-between">
          <button
            class="btnsModal"
            variant="info"
            size="sm"
            @click="handleCloseModalAndClear"
          >
            Fechar
          </button>
          <button
            class="btnsModal btnSave"
            variant="info"
            size="sm"
            @click="saveOrUpdate"
          >
            Salvar
          </button>
        </div>
      </template>
      <div class="mt-3">
        <div class="titleCard">
          <p>Cores</p>
        </div>
        <div>
          <label style="font-size: 14px; color: #666360">
            Informe abaixo o nome da cor
          </label>
          <div>
            <Input
              type="text"
              :dataInput.sync="dataColors.nome"
              ref="colorName"
            />
          </div>
        </div>

        <table class="table table-sm mt-5">
          <thead>
            <tr style="width: 100%">
              <th style="width: 50%; font-size: 14px; font-weight: 600">
                Nome
              </th>
              <th
                style="
                  width: 50%;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 600;
                  padding: 0 10px;
                "
              >
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="color in allColors" :key="color.id">
              <td style="font-size: 14px">{{ color.nome }}</td>
              <td
                style="
                  text-align: center;
                  padding: 0 !important;
                  display: flex;
                  height: 100%;
                  justify-content: center;
                  gap: 20px;
                  padding: 0.5rem;
                "
              >
                <b-icon-pencil
                  @click="findById(color.id)"
                  scale="1"
                  role="button"
                />

                <b-icon-x-circle
                  @click="deleteColor(color.id)"
                  role="button"
                  style="color: red"
                  scale="1"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ServiceColors from "../../services/serviceColors";
import Input from "../UI/CustomInputs/Input.vue";

export default {
  data() {
    return {
      dataColors: {
        id: "",
        nome: "",
        observacao: "",
      },
      allColors: [],
    };
  },
  components: {
    Input,
  },
  methods: {
    async save() {
      try {
        await ServiceColors.save(this.dataColors);
        this.getAllColors();

        this.$emit("getNewColors");

        return this.$toast.open({
          message: "Salvo com sucesso!",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message:
            `${error.response.data.erros}` || "Ocorreu um erro o salvar!",
          type: "info",
        });
      }
    },

    async update() {
      try {
        await ServiceColors.update(this.dataColors);
        this.getAllColors();

        this.$emit("getNewColors");

        return this.$toast.open({
          message: "Atualizado com sucesso!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um problema ao deletar!",
          type: "info",
        });
      }
    },

    async deleteColor(id) {
      try {
        await ServiceColors.delete(id);
        this.getAllColors();

        this.$emit("getNewColors");

        return this.$toast.open({
          message: "deletado com sucesso!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um problema ao deletar!",
          type: "info",
        });
      }
    },

    async findById(id) {
      const result = await ServiceColors.findById(id);
      Object.assign(this.dataColors, result);
      this.$refs.colorName.valueInput = result.nome;
    },

    async getAllColors() {
      const result = await ServiceColors.fillComobobox();
      this.allColors = result;
    },

    async saveOrUpdate() {
      this.dataColors.id === "" ? await this.save() : await this.update();
      this.clear();
    },

    clear() {
      this.dataColors.id = "";
      this.dataColors.nome = "";
      this.$refs.colorName.valueInput = "";
    },

    handleCloseModalAndClear() {
      this.clear();
      this.$bvModal.hide("modalColors");
    },
  },
  mounted() {
    this.getAllColors();
  },
};
</script>

<style scoped>
.styleHoverDelete:hover {
  background-color: #ff6347;
  border: solid 1px black;
}

.tabsContentSearch {
  overflow-y: scroll;
  max-height: 300px;
}

.btnsModal {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  height: 28px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;
}

.btnSave {
  background-color: transparent;
  border: solid 1px #0fbf4d;
  color: #0fbf4d;
}

.titleCard {
  margin-bottom: 32px !important;
  font-weight: 600;
  color: #666360;
  font-size: 16px;
}
</style>