<template>
  <GeneralSalesScreen />
</template>

<script>
import GeneralSalesScreen from '../../components/GeneralSalesScreen/Index-GeneralSalesScreen.vue'
export default {
    components:{
        GeneralSalesScreen,
    }
}
</script>