<template>
  <b-modal
    :id="nameModal"
    hide-footer
    hide-header
    centered
    @hidden="clickAwayModal"
  >
    <div style="display: flex; flex-direction: column; gap: 20px">
      <h6 class="titleModal">{{ title }}</h6>

      <section
        v-if="subTitle && footerTitle"
        style="display: flex; flex-direction: column; gap: 28px; padding: 0 5px"
      >
        <p class="subTitle">
          {{ subTitle }}
        </p>

        <div
          style="
            display: flex;
            align-items: center;
            jusitfy-content: space-between;
            width: 100%;
            margin-top: 10px;
            gap: 20px;
          "
        >
          <p class="footerTitle">
            {{ footerTitle }}
          </p>

          <div v-if="textNextButton" style="display: flex">
            <button
              class="btnContentLeft mr-4"
              style="width: 100px"
              @click="clickLeftButton"
            >
              {{ leftButtonText }}
            </button>
            <button
              class="btnContentRight"
              style="width: 100px"
              @click="clickRightButton"
            >
              {{ rightButtonText }}
            </button>
          </div>
        </div>
      </section>

      <div v-if="!textNextButton" class="btnModal">
        <button class="btnContentLeft" @click="clickLeftButton">
          {{ leftButtonText }}
        </button>
        <button class="btnContentRight" @click="clickRightButton">
          {{ rightButtonText }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    nameModal: {
      type: String,
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    footerTitle: {
      type: String,
      default: "",
    },
    leftButtonText: {
      type: String,
      default: "",
    },
    rightButtonText: {
      type: String,
      default: "",
    },
    textNextButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickLeftButton() {
      this.$emit("clickLeftButton");
    },
    clickRightButton() {
      this.$emit("clickRightButton");
    },
    clickAwayModal() {
      this.$emit("clickAwayModal");
    },
  },
};
</script>

<style scoped>
.btnModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.btnContentLeft {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.btnContentLeft:hover {
  box-shadow: 0px 0px 4px 0px #003e5f32;
}

.btnContentRight {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentRight:hover {
  background-color: #003e5f;
  box-shadow: 0px 0px 4px 0px #003e5f70;
}

.titleModal {
  font-size: 1rem;
  font-family: Poppins-Regular, sans-serif !important;
  padding: 10px;
  background-color: #00496e;
  border-radius: 5px;
  color: white;
  margin: 0;
}

.subTitle {
  color: #767676;
  font-size: 13px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  margin: 0;
}

.footerTitle {
  color: #000000;
  font-size: 13px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  margin: 0;
}
</style>