import api from "./axios";

class serviceSaleSquareMeter {
  async save(dataSaleSquareMeter) {
    const { data } = await api.post('/sales-square-meter', dataSaleSquareMeter)
    return data
  }

  async createNewCompleted(dataSaleSquareMeter) {
    const { data } = await api.post('/sales-square-meter/create-completed', dataSaleSquareMeter)
    return data
  }

  async updateCompleted(dataSaleSquareMeter) {
    const { data } = await api.put(`/sales-square-meter/update-completed/${dataSaleSquareMeter.saleSquareMeter.id}`, dataSaleSquareMeter)
    return data
  }

  async update(dataSaleSquareMeter) {
    await api.put(`/sales-square-meter/${dataSaleSquareMeter.id}`, dataSaleSquareMeter);
  }

  async findById(id) {
    const { data } = await api.get(`/sales-square-meter/${id}`);
    return data
  }

  async delete(id) {
    await api.delete(`/sales-square-meter/${id}`)
  }

  async deleteAll(dataSaleSquareMeterUnique) {
    await api.delete(`/sales-square-meter/all/${dataSaleSquareMeterUnique}`)
  }

  async transformWithNota(id) {
    const { data } = await api.get(`/sales-square-meter/transform/orcamento/venda/${id}`)
    return data
  }

  async getAllBillsById(id) {
    const { data } = await api.get(`/sales-square-meter/${id}`);
    return data;
  }

  async filterPerMonth(month, year) {
    const { data } = await api.get(
      `/sales-square-meter/per/month?month=${month}&year=${year}`
    );

    return data
  }

  async cancel(id, status) {
    await api.post(`/sales-square-meter/cancel/registers`, { id, status })
  }

  async reOpen(id) {
    await api.post(`/sales-square-meter/reopen/after/cancel`, { id })
  }

  async search(q, type, page, startDate, endDate) {
    const { data } = await api.get(
      `/sales-square-meter?q=${q}&type=${type}&page=${page}&startDate=${startDate}&endDate=${endDate}`
    );
    return data;
  }
}

export default new serviceSaleSquareMeter()