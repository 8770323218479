import api from "./axios";

class ServiceConductor {
  async save(conductor) {
    const { data } = await api.post("/conductor", conductor)
    return data.id;
  }

  async update(conductor) {
    await api.put(`/conductor/${conductor.id}`, conductor);
  }

  async delete(id) {
    await api.delete(`/conductor/${id}`);
  }

  async findById(id) {
    const { data } = await api.get(`/conductor/${id}`);
    return data;
  }

  async fillCombobox() {
    const { data } = await api.get(`/conductor/combobox`);
    return data;
  }
}

export default new ServiceConductor()