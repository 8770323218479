import api from "../../services/axios";
import { HandleErrors } from "../../utils/handleErrors"

const mixinSearchProducts = {
  data() {
    return {
      dataProducts: {},
      productsTable: [],
      textPesquisa: "",
      tabIndex: 0,
      dataLength: 0,
      spinGeneral: false,
      page: 1,
      type: "",
      filterCombobox: [
        { value: "nome", text: "Nome" },
        { value: "valorVenda", text: "Valor de Venda" },
        { value: "codReferencia", text: "Código Referência" },
        { value: "estoque", text: "Estoque" },
        { value: "", text: "Selecione..." },
      ],
    };
  },
  methods: {
    async readProducts(page) {
      try {
        this.spinGeneral = true;
        const { data } = await api.get(
          `/products/filter/products?q=${this.textPesquisa}&type=${this.type}&page=${page}`
        );
        this.dataLength = data.data.length
        this.dataProducts = data.data;
      } catch (error) {
        return this.$toast.open({
          message: "Não foi possível listar os produtos",
          type: "warning",
        });
      } finally {
        this.spinGeneral = false;
      }
      if (this.dataLength < 9) {
        this.clearSearch();
      }
    },

    clearSearch() {
      this.textPesquisa = "";
      this.type = "";
    },

    async editproducts(products) {
      try {
        this.$emit("dataProducts", products);
        this.$root.$emit("bv::toggle::collapse", "accordion-dadosCadastrais");
        this.$emit("alterTabIndex", this.tabIndex);
      } catch (error) {
        console.log(error);
      }
    },

    async destroyproducts(idProducts) {
      try {
        await api.delete("/products/" + idProducts);
        this.readProducts(this.page);
        return this.$toast.open({
          message: "Produto deletado com sucesso",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });

        const typeError = HandleErrors(error?.response?.data?.message);
        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        } else this.$toast.open({
          message: `${error.response.data.message}`,
          type: "error",
        });
      }
    },

    nextPage() {
      this.readProducts((this.page += 1));
    },

    previousPage() {
      if (this.page === 1) {
        return;
      } else {
        this.readProducts((this.page -= 1));
      }
    },
  },
  created() {
    this.readProducts(1)
  }
}

export default mixinSearchProducts