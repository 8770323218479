<template>
  <div class="generalContainer">
    <div class="contentTable">
      <table style="width: 100%">
        <thead
          style="
            color: whitesmoke;
            background: #3a4750;
            text-align: center;
            height: 28px;
          "
        >
          <tr style="width: 100%">
            <th style="width: 10%">Data Emissão</th>
            <th style="width: 10%">Data Execução</th>
            <th style="width: 10%">
              {{
                typeDocument === nfseModel.NFSE ? "Série RPS" : "Nº Referencia"
              }}
            </th>
            <th style="width: 10%" v-if="typeDocument === nfseModel.NFSE">Documento</th>
            <th style="width: 20%">Cliente</th>
            <th style="width: 20%">Funcionário</th>
            <th style="width: 14%">Status</th>
            <th style="width: 10%">Valor Total</th>
            <th style="width: 10%">Ações</th>
          </tr>
        </thead>
        <tbody v-if="dataFilterNfse.length" style="color: #3a4750; width: 100%">
          <tr
            v-for="nfse in dataFilterNfse"
            :key="nfse.id"
            style="text-align: center; height: 30px"
          >
            <td>
              {{ formatDate(nfse.data_nfse) }}
            </td>
            <td>
              {{ formatDate(nfse.data_execucao) }}
            </td>
            <td v-if="typeDocument === nfseModel.NFSE">
              {{ nfse.response ? nfse.response.serie_rps : "--" }}
            </td>
            <td v-else>{{ nfse.referencia ? nfse.referencia : "--" }}</td>

            <td v-if="typeDocument === nfseModel.NFSE">
              {{ nfse.response ? nfse.response.numero : "--" }}
            </td>
            <td>
              {{ nfse.nome || "______" }}
            </td>
            <td>{{ nfse.nomeFuncionario }}</td>
            <td style="width: 150px">
              <InputSelect
                :items="nfseSituationsNfse"
                valueField="value"
                textField="text"
                :enableIconCreate="false"
                :dataSelect.sync="nfse.status"
                ref="dataFilterNfse"
                @changed="handleUpdateOrCancelNfse(nfse)"
                textCenter
              />
            </td>
            <td>
              {{
                nfse.valorTotal
                  ? nfse.valorTotal.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  : "R$ 0,00"
              }}
            </td>
            <td
              style="display: flex; justify-content: center; width: 100%"
            >
              <div class="btnActions">
                <b-dropdown
                  id="dropdown-1"
                  class="btnActions"
                  size="sm"
                  variant="none"
                >
                  <template #button-content>
                    <b-icon-gear-fill class="contentActions"></b-icon-gear-fill>
                  </template>
                  <b-dropdown-item @click="findNfseById(nfse.id)">
                    Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="printReportOrder(nfse.id)">
                    Imprimir
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="nfse.response"
                    @click="openPdf(nfse.url_pdf)"
                  >
                    Abrir PDF
                  </b-dropdown-item>
                  <b-dropdown-item @click="forwardToFinance(nfse)">
                    Gerar Parcelas
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="getIdToRemoveOrderService(nfse.id)"
                    v-b-modal.modalConfirmDeletion
                  >
                    Excluir
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="findBills(nfse.id)"
                    v-b-modal.modal-service-bills
                  >
                    Ver parcelas
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <td colspan="9">
            <div class="defaultSearch">
              <span>Ops!... nenhum resultado encontrado</span>
            </div>
          </td>
        </tbody>
        <div v-if="spin" class="loadingLazy">
          <div class="loader"></div>
        </div>
      </table>
    </div>

    <b-modal
      id="modalConfirmDeletion"
      hide-footer
      hide-header
      centered
      title="Excluir"
    >
      <div>
        <h6 class="titleModal">Deseja excluir essa movimentação ?</h6>
        <div class="btnModal">
          <button class="btnContent" @click="deleteOrder">Deletar</button>
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modalConfirmDeletion')"
          >
            Não, Obrigado!
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-service-bills"
      hide-footer
      hide-header
      centered
      size="lg"
      title="Excluir"
    >
      <div>
        <div class="titleModalBill">
          <h6 class="textModalBill">Resumo das Duplicatas</h6>
          <div
            class="closeModalBill"
            @click="$bvModal.hide('modal-service-bills')"
          >
            X
          </div>
        </div>
        <div class="contentTableBill">
          <div class="noContentTable" v-if="dataBills == ''">
            Não há duplicatas pagas registradas!
          </div>
          <table style="border-spacing: 1px 0" v-else>
            <thead
              style="
                color: whitesmoke;
                background: #3a4750;
                padding: 10px;
                height: 35px;
                text-align: center;
                border-spacing: 0;
              "
            >
              <tr style="width: 100%">
                <th style="width: 16%">Data</th>
                <th style="width: 16%">Tipo</th>
                <th style="width: 16%">Status</th>
                <th style="width: 16%">Vl Total</th>
                <th style="width: 16%">Vl Pago</th>
                <th style="width: 16%">Excluir</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="
                  text-align: center;
                  height: 60px;
                  border-bottom: 1px solid rgb(218, 218, 218);
                "
                v-for="dataBill in dataBills"
                :key="dataBill.id"
              >
                <td>
                  {{
                    dataBill.data.split(" ")[0].split("-").reverse().join("/")
                  }}
                </td>
                <td>{{ dataBill.tipo }}</td>
                <td
                  class="contentStatus"
                  v-if="dataBill.dataPagamento === null"
                >
                  <b-icon-x-circle-fill
                    class="statusPaymentOpen"
                  ></b-icon-x-circle-fill>
                  <span style="width: 50%">Em aberto</span>
                </td>
                <td class="contentStatus" v-else>
                  <b-icon-check-circle-fill
                    class="statusPayment"
                  ></b-icon-check-circle-fill>
                  <span style="width: 50%">Pago</span>
                </td>
                <td>
                  {{
                    dataBill.valorTotal.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </td>
                <td>
                  {{
                    dataBill.valorPago.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </td>
                <td class="delBill">
                  <button
                    @click="deleteBill(dataBill, dataBill.id)"
                    class="buttonRemoveBill"
                  >
                    <b-icon-trash-fill />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modalCancelNfse"
      hide-footer
      hide-header
      centered
      title="Excluir"
      @hidden="clearId"
    >
      <div>
        <h6 class="titleModal">Cancelar NFS-e</h6>
        <div class="p-3">
          <InputSelect
            label="Motivo do cancelamento"
            :items="reasonsForCancellationNFSe"
            valueField="value"
            textField="text"
            :enableIconCreate="false"
            :dataSelect.sync="motivo"
          />
        </div>
        <div class="btnModalCancelNfse">
          <button class="btnContent" @click="cancelNfse">
            Confirmar cancelamento
            <b-spinner v-if="spinFromCancelNfse" class="spin" />
          </button>
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modalCancelNfse')"
          >
            Não, Obrigado!
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import moment from "moment";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";

import ServiceNfse from "../../services/serviceNfse";
import ServiceBill from "../../services/serviceBill";

import { HandleErrors } from "../../utils/handleErrors";
import { BillColumnsToSearchById } from "../../utils/enums/BillColumnsToSearchById";
import { NfseModel } from "../../utils/enums/NfseModel";
import { TypeSituationNfse } from "../../utils/enums/TypeSituationNfse";

import { nfseSituationsNfse } from "../../common";
import printReport from "../../reports/sale";

export default {
  props: {
    page: {
      type: [Number, String],
      default: 1,
    },
    typeDocument: {
      type: String,
      default: NfseModel.NFSE,
    },
    searchPerStatus: {
      type: String,
      default: null,
    },
    dataFilterNfse: {},
  },
  components: {
    InputSelect,
  },
  data() {
    return {
      dataSearchNfse: [],
      spin: false,
      filter: {
        text: "",
        type: "",
        startDate: "",
        endDate: "",
      },
      filterOptions: [
        { value: "", text: "Selecione uma opção" },
        { value: "nomeCliente", text: "Cliente" },
        { value: "status", text: "Status" },
        { value: "nomeFuncionario", text: "Funcionário" },
        { value: "cpfCnpj", text: "CPF/CNPJ" },
        { value: "referencia", text: "Referencia" },
      ],
      situationOptions: [
        { value: "", text: "Selecione um status" },
        { text: "Pendente", value: "Pendente" },
        { text: "Em andamento", value: "Em andamento" },
        { text: "Finalizado", value: "Finalizado" },
        { text: "Faturado", value: "Faturado" },
        { text: "Cancelado", value: "Cancelado" },
      ],
      idToRemoveOrderService: "",
      dataBills: [],
      isCpfCnpj: false,
      nfseSituationsNfse: nfseSituationsNfse,
      nfseModel: NfseModel,
      idFromCancelNfse: "",
      motivo: "",
      reasonsForCancellationNFSe: [
        { text: "Erro na emissão", value: 1 },
        { text: "Serviço não prestado", value: 2 },
        { text: "Duplicidade da nota", value: 4 },
      ],
      spinFromCancelNfse: false,
    };
  },
  methods: {
    ...mapMutations([
      "SET_DATA_SEARCH_FROM_PROVISION_SERVICE",
      "SET_DATA_SEARCH_FROM_BILL",
    ]),
    // async searchNfses(page = 1) {
    //   try {
    //     this.dataSearchNfse = await ServiceNfse.search(
    //       this.filter.text,
    //       this.filter.type,
    //       page,
    //       this.filter.startDate,
    //       this.filter.endDate,
    //       this.typeDocument
    //     );
    //     this.$emit("tableLength", this.dataSearchNfse.length);
    //     this.handleStatusRef();
    //   } catch (error) {
    //     this.$toast.open({
    //       message: error?.reponse?.data?.message || "Ocorreu um erro ao listar",
    //       type: "info",
    //     });
    //   }
    // },

    async searchAll() {
      try {
        this.dataSearchNfse = await ServiceNfse.search(
          "",
          "",
          1,
          this.filter.startDate,
          this.filter.endDate,
          this.typeDocument
        );

        this.$emit("tableLength", this.dataSearchNfse.length);
        this.handleStatusRef();
      } catch (error) {
        this.$toast.open({
          message: error?.reponse?.data?.message || "Ocorreu um erro ao listar",
          type: "info",
        });
      }
    },

    async updateStatus(id, status) {
      try {
        await ServiceNfse.updateStatus(id, { status });
        this.$toast.open({
          message: `Status alterado para (${status})`,
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (typeError && Object.keys(typeError).length) {
          this.searchAll();
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        this.$toast.open({
          message: "Ocorreu um erro ao atualizar o status",
          type: "info",
        });
      }
    },

    async cancelNfse() {
      try {
        this.spinFromCancelNfse = true;
        await ServiceNfse.cancelNfse(this.idFromCancelNfse, this.motivo);

        this.$bvModal.hide("modalCancelNfse");
        this.$toast.open({
          message: "NFS-e cancelada com sucesso",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message: error?.response?.data?.message,
          type: "info",
        });
      } finally {
        this.spinFromCancelNfse = false;
      }
    },

    async handleUpdateOrCancelNfse(nfse) {
      if (
        this.typeDocument === NfseModel.NFSE &&
        nfse.status === TypeSituationNfse.cancelado &&
        nfse.response
      ) {
        if (nfse && Object.keys(nfse.response)) {
          this.idFromCancelNfse = nfse.id;
          this.openModalCancelNfse();
        }
      } else {
        await this.updateStatus(nfse.id, nfse.status);
      }
    },

    async findNfseById(id) {
      const data = await ServiceNfse.findById(id);
      this.handleEdit(data);
    },

    async deleteOrder() {
      try {
        await ServiceNfse.deleteAll(this.idToRemoveOrderService);
        this.idToRemoveOrderService = "";

        // this.searchNfses(this.page);
        this.$bvModal.hide("modalConfirmDeletion");

        return this.$toast.open({
          message: "Movimentação Excluida!",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um erro ao deletar!",
          type: "info",
        });
      }
    },

    async deleteBill(bill, id) {
      await ServiceBill.deleteAllRelationshipsFromDuplicate(id);

      this.findBills(bill.idNfse);
    },

    async findBills(id) {
      this.dataBills = await ServiceBill.getAllBillsByParamsId(
        id,
        BillColumnsToSearchById.idNfse
      );
    },

    clearId() {
      this.idFromCancelNfse = "";
      this.motivo = "";
      // this.searchNfses(this.page);
    },

    openModalCancelNfse() {
      this.$bvModal.show("modalCancelNfse");
    },

    forwardToFinance(nfse) {
      this.SET_DATA_SEARCH_FROM_BILL({
        idCliente: nfse.idCliente,
        valorTotal: nfse.valorTotal,
      });

      return this.$router.push({
        path: `/financeiro`,
      });
    },

    handleStatusRef() {
      this.$nextTick(() => {
        this.dataFilterNfse.forEach((order, idx) => {
          this.$refs.dataFilterNfse[idx].valueItems = order.status;
        });
      });
    },

    getIdToRemoveOrderService(id) {
      this.idToRemoveOrderService = id;
    },

    async openPdf(pdf) {
      try {
        window.open(`${pdf}`, "_blank");
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao abrir o arquivo",
          type: "info",
        });
      }
    },

    handleEdit(dataNfse) {
      this.SET_DATA_SEARCH_FROM_PROVISION_SERVICE(dataNfse);
      this.$emit("openToEdit", "nfse");
    },

    clearFilterText() {
      if (this.filter.type === "cpfCnpj") {
        this.isCpfCnpj = true;
      } else {
        this.isCpfCnpj = false;
      }
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    printReportOrder(id) {
      printReport("reportOrder", id);
    },
  },
  created() {
    // this.searchNfses(1);
    this.handleStatusRef();
    this.SET_DATA_SEARCH_FROM_PROVISION_SERVICE({});
  },
  watch: {
    // page() {
    //   this.searchNfses(this.page);
    // },
    dataFilterNfse(){
      this.handleStatusRef();
    },
    searchPerStatus() {
      this.filter.text = this.searchPerStatus;
      this.filter.type = "status";
      // this.searchNfses();
    },
  },
};
</script>

<style scoped>
.generalContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  padding: 10px 20px;
  width: 100%;
  max-width: 100%;
}

.contentTable {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
}

tr:nth-child(even) {
  background: #f2f3f3;
}

th {
  font-size: 12px;
  font-weight: normal;
}

td {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.btnActions {
  display: flex;
}

.defaultSearch {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.defaultSearch span {
  font-size: 1rem;
  font-weight: normal;
}

.dropdown-menu {
  font-size: 12px;
  min-width: 5rem !important;
  width: 5rem !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  color: red;
  background: red;
  text-align: center;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu li {
  font-size: 12px;
  width: 100% !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  display: flex;
  text-align: center;
  list-style: none;
  border: none;
}

.titleModal {
  font-size: 12px;
  border-bottom: 1px solid #e6e7e8;
}

.btnModal {
  margin-top: 10px;
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.btnModalCancelNfse {
  margin-top: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.spin {
  position: absolute;
  right: 5px;
  height: 18px;
  width: 18px;
  color: white;
}

.btnContent {
  position: relative;
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentReceive {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.loadingLazy {
  position: absolute;
  bottom: 20px;
  height: 50vh;
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(140, 140, 140);
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
  opacity: 0.5;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #403b74;
  box-shadow: 30px 0 0 #f2a83e;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #3e66ae;
  box-shadow: 30px 0 0 #48b865;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f2a83e;
  }

  50% {
    box-shadow: 0 0 0 #f2a83e;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f2a83e;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #48b865;
  }

  50% {
    box-shadow: 0 0 0 #48b865;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #48b865;
    margin-top: 0;
  }
}

/* MODAL BILLS */
.titleModal {
  font-size: 12px;
  border-bottom: 1px solid #e6e7e8;
}

.titleModalBill {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 5px 0px;
  border-bottom: 1px solid #e6e7e8;
}

.textModalBill {
  font-weight: bold;
  width: 95%;
  text-align: center;
}

.closeModalBill {
  cursor: pointer;
}

.closeModalBill:hover {
  color: red;
}

.contentTableBill {
  display: flex;
  flex-direction: column;
  overflow: auto;
  font-size: 13px;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  padding: 0 0 10px 0;
  background: #fbfbfb;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 10px;
}

.noContentTable {
  background: #edebeb;
  padding: 10px;
  height: 100%;
  text-align: center;
  letter-spacing: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentStatus {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 60px;
}

.contentStatus span {
  cursor: context-menu;
}

.statusPayment {
  background: #eff0f0;
  color: green;
}

.statusPaymentOpen {
  background: #eff0f0;
  color: rgb(132, 132, 132);
}

.delBill {
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
}

.closeModalBill {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 900;
  font-size: 18px;
  height: 30px;
  background: transparent;
  border: none;
  color: rgb(6, 6, 6);
}

.closeModalBill:hover {
  background: whitesmoke;
  color: red;
}

.closeModalBill:focus {
  background: red;
  border: none;
  box-shadow: none;
}
/* BTN REMOVE BILL */

.buttonRemoveBill {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #3a4750;
  color: whitesmoke;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
}

.buttonRemoveBill:hover {
  width: 70px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: rgb(255, 69, 69);
  align-items: center;
}

.buttonRemoveBill::before {
  position: absolute;
  top: -33px;
  content: "Deletar";
  color: white;
  transition-duration: 0.3s;
  font-size: 2px;
}

.buttonRemoveBill:hover::before {
  font-size: 10px;
  opacity: 1;
  transform: translateY(30px);
  transition-duration: 0.3s;
}

.searchContainer {
  height: auto;
  width: 100%;
}

@media screen and (max-width: 1200px) and (min-width: 768px) {
  .searchContainer {
    padding-top: 0;
  }
}

@media screen and (max-width: 700px) {
  .generalContainer {
    padding: 20px 0 !important;
  }
}

@media screen and (max-width: 700px) {
  .searchContainer {
    padding: 0 0 1rem 0;
  }
}
</style>