<template>
  <FinancialMovements />
</template>

<script>
import FinancialMovements from '../../components/FinancialMovements/Index-FinancialMovements.vue'
export default {
    components:{
        FinancialMovements,
    }
}
</script>