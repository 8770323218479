import api from "./axios";

class ServiceSafeRegister {

  async update(dataSafe) {
    const { data } = await api.put(`/safe-register/${dataSafe.id}` , dataSafe)
    return data
  }

  async toOpen(safeRegister) {
    const { data } = await api.post("/safe-register/to-open", safeRegister)
    return data
  }

  async toClose(id, closedValue) {
    const { data } = await api.get(`/safe-register/to-close/${id}?closedValue=${closedValue}`)
    return data
  }

  async closingWithConference(id, payload) {
    const { data } = await api.put(`/safe-register/close/with/conference/${id}`, payload)
    return data
  }

  async existsSafeRegisterOpen() {
    const { data } = await api.get("/safe-register/exists/register/open")
    return data
  }
}

export default new ServiceSafeRegister()