import Joi from "joi";
import { VirtualManagerResumeTypes } from "../utils/enums/VirtualManagerResumeTypes";

export const ValidateVirtualManager = (virtualManager) => {
  const schema = Joi.object({
    email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: ['com', 'br'] } }).messages({
      'string.empty': 'Campo (Email) deve ser preechido',
      'string.base': 'Campo (Email) deve ser preechido',
      'string.email': 'Campo (Email) deve ser um endereço de e-mail válido'
    }),
    periodo: Joi.alternatives().conditional('ativo', {
      is: true,
      then: Joi.string().valid(
        VirtualManagerResumeTypes.diario,
        VirtualManagerResumeTypes.mensal,
        VirtualManagerResumeTypes.semanal
      ).required().messages({
        'any.only': 'Campo (Tipo de Resumo) deve ser informado',
        'any.required': 'Campo (Tipo Resumo) é obrigatório',
        'string.empty': 'Campo (Tipo de Resumo) deve ser informado',
      }),
      otherwise: Joi.string().optional().allow('', null)
    }),
    ativo: Joi.boolean().optional()
  })

  const { error } = schema.validate(virtualManager, {
    allowUnknown: true,
  })

  if (error?.details[0]?.message || error?.details[0]?.path[0]) {
    return {
      message: error?.details[0]?.message,
      keyError: error?.details[0]?.path[0]
    }
  }
}
