<template>
  <div class="d-flex justify-content-center container-geral">
    <div class="container-components">
      <Budget />
    </div>
  </div>
</template>

<script>
import Budget from "./Budget.vue";
export default {
  components: {
    Budget,
  },
};
</script>
<style scoped>
.container-geral {
  width: 100% !important;
}

.container-components {
  width: 85%;
  transition: all 0.4s linear;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .container-components {
    width: 100% !important;
  }
}
@media screen and (max-width: 700px) {
  .container-components {
    width: 100%;
  }
}
</style>