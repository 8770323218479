<template>
  <div class="BoxFront" id="boxfront">
    <div class="contentFront">
      <p class="title">FRENTE DE CAIXA (NFC-e)</p>
      <div class="formFront">
        <div style="display: flex; margin-bottom: 40px" v-if="activecodRef">
          <div class="input-group">
            <b-form-input
              required
              class="input borderInput"
              v-model="codRef"
              @keyup.enter="findCodRef(codRef)"
            />
            <label class="user-label"> Digite o Código de Referência </label>
            <div class="iconSelectBarCode" @click="findCodRef(codRef)">
              <div class="loadingRef" v-if="sendCodRef">
                <b-spinner small label="Loading..."></b-spinner>
              </div>
              <div v-else role="button">
                <b-icon-search scale="1.5"></b-icon-search>
              </div>
            </div>
          </div>
        </div>
        <div style="position: relative" size="sm">
          <span style="position: absolute; top: -23px; right: 0; z-index: 9999">
            <button class="btnsComposition" @click="handleShowCompositionItem">
              Composições
            </button>
          </span>
          <!-- <div class="selectPlus">
            <label class="fixedSelect">Produto</label>
            <b-form-select
              class="borderInput shadow-none"
              v-model="productsSelected"
              size="sm"
              @change="selectProductSetDefaultValue"
            >
              <b-form-select-option
                v-for="produtos in productsSelect"
                :key="produtos.id"
                :value="produtos"
                >{{ produtos.nome }}</b-form-select-option
              >
            </b-form-select>
            <div class="iconSelectBarCode" v-b-modal.modal-1>
              <div role="button">
                <b-icon-plus scale="2"></b-icon-plus>
              </div>
            </div>
          </div> -->
          <div class="infinite">
            <InputInfinit
              label="Itens"
              :onSearch="fillProductsComboBoxWithPaginate"
              :openCreateModal="
                () => handleModalCreateProduct('modalCreateProduct')
              "
              :findAll="createdNewProducts"
              :clearSelected="toggleClearSelect"
              :searchFields="['nome', 'codRef']"
              @selectItem="selectProductSetDefaultValue"
            />

            <span
              v-if="
                productsSelected.length !== 0 && productsSelected.estoque >= 0
              "
              class="alertStock"
              >Estoque Atual : {{ productsSelected.estoque }}</span
            >
            <span v-if="productsSelected.estoque < 0" class="alertStockNegative"
              >Estoque Atual : {{ productsSelected.estoque }}</span
            >
          </div>
        </div>

        <b-row class="infoValue">
          <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <InputMoney
              label="Valor"
              type="text"
              :dataInput.sync="productsSales.valorUnitario"
              ref="valor"
            />
          </div>

          <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <InputQuant
              label="Quant."
              type="text"
              :dataInput.sync="productsSales.quantidade"
              ref="quantidade"
            />
          </div>

          <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <div
              style="
                width: 100%;
                display: flex;
                justify-content: space-between;
                position: relative;
              "
            >
              <InputPercentage
                v-if="discountIsChecked"
                label="Desc"
                type="text"
                :dataInput.sync="productsSales.desconto"
                ref="desconto"
              />

              <InputMoney
                v-if="!discountIsChecked"
                label="Desc"
                type="text"
                :dataInput.sync="productsSales.desconto"
                ref="desconto"
              />
              <label
                style="
                  position: absolute;
                  right: -70px;
                  top: 0;
                  bottom: 0;
                  height: 100%;
                "
                class="switch col-sm-2 col-md-2 col-lg-2 col-xl-2"
              >
                <input
                  type="checkbox"
                  :disabled="validatorDesc"
                  v-model="discountIsChecked"
                />
                <div class="slider">
                  <span>R$</span>
                  <span>%</span>
                </div>
              </label>
            </div>
          </div>
        </b-row>
        <b-row v-if="this.productsSelected.id">
          <div class="radio-input">
            <label>
              <input
                type="radio"
                id="value-1"
                name="value-radio"
                value="P1"
                v-model="selectedRadio"
                @change="updateProductValue('P1')"
              />
              <span>VL . 1</span>
            </label>
            <label>
              <input
                type="radio"
                id="value-2"
                name="value-radio"
                value="P2"
                v-model="selectedRadio"
                @change="updateProductValue('P2')"
              />
              <span>VL . 2</span>
            </label>
            <label>
              <input
                type="radio"
                id="value-3"
                name="value-radio"
                value="P3"
                v-model="selectedRadio"
                @change="updateProductValue('P3')"
              />
              <span>VL . 3</span>
            </label>
            <span class="selection"></span>
          </div>
        </b-row>
        <b-row>
          <div
            class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
            style="padding-top: 30px"
          >
            <div
              style="
                display: flex;
                gap: 3px;
                height: 20px;
                align-items: stretch;
                justify-content: center;
              "
            >
              <p class="obsGeneral">Observações Gerais</p>
            </div>

            <textarea
              class="inputTextArea"
              rows="3"
              max-rows="6"
              v-model="dataSale.dadosAdicionais"
            />
          </div>
        </b-row>
      </div>
      <div class="content-form-btn">
        <button
          variant="secundary"
          class="form-btn"
          style="background: #fbfbfb; color: black; border: 1px solid gray"
          @click="clear"
        >
          LIMPAR
        </button>
        <button
          class="form-btn"
          :disabled="
            productsSales.valorUnitario == '0,00' ||
            productsSales.valorUnitario == 0
          "
          @click="storeProducts"
        >
          ADICIONAR
          <b-icon-arrow-right-short></b-icon-arrow-right-short>
        </button>
      </div>
      <div style="margin-top: 20px">
        <label class="cyberpunk-checkbox-label">
          <input
            type="checkbox"
            v-model="activecodRef"
            class="cyberpunk-checkbox"
          />
          Adicionar por Código de Referência</label
        >
      </div>
      <div style="margin-top: 10px">
        <label class="cyberpunk-checkbox-label">
          <input
            type="checkbox"
            v-model="activeBarCode"
            class="cyberpunk-checkbox"
            @change="habilityCodeBar"
          />
          Leitor de Codigo de Barras</label
        >
      </div>
      <b-modal id="modal-1" hide-footer hide-header size="lg">
        <div>
          <button class="closeModal" @click="$bvModal.hide('modal-1')">
            <b-icon-x scale="1.3"></b-icon-x>
          </button>
          <Products
            :readOrEditProducts="dataProducts"
            @getAllProducts="getProdutos"
          />
        </div>
      </b-modal>
    </div>
    <div class="contentSketch">
      <p class="titleRight">RASCUNHO (NFC-e)</p>
      <div class="tableProducts">
        <table style="width: 100%">
          <thead style="border-bottom: 2px solid #d5d3d3; font-size: 12px">
            <tr>
              <th style="width: 25%">Nome</th>
              <th style="width: 20%">Quant</th>
              <th style="width: 20%">Vl. Unitário</th>
              <th style="width: 20%">Vl. Total</th>
              <th style="width: 15%">Excluir</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="rowProduct"
              v-for="(productsSaleTable, index) in productsInTable"
              :key="index"
            >
              <td style="width: 25%">{{ productsSaleTable.nome }}</td>
              <td style="width: 20%">{{ productsSaleTable.quantidade }}</td>
              <td style="width: 20%">
                R$
                {{
                  parseFloat(productsSaleTable.valorUnitario)
                    .toFixed(2)
                    .replace(".", ",")
                }}
              </td>
              <td style="width: 20%">
                {{
                  productsSaleTable.valorTotal.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                }}
              </td>
              <td style="width: 15%" @click="deleteProductTable(index)">
                <b-icon-x-circle
                  scale="1.3"
                  class="trashDelete"
                ></b-icon-x-circle>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="styleTotal">
        <span>VALOR TOTAL</span>
        <span>
          {{ productsInTable == "" ? (this.amount = "R$ 0,00") : this.amount }}
        </span>
      </div>
      <div class="content-sketch-btn">
        <div>
          <button
            v-b-modal.modal-center1
            class="sketch-btn"
            :disabled="productsInTable == ''"
          >
            RECEBER
          </button>
        </div>
      </div>
    </div>
    <b-toast
      class="stockToast"
      id="stock-toast"
      variant="primary"
      no-close-button
      static
      auto-hide-delay="5000"
    >
      <span> O Estoque está Zerado ou Negativo. </span>
    </b-toast>
    <ModalReceiveBoxFront
      :dataSale="dataSale"
      :subtotal="subtotali"
      :amountDesc="amountDesc"
      :amount="amount"
      @clearAll="clearAll = $event"
    />
    <ModalComposition
      :compositions="compositionItems"
      :idProduct="idProduct"
      :forceUpdateSelect="toggleToUpdate"
      @findCompositions="handleItemFromModalComposition($event)"
    />
    <ModalCreateProducts
      @getAllNewProduct="handleCreatedNewProduct"
      :idProductFromSearch="idProductFromSearch"
    />
  </div>
</template>
<script>
import api from "../../services/axios";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { mapMutations, mapState } from "vuex";

import Products from "../Products/Products.vue";
import ModalReceiveBoxFront from "./Modal-receive-boxFront.vue";
import ModalComposition from "../Sale/Modal-Composition.vue";
import ModalCreateProducts from "../Modals/ModalCreateProduct.vue";
import InputMoney from "../UI/CustomInputs/InputMoney.vue";
import InputPercentage from "../UI/CustomInputs/InputPercentage.vue";
import InputQuant from "../UI/CustomInputs/InputQuant.vue";
import InputInfinit from "../../components/UI/CustomInputs/InputInfinit.vue";
import ServiceProducts from "../../services/serviceProducts";

import { INITIAL_STATE_PRODUCTS } from "../../initialStates/sale";
import serviceSettings from "../../services/serviceSettings";

export default {
  components: {
    Products,
    ModalReceiveBoxFront,
    InputMoney,
    InputQuant,
    InputInfinit,
    InputPercentage,
    ModalComposition,
    ModalCreateProducts,
  },
  data() {
    return {
      activeBarCode: false,
      activecodRef: false,
      dataProducts: {},
      getAllProducts: null,
      productsSelect: [],
      codRef: "",
      sendCodRef: false,
      keyCodes: [],
      productBarCode: {},
      productsSelected: [],
      discountIsChecked: false,
      subtotali: "0,00",
      amount: 0,
      amountDesc: "0,00",
      productsInTable: [],
      dataSale: {
        id: "",
        idCliente: "",
        idFuncionario: "",
        idSituacao: "",
        data: moment().format("YYYY-MM-DD"),
        dadosAdicionais: "",
        status: "Venda",
        tipo: "Frente de caixa",
        frete: 0,
        tipoDesconto: "Valor Monetário",
        products: [],
        comissao: 0,
        bills: [
          {
            tipo: "entrada",
            idCliente: "",
            idFuncionario: "",
            idFormaPagamento: "",
            idContaRecebimento: "",
            idVenda: "",
            valorTotal: 0.0,
            valorPago: 0.0,
            valorRestante: 0.0,
            data: moment().format("YYYY-MM-DD"),
          },
        ],
      },
      productsSales: {
        id: "",
        idVenda: "",
        idFornecedor: "",
        idProduto: "",
        quantidade: "1",
        altura: "",
        largura: "",
        metragem: "",
        porcentagemPerda: "",
        desconto: 0,
        valorUnitario: 0,
        valorTotal: 0,
        dadosAdicionais: "",
        tipo: "Frente de caixa",
        tipoProduto: "normal",
        composicao: [],
      },
      clearAll: false,
      compositionItems: [],
      prodIndice: null,
      idProduct: "",
      isBlockStock: "",
      toggleToUpdate: false,
      idProductFromSearch: "",
      createdNewProducts: null,
      toggleClearSelect: false,
      selectedRadio: "P1",
    };
  },
  methods: {
    ...mapMutations(["SET_DATA_SEARCH_FROM_BOX_FRONT", "SET_DATA_NFE"]),
    async getProdutos() {
      const { data } = await api.get("/products");
      this.productsSelect = data.data;
    },

    storeProducts() {
      if (this.isBlockStock === "2") {
        const verifyStockRemaining =
          this.productsSelected.estoque - this.productsSales.quantidade;
        if (verifyStockRemaining < 0) {
          return this.$toast.open({
            message:
              "Não foi possivel adicionar o produto! Verifique o estoque.",
            type: "info",
          });
        }
      }
      this.alterDiscountIsChecked();
      if (this.dataSale.tipoDesconto === "Porcentagem") {
        this.storeProductsPercentageDiscount();
      } else {
        const { valorUnitario, quantidade, desconto, composicao } =
          this.productsSales;
        const discountPercentage = desconto;
        const discountedPrice = valorUnitario * quantidade - desconto;

        const { id, nome } = this.productsSelected;
        if (Object.keys(this.productsSelected).length) {
          const newProduct = {
            idProduto: id,
            desconto: discountPercentage,
            valorUnitario: valorUnitario,
            valorTotal: valorUnitario * quantidade,
            quantidade: quantidade,
          };

          newProduct.composicao = this.handleComposition(composicao);

          this.productsInTable.push({
            idProduto: id,
            nome,
            valorUnitario: valorUnitario,
            valorTotal: discountedPrice,
            quantidade,
            desconto: desconto,
          });

          this.dataSale.products.push(newProduct);
        }
      }

      this.calculateSubtotal();
      this.sumOfTotalValue();
      this.sumOfDiscountValue();
      this.clear();
    },

    async findBarCode(barCode) {
      this.keyCodes = [];
      const { data } = await api.get(
        `products/search/product/by/bar/code?cod=${barCode}`
      );
      this.productBarCode = data.data;

      if (data.data === null || data.data === undefined || data.data === "") {
        return this.$toast.open({
          message: "Não foi encontrado produto com esse código de barras",
          type: "info",
        });
      } else {
        this.assignBarCode(this.productBarCode);
      }
    },

    async findCodRef() {
      try {
        this.sendCodRef = true;
        const { data } = await api.get(
          `products/search/product/by/bar/code/ref?codRef=${this.codRef}`
        );
        this.productsSelected = data.data;
        this.selectProductSetDefaultValue();
        if (data.data === null || data.data === undefined || data.data === "") {
          return this.$toast.open({
            message: "Não foi encontrado produto com esse código de referência",
            type: "info",
          });
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ops... Houve algum erro!",
            type: "info",
          });
      } finally {
        this.sendCodRef = false;
        this.codRef = "";
      }
    },

    assignBarCode(product) {
      this.dataSale.tipoDesconto = "Valor Monetário";
      this.productsSelected = product;
      this.productsSales.idProduto = product.id;
      this.productsSales.nome = product.nome;
      this.productsSales.quantidade = "1";
      this.productsSales.desconto = 0;
      if (product.valorVenda === 0) {
        this.productsSales.valorUnitario = product.valor;
        this.productsSales.valorTotal = product.valor;
      } else {
        this.productsSales.valorUnitario = product.valorVenda;
        this.productsSales.valorTotal = product.valorVenda;
      }

      this.storeProducts();
    },

    storeProductsPercentageDiscount() {
      const { valorUnitario, quantidade, desconto } = this.productsSales;
      if (!this.productsSelected || !this.productsSelected.id) {
        return;
      }

      if (
        this.dataSale.id !== "" &&
        this.dataSale.products[0].idProduto !== ""
      ) {
        this.productsSales.desconto =
          this.productsSales.valorTotal -
          this.productsSales.valorTotal * (this.productsSales.desconto / 100);
      } else if (this.productsSales.desconto !== 0) {
        this.productsSales.desconto = desconto / 100;
      }

      const discountedPrice =
        valorUnitario -
        (valorUnitario * this.productsSales.desconto).toFixed(2);

      const newProduct = {
        ...this.productsSales,
        idProduto: this.productsSelected.id,
        desconto: desconto,
        valorUnitario: valorUnitario,
        valorTotal: quantidade * valorUnitario,
      };

      newProduct.composicao = this.handleComposition(
        this.productsSales.composicao
      );

      const { id, nome, refFiscal, ncm, cest } = this.productsSelected;
      const valorUnitarioFormatted = valorUnitario;
      const descontoFormatted = desconto;

      this.productsInTable.push({
        idProduto: id,
        nome,
        valorUnitario: valorUnitarioFormatted,
        valorTotal: quantidade * discountedPrice,
        quantidade,
        desconto: descontoFormatted,
        refFiscal,
        ncm,
        cest,
      });

      this.dataSale.products.push(newProduct);
    },

    async fillProductsComboBoxWithPaginate(id, q) {
      try {
        return await ServiceProducts.fillComboBoxWithPaginate(id, q);
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao listar os produtos",
          type: "info",
        });
      }
    },

    deleteProductTable(index) {
      this.productsInTable.splice(index, 1);
      this.dataSale.products.splice(index, 1);
      this.sumOfTotalValue();
      this.calculateSubtotal();
      this.sumOfDiscountValue();
    },

    sumOfTotalValue() {
      const totalofValor = this.productsInTable.reduce(
        (oldValue, newValue) => oldValue + newValue.valorTotal,
        0
      );

      this.amount = parseFloat(totalofValor).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    sumOfDiscountValue() {
      if (this.dataSale.tipoDesconto === "Porcentagem") {
        this.amountDesc = this.productsInTable.reduce(
          (oldValue, newValue) =>
            oldValue +
            parseFloat(
              newValue.valorTotal -
                newValue.valorUnitario * parseFloat(newValue.quantidade)
            ),
          0
        );

        this.amountDesc = (this.amountDesc * -1).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      } else {
        this.amountDesc = this.productsInTable.reduce(
          (oldValue, newValue) =>
            parseFloat(oldValue) + parseFloat(newValue.desconto),
          0
        );
      }

      this.amountDesc = this.amountDesc.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    calculateSubtotal() {
      this.subtotali = this.productsInTable.reduce(
        (oldValue, newValue) =>
          oldValue + parseFloat(newValue.valorUnitario) * newValue.quantidade,
        0
      );
      this.subtotali = this.subtotali.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    handleModalCreateProduct(nameModal) {
      this.idProductFromSearch = this.productsSelected.id;
      console.log(this.productsSelected.id);
      this.openModals(nameModal);
    },

    handleCreatedNewProduct() {
      this.createdNewProducts = !this.createdNewProducts;
    },

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    clear() {
      this.productsSales.valorUnitario = 0;
      this.productsSales.quantidade = "1";
      this.productsSales.desconto = 0;
      this.$refs.valor.valueInput = 0;
      this.$refs.quantidade.valueInput = "1";
      this.$refs.desconto.valueInput = 0;
      this.productsSelected = "";

      this.toggleClearSelect = !this.toggleClearSelect;
    },

    alterDiscountIsChecked() {
      if (this.discountIsChecked === true) {
        this.dataSale.tipoDesconto = "Porcentagem";
      } else {
        this.dataSale.tipoDesconto = "Valor Monetário";
      }
    },

    habilityCodeBar() {
      if (this.activeBarCode) {
        document.body.addEventListener("keydown", this.handleKeyDown);
      }

      if (!this.activeBarCode) {
        document.body.removeEventListener("keydown", this.handleKeyDown);
      }
    },

    updateProductValue(selected) {
      const valueMapping = {
        P1: this.productsSelected?.valorVenda,
        P2: this.productsSelected?.valorVenda2,
        P3: this.productsSelected?.valorVenda3,
      };

      const currencyValueProduct = valueMapping[selected] || 0;
      this.productsSales.valorUnitario = currencyValueProduct;
      if (this.$refs.valor) {
        this.$refs.valor.valueInput = currencyValueProduct;
      }
    },

    async selectProductSetDefaultValue(product) {
      if (product) {
        this.productsSelected = product;

        if (product?.composicao) {
          this.productsSales.composicao = product?.composicao?.map((item) => {
            const newInitialState = {
              ...INITIAL_STATE_PRODUCTS,
              idList: uuidv4(),
              dadosAdicionais: item.dadosAdicionais,
              idProduto: item.idProduto,
              tipoProduto: "composicao",
              tipo: "Venda",
              quantidade: item.quantidade,
            };

            Reflect.deleteProperty(item, "idRefProduto");

            return {
              ...item,
              ...newInitialState,
            };
          });
        }

        this.productsSales.valorUnitario = product.valorVenda;

        if (this.$refs.valor) {
          this.$refs.valor.valueInput = product.valorVenda;
        }

        if (this.isBlockStock === "2") {
          if (this.productsSelected.estoque <= 0) {
            this.$bvToast.show("stock-toast");
          }
        }

        this.updateProductValue("P1");
      }
    },

    handleKeyDown(event) {
      if (event) {
        this.keyCodes.push(event.key);
        const codes = this.keyCodes.filter((item) => !isNaN(parseInt(item)));

        if (codes && codes.length) {
          const mergedCodes = codes.join("");
          if (isNaN(event.key) && mergedCodes.length > 1) {
            this.findBarCode(parseInt(mergedCodes));
          }
        }
      }
    },

    handleEditNFe() {
      if (Object.keys(this.dataNFe).length) {
        this.assignToEditNfce(this.dataNFe);
      }
    },

    assignToEditNfce(data) {
      if (Object.keys(data).length) {
        const productsToBoxFront = data.products.map((prod) => {
          return {
            idProduto: prod.idProduto,
            nome: prod.nome,
            valorUnitario: prod.subtotal,
            valorTotal: prod.total,
            quantidade: prod.quantidade,
            desconto: prod.desconto,
          };
        });

        this.dataSale.products = productsToBoxFront;
        this.dataSale.id = data.idVenda;
        this.dataSale.idCliente = data.idCliente;
        this.dataSale.idFuncionario = "";
        this.dataSale.idSituacao = data.status;
        this.dataSale.data = data.data_nfe;
        this.dataSale.dadosAdicionais = data.informacoes_complementares;
        this.dataSale.status = "Venda";
        this.dataSale.tipo = "Frente de caixa";
        this.dataSale.frete = data.frete;
        this.dataSale.tipoDesconto = "Valor Monetário";
        this.editBoxFront();
        this.storeProducts();
        this.sumOfDiscountValue();
        this.SET_DATA_SEARCH_FROM_BOX_FRONT({});
      }
    },

    editBoxFront() {
      if (this.dataSale.tipoDesconto === "Porcentagem") {
        const productEditFormated = this.dataSale.products.map((product) => {
          const discountValue = (
            product.valorTotal *
            (product.desconto / 100)
          ).toFixed(2);

          return {
            idProduto: product.idProduto,
            nome: product.nome,
            desconto: discountValue,
            valorUnitario: product.valorUnitario,
            valorTotal: product.valorTotal - discountValue,
            quantidade: product.quantidade,
          };
        });

        this.discountIsChecked = true;
        this.productsInTable = productEditFormated;
      } else {
        const productEditFormated = this.dataSale.products.map((product) => {
          return {
            idProduto: product.idProduto,
            nome: product.nome,
            valorUnitario: product.valorUnitario,
            valorTotal: product.valorTotal - product.desconto,
            quantidade: product.quantidade,
            desconto: product.desconto,
          };
        });
        this.productsInTable = productEditFormated;
      }
    },

    handleComposition(composicao) {
      if (composicao && composicao.length) {
        return composicao.map((item) => {
          const newInitialState = {
            ...INITIAL_STATE_PRODUCTS,
            idList: uuidv4(),
            dadosAdicionais: item.dadosAdicionais,
            idProduto: item.idProduto,
            tipoProduto: "composicao",
            tipo: "Orçamento",
            quantidade: item.quantidade,
          };

          Reflect.deleteProperty(item, "idRefProduto");

          return {
            ...item,
            ...newInitialState,
          };
        });
      }

      return [];
    },

    handleShowCompositionItem() {
      this.compositionItems = this.handleComposition(
        this.productsSales.composicao
      );
      this.idProduct = this.productsSales.idProduto;
      this.toggleToUpdate = !this.toggleToUpdate;
      this.$bvModal.show("modalProductSaleComposition");
    },

    handleItemFromModalComposition(payload) {
      this.productsSales.composicao = payload.composition;
    },

    async getConfig() {
      const result = await serviceSettings.findSettingsAtm();
      if (Object.keys(result).length) {
        this.isBlockStock = result.venda.bloqueio_estoque;
      }
    },
  },

  mounted() {
    this.handleEditNFe();
    this.getProdutos();
    this.getConfig();
    if (Object.keys(this.dataSearchFromBoxFront).length) {
      Object.assign(this.dataSale, this.dataSearchFromBoxFront);
      this.editBoxFront();
      this.storeProducts();
      this.sumOfDiscountValue();
      this.SET_DATA_SEARCH_FROM_BOX_FRONT({});
    }
  },

  computed: {
    ...mapState(["dataSearchFromBoxFront", "dataNFe"]),
    validatorDesc() {
      if (this.productsInTable.length) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    clearAll() {
      this.productsInTable = [];
      this.dataSale.products = [];
      this.dataSale.bills = [
        {
          tipo: "entrada",
          idCliente: "",
          idFuncionario: "",
          idFormaPagamento: "",
          idContaRecebimento: "",
          idVenda: "",
          valorTotal: 0.0,
          valorPago: 0.0,
          valorRestante: 0.0,
          data: moment().format("YYYY-MM-DD"),
        },
      ];
      this.dataSale.dadosAdicionais = "";
      this.clearAll = false;
      this.activeBarCode = false;
      this.activecodRef = false;
    },
  },
  beforeDestroy() {
    document.body.removeEventListener("keydown", this.handleKeyDown);
  },
};
</script>
<style scoped>
.BoxFront {
  display: flex;
  width: 100%;
  height: 100vh;
}

.contentFront {
  width: 40%;
  height: auto;
  margin-left: 40px;
}

.title {
  font-size: 1rem;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.selectPlus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.infinite {
  padding-right: 18px;
  position: relative;
}

.borderInput {
  font-size: 14px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px !important;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #505050 !important;
  box-shadow: none;
}

.form-control {
  box-shadow: none;
  position: relative;
}

.input {
  font-size: 12px !important;
  width: 100%;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
  font-weight: 600;
}

.screenProducts {
  align-items: center;
  background: white;
  cursor: pointer;
  display: flex;
  width: 2em;
  height: 2em;
  justify-content: center;
}
/*  ------------------ */

.fixedSelect {
  position: absolute;
  font-size: 10px;
  bottom: 15px;
  left: 10px;
  background-color: #ffffff;
  padding: 0 0.4em;
}

/* ----------------------------  */
.radio-input {
  display: flex;
  margin: 10px 0px 0px 15px;
}

.infoValue {
  gap: 5px;
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.radio-input input {
  display: none;
}

.radio-input {
  --container_width: 130px;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  color: #000000;
  width: var(--container_width);
  overflow: hidden;
  border: 1px solid rgba(53, 52, 52, 0.226);
}

.radio-input label {
  width: 100%;
  padding: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-size: 11px;
  margin-bottom: 0px !important;
}

.selection {
  display: none;
  position: absolute;
  height: 100%;
  width: calc(var(--container_width) / 3);
  z-index: 0;
  left: 0;
  top: 0;
  transition: 0.15s ease;
}

.radio-input label:has(input:checked) {
  color: #fff;
}

.radio-input label:has(input:checked) ~ .selection {
  background-color: #3a4750;
  display: inline-block;
}

.radio-input label:nth-child(1):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 0 / 3));
}

.radio-input label:nth-child(2):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 1 / 3));
}

.radio-input label:nth-child(3):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 2 / 3));
}

.formFront {
  width: 95%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  margin-top: 50px;
}

.cyberpunk-checkbox {
  appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid #555151;
  border-radius: 5px;
  background-color: transparent;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.cyberpunk-checkbox:before {
  content: "";
  background-color: #00486e;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 8px;
  height: 8px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.cyberpunk-checkbox:checked:before {
  transform: translate(-50%, -50%) scale(1);
}

.cyberpunk-checkbox-label {
  font-size: 12px;
  color: black;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}

.content-form-btn {
  display: flex;
  width: 87%;
  justify-content: space-between;
}

.form-btn {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background: #fbfbfb;
  border: 1px solid green;
  color: green;
  border-radius: 3px;
  width: 35%;
  height: 28px;
  margin-top: 50px;
  box-shadow: 0px 1px 1px 0px black;
}

.form-btn:hover {
  border: 1px solid green;
  color: green;
  background: whitesmoke;
}

.contentSketch {
  width: 65%;
  border-left: 2px solid #d5d3d3;
  height: auto;
  position: relative;
}

.contentSketch p {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.titleRight {
  font-size: 1rem;
  padding: 0 25px;
}

.obsGeneral {
  font-size: 13px;
  color: #666360;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.observacoes {
  font-size: 12px;
  color: #d5d3d3;
  font-style: italic;
}

.inputTextArea {
  width: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: white;
  color: #5c5855;
  border-radius: 5px;
  height: 60px;
  padding-left: 5px;
  border: solid 1px #d9d9d9;
  resize: none;
}

.closeModal {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
  color: rgb(255, 153, 153);
  cursor: pointer;
  border: 1px solid rgb(238, 238, 238);
  padding: 2px 3px;
  border-radius: 4px;
}

.closeModal:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}

.tableProducts {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  height: 50vh;
  max-height: 50vh;
  overflow: scroll;
  text-align: center;
  background: #fffcfc;
}

.styleTotal {
  display: flex;
  width: 100%;
  padding: 0 100px;
  background: #e3e9e6;
  font-size: 12px;
  height: 30px;
  gap: 2vw;
  font-weight: bold;
  justify-content: flex-end;
  align-items: center;
}

tbody {
  width: 100%;
  height: auto;
  max-height: 200px;
  overflow: scroll;
}

tr:nth-child(even) {
  background: white;
}

tr {
  background: #fffcfc;
}

.rowProduct {
  width: 100%;
  height: 30px;
  border-bottom: 1px solid #f1efef;
}

.trashDelete {
  color: red;
  cursor: pointer;
}

.sketch-btn {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background: rgb(255, 255, 255);
  border: 1px solid green;
  color: green;
  border-radius: 3px;
  width: 180px;
  height: 28px;
  margin-top: 90px;
  gap: 10px;
  box-shadow: 0px 1px 1px 0px black;
}

.sketch-btn:hover {
  background: whitesmoke;
  border: 1px solid green;
  color: green;
}

.content-sketch-btn {
  bottom: 50px;
  right: 90px;
  position: fixed;
}

.btnsComposition {
  border-radius: 5px;
  width: auto;
  height: auto;
  border: none;
  color: white;
  font-size: 9px;
  background-color: #a8a8a8;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.353);
  transition: ease-in all 0.1s;
}

.btnsComposition:hover {
  background-color: rgb(116, 116, 116);
  transition: ease-in all 0.1s;
}

.iconSelect {
  position: absolute;
  right: 0px !important;
  z-index: 10;
  background-color: #c1c1c1;
  color: whitesmoke;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
}
.iconSelectBarCode {
  position: absolute;
  right: 0px !important;
  z-index: 10;
  background-color: #c1c1c1;
  color: black;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
}

.stockToast {
  position: absolute;
  right: 20px;
  display: flex;
}

.stockToast span {
  color: black;
}

.iconSelectCodRef {
  background-color: #c1c1c1;
  color: black;
  height: 30px;
  width: 120px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
}

.alertStock {
  font-size: 10px;
  color: #3e66ae;
  padding-left: 10px;
}

.alertStockNegative {
  font-size: 10px;
  color: #ff1d08;
  padding-left: 10px;
}

.loadingRef {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
}

.switch {
  font-size: 12px;
  position: relative;
  display: inline-block;
}

.user-label {
  position: absolute;
  left: 10px;
  font-size: 12px;
  bottom: 5px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  z-index: 300;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #e7e6e6;
  font-weight: bold;
  color: #000000;
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.4s;
  width: 60px !important;
  height: 100%;
}

.slider:before {
  position: absolute;
  content: "R$";
  height: 90%;
  width: 45%;
  left: 5%;
  font-weight: bold;
  border-radius: 50%;
  background-color: rgb(66, 203, 66);
  color: white;
  display: grid;
  align-content: center;
  justify-content: center;
  transition: 0.4s;
}

.slider:after {
  content: "";
  position: absolute;
  z-index: -1;
  font-weight: bold;
}

.switch input:checked + .slider {
  background-color: #e7e6e6;
  color: rgb(0, 0, 0);
}

.switch input:checked + .slider:before {
  content: "%";
  background-color: rgb(66, 203, 66);
  transform: translateX(100%);
  color: white;
  font-weight: bold;
}

.switch input {
  display: none;
}

th {
  font-weight: normal;
  font-size: 12px;
}

td {
  text-transform: capitalize;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .contentFront {
    margin-left: 15px;
  }

  .contentSketch {
    width: 60%;
  }

  .title {
    margin-top: 62px;
    font-size: 14px;
  }

  .titleRight {
    margin-top: 62px;
    font-size: 14px;
  }

  .formFront {
    width: 98%;
    margin-top: 35px;
  }

  .infoValue {
    gap: 0px;
  }

  .form-btn {
    font-size: 10px;
    width: 45%;
    height: 28px;
    margin-top: 80px;
  }

  .tableProducts {
    padding: 15px 7px;
  }

  .cyberpunk-checkbox-label {
    font-size: 10px;
  }

  .content-form-btn {
    width: 92%;
  }

  .slider {
    width: 40px !important;
  }

  .styleTotal {
    padding: 0 40px;
    font-size: 11px;
  }

  .content-sketch-btn {
    right: 30px;
  }

  .sketch-btn {
    width: 140px;
    height: 29px;
  }
}

@media screen and (max-width: 700px) {
  .BoxFront {
    flex-direction: column;
  }

  .contentFront {
    width: 80%;
    margin-left: 40px;
  }

  .contentSketch {
    width: 100%;
    margin-top: 30px;
  }

  .content-form-btn {
    width: 95%;
  }

  .form-btn {
    width: 40%;
    height: 24px;
    margin-top: 35px;
  }

  .switch {
    right: -5px !important;
  }

  .infoValue {
    margin-top: 1rem !important;
  }

  .titleRight {
    font-size: 1rem;
    padding: 0;
    margin-left: 40px;
  }

  .tableProducts {
    padding: 20px;
    height: auto;
    max-height: 50vh;
  }

  .content-sketch-btn {
    bottom: 20px;
    right: 20px;
    position: fixed;
  }
  .sketch-btn {
    width: 150px;
    height: 28px;
    margin-top: 0px;
    gap: 0px;
  }
}
</style>