export default function printReportGeneral(name, type, startDate, endDate, isSimple) {
  const token = localStorage.getItem("token");
  const actions = {
    allSales: printSalesPerType,
    salePerComposition: printSalesPerComposition,
    client: printSalesPerClient,
    employee: printSalesPerEmployee,
    allBills: printBillsPerType,
    safes: printSafes,
    notes: printNotes,
    nfse: printNfse,
    os: printNfse,
    purchase: printPurchase,
    purchaseProducts: printProductsPurchase,
    voucher: printVoucher
  };

  if (actions[name]) {
    actions[name](token, name, type, startDate, endDate, isSimple);
  } else {
    console.log("invalid name");
  }
}

function printSalesPerType(token, name, type, startDate, endDate) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales/gerar/relatorio/geral/todas/vendas/por/periodo/group/A4/?auth=${token}&name=${name}&type=${type}&startDate=${startDate}&endDate=${endDate}`
  );
}
function printSalesPerComposition(token, name, type, startDate, endDate) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales/gerar/relatorio/geral/todas/vendas/por/periodo/group/A4/?auth=${token}&name=${name}&type=${type}&startDate=${startDate}&endDate=${endDate}`
  );
}
function printSalesPerClient(token, name, type, startDate, endDate) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales/gerar/relatorio/geral/todas/vendas/por/periodo/group/A4/?auth=${token}&name=${name}&type=${type}&startDate=${startDate}&endDate=${endDate}`
  );
}
function printSalesPerEmployee(token, name, type, startDate, endDate, isSimple) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales/gerar/relatorio/geral/todas/vendas/por/periodo/group/A4/?auth=${token}&name=${name}&type=${type}&startDate=${startDate}&endDate=${endDate}&isSimple=${isSimple}`
  );
}
function printBillsPerType(token, name, type, startDate, endDate) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/bills/print/report/find/all/account/or/find/per/type/bill/?auth=${token}&name=${name}&type=${type}&startDate=${startDate}&endDate=${endDate}`
  );
}
function printSafes(token, name, type, startDate, endDate) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/safe/print/report/find/all/safes/?auth=${token}&name=${name}&type=${type}&startDate=${startDate}&endDate=${endDate}`
  );
}
function printNotes(token, name, type, startDate, endDate) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/nota/print/report/all/notes/or/per/model/?auth=${token}&name=${name}&type=${type}&startDate=${startDate}&endDate=${endDate}`
  );
}
function printNfse(token, name, startDate, endDate) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/nfse/print/report/find/all/nfse/?auth=${token}&model=${name}&startDate=${startDate}&endDate=${endDate}`
  );
}
function printPurchase(token, name, startDate, endDate) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/purchase/print/report/find/all/purchase/?auth=${token}&name=${name}&startDate=${startDate}&endDate=${endDate}`
  );
}
function printProductsPurchase(token, name, startDate, endDate) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/purchase/print/report/find/all/products/purchase/?auth=${token}&name=${name}&startDate=${startDate}&endDate=${endDate}`
  );
}
function printVoucher(token, name, startDate, endDate) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/voucher/print/report/find/all/?auth=${token}&name=${name}&startDate=${startDate}&endDate=${endDate}`
  );
}