import api from "./axios";

class serviceAccountingMessages {
  async save(accountingMessage) {
    const { data } = await api.post('/accounting-message', accountingMessage)
    return data
  }

  async update(accountingMessage, id) {
    await api.put(`/accounting-message/${id}`, accountingMessage)
  }

  async findById(id) {
    const { data } = await api.get(`/accounting-message/${id}`)
    return data
  }

  async verifyExistsNewMessages() {
    const { data } = await api.get("/accounting-message/notification/messages")
    return data
  }

  async findNumberOfFilesById(id) {
    const { data } = await api.get(`/accounting-message/files/count/${id}`)
    return data
  }

  async findByIdSolicitation(id) {
    const { data } = await api.get(`/accounting-message/find/by/solicitation/${id}`)
    return data
  }

  async listMessageFilesById(id) {
    const { data } = await api.get(`/accounting-message/find/list/message/files/${id}`)
    return data
  }

  async delete(id) {
    const { data } = await api.delete(`/accounting-message/${id}`)
    return data
  }
}

export default new serviceAccountingMessages()