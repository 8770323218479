<template>
    <Purchase />
</template>

<script>
import Purchase from "../../components/Purchase/Index-Purchase.vue";
export default {
  components: {
    Purchase,
  },
};
</script>