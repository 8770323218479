<template>
  <GeneralSalesScreen />
</template>

<script>
import GeneralSalesScreen from "./GeneralSalesScreen.vue";
export default {
  components: {
    GeneralSalesScreen,
  },
};
</script>