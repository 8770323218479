export default function printReportRegisters(name) {
  const token = localStorage.getItem("token");

  if (name === "Clientes") {
    reportCustomers(token)
  }
  if (name === "Colaborador") {
    reportEmployees(token)
  }
  if (name === "Fornecedores") {
    reportSupplier(token)
  }
  if (name === "Produtos") {
    reportProdutcs(token)
  }
}

function reportCustomers(token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/customers/gerar/relatorio/geral/?auth=${token}`
  );
}
function reportEmployees(token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/employees/report/all/employee/print/?auth=${token}`
  );
}
function reportSupplier(token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/providers/print/report/all/?auth=${token}`
  );
}
function reportProdutcs(token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/products/print/report/all/a4/?auth=${token}`
  );
}
