export default {
  OPEN_OR_CLOSE_BIG_BOX({commit, state}) {
    commit('SET_COLAPSE_BIG_BOX', !state.colapseBigBox)

    state.colapseBigBox
      ? commit('ALTER_SIZE_MODAL_BIG_BOX', 'ModalGeneral')
      : commit('ALTER_SIZE_MODAL_BIG_BOX', 'disable')

      state.modalContainerBigBox
      ? commit('ALTER_MODAL_CONTAINER_BIG_BOX', 'modalContainerBigBox')
      : commit('ALTER_MODAL_CONTAINER_BIG_BOX', 'contentModalDisable');
  },

  registerWidth({ commit }, widthCards) {
    return commit("REGISTER_WIDTH", widthCards);
  },

  uuidCompany({ commit }, dataCompany) {
    return commit("DATA_COMPANY", dataCompany)
  },
};
