<template>
  <Budget />
</template>

<script>
import Budget from "../../components/Budget/Index-Budget.vue";
export default {
  components: {
    Budget,
  },
};
</script>