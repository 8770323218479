import api from "../../services/axios";
import toastAlertErros from "../../utils/toastAlertErros";

const searchCustomer = {
  data() {
    return {
      customers: [],
      textPesquisa: "",
      tabIndex: 0,
      dataLength: 0,
      page: 1,
      type: "",
      boolClear: false,
      spinGeneral: false,
      filterCombobox: [
        { value: "nome", text: "Nome" },
        { value: "cpfCnpj", text: "CFP / CNPJ" },
        { value: "celular", text: "Contato" },
        { value: "", text: "Selecione..." },
      ],
    };
  },
  methods: {
    nextPage() {
      this.readCustomers((this.page += 1));
    },

    previousPage() {
      if (this.page === 1) {
        return;
      } else {
        this.readCustomers((this.page -= 1));
      }
    },

    async readCustomers(page) {
      try {
        this.spinGeneral = true;
        const { data } = await api.get(
          `/customers?q=${this.textPesquisa}&type=${this.type}&page=${page}`
        );
        this.dataLength = data.data.length;
        this.customers = data.data;
      } catch (error) {
        this.$toast.open({
          message: "Não foi possível listar os clientes",
          type: "info",
        });
      } finally {
        this.spinGeneral = false;
      }
      if (this.dataLength < 9) {
        this.clearSearch();
      }
    },

    clearSearch() {
      this.textPesquisa = "";
      this.type = "";
    },

    async editCustomer(customer) {
      this.$emit("readOrEditCustomers", customer);
      this.$root.$emit("bv::toggle::collapse", "accordion-dadosCadastrais");
      this.$emit("alterTabIndex", this.tabIndex);
    },
    async destroyCustomer(idCustomer) {
      try {
        await api.delete(`/customers/${idCustomer}`);
        this.readCustomers(this.page);
        this.$emit("clearAll", this.boolClear);
        this.boolClear = !this.boolClear;
        return this.$toast.open({
          message: "Cliente excluido com sucesso",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          toastAlertErros.validateMessage(error, this.$toast);
      }
    },
  },
  created() {
    this.readCustomers(1)
  }
}

export default searchCustomer