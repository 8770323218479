<template>
  <div class="containerGeral">
    <div class="rowContainer">
      <div class="contentRows">
        <span class="mb-3"> <b-icon-building /> Registrar Empresa</span>
        <div class="rowContent">
          <span class="contentInput">
            <Input
              label="Email"
              type="text"
              :labelFixed="true"
              :dataInput.sync="dataLogin.email"
              ref="email"
            />
          </span>
        </div>

        <div class="rowContent">
          <span class="contentInput">
            <InputPassword
              label="Senha"
              type="text"
              :labelFixed="true"
              :dataInput.sync="dataLogin.password"
              ref="password"
            />
          </span>
        </div>
      </div>

      <div class="rowContent">
        <span class="contentBtnRegister">
          <button
            size="sm"
            class="btnGeneral btnRegister"
            variant="none"
            @click="joinMoreCompanies"
          >
            <p>Registrar</p>
          </button>
        </span>
      </div>
    </div>

    <div class="contentAlert">
      <p>
        * Ao efetuar o login em outra empresa, você podera ver informações da
        mesma em até 7 dias!
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Input from "../UI/CustomInputs/Input.vue";
import InputPassword from "../UI/CustomInputs/InputPassword.vue";
import ServiceLogin from "../../services/serviceLogin";

export default {
  components: {
    Input,
    InputPassword,
  },
  data() {
    return {
      dataLogin: {
        email: null,
        password: null,
      },
      tokenList: [],
    };
  },
  methods: {
    async joinMoreCompanies() {
      try {
        const result = await ServiceLogin.joinMoreCompanies(this.dataLogin);
        const userToken = localStorage.getItem("token");
        const userLevel = localStorage.getItem("userLevel");
        const userPermissions = JSON.parse(localStorage.getItem("permissions"));
        const user = Array({
          token: userToken,
          permissions: userPermissions,
          userLevel: userLevel,
          nameCompany: this.dataCompany.nome,
        });

        user.push({ ...result });
        this.tokenList = user;
        localStorage.setItem("tokenList", JSON.stringify(this.tokenList));
        window.location.reload();
      } catch (error) {
        return this.$toast.open({
          message: `${
            error.response.data.message ||
            "Ocorreu um erro ao registrar novas empresas"
          }`,
          type: "info",
        });
      }
    },
  },
  computed: {
    ...mapState(["dataCompany"]),
  },
};
</script>

<style scoped>
.containerGeral {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.rowContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  gap: 30px;
  flex-direction: column;
  padding: 0;
  background: #f9f9f94d;
}

.rowContainer span {
  font-size: 14px;
}

.contentRows {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 10px 5px 10px;
  gap: 30px;
}

.rowContent {
  display: flex;
  width: 100%;
  gap: 15px;
}

.contentBtnRegister {
  width: 100%;
}

.contentInput {
  width: 100%;
}

.btnGeneral {
  font-size: 14px;
  font-weight: normal;
  border: none;
  outline: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: white;
  transition: ease-in all 0.2s;
  cursor: pointer;
}

.btnRegister {
  background-color: rgb(72, 184, 101);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.179) !important;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

.btnRegister p {
  margin: 0;
}

.btnRegister:hover {
  background-color: rgb(59, 149, 83);
  color: white;
}

.contentAlert p {
  font-size: 12px;
  margin: 0;
  color: rgb(62, 102, 174);
}
</style>