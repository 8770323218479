import { mapMutations, mapState } from "vuex";
import api from "../../services/axios";

import ServiceCustomer from "../../services/serviceCustomer"
import toastAlertErros from "../../utils/toastAlertErros";
import { HandleErrors } from "../../utils/handleErrors";
import { handleCpfOrCnpj } from "../../utils/validateCpfCnpj";
import { ufs } from "../../common";

import whatsapp from "../../assets/whatsapp.svg";

const mixinCustomer = {
  props: {
    readOrEditCustomers: {
      type: Object,
    },
    clearAll: {
      type: Boolean,
    },
    idCustomerFromSearch: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      whatsapp: whatsapp,
      dataCostumer: {
        id: "",
        nome: "",
        cpfCnpj: "",
        ie: "",
        rg: "",
        endereco: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        uf: "",
        cep: "",
        telefone: "",
        celular: "",
        email: "",
        dataNascimento: "",
        observacao: "",
        consumidor_final: "1",
        contribuinte: "9",
        status: "Ativo",
      },
      spinGeneralCnpj: false,
      consumidorFinal: [
        { value: "0", text: "0 - Normal" },
        { value: "1", text: "1 - Consumidor final" },
      ],
      status: [
        { value: "Ativo", text: "Ativo" },
        { value: "Inativo", text: "Inativo" },
      ],
      contribuinte: [
        { value: "1", text: "1 - Contribuinte ICMS" },
        {
          value: "2",
          text: "2 - Contribuinte isento de Inscrição no cadastro de Contribuintes do ICMS",
        },
        {
          value: "9",
          text: "9 - Não Contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro de Contribuintes do ICMS.",
        },
      ],
      spinGeneral: false,
      ufs: ufs
    };
  },

  methods: {
    ...mapMutations([
      "SET_IS_SEARCH_REGISTERS",
      "ALTER_SIZE_MODAL_REGISTER",
      "ALTER_TYPE_REGISTER",
      "SET_COLAPSE_REGISTER",
      "ALTER_MODAL_CONTAINER_REGISTER",
      "SET_DATA_SEARCH_FROM_CUSTOMER",
    ]),
    clearInputs() {
      this.dataCostumer.id = "";
      this.dataCostumer.nome = "";
      this.dataCostumer.cpfCnpj = "";
      this.dataCostumer.rg = "";
      this.dataCostumer.endereco = "";
      this.dataCostumer.numero = "";
      this.dataCostumer.complemento = "";
      this.dataCostumer.bairro = "";
      this.dataCostumer.cidade = "";
      this.dataCostumer.uf = "";
      this.dataCostumer.cep = "";
      this.dataCostumer.email = "";
      this.dataCostumer.telefone = "";
      this.dataCostumer.celular = "";
      this.dataCostumer.dataNascimento = "";
      this.dataCostumer.observacao = "";
      this.dataCostumer.ie = "";
      this.dataCostumer.contribuinte = "9";
      this.dataCostumer.consumidor_final = "0";

      this.SET_DATA_SEARCH_FROM_CUSTOMER({})
    },

    saveOrUpdateCustomer() {
      if (this.dataCostumer.id === "") return this.saveCustomer();

      this.updateCustomer();
    },

    async saveCustomer() {
      try {
        const result = handleCpfOrCnpj(this.dataCostumer.cpfCnpj, this.$toast)
        if (result && !result.isValid) {
          return result.alert()
        }

        const { data } = await api.post("/customers", this.dataCostumer);
        this.dataCostumer.id = data.id;

        this.$emit("getAllCustomer");
        this.SET_IS_SEARCH_REGISTERS();
        this.clearInputs();
        return this.$toast.open({
          message: "Cliente salvo com Sucesso",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message

        const typeError = HandleErrors(message);
        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        if (message && message.includes('NOT_ALLOWED'))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          toastAlertErros.validateErroDoesNotContainFor(
            error,
            this.$toast
          );
      }
    },
    async updateCustomer() {
      try {
        const result = handleCpfOrCnpj(this.dataCostumer.cpfCnpj, this.$toast)
        if (result && !result.isValid) {
          return result.alert()
        }

        await api.put("/customers/" + this.dataCostumer.id, this.dataCostumer);

        this.$emit("getAllCustomer");
        this.SET_IS_SEARCH_REGISTERS();
        this.clearInputs();
        this.$toast.open({
          message: "Cliente atualizado com Sucesso",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message

        const typeError = HandleErrors(message);
        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        if (message && message.includes('NOT_ALLOWED'))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          toastAlertErros.validateErroDoesNotContainFor(
            error,
            this.$toast
          );
      }
    },

    async fetchCnpj(cnpj) {
      try {
        this.spinGeneralCnpj = true;
        const formatedCnpj = cnpj.replace(/\D/g, "");
        const data = await api.get(
          `https://brasilapi.com.br/api/cnpj/v1/${formatedCnpj}`
        );

        if (data.data.nome_fantasia) {
          this.dataCostumer.nome = data.data.nome_fantasia;
        } else {
          this.dataCostumer.nome = data.data.razao_social;
        }
        this.dataCostumer.endereco = data.data.logradouro;
        this.dataCostumer.numero = data.data.numero;
        this.dataCostumer.bairro = data.data.bairro;
        this.dataCostumer.cidade = data.data.municipio;
        this.dataCostumer.uf = data.data.uf;
        this.dataCostumer.telefone = data.data.ddd_telefone_1;
        this.dataCostumer.cep = data.data.cep;
      } catch (error) {
        this.$toast.open({
          message:
            "Ocorreu um erro ao buscar as informações , verifique o CNPJ e tente novamente",
          type: "info",
        });
      } finally {
        this.spinGeneralCnpj = false;
      }
    },

    async findById(idCustomer) {
      const customer = await ServiceCustomer.findById(idCustomer)
      return customer
    },


    async handleModalCreateCustomer() {
      const customer = await this.findById(this.idCustomerFromSearch)
      this.SET_DATA_SEARCH_FROM_CUSTOMER(customer)
      this.assingEditCustomer()
    },

    assingEditCustomer() {
      this.dataCostumer.id = this.dataSearchCustomer.id;
      this.dataCostumer.nome = this.dataSearchCustomer.nome;
      this.dataCostumer.cpfCnpj = this.dataSearchCustomer.cpfCnpj;
      this.dataCostumer.rg = this.dataSearchCustomer.rg;
      this.dataCostumer.ie = this.dataSearchCustomer.ie;
      this.dataCostumer.endereco = this.dataSearchCustomer.endereco;
      this.dataCostumer.numero = this.dataSearchCustomer.numero;
      this.dataCostumer.complemento = this.dataSearchCustomer.complemento;
      this.dataCostumer.bairro = this.dataSearchCustomer.bairro;
      this.dataCostumer.cidade = this.dataSearchCustomer.cidade;
      this.dataCostumer.uf = this.dataSearchCustomer.uf;
      this.dataCostumer.cep = this.dataSearchCustomer.cep;
      this.dataCostumer.telefone = this.dataSearchCustomer.telefone;
      this.dataCostumer.celular = this.dataSearchCustomer.celular;
      this.dataCostumer.email = this.dataSearchCustomer.email;
      this.dataCostumer.dataNascimento = this.dataSearchCustomer.dataNascimento;
      this.dataCostumer.consumidor_final = this.dataSearchCustomer.consumidor_final;
      this.dataCostumer.contribuinte = this.dataSearchCustomer.contribuinte;
      this.dataCostumer.observacao = this.dataSearchCustomer.observacao;
      this.dataCostumer.status = this.dataSearchCustomer.status;
    },

    async fetchCep(cep) {
      try {
        this.spinGeneral = true;
        const formatedCep = cep.replace(/\D/g, "");
        const data = await api.get(`https://brasilapi.com.br/api/cep/v1/${formatedCep}`);
        this.dataCostumer.endereco = data.data.street;
        this.dataCostumer.bairro = data.data.neighborhood
        this.dataCostumer.cidade = data.data.city
        this.dataCostumer.uf = data.data.state
        this.dataCostumer.cep = data.data.cep

      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao buscar as informações , verifique o CEP e tente novamente",
          type: "info",
        });
      } finally {
        this.spinGeneral = false;
      }
    },
    checkInputLength() {
      if (this.dataCostumer.cpfCnpj.length === 18)
        this.$forceUpdate();
    },
  },
  watch: {
    readOrEditCustomers() {
      Object.assign(this.dataCostumer, this.readOrEditCustomers);
    },
    clearAll() {
      this.clearInputs();
    },
  },

  mounted() {
    if (this.dataSearchCustomer.id) {
      this.assingEditCustomer();
    } else if (this.idCustomerFromSearch) {
      this.handleModalCreateCustomer()
    }
  },
  computed: {
    ...mapState({
      dataSearchCustomer: (state) => state.dataSearchCustomer,
    }),
    maskCpfCnpj() {
      if (this.dataCostumer.cpfCnpj === null) {
        return "";
      }
      if (this.dataCostumer.cpfCnpj.length <= 14) {
        return "###.###.###-##";
      }
      if (this.dataCostumer.cpfCnpj.length > 14) {
        return "##.###.###/####-##";
      } else {
        return "";
      }
    },
    maskCelular() {
      return "(##) #####-####";
    },
    maskTelefone() {
      return "(##) ####-####";
    },
    maskCep() {
      return "#####-###";
    },
    isInputLengthValid() {
      if (this.dataCostumer.cpfCnpj)
        return this.dataCostumer.cpfCnpj.length === 18;
    }
  },
  beforeDestroy() {
    this.clearInputs();
  }
}

export default mixinCustomer