export const INITIAL_STATE_DATA_REF = {
  id: "",
  descricao: "",
  ref: "",
  refObject: {
    codigo_servico: "",
    natureza_operacao: "",
    exigibilidade_iss: "",
    iss_retido: "",
    responsavel_retencao:"",
    codigo_cnae: "",
    codigo_tributacao_municipio: "",
    tipo: "nfse",
    iss: 0,
    pis: 0,
    cofins: 0,
    inss: 0,
    ir: 0,
    csll: 0,
    deducao: "",
  }
}