<template>
  <div><PointControl /></div>
</template>

<script>
import PointControl from "../../components/PointControl/PointControl.vue";
export default {
  components: {
    PointControl,
  },
};
</script>

<style>
</style>