export const INITIAL_STATE_PRODUCTS = {
  id: "",
  idVendaMtq: "",
  idFornecedor: "",
  idProduto: "",
  quantidade: 1,
  altura: 0,
  largura: 0,
  metragem: 0,
  valorUnitario: 0,
  valorTotal: 0,
  desconto: 0,
  dadosAdicionais: "",
  porcentagemPerda: 0,
  idCor: "",
  idAmbiente: "",
  linear: false
}