import api from "./axios";

class ServiceNcms {
  async save(ncm) {
    const { data } = await api.post("/ncm", ncm)
    return data.id;
  }

  async update(ncm) {
    await api.put(`/ncm/${ncm.id}`, ncm);
  }

  async delete(id) {
    await api.delete(`/ncm/${id}`);
  }

  async findById(id) {
    const { data } = await api.get(`/ncm/${id}`);
    return data;
  }

  async fillComobobox() {
    const { data } = await api.get(`/ncm/combobox`);
    return data;
  }
}

export default new ServiceNcms()