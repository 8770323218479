<template>
  <div class="containerGeralVendaMtq">
    <div>
      <b-row
        class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="align-items: center; justify-content: space-between"
      >
        <div class="titleHeader">
          <p class="title">
            {{
              !budgetToSale &&
              statusSquareMeter === salesSquareMeterType.orcamento
                ? "NOVO ORÇAMENTO MT2 Nº"
                : "NOVA VENDA MT2 Nº"
            }}
            {{ numberSaleSquareMeter || saleSquareMeterFromModal.number }}
          </p>
        </div>

        <div
          style="
            margin-right: 16px;
            display: flex;
            align-items: center;
            gap: 10px;
          "
        >
          <label
            :style="`color: ${alterColorSituation}`"
            class="title m-0 p-0"
            >{{ getNameSituantionById(saleSquareMeter.idSituacao) }}</label
          >
        </div>
      </b-row>

      <b-row
        :class="`${
          statusSquareMeter === salesSquareMeterType.orcamento &&
          'rowResponsive'
        } mt-3 col-sm-12 col-md-12 col-lg-12 col-xl-12`"
      >
        <div
          :class="`${
            statusSquareMeter === salesSquareMeterType.orcamento
              ? 'inputResponsive col-sm-2 col-md-2 col-lg-2 col-xl-2'
              : 'col-sm-6 col-md-6 col-lg-2 col-xl-2'
          }`"
        >
          <Input
            ref="data"
            label="Data"
            type="date"
            :dataInput.sync="saleSquareMeter.data"
            :labelFixed="true"
          />
        </div>

        <div
          v-if="statusSquareMeter === salesSquareMeterType.orcamento"
          class="inputResponsive col-sm-2 col-md-2 col-lg-2 col-xl-2"
        >
          <Input
            ref="dataVencimento"
            label="Data Vencimento"
            :labelFixed="true"
            type="date"
            :dataInput.sync="saleSquareMeter.dataVencimento"
          />
        </div>

        <div
          :class="`${
            statusSquareMeter === salesSquareMeterType.orcamento
              ? 'inputResponsive col-sm-7 col-md-7 col-lg-6 col-xl-5'
              : 'col-sm-6 col-md-6 col-lg-5 col-xl-6'
          }`"
          size="sm"
        >
          <InputInfinit
            label="Cliente"
            :isEdit="saleSquareMeter.idCliente"
            :dataInput.sync="saleSquareMeter.idCliente"
            :onSearch="fillCustomerComboBoxWithPaginate"
            :openCreateModal="() => openModals('modalCreateCustomer')"
            :findAll="createdNewCustomer"
            @selectItem="handleDataCustomers($event)"
          />
        </div>

        <div
          :class="`${
            statusSquareMeter === salesSquareMeterType.orcamento
              ? 'inputResponsive col-sm-4 col-md-4 col-lg-3 col-xl-3'
              : 'col-sm-6 col-md-6 col-lg-4 col-xl-4'
          } `"
          size="sm"
        >
          <InputSelect
            label="Funcionário"
            ref="refResponsavel"
            :items="dataEmployee"
            valueField="id"
            textField="nomeFuncionario"
            :dataSelect.sync="saleSquareMeter.idFuncionario"
            :openCreateModal="() => openModals('modalCreateEmployee')"
          />
        </div>
      </b-row>
    </div>

    <hr />

    <div class="contentItens">
      <div style="border-radius: 5px">
        <!-- <b-row class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div
            class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-end"
          > -->
        <button
          class="btnsSaleSquareMeter btnAddMoreItems"
          @click="handleAddItensAndHandleAssignValues"
        >
          <b-icon-plus scale="2" />
          Adicionar mais itens
        </button>
        <!-- </div>
        </b-row> -->

        <div class="responsiveItems" ref="scrollableDiv">
          <div
            class="itemsSaleSquareMeter"
            v-for="(prod, indice) in saleSquareMeter.products"
            :key="prod.idList"
          >
            <div size="sm">
              <InputSelect
                label="Itens"
                ref="refItens"
                :items="products"
                valueField="id"
                textField="nome"
                :dataSelect.sync="saleSquareMeter.products[indice].idProduto"
                :openCreateModal="
                  () =>
                    handleModalCreateProduct(
                      'modalCreateProduct',
                      saleSquareMeter.products[indice].idProduto
                    )
                "
                @selectedIten="selectProductSetDefaultValue($event, indice)"
              />
            </div>

            <div
              class="radio-input"
              v-if="saleSquareMeter.products[indice].idProduto"
            >
              <label>
                <input
                  type="radio"
                  :name="'value-radio-' + indice"
                  value="P1"
                  v-model="saleSquareMeter.products[indice].tipoPreco"
                  @change="updateProductValue(indice, 'P1', prod)"
                />
                <span>VL . 1</span>
              </label>
              <label>
                <input
                  type="radio"
                  :name="'value-radio-' + indice"
                  value="P2"
                  v-model="saleSquareMeter.products[indice].tipoPreco"
                  @change="updateProductValue(indice, 'P2', prod)"
                />
                <span>VL . 2</span>
              </label>
              <label>
                <input
                  type="radio"
                  :name="'value-radio-' + indice"
                  value="P3"
                  v-model="saleSquareMeter.products[indice].tipoPreco"
                  @change="updateProductValue(indice, 'P3', prod)"
                />
                <span>VL . 3</span>
              </label>
              <span class="selection"></span>
            </div>

            <div class="customRow">
              <InputSelect
                label="Cores"
                :items="colors"
                valueField="id"
                textField="nome"
                :dataSelect.sync="saleSquareMeter.products[indice].idCor"
                :openCreateModal="() => openModals('modalColors')"
                ref="refColors"
              />

              <InputSelect
                label="Ambientes"
                :items="rooms"
                valueField="id"
                textField="nome"
                :dataSelect.sync="saleSquareMeter.products[indice].idAmbiente"
                :openCreateModal="() => openModals('modalRooms')"
                ref="refRooms"
              />
            </div>

            <div class="customRow">
              <div>
                <InputQuant
                  label="Qtd."
                  :labelFixed="true"
                  ref="refQtd"
                  type="text"
                  :dataInput.sync="saleSquareMeter.products[indice].quantidade"
                  @calculateTotal="calculateTotalValue = { prod, indice }"
                  :decimalPlaces="decimalPlaces"
                  isSelectAll
                />
              </div>

              <div>
                <InputMoney
                  label="Vl. Unit"
                  labelFixed
                  ref="refVlUnit"
                  type="text"
                  :dataInput.sync="
                    saleSquareMeter.products[indice].valorUnitario
                  "
                  @calculateTotal="calculateTotalValue = { prod, indice }"
                  isSelectAll
                />
              </div>

              <div>
                <InputMoney
                  v-if="!discountIsCheked"
                  label="Desc"
                  :labelFixed="true"
                  type="text"
                  :dataInput.sync="saleSquareMeter.products[indice].desconto"
                  @calculateTotal="calculateTotalValue = { prod, indice }"
                  ref="discount"
                  isSelectAll
                />

                <InputPercentage
                  v-else
                  label="Desc"
                  :labelFixed="true"
                  type="text"
                  :dataInput.sync="saleSquareMeter.products[indice].desconto"
                  @calculateTotal="calculateTotalValue = { prod, indice }"
                  ref="discount"
                  isSelectAll
                />
                <label
                  ref="hideDiscount"
                  style="
                    position: absolute;
                    left: -8px;
                    top: -31px;
                    height: 30px;
                  "
                  class="switch col-sm-2 col-md-2 col-lg-2 col-xl-2"
                >
                  <input
                    type="checkbox"
                    ref="typeDiscount"
                    @click="alterDiscountIsCheked"
                    @change="enableTooltipDiscount"
                    v-model="discountIsCheked"
                  />
                  <div class="slider">
                    <span>R$</span>
                    <span>%</span>
                  </div>
                </label>

                <div
                  style="display: none"
                  ref="enableLabelApplyAll"
                  class="tooltipDiscount"
                >
                  <div class="tooltipItems">
                    <div>
                      <label for="apllyAll">Aplicar em todos</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="apllyAll"
                        v-model="discountToAllItems"
                        @change="alterAllChecksDiscount"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="customRow">
              <div>
                <InputQuant
                  label="Altura"
                  :labelFixed="true"
                  ref="refAlt"
                  type="text"
                  :dataInput.sync="saleSquareMeter.products[indice].altura"
                  @calculateTotal="calculateTotalValue = { prod, indice }"
                  :decimalPlaces="decimalPlaces"
                  :isDisabled="enableEditMt2"
                  isSelectAll
                />
              </div>

              <div>
                <InputQuant
                  label="Largura"
                  :labelFixed="true"
                  ref="refLarg"
                  type="text"
                  :dataInput.sync="saleSquareMeter.products[indice].largura"
                  @calculateTotal="calculateTotalValue = { prod, indice }"
                  :decimalPlaces="decimalPlaces"
                  isSelectAll
                  :isDisabled="enableEditMt2"
                />
              </div>

              <div>
                <InputMoney
                  label="Vl. Total"
                  labelFixed
                  ref="valorTotal"
                  type="text"
                  isDisabled
                  :dataInput.sync="calculateTotalValue"
                  isSelectAll
                />
              </div>
            </div>

            <div class="customRow">
              <div>
                <InputPercentage
                  label="% Perda"
                  :labelFixed="true"
                  type="text"
                  :dataInput.sync="
                    saleSquareMeter.products[indice].porcentagemPerda
                  "
                  @calculateTotal="calculateTotalValue = { prod, indice }"
                  isSelectAll
                />
              </div>

              <div>
                <InputQuant
                  label="MT2 Total"
                  labelFixed
                  type="text"
                  :isDisabled="!enableEditMt2"
                  :dataInput.sync="saleSquareMeter.products[indice].metragem"
                  ref="totalMeter"
                  @calculateTotal="calculateTotalValue = { prod, indice }"
                  :decimalPlaces="decimalPlaces"
                  isSelectAll
                />
              </div>
            </div>

            <div class="customRow">
              <Input
                label="Observação"
                :labelFixed="true"
                type="text"
                :dataInput.sync="
                  saleSquareMeter.products[indice].dadosAdicionais
                "
              />
            </div>

            <div
              class="contentIconRemoveItems"
              @click="handleRemoveItens(prod.idList, prod.id)"
            >
              <div>
                <b-icon-x-circle-fill role="button" scale="1.7" />
              </div>
            </div>

            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                height: 30px;
              "
            >
              <div style="display: flex; align-items: center; gap: 0.3rem">
                <label
                  style="margin: 0; cursor: pointer"
                  :for="`${indice}_IsMTL`"
                  >Metro Linear</label
                >
                <input
                  type="checkbox"
                  :id="`${indice}_IsMTL`"
                  v-model="saleSquareMeter.products[indice].linear"
                  @change="calculateTotalValue = { prod, indice }"
                />
              </div>

              <div
                v-if="prod.idProduto"
                style="display: flex; align-items: center; gap: 0.3rem"
              >
                <b-icon-back
                  v-if="!selectedItem.id"
                  class="btnReplaceAll"
                  @click="handleDuplicate(prod)"
                  v-b-tooltip.hover.top="'Clonar!'"
                  ref="replaceAll"
                />

                <b-icon-bookmarks-fill
                  v-if="!selectedItem.id"
                  tag="span"
                  class="btnReplaceAll"
                  @click="handleItemSelected(prod.idProduto, indice)"
                  v-b-tooltip.hover.top="'Substituir todos!'"
                  ref="replaceAll"
                />

                <div
                  v-else-if="selectedItem.id && selectedItem.idx === indice"
                  style="display: flex; gap: 0.5rem"
                >
                  <b-icon-check-square-fill
                    @click="applyReplaceAll"
                    style="background-color: green"
                    class="btnReplaceAll"
                    v-b-tooltip.hover.top="'Confirmar Substituição!'"
                  />

                  <b-icon-x-square-fill
                    @click="cancelReplaceAll"
                    style="background-color: red"
                    class="btnReplaceAll"
                    v-b-tooltip.hover.top="'Cancelar Substituição!'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <b-row class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="contentDataTotals col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="contentTotals">
            <span class="inputTotals">
              <p>Total de Itens (R$)</p>
              <input disabled type="text" v-model="totalOfItens" />
            </span>

            <span class="inputTotals">
              <p>Desconto (R$)</p>
              <input disabled type="text" v-model="totalOfDiscount" />
            </span>

            <span class="dashed"></span>

            <span class="inputTotals contentFreteTotals">
              <p>Frete (R$)</p>
              <input
                style="color: #5c5855"
                type="text"
                :value="maskCurrency(saleSquareMeter.frete)"
                @input="handleFreteMonetaryValue($event)"
              />
            </span>

            <span class="inputTotals contentFreteTotals">
              <p>Outros (R$)</p>
              <input
                style="color: #5c5855"
                type="text"
                :value="maskCurrency(saleSquareMeter.outrosValores)"
                @input="handleOtherValuesMonetaryValue($event)"
              />
            </span>

            <span class="inputTotals">
              <p v-if="saleSquareMeter.status === salesSquareMeterType.venda">
                Total da Venda (R$)
              </p>
              <p v-else>Total do Orçamento (R$)</p>
              <input disabled type="text" v-model="totalValue" />
            </span>
          </div>
        </div>
      </b-row>

      <hr v-if="saleSquareMeter.status === salesSquareMeterType.venda" />

      <div
        class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        v-if="saleSquareMeter.status === salesSquareMeterType.venda"
      >
        <b-row
          class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
          style="align-items: center"
        >
          <div class="titleBillsPayments">
            <p class="title">Informações de Pagamento</p>
          </div>
        </b-row>

        <b-row class="contentBills mt-3" size="sm">
          <div class="selectPlus col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <label class="fixedSelect">Forma de pagamento</label>
            <b-form-select
              value-field="id"
              text-field="tipo"
              class="borderInput shadow-none"
              :options="allPaymentsTypes"
              v-model="dataBillSquareMeter.idFormaPagamento"
              size="sm"
              @change="selectPayment"
            >
            </b-form-select>
          </div>

          <div class="receiptAccount col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <InputSelect
              label="Conta de recebimento"
              valueField="id"
              textField="nome"
              :isDisabled="blocked"
              :items="allAccountReceiptBills"
              :dataSelect.sync="dataBillSquareMeter.idContaRecebimento"
              :openCreateModal="() => openModals('modalContaRecebimento')"
              ref="refContaDeRecebimento"
            />
          </div>

          <div class="paymentInterval col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <Input
              label="Interval. de pagamento"
              :labelFixed="true"
              type="text"
              :dataInput.sync="intervaloDias"
              ref="refIntervaloDePagamento"
              :isDisabled="paymentMoneyIsSelected"
            />
          </div>

          <div class="fistBill col-sm-6 col-md-3 col-lg-2 col-xl-2">
            <Input
              label="Venc. 1º parcela"
              :labelFixed="true"
              type="date"
              :dataInput.sync="dataBillSquareMeter.data"
              ref="refVencimentoPrimeiraParcela"
              :isDisabled="paymentMoneyIsSelected"
            />
          </div>

          <div class="amountBills col-sm-6 col-md-3 col-lg-2 col-xl-1">
            <Input
              label="Parcelas"
              :labelFixed="true"
              type="text"
              :dataInput.sync="totalParcelas"
              ref="refTotalParcelas"
              :isDisabled="paymentMoneyIsSelected"
            />
          </div>
        </b-row>

        <b-row class="contentBills">
          <div
            class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-end"
          >
            <button
              class="btnsSaleSquareMeter btnGenerateBills"
              @click="handleGenerateBillsAndOpenModalBills"
            >
              {{ verifyIfBillIsRegistred }}
            </button>
          </div>
        </b-row>
      </div>

      <hr />

      <b-row class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div style="display: flex; gap: 3px; align-items: center">
            <p class="obsGeneral">Observações Gerais</p>

            <p class="observacoes">
              (Insira aqui observações que serão importantes para você)
            </p>
          </div>

          <textarea
            class="inputTextArea"
            rows="3"
            max-rows="6"
            v-model="saleSquareMeter.dadosAdicionais"
          />
        </div>

        <div class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div style="display: flex; gap: 3px; align-items: center">
            <p class="obsGeneral">Observações de Nota Fiscal</p>

            <p class="observacoes">
              (Insira aqui, observações que sairão na Nota Fiscal do cliente)
            </p>
          </div>

          <textarea
            class="inputTextArea"
            rows="3"
            max-rows="6"
            v-model="saleSquareMeter.observacoesNf"
          />
        </div>
      </b-row>

      <hr />

      <b-row class="mb-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div
          class="col-sm-12 col-md-12 col-lg-12 col-xl-12 contentButtonsActions"
        >
          <div>
            <button
              v-if="
                saleSquareMeter.id &&
                saleSquareMeter.idSituacao !==
                  getSpecificSituantionId(squareMeterSituationType.cancelado)
              "
              class="btnsSaleSquareMeter btnCancelSaleSquareMeter"
              @click="cancelSaleSquareMeter"
            >
              Cancelar {{ showTextSaleOrBudget }}
            </button>

            <button
              class="btnsSaleSquareMeter btnReopenSaleSquareMeter"
              v-if="
                saleSquareMeter.id &&
                saleSquareMeter.idSituacao ===
                  getSpecificSituantionId(squareMeterSituationType.cancelado)
              "
              @click="reOpen"
            >
              Reabrir {{ showTextSaleOrBudget }}
            </button>
          </div>

          <div style="display: flex; gap: 10px">
            <div>
              <button
                class="btnsSaleSquareMeter btnConfirm"
                @click="handleDataToSendModalSquareMeter"
              >
                Finalizar
              </button>
            </div>
          </div>
        </div>
      </b-row>
    </div>

    <ModalCreateAccountReceiptBill
      @getNewReceiptBill="createdNewAccountReceiptBill = $event"
    />
    <ModalBillsSquareMeter
      :saleIsCanceled="saleIsCanceled"
      :amountBills="billsFromModal"
      :totalValue="totalValue"
      :paymentSelected="paymentSelected"
      :receiptBillSelected="receiptBillSelected"
      :rangeOfDays="intervaloDias"
      :verifyBillIsPaid="verifyBillIsPaid"
      @isDropBills="isDropBills = $event"
      @confirmedBills="saleSquareMeter.bills = $event"
      @clearBillsDataAfterRemove="isRemovedBills = $event"
    />
    <ModalSaleSquareMeter
      :allSituations="allSituations"
      :paymentsDataInfo="paymentsDataInfo"
      :dataSaleSquareMeter="saleSquareMeter"
      :squareMeterForModal="squareMeterForModal"
      :totals="objectTotal"
      @isPaydBill="isPaydBill = $event"
      @saleSquareMeterSaved="saleSquareMeterFromModal = $event"
      @transformToSale="handleTransformToSale"
    />
    <ModalCreateCustomer
      @getAllNewCustomer="handleCreatedNewCustomer"
      :idCustomerFromSearch="saleSquareMeter.idCliente"
    />
    <ModalCreateEmployees @getAllNewEmployee="createdNewEmployee = $event" />
    <ModalCreateProducts
      @getAllNewProduct="createdNewProducts = $event"
      :idProductFromSearch="idProductFromSearch"
    />
    <ModalCreateColors @getNewColors="getAllColors" />
    <ModalCreateRooms @getNewRooms="getAllRooms" />
  </div>
</template>

<script>
import { mixinSalePerMeterSquare } from "../../mixins/SalePerMeterSquare/SalePerMeterSquare";

export default {
  mixins: [mixinSalePerMeterSquare],
};
</script>

<style scoped>
#dadosVendaStyle {
  border: none !important;
}

.cardDadosVendas {
  padding-bottom: 15px !important;
  border-radius: 5px !important;
  background-color: rgba(255, 255, 255, 0.788) !important;
  box-shadow: inset 0 0 0.5em rgb(255, 255, 255), 0 0 0.5em rgba(5, 5, 5, 0.171);
}

.tamanhoBotaoOpenCard {
  color: black;
  font-size: 16px;
  height: 30px;
  display: flex;
  align-items: center;
  /* color: white; */
}

.cardDadosVendasBorda {
  border-radius: 5px !important;
  margin-top: 25px !important;
  margin-bottom: 20px;
  /* background-color: #6a88bd !important; */
  /* color: white; */
}

.tamanhoCards {
  width: 100%;
  display: flex;
  /* background-color: #6a88bd !important; */
  /* box-shadow: 2px 2px 4px 0px black !important ; */
}

.cardDadosVendas {
  padding: 0px 16px 0px 16px !important;
}

.acoesBtns {
  padding: 40px 0 10px 40px;
  margin: 0;
  width: 100%;
  border: none;
}

.btnsGeral {
  display: flex;
  justify-content: space-around;
}

.containerAllCard {
  margin-top: 10px;
}

.containerCheckBox {
  display: flex;
  height: 32px !important;
  margin-top: 31px;
  align-items: center !important;
  background-color: #e9ecef !important;
  border: solid 1px rgb(216, 215, 215);
  border-radius: 5px;
  padding-left: 15px !important;
}

.chkVendaOrcamento {
  margin-top: 18px;
}

.radio-input input {
  display: none;
}

.radio-input {
  position: absolute;
  right: 100px;
  top: 0px;
  display: flex;
  --container_width: 130px;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  color: #000000;
  width: var(--container_width);
  overflow: hidden;
  border: 1px solid rgba(53, 52, 52, 0.226);
}

.radio-input label {
  width: 100%;
  padding: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-size: 11px;
  margin-bottom: 0px !important;
}

.selection {
  display: none;
  position: absolute;
  height: 100%;
  width: calc(var(--container_width) / 3);
  z-index: 0;
  left: 0;
  top: 0;
  transition: 0.15s ease;
}

.radio-input label:has(input:checked) {
  color: #fff;
}

.radio-input label:has(input:checked) ~ .selection {
  background-color: #3a4750;
  display: inline-block;
}

.radio-input label:nth-child(1):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 0 / 3));
}

.radio-input label:nth-child(2):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 1 / 3));
}

.radio-input label:nth-child(3):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 2 / 3));
}

.containerGeralVendaMtq {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 2rem;
  margin-left: 2rem;
}

.iconFormaPagamento {
  display: flex;
  justify-content: space-between;
}

.btnFormaPagamento {
  cursor: pointer;
}

p {
  margin: 0;
}

.titleHeader {
  margin-left: 16px;
}

.btnsSaleSquareMeter {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  height: 30px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;
}

.btnAddMoreItems {
  width: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem !important;
  position: absolute;
  top: -25px;
  right: 0.5rem;
  z-index: 99999;
  font-size: 10px;
}

.btnReplaceAll {
  border-radius: 5px;
  background-color: #00395d;
  border: none;
  color: white;
  font-size: 12px;
  width: 25px;
  height: 25px;
  padding: 0.2rem;
  cursor: pointer;
  transition: ease-in all 0.1s;
}

.btnReplaceAll:hover {
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.338);
  transition: ease-in all 0.1s;
}

.btnGenerateBills {
  background-color: transparent;
  border: solid 1px black;
  color: black;
}

.btnCancelSaleSquareMeter {
  background-color: #ff3131;
}

.btnReopenSaleSquareMeter {
  background-color: #cac64e;
}

.isDisabled {
  background-color: #e1e1e1;
}

.btnConfirm {
  background-color: whitesmoke;
  border: solid 1px #0fbf4d;
  color: #0fbf4d;
}

.inputDesc {
  width: 8.3% !important;
}

.labelInputs p {
  font-weight: 100 !important;
  color: #00395d;
  padding: 0;
}

.iconPlus {
  position: absolute;
  right: 20px;
  top: 0;
}

.switch {
  font-size: 14px;
  position: relative;
  display: inline-block;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #d9d9d9;
  color: #ffffff;
  border-radius: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.4s;
  width: 50px !important;
  height: 90%;
  font-size: 8px;
}

.slider:before {
  position: absolute;
  content: "R$";
  font-size: 10px;
  height: 82%;
  width: 44%;
  left: 6%;
  border-radius: 50px;
  background-color: #00aa62;
  color: white;
  display: grid;
  align-content: center;
  justify-content: center;
  transition: 0.4s;
  font-weight: bold;
}

.slider:after {
  content: "";
  position: absolute;
  z-index: -1;
}

.switch input:checked + .slider {
  color: white;
}

.switch input:checked + .slider:before {
  content: "%";
  background-color: #00aa62;
  transform: translateX(100%);
  color: white;
}

.switch input {
  display: none;
}

.contentDataTotals {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.contentFreteTotals input {
  background-color: white !important;
  border: solid 1px #d9d9d9;
}

.dashed {
  border-bottom: 1px dashed #b1aaa5;
  width: 100%;
  margin: 10px 0;
}

.inputTotals {
  display: flex;
  align-items: center;
  gap: 30px;
  color: #666360;
}

.inputTotals input {
  border: none;
  outline: none;
  text-align: end;
  padding-right: 5px;
  background-color: #d9d9d9;
  border-radius: 5px;
  height: 30px;

  border: solid 1px #d9d9d9;
}

.contentTotals {
  width: 400px;
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  font-size: 14px;
  gap: 5px;
}

.responsiveItems {
  display: flex;
  align-items: center;
  overflow-x: auto;
  height: 420px;
}

.itemsSaleSquareMeter {
  width: 320px;
  min-width: 320px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 16px;
  margin-right: 16px;
  padding: 2rem 1rem 0 1rem;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.241);
  border-radius: 5px;
  background-color: white;
  /* margin-top: 2rem; */
}

.customRow {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.observacoes {
  font-size: 12px;
  color: #d5d3d3;
  font-style: italic;
}

.title {
  font-size: 14px;
  color: #666360;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.contentIconRemoveItems {
  position: absolute;
  right: -8px;
  top: -12px;
  color: rgb(255, 51, 51);
}

.tooltipDiscount {
  z-index: 3;
  width: 200px;
  padding: 5px;
  font-size: 12px;
  position: absolute;
  color: black;
  border-radius: 5px;
  top: -31px;
  left: 30px;
}

.tooltipItems {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1300px) {
  .responsiveItems {
    max-width: 1336px;
    overflow-x: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tooltipDiscount {
    right: 100px;
  }
}

@media screen and (max-width: 1199px) {
  .amountBills {
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 991px) {
  .fistBill {
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 768px) {
  .paymentInterval {
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 575px) {
  .receiptAccount {
    margin-top: 1rem !important;
  }

  .btnAddMoreItems {
    left: 1rem;
  }
}

.contentItens {
  margin-top: 1.5rem !important;
  position: relative;
}

.contentButtonsActions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.titleBillsPayments {
  font-size: 20px;
}

.contentBills {
  margin-right: 16px;
}

.inputTextArea {
  width: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: white;
  color: #5c5855;
  border-radius: 5px;
  height: 60px;
  padding-left: 5px;
  border: solid 1px #d9d9d9;
  resize: none;
}

.inputSelectSituation {
  border: solid 1px #d5d3d3;
  padding: 3px;
  border-radius: 0.25rem;
  outline: none;
  color: #5c5855;
  font-size: 12px;
}

.obsGeneral {
  font-size: 14px;
  color: #666360;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.selectPlus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect {
  position: absolute;
  margin-bottom: 0px;
  bottom: 20px;
  left: 12px;
  z-index: 2;
  background-color: #ffff;
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  pointer-events: none;
  transform: translateY(-50%) scale(0.8);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  height: 12px;
  padding: 0 0.2em;
}

.custom-select:disabled {
  color: black;
  background-color: #d5d3d3;
}

.custom-select {
  background: none;
  text-transform: capitalize;
}

.borderInput {
  border-radius: 3px;
  height: 28px;
  font-size: 12px;
  padding: 0 10px;
  border: 1px solid #d5d3d3;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .containerGeralVendaMtq {
    margin-left: 0rem;
  }
  .responsiveItems {
    padding-top: 30px;
    padding-bottom: 10px;
    margin-top: 0rem !important;
  }
  .contentIconRemoveItems {
    right: 10px;
  }
  .contentItens {
    margin-top: 0rem !important;
  }
  .itemsSaleSquareMeter {
    margin-left: 0px;
    margin-right: 0px;
  }
  .btnsSaleSquareMeter {
    width: 140px;
    margin-top: 15px;
    font-size: 10px;
  }
  .contentTotals {
    font-size: 11px;
  }
  .col-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12,
  .col-xl-12 {
    padding-right: 0px;
    padding-left: 10px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
  }
  .col-md-6 {
    flex: 0 0 32%;
    max-width: 32%;
  }
  .col-md-5 {
    flex: 0 0 32%;
    max-width: 32%;
  }
}

@media (min-width: 576px) and (max-width: 1200px) {
  .col-sm-6 {
    flex: 0 0 32%;
    max-width: 32%;
  }
}

@media screen and (max-width: 700px) {
  .containerGeralVendaMtq {
    margin-left: 0rem;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 0px;
    padding-left: 15px;
  }

  .row {
    gap: 10px;
  }

  .responsiveItems {
    padding-top: 25px;
    padding-bottom: 5px;
    margin-top: 0rem !important;
  }

  .inputTotals {
    gap: 10px;
  }

  .obsGeneral {
    width: 80%;
  }

  .contentIconRemoveItems {
    right: -20px;
  }

  .switch {
    top: -35px;
    left: -5px !important;
  }
}
</style>
