<template>
  <div class="d-flex flex-column container-schedule">
    <Schedule style="margin-top:50px" />
  </div>
</template>
<script>
import Schedule from "./Schedule.vue";

export default {
  components: {
    Schedule,
  },
  data() {
    return {};
  },
};
</script>
 <style scoped>
.container-schedule {
  text-align: center;
}
</style>