export default function printReport(name, id) {
  const token = localStorage.getItem("token");

  const reports = {
    reportVendaOrcamento: () => reportVendaOrcamento(id, token),
    reportCoupon: () => reportCoupon(id, token),
    proofOfAcquittance: () => reportProofOfAcquittance(id, token),
    acquittance: () => printAcquittance(id, token),
    reportOrder: () => reportOrder(id, token),
    reportCoupon58mm: () => reportCoupon(id, token, true),
  }

  if (!reports[name]) {
    console.log('invalid name')
    return
  }

  reports[name]()
}

function reportVendaOrcamento(id, token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales/gerar/relatorio/${id}?auth=${token}`
  );
}
function reportOrder(id, token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/nfse/gerar/relatorio/${id}?auth=${token}`
  );
}
function reportCoupon(id, token, is58mm = false) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales/gerar/relatorio/coupon/venda/${id}?auth=${token}&is58mm=${is58mm}`
  );
}
function reportProofOfAcquittance(id, token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/bills/generate/print/proof/of/acquittance/A4/${id}?auth=${token}`
  );
}
function printAcquittance(id, token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/safe/look/for/print/transaction/receipt/cashier/${id}?auth=${token}`
  );
}