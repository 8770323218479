<template>
  <FiscalIssuance />
</template>

<script>
import FiscalIssuance from "../../components/FiscalIssuance/Index-FiscalIssuance.vue";
export default {
  components: {
    FiscalIssuance,
  },
};
</script>