<template>
  <div class="dashboard">
    <div class="title">
      <span>Relatórios Gerais</span>
    </div>
    <div class="container">
      <div class="subtitle" style="color: #403b74">
        <b-icon-cash></b-icon-cash>
        <span>Vendas</span>
      </div>
      <div class="contentCard">
        <button
          v-b-modal.modal-center
          @click="handleType"
          name="Frente de Caixa"
          class="cardItem"
          style="border-right: 3px ridge #403b74"
        >
          Frente de Caixa
        </button>
        <button
          v-b-modal.modal-center
          @click="handleType"
          class="cardItem"
          name="Venda"
          style="border-right: 3px ridge #3e66ae"
        >
          Vendas
        </button>
        <button
          v-b-modal.modal-center
          @click="handleType"
          class="cardItem"
          name="Orçamento"
          style="border-right: 3px ridge #48b865"
        >
          Orçamento
        </button>
        <button
          v-b-modal.modal-center
          @click="handleType"
          class="cardItem"
          name=""
          style="border-right: 3px ridge #f2a83e"
        >
          Todas as Vendas
        </button>
      </div>
      <div class="subtitle" style="color: #403b74" v-if="enableMtq">
        <b-icon-cash></b-icon-cash>
        <span>Vendas MT²</span>
      </div>
      <div class="contentCard" v-if="enableMtq">
        <button
          v-b-modal.modal-mtq
          @click="handleType"
          class="cardItem"
          name="Venda"
          style="border-right: 3px ridge #403b74"
        >
          Vendas MT²
        </button>
        <button
          v-b-modal.modal-mtq
          @click="handleType"
          class="cardItem"
          name="Orçamento"
          style="border-right: 3px ridge #3e66ae"
        >
          Orçamento MT²
        </button>
        <button
          v-b-modal.modal-mtq
          @click="handleType"
          class="cardItem"
          name=""
          style="border-right: 3px ridge #48b865"
        >
          Todas Movimentações MT²
        </button>
        <button
          v-b-modal.modal-center
          @click="handleType"
          class="cardItem"
          name=""
          style="border-right: 3px ridge #f2a83e; visibility: hidden"
        >
          Todas as Vendas
        </button>
      </div>
      <div class="subtitle" style="color: #3e66ae">
        <b-icon-calculator></b-icon-calculator>
        <span>Financeiro</span>
      </div>
      <div class="contentCard">
        <button
          v-b-modal.modal-center2
          @click="handleType"
          class="cardItem"
          name="entrada"
          style="border-right: 3px ridge #403b74"
        >
          Contas a Receber
        </button>
        <button
          v-b-modal.modal-center2
          @click="handleType"
          class="cardItem"
          name="saida"
          style="border-right: 3px ridge #3e66ae"
        >
          Contas a Pagar
        </button>
        <button
          v-b-modal.modal-center2
          @click="handleType"
          class="cardItem"
          name=""
          style="border-right: 3px ridge #48b865"
        >
          Contas Gerais
        </button>
        <button
          v-b-modal.modal-center5
          class="cardItem"
          name="caixa"
          style="border-right: 3px ridge #f2a83e"
        >
          Caixa Geral
        </button>
      </div>
      <div class="subtitle" style="color: #48b865">
        <b-icon-receipt></b-icon-receipt>
        <span>Emissão fiscal</span>
      </div>
      <div class="contentCard">
        <button
          v-b-modal.modal-center1
          @click="handleType"
          class="cardItem"
          name="1"
          style="border-right: 3px ridge #403b74"
        >
          NF-e
        </button>
        <button
          v-b-modal.modal-center1
          @click="handleType"
          class="cardItem"
          name="2"
          style="border-right: 3px ridge #3e66ae"
        >
          NFC-e
        </button>
        <button
          v-b-modal.modal-center3
          @click="handleType"
          class="cardItem"
          name="nfse"
          style="border-right: 3px ridge #48b865"
        >
          NFS-e
        </button>
        <button
          v-b-modal.modal-center1
          @click="handleType"
          class="cardItem"
          name=""
          style="border-right: 3px ridge #f2a83e"
        >
          Todas as Emissões
        </button>
      </div>
      <div class="subtitle" style="color: #403b74">
        <b-icon-people></b-icon-people>
        <span>Cadastros</span>
      </div>
      <div class="contentCard">
        <button
          @click="printAllRegisters('Clientes')"
          class="cardItem"
          name="Clientes"
          style="border-right: 3px ridge #403b74"
        >
          Clientes
        </button>
        <button
          @click="printAllRegisters('Colaborador')"
          class="cardItem"
          name="Colaborador"
          style="border-right: 3px ridge #3e66ae"
        >
          Funcionários
        </button>
        <button
          v-b-modal.modal-center6
          @click="handleType"
          class="cardItem"
          name="Produtos"
          style="border-right: 3px ridge #48b865"
        >
          Produtos
        </button>
        <button
          @click="printAllRegisters('Fornecedores')"
          class="cardItem"
          name="Fornecedores"
          style="border-right: 3px ridge #f2a83e"
        >
          Fornecedores
        </button>
      </div>
      <div class="subtitle" style="color: #3e66ae">
        <b-icon-paperclip></b-icon-paperclip>
        <span>Outros</span>
      </div>
      <div class="contentCard">
        <button
          v-b-modal.modal-center3
          @click="handleType"
          class="cardItem"
          name="os"
          style="border-right: 3px ridge #403b74"
        >
          Ordem de Serviço
        </button>
        <button
          v-b-modal.modal-center4
          @click="handleType"
          class="cardItem"
          name="compras"
          style="border-right: 3px ridge #3e66ae"
        >
          Compras
        </button>
        <button
          @click="handleType"
          class="cardItem"
          name="servico"
          style="border-right: 3px ridge #3e66ae; visibility: hidden"
        >
          Serviços
        </button>
        <button
          @click="handleType"
          class="cardItem"
          name="gaveta"
          style="border-right: 3px ridge #f2a83e; visibility: hidden"
        >
          Caixa Gaveta
        </button>
      </div>
    </div>

    <div class="rocketBkg">
      <img style="width: 100%; height: 100%" :src="rocket" />
    </div>
    <b-modal id="modal-center" hide-footer hide-header centered>
      <div class="containerModal">
        <h6 class="titleModal">Deseja Imprimir o Relatório?</h6>

        <div class="rowContainer">
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Selecione o periodo
            :</span
          >
          <div class="rowContent">
            <div class="searchInput">
              <div class="input-group">
                <input
                  type="date"
                  class="input borderInput"
                  v-model="startDate"
                />
                <label class="user-label"> Inicio </label>
              </div>
              <div class="input-group">
                <input
                  type="date"
                  class="input borderInput"
                  v-model="endDate"
                />
                <label class="user-label"> Final </label>
              </div>
            </div>
          </div>
        </div>
        <div class="rowContainer">
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Selecione o
            tipo:</span
          >
          <div class="rowContent">
            <div class="containerRadio">
              <label @click="storeLabelName('')">
                <input type="radio" name="radio" checked="" />
                <span>Geral</span>
              </label>
              <label @click="storeLabelName('Vendas por cliente')">
                <input type="radio" name="radio" />
                <span>Vendas por cliente</span>
              </label>
              <label @click="storeLabelName('vendaPorComposicao')">
                <input type="radio" name="radio" />
                <span>Vendas por Composição</span>
              </label>
            </div>
            <div class="containerRadio">
              <label @click="storeLabelName('Vendas por Vendedor')">
                <input type="radio" name="radio" />
                <span>Vendas por Funcionário</span>
              </label>
              <label @click="storeLabelName('Mais Vendidos')">
                <input type="radio" name="radio" />
                <span>Mais Vendidos</span>
              </label>
            </div>
          </div>
        </div>
        <div
          class="rowContainer"
          v-if="this.selectedLabel === 'Vendas por Vendedor'"
        >
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Tipo do filtro</span
          >
          <div class="rowContent">
            <div class="selectPlus">
              <label class="fixedSelect2">Selecione o Funcionário</label>
              <b-form-select
                value-field="id"
                text-field="nomeFuncionario"
                class="borderInput shadow-none"
                v-model="idEmployee"
                :options="dataEmployee"
              ></b-form-select>
            </div>
            <div class="containerRadio">
              <label>
                <input type="checkbox" v-model="isSimple" />
                <span>Relatório Simples</span>
              </label>
            </div>
          </div>
        </div>
        <div
          class="rowContainer"
          v-if="this.selectedLabel === 'Vendas por cliente'"
        >
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Tipo do filtro</span
          >
          <div class="rowContent">
            <div class="selectPlus">
              <label class="fixedSelect2">Selecione o Cliente:</label>
              <b-form-select
                class="borderInput shadow-none"
                value-field="id"
                text-field="nome"
                :options="dataCustomers"
                v-model="idCliente"
              >
              </b-form-select>
            </div>
          </div>
        </div>
        <div class="btnModal-report-sales1">
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modal-center')"
          >
            Não, Obrigado!
          </button>
          <button @click="printReportSales" class="btnContent">Imprimir</button>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-center1" hide-footer hide-header centered>
      <div class="containerModal1">
        <h6 class="titleModal">Deseja Imprimir o Relatório de Notas?</h6>

        <div class="rowContainer">
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Selecione o periodo
            :</span
          >
          <div class="rowContent">
            <div class="searchInput">
              <div class="input-group">
                <input
                  type="date"
                  class="input borderInput"
                  v-model="startDate"
                />
                <label class="user-label"> Inicio </label>
              </div>
              <div class="input-group">
                <input
                  type="date"
                  class="input borderInput"
                  v-model="endDate"
                />
                <label class="user-label"> Final </label>
              </div>
            </div>
          </div>
        </div>
        <div class="btnModal-report-sales">
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modal-center1')"
          >
            Não, Obrigado!
          </button>
          <button @click="printNotes" class="btnContent">Imprimir</button>
        </div>
      </div>
    </b-modal>
    <b-modal size="lg" id="modal-center2" hide-footer hide-header centered>
      <div class="containerModal">
        <h6 class="titleModal">Deseja Imprimir o Relatório de Contas?</h6>

        <div class="rowContainer">
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Selecione o periodo
            :</span
          >
          <div class="rowContent">
            <div class="searchInput1">
              <div class="input-group" style="width: 32%">
                <input
                  type="date"
                  class="input borderInput"
                  v-model="startDate"
                />
                <label class="user-label"> Inicio </label>
              </div>
              <div class="input-group" style="width: 32%">
                <input
                  type="date"
                  class="input borderInput"
                  v-model="endDate"
                />
                <label class="user-label"> Final </label>
              </div>
            </div>
          </div>
        </div>
        <div class="rowContainer">
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Tipo de pesquisa</span
          >
          <div class="rowContent">
            <div class="selectPlus" v-if="type === 'entrada'">
              <label class="fixedSelect2">Selecione o Funcionário</label>
              <b-form-select
                value-field="id"
                text-field="nomeFuncionario"
                class="borderInput shadow-none"
                v-model="idEmployee"
                :options="dataEmployee"
              ></b-form-select>
            </div>
            <div class="selectPlus" v-else>
              <label class="fixedSelect2">Selecione o Fornecedor</label>
              <b-form-select
                value-field="id"
                text-field="nomeFantasia"
                class="borderInput shadow-none"
                v-model="idSupplier"
                :options="allSupplier"
              ></b-form-select>
            </div>
            <div class="selectPlus">
              <label class="fixedSelect2">Situação</label>
              <b-form-select
                value-field="value"
                text-field="text"
                class="borderInput shadow-none"
                v-model="status"
                :options="filterSituationCombobox"
              ></b-form-select>
            </div>
            <div class="selectPlus">
              <label class="fixedSelect2">Forma de pagamento:</label>
              <b-form-select
                value-field="id"
                text-field="tipo"
                class="borderInput shadow-none"
                :options="typeFormPayment"
                v-model="idFormaDePagamento"
                size="sm"
              >
              </b-form-select>
            </div>
          </div>
        </div>
        <div class="btnModal-report-sales1">
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modal-center2')"
          >
            Não, Obrigado!
          </button>
          <button @click="printReportBills" class="btnContent">Imprimir</button>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-center3" hide-footer hide-header centered>
      <div class="containerModal1">
        <h6 class="titleModal">Deseja Imprimir o Relatório?</h6>

        <div class="rowContainer">
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Selecione o periodo
            :</span
          >
          <div class="rowContent">
            <div class="searchInput">
              <div class="input-group">
                <input
                  type="date"
                  class="input borderInput"
                  v-model="startDate"
                />
                <label class="user-label"> Inicio </label>
              </div>
              <div class="input-group">
                <input
                  type="date"
                  class="input borderInput"
                  v-model="endDate"
                />
                <label class="user-label"> Final </label>
              </div>
            </div>
          </div>
        </div>
        <div class="btnModal-report-sales">
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modal-center3')"
          >
            Não, Obrigado!
          </button>
          <button @click="verifyTypeService" class="btnContent">
            Imprimir
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-center4" hide-footer hide-header centered>
      <div class="containerModal2">
        <h6 class="titleModal">Deseja Imprimir o Relatório?</h6>

        <div class="rowContainer">
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Selecione o periodo
            :</span
          >
          <div class="rowContent">
            <div class="searchInput">
              <div class="input-group">
                <input
                  type="date"
                  class="input borderInput"
                  v-model="startDate"
                />
                <label class="user-label"> Inicio </label>
              </div>
              <div class="input-group">
                <input
                  type="date"
                  class="input borderInput"
                  v-model="endDate"
                />
                <label class="user-label"> Final </label>
              </div>
            </div>
          </div>
          <div class="rowContainer">
            <span>
              <b-icon-calendar-week></b-icon-calendar-week> Selecione o
              tipo:</span
            >
            <div class="rowContent">
              <div class="containerRadio">
                <label @click="storeLabelName('')">
                  <input type="radio" name="radio" checked="" />
                  <span>Geral</span>
                </label>
              </div>
              <div class="containerRadio">
                <label @click="storeLabelName('Produtos Importados')">
                  <input type="radio" name="radio" />
                  <span>Produtos Importados</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="btnModal-report-sales1">
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modal-center4')"
          >
            Não, Obrigado!
          </button>
          <button @click="printPurchase" class="btnContent">Imprimir</button>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-center5" hide-footer hide-header centered>
      <div class="containerModal3">
        <h6 class="titleModal">Deseja Imprimir o Relatório?</h6>
        <div class="rowContainer">
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Selecione o periodo
            :</span
          >
          <div class="rowContent">
            <div class="searchInput">
              <div class="input-group">
                <input
                  type="date"
                  class="input borderInput"
                  v-model="startDate"
                />
                <label class="user-label"> Inicio </label>
              </div>
              <div class="input-group">
                <input
                  type="date"
                  class="input borderInput"
                  v-model="endDate"
                />
                <label class="user-label"> Final </label>
              </div>
            </div>
          </div>
        </div>
        <div class="rowContainer">
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Selecione o
            tipo:</span
          >
          <div class="rowContent">
            <div class="containerRadio">
              <label @click="storeLabelName('')">
                <input type="radio" name="radio" checked="" />
                <span>Geral</span>
              </label>
            </div>
            <div class="containerRadio">
              <label @click="storeLabelName('conta')">
                <input type="radio" name="radio" />
                <span>Por Conta</span>
              </label>
            </div>
          </div>
        </div>
        <div class="rowContainer" v-if="this.selectedLabel === 'conta'">
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Selecione a
            Conta</span
          >
          <div class="rowContent">
            <div class="selectPlus">
              <label class="fixedSelect2">Contas com Movimentação:</label>
              <b-form-select
                value-field="conta"
                text-field="conta"
                class="borderInput shadow-none"
                :options="allAccounts"
                v-model="conta"
                size="sm"
              >
              </b-form-select>
            </div>
          </div>
        </div>
        <div class="btnModal-report-sales">
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modal-center5')"
          >
            Não, Obrigado!
          </button>
          <button @click="printSafe" class="btnContent">Imprimir</button>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-center6" hide-footer hide-header centered>
      <div class="containerModal2">
        <h6 class="titleModal">Deseja Imprimir o Relatório?</h6>
        <div class="rowContainer1">
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Selecione o
            tipo:</span
          >
          <div class="rowContent">
            <div class="containerRadio">
              <label @click="storeLabelName('')">
                <input type="radio" name="radio" checked="" />
                <span>Geral</span>
              </label>

              <label @click="storeLabelName('Anual')">
                <input type="radio" name="radio" />
                <span>Estoque Anual</span>
              </label>
            </div>
            <div class="containerRadio">
              <label @click="storeLabelName('Categoria')">
                <input type="radio" name="radio" />
                <span>Vendas por Categoria</span>
              </label>

              <label @click="storeLabelName('Movimentacao')">
                <input type="radio" name="radio" />
                <span>Movimentações de Entrada/Saída</span>
              </label>
            </div>
          </div>
        </div>
        <div class="rowContainer" v-if="this.selectedLabel === 'Categoria'">
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Tipo do filtro</span
          >
          <div class="rowContent">
            <div class="selectPlus">
              <label class="fixedSelect2">Selecione a Categoria</label>
              <b-form-select
                value-field="id"
                text-field="nome"
                class="borderInput shadow-none"
                v-model="idCategoria"
                :options="categories"
              ></b-form-select>
            </div>
          </div>
        </div>
        <div class="rowContainer" v-if="this.selectedLabel === 'Anual'">
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Informe o ano:</span
          >
          <div class="rowContent">
            <div class="selectPlus">
              <label class="fixedSelect2">Selecione o Ano</label>
              <b-form-select
                value-field="value"
                text-field="text"
                class="borderInput shadow-none"
                v-model="year"
                :options="filterYear"
              ></b-form-select>
            </div>
          </div>
        </div>
        <div class="rowContainer" v-if="this.selectedLabel === 'Movimentacao'">
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Selecione o
            Período:</span
          >
          <div class="rowContent">
            <div class="searchInput">
              <div class="input-group">
                <input
                  type="date"
                  class="input borderInput"
                  v-model="startDate"
                />
                <label class="user-label"> Inicio </label>
              </div>
              <div class="input-group">
                <input
                  type="date"
                  class="input borderInput"
                  v-model="endDate"
                />
                <label class="user-label"> Final </label>
              </div>
            </div>
          </div>
        </div>
        <div class="btnModal-report-sales1">
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modal-center6')"
          >
            Não, Obrigado!
          </button>
          <button @click="printRegisters" class="btnContent">Imprimir</button>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-mtq" hide-footer hide-header centered>
      <div class="containerModal">
        <h6 class="titleModal">Deseja Imprimir o Relatório?</h6>

        <div class="rowContainer">
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Selecione o periodo
            :</span
          >
          <div class="rowContent">
            <div class="searchInput">
              <div class="input-group">
                <input
                  type="date"
                  class="input borderInput"
                  v-model="startDate"
                />
                <label class="user-label"> Inicio </label>
              </div>
              <div class="input-group">
                <input
                  type="date"
                  class="input borderInput"
                  v-model="endDate"
                />
                <label class="user-label"> Final </label>
              </div>
            </div>
          </div>
        </div>
        <div class="rowContainer">
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Selecione o
            tipo:</span
          >
          <div class="rowContent">
            <div class="containerRadio">
              <label @click="storeLabelName('')">
                <input type="radio" name="radio" checked="" />
                <span>Geral</span>
              </label>
              <label @click="storeLabelName('Vendas por cliente')">
                <input type="radio" name="radio" />
                <span>Vendas por cliente</span>
              </label>
            </div>
            <div class="containerRadio">
              <label @click="storeLabelName('Vendas por Vendedor')">
                <input type="radio" name="radio" />
                <span>Vendas por Funcionário</span>
              </label>
            </div>
          </div>
        </div>
        <div class="btnModal-report-sales1">
          <button class="btnContentReceive" @click="$bvModal.hide('modal-mtq')">
            Não, Obrigado!
          </button>
          <button @click="printReportSalesMtq" class="btnContent">
            Imprimir
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import rocket from "../../assets/rocketi.png";
import printReportGeneral from "../../reports/General";
import printReportGeneralMtq from "../../reports/General/mtq";
import printReportPerEmployee from "../../reports/sale/perEmployee";
import printReportClient from "../../reports/sale/perClient";
import printReportBills from "../../reports/Bills/perType";
import printProductCategory from "../../reports/Products/PerCategory";
import printProductAnual from "../../reports/Products/Anual";
import printMovement from "../../reports/Products/Moviment";
import printReportRegisters from "../../reports/Registers";
import api from "../../services/axios";
import serviceSupplier from "../../services/serviceSupplier";
import SafeService from "../../services/serviceSafe";
import serviceCategory from "../../services/serviceCategory";
import servicePaymentForm from "../../services/servicePaymentForm";
import serviceCustomer from "../../services/serviceCustomer";
import { mapState } from "vuex";

export default {
  data() {
    return {
      isSimple: false,
      rocket: rocket,
      type: "",
      startDate: "",
      endDate: "",
      selectedLabel: "",
      status: "",
      conta: "",
      idEmployee: "",
      idCategoria: "",
      idSupplier: "",
      idCliente: "",
      idFormaDePagamento: "",
      dataEmployee: [],
      categories: [],
      allSupplier: [],
      allAccounts: [],
      dataCustomers: [],
      year: "",
      typeFormPayment: [],
      filterSituationCombobox: [
        { value: "pago", text: "Pago" },
        { value: "pago Parcial", text: "Pago Parcial" },
        { value: "perda", text: "Perda" },
        { value: "Em Aberto", text: "Em aberto" },
        { value: "vencido", text: "Vencido" },
        { value: "", text: "Selecione a situação" },
      ],
      filterYear: [
        { value: "2021", text: "2021" },
        { value: "2022", text: "2022" },
        { value: "2023", text: "2023" },
        { value: "2024", text: "2024" },
        { value: "2025", text: "2025" },
        { value: "2026", text: "2026" },
        { value: "2027", text: "2027" },
        { value: "2028", text: "2028" },
        { value: "2029", text: "2029" },
        { value: "2030", text: "2030" },
        { value: "2031", text: "2031" },
        { value: "2032", text: "2032" },
      ],
    };
  },
  methods: {
    handleType(event) {
      this.type = event.target.name;
    },

    storeLabelName(labelName) {
      this.selectedLabel = labelName;
    },

    async getCustomersForSelectBox() {
      try {
        const data = await serviceCustomer.getCustomersForSelectBox();
        this.dataCustomers = data.data;
      } catch (error) {
        console.log(error.response);
      }
    },

    async sumPerAccount() {
      const data = await SafeService.sumPerAccount();
      this.allAccounts = data?.data ?? [];
    },

    async listEmployeesSelectBox() {
      try {
        const { data } = await api.get("/employees/combobox/fill");
        this.dataEmployee = data.data;
      } catch (error) {
        console.log(error.response);
      }
    },

    async findAllCategories() {
      try {
        this.categories = await serviceCategory.fillComobobox();
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao buscar as categorias do produto",
          type: "info",
        });
      }
    },

    async getAllSuppliers() {
      const result = await serviceSupplier.fillComobobox();
      this.allSupplier = result;
    },

    async getAllPayment() {
      const result = await servicePaymentForm.getAllPayment();
      this.typeFormPayment = result.sort((a, b) => a.cod - b.cod);
    },

    printReportSales() {
      if (this.selectedLabel === "") {
        printReportGeneral("allSales", this.type, this.startDate, this.endDate);
      } else if (this.selectedLabel === "Vendas por cliente") {
        if (this.idCliente) {
          printReportClient(
            this.idCliente,
            this.type,
            this.startDate,
            this.endDate
          );
        } else {
          printReportGeneral("client", this.type, this.startDate, this.endDate);
        }
      } else if (this.selectedLabel === "Vendas por Vendedor") {
        if (this.selectedLabel === "Vendas por Vendedor" && this.idEmployee) {
          printReportPerEmployee(
            this.idEmployee,
            this.type,
            this.startDate,
            this.endDate,
            this.isSimple
          );
        } else {
          printReportGeneral(
            "employee",
            this.type,
            this.startDate,
            this.endDate,
            this.isSimple
          );
        }
      } else if (this.selectedLabel === "vendaPorComposicao") {
        printReportGeneral(
          "salePerComposition",
          this.type,
          this.startDate,
          this.endDate
        );
      }

      this.clearSearch();
    },

    printReportSalesMtq() {
      if (this.selectedLabel === "") {
        printReportGeneralMtq(
          "allSales",
          this.type,
          this.startDate,
          this.endDate
        );
      } else if (this.selectedLabel === "Vendas por cliente") {
        printReportGeneralMtq(
          "client",
          this.type,
          this.startDate,
          this.endDate
        );
      } else if (this.selectedLabel === "Vendas por Vendedor") {
        printReportGeneralMtq(
          "employee",
          this.type,
          this.startDate,
          this.endDate
        );
      } else if (this.selectedLabel === "vendaPorComposicao") {
        printReportGeneralMtq(
          "salePerComposition",
          this.type,
          this.startDate,
          this.endDate
        );
      }

      this.clearSearch();
    },

    printReportBills() {
      printReportBills(
        this.type,
        this.status,
        this.idEmployee,
        this.idSupplier,
        this.idFormaDePagamento,
        this.startDate,
        this.endDate
      );
      this.clearSearch();
    },

    printRegisters() {
      if (this.selectedLabel === "Categoria") {
        printProductCategory(this.idCategoria);
      } else if (this.selectedLabel === "Anual") {
        printProductAnual(this.year);
      } else if (this.selectedLabel === "Movimentacao") {
        printMovement(this.startDate, this.endDate);
      } else {
        printReportRegisters(this.type);
      }
      this.clearSearch();
    },

    printAllRegisters(type) {
      printReportRegisters(type);
    },

    printSafe() {
      this.type = this.conta;
      printReportGeneral("safes", this.type, this.startDate, this.endDate);
      this.clearSearch();
    },

    verifyTypeService() {
      if (this.type === "nfse") {
        this.printNfse();
      } else {
        this.printOs();
      }
    },

    printNfse() {
      printReportGeneral("nfse", this.startDate, this.endDate);
      this.clearSearch();
    },

    printOs() {
      printReportGeneral("os", this.startDate, this.endDate);
      this.clearSearch();
    },

    printNotes() {
      printReportGeneral("notes", this.type, this.startDate, this.endDate);
      this.clearSearch();
    },

    printPurchase() {
      if (this.selectedLabel === "") {
        printReportGeneral("purchase", this.startDate, this.endDate);
      }
      if (this.selectedLabel === "Produtos Importados") {
        printReportGeneral("purchaseProducts", this.startDate, this.endDate);
      }
      this.clearSearch();
    },

    clearSearch() {
      this.type = "";
      this.startDate = "";
      this.endDate = "";
      this.selectedLabel = "";
      this.status = "";
      this.year = "";
      this.conta = "";
      this.idEmployee = "";
      this.idSupplier = "";
      this.idCliente = "";
      this.idCategoria = "";
      this.idFormaDePagamento = "";
      this.isSimple = false;
      this.$bvModal.hide("modal-center");
      this.$bvModal.hide("modal-center2");
      this.$bvModal.hide("modal-center5");
      this.$bvModal.hide("modal-center6");
    },
  },
  computed: {
    ...mapState(["enableMtq"]),
  },
  mounted() {
    this.listEmployeesSelectBox();
    this.findAllCategories();
    this.getAllSuppliers();
    this.getAllPayment();
    this.sumPerAccount();
    this.getCustomersForSelectBox();
  },
};
</script>
<style scoped>
.dashboard {
  width: 85%;
  margin-top: 60px;
}

.title {
  font-weight: bold;
  font-size: 1.2rem;
  color: gray;
  width: 100%;
  display: flex;
  justify-content: center;
}

.title span {
  cursor: context-menu;
}

.subtitle {
  display: flex;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  gap: 10px;
  margin: 0 auto;
  border-bottom: 1px solid rgb(206, 206, 206);
}

.container {
  display: flex;
  justify-content: space-around;
  height: 75vh;
  flex-direction: column;
}

.contentCard {
  display: flex;
  justify-content: space-evenly;
}

.cardItem {
  display: flex;
  width: 23%;
  height: 50px;
  border: 1px solid rgb(215, 215, 215);
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  padding: 6px 12px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  z-index: 50;
  letter-spacing: 1px;
  color: rgb(85, 85, 85);
  background: rgba(255, 255, 255, 0.438);
  box-shadow: -2px 12px 2px -10px rgba(0, 0, 0, 0.237);
}

.cardItem:hover {
  background: rgba(238, 237, 237, 0.678);
}

.rocketBkg {
  width: 400px;
  height: 400px;
  background: transparent;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 20;
  margin: 0 50px 0 0;
  opacity: 0.5;
}

.containerModal {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.containerModal3 {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
}

.containerModal2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.containerModal1 {
  height: 30vh;
}

.titleModal {
  font-size: 1rem;
  border-bottom: 1px solid #e6e7e8;
  font-weight: bold;
}

.btnModal-report-sales {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  left: 0;
}

.btnModal-report-sales1 {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.btnContent {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 40%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentReceive {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 40%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

input {
  border: 1px solid #e3e0e0;
  border-radius: 5px;
  height: 30px;
  font-size: 11px;
}

.searchInput {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.searchInput1 {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: flex-start;
}

.rowContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
  background: #f9f9f94d;
  margin-top: 20px;
}

.rowContainer span {
  font-weight: 600;
  font-size: 11px;
}

.rowContainer1 {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
  background: #f9f9f94d;
}

.rowContainer1 span {
  font-weight: 600;
  font-size: 11px;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}

.selectPlus {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 9.5px;
  bottom: 22px;
  left: 11px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: white;
}

.user-label {
  position: absolute;
  left: 10px;
  font-size: 11px;
  bottom: 7px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
  font-weight: 600;
}

.borderInput {
  font-size: 12px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  width: 95%;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #505050;
}

.containerRadio label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
}

.containerRadio label input {
  position: absolute;
  left: -9999px;
}

.containerRadio label input:checked + span {
  background-color: #f3f2f2;
  color: rgb(0, 0, 0);
}

.containerRadio label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4375em #00486e;
}

.containerRadio label span {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 2px;
  transition: 0.25s ease;
}

.containerRadio label span:hover {
  background-color: #e6e6e6;
}

.containerRadio label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.1em #00486e;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .title {
    font-size: 17px;
  }
  .cardItem {
    height: 35px;
    font-size: 11px;
  }
}

@media screen and (max-width: 700px) {
  .dashboard {
    width: 100%;
    margin-top: 55px;
  }

  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-right: 5px;
    padding-left: 5px;
  }

  .cardItem {
    display: flex;
    width: 23%;
    height: 60px;
    border: 1px solid rgb(215, 215, 215);
    border-radius: 4px;
    text-align: center;
    font-size: 11px;
    padding: 3px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    letter-spacing: 0px;
  }
}
</style>