<template>
  <div style="max-height: 100px">
    <b-modal size="xl" id="modalNcms" centered hide-header>
      <template #modal-footer>
        <div style="width: 100%; display: flex; justify-content: space-between">
          <button
            class="btnsModal"
            variant="info"
            size="sm"
            @click="handleCloseModalAndClear"
          >
            Fechar
          </button>
          <button
            class="btnsModal btnSave"
            variant="info"
            size="sm"
            @click="saveOrUpdate"
          >
            Salvar
          </button>
        </div>
      </template>
      <div class="mt-3">
        <div class="rowContainer">
          <span>
            <b-icon-card-checklist></b-icon-card-checklist> Novo NCM
          </span>
          <div class="rowContent">
            <Input
              label="Código NCM"
              style="width: 20% !important"
              type="text"
              :dataInput.sync="dataNcm.codigo_ncm"
              ref="codigo_ncm"
            />
            <Input
              label="Descrição do NCM"
              style="width: 80% !important"
              type="text"
              :dataInput.sync="dataNcm.descricao"
              ref="descricao"
            />
          </div>
        </div>

        <span class="titleNcm">NCM's Cadastrados</span>
        <div class="ContentTable">
          <table>
            <thead class="headTable">
              <tr style="width: 100%">
                <th style=" font-size: 14px; font-weight: 600">
                  Código
                </th>
                <th style="width: 80%; font-size: 14px; font-weight: 600">
                  Descrição
                </th>
                <th
                  style="
                    text-align: center;
                    font-size: 14px;
                    font-weight: 600;
                    padding: 0 10px;
                  "
                >
                  Ações
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="ncm in allNcms" :key="ncm.id">
                <td style="text-align: center; font-size: 13px">
                  {{ ncm.codigo_ncm }}
                </td>
                <td style="width: 80%; text-align: center; font-size: 13px">
                  {{ ncm.descricao }}
                </td>
                <td
                  style="
                    text-align: center;
                    padding: 0 !important;
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    gap: 20px;
                    padding: 0.5rem;
                  "
                >
                  <b-icon-pencil
                    @click="getNcmFindById(ncm.id)"
                    scale="1"
                    role="button"
                  />

                  <b-icon-x-circle
                    @click="deleteNcm(ncm.id)"
                    role="button"
                    style="color: red"
                    scale="1"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import serviceNcms from "../../services/serviceNcms";
import Input from "../UI/CustomInputs/Input.vue";
import { removeSpecialCharacters } from "../../utils/removeSpecialCharacters";

export default {
  data() {
    return {
      dataNcm: {
        id: "",
        descricao: "",
        codigo_ncm: "",
      },
      allNcms: [],
    };
  },
  components: {
    Input,
  },
  methods: {
    async saveNcm() {
      try {
        this.handleNcmOrCest(this.dataNcm.codigo_ncm);
        await serviceNcms.save(this.dataNcm);
        this.getAllNcm();

        this.$emit("getNewNcm");

        return this.$toast.open({
          message: "Salvo com sucesso!",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message:
            `${error.response.data.erros}` || "Ocorreu um erro o salvar!",
          type: "info",
        });
      }
    },

    async updateNcm() {
      try {
        this.handleNcmOrCest(this.dataNcm.codigo_ncm);
        await serviceNcms.update(this.dataNcm);
        this.getAllNcm();

        this.$emit("getNewNcm");

        return this.$toast.open({
          message: "Atualizado com sucesso!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um problema ao deletar!",
          type: "info",
        });
      }
    },

    handleNcmOrCest(value) {
      const formattedValue = removeSpecialCharacters(value);
      this.dataNcm.codigo_ncm = formattedValue;
      return formattedValue;
    },

    async deleteNcm(idNcm) {
      try {
        await serviceNcms.delete(idNcm);
        this.getAllNcm();

        this.$emit("getNewNcm");

        return this.$toast.open({
          message: "deletado com sucesso!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um problema ao deletar!",
          type: "info",
        });
      }
    },

    async getNcmFindById(idNcm) {
      const result = await serviceNcms.findById(idNcm);
      Object.assign(this.dataNcm, result);
      this.$refs.codigo_ncm.valueInput = result.codigo_ncm;
      this.$refs.descricao.valueInput = result.descricao;
    },

    async getAllNcm() {
      const result = await serviceNcms.fillComobobox();

      this.allNcms = result.map((ncm) => {
        return {
          ...ncm,
          descricao: ncm.nomeNcm.split(" - ")[1],
        };
      });
    },

    async saveOrUpdate() {
      this.dataNcm.id === "" ? await this.saveNcm() : await this.updateNcm();
      this.clear();
    },

    clear() {
      this.dataNcm.id = "";
      this.dataNcm.descricao = "";
      this.dataNcm.codigo_ncm = "";
      this.$refs.codigo_ncm.valueInput = "";
      this.$refs.descricao.valueInput = "";
    },

    handleCloseModalAndClear() {
      this.clear();
      this.$bvModal.hide("modalNcms");
    },
  },
  mounted() {
    this.getAllNcm();
  },
};
</script>

<style scoped>
.styleHoverDelete:hover {
  background-color: #ff6347;
  border: solid 1px black;
}

.tabsContentSearch {
  overflow-y: scroll;
  max-height: 300px;
}

.btnsModal {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  height: 28px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}

.rowContainer {
  position: relative;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 10px;
}

.rowContainer span {
  font-weight: 600;
  font-size: 14px;
}

.ContentTable {
  display: flex;
  flex-direction: column;
  height: 50vh;
  max-height: 50vh;
  overflow: auto;
  width: 100%;
  max-width: 100%;
}

.titleNcm {
  margin-top: 30px;
  width: 100%;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  color: #3a4750;
  justify-content: flex-start;
}

tr:nth-child(even) {
  background: #f2f3f3;
}

.headTable {
  color: whitesmoke;
  background: #3a4750;
  text-align: center;
  height: 30px;
}

.btnSave {
  background-color: transparent;
  border: solid 1px #0fbf4d;
  color: #0fbf4d;
}

.titleCard {
  margin-bottom: 32px !important;
  font-weight: 600;
  color: #666360;
  font-size: 16px;
}
</style>