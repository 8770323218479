<template>
<div class="d-flex justify-content-center container-geral-lancar-pagamento">
    <div class="container-components-lancar-pagamento" role="tablist">
        <BillDetails />
    </div>
  </div>
</template>

<script>
import BillDetails from "./BillDetails.vue";
export default {
  components: {
    BillDetails,
  },
};
</script>

<style>
.container-geral-lancar-pagamento {
  width: 100% !important;
}

.container-components-lancar-pagamento {
  transition: all 0.4s linear;
  width: 95%;
}
</style>