<template>
  <div class="input-group">
    <input
      required
      :type="type"
      :class="`input col-sm-12 col-md-12 col-lg-12 col-xl-12 borderInput ${isInvalidInput} ${textAlign} ${
        isDisabled && 'inputDisabled'
      }`"
      v-model="valueInput"
      @input="valueSelected"
      v-mask="maskMoney(mask)"
      :placeholder="placeholder"
      @blur="clearInvalidInput"
    />
    <label
      :style="`background-color: ${labelBackground}`"
      :class="`${labelFixed ? 'labelFixed' : 'user-label'}  ${
        !label && 'labelFixedDisabled'
      }`"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
    },
    labelFixed: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    dataInput: {
      type: [String, Number, Function],
    },
    mask: {
      type: [String, Number],
    },
    isDisabled: {
      type: Boolean,
    },
    isDiscountAllItems: {
      type: Boolean,
    },
    isDiscountToIndividuallyItem: {
      type: Boolean,
    },
    textAlign: {
      type: String,
    },
    change: {
      type: Function,
    },
    labelBackground: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    isInvalidInput: {
      type: String,
    },
  },
  data() {
    return {
      valueInput: "",
    };
  },
  created() {
    this.valueInput = this.dataInput;
  },
  methods: {
    valueSelected() {
      this.$emit("update:dataInput", this.valueInput);
      this.$emit("calculateTotal", this.valueInput);
      this.$emit("changed");
    },
    maskMoney(value) {
      if (this.mask) {
        if (value?.length === 3) {
          return "#,##";
        }
        if (value?.length === 5) {
          return "##,##";
        }
        if (value?.length === 6) {
          return "###,##";
        }
        if (value?.length === 7) {
          return "#.###,##";
        }
        if (value?.length === 9) {
          return "##.###,##";
        }
        if (value?.length === 10) {
          return "###.###,##";
        }
        if (value?.length === 11) {
          return "#.###.###,##";
        }
        if (value?.length === 12) {
          return "##.###.###,##";
        } else {
          return "";
        }
      }
    },
    clearInvalidInput() {
      this.$emit("clearInvalidInput");
    },
  },
};
</script>

<style scoped>
.user-label {
  position: absolute;
  left: 3px;
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  bottom: 7px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.labelFixed {
  position: absolute;
  left: 3px;
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  bottom: 13px;
  pointer-events: none;
  transform: translateY(-50%) scale(0.8);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  height: 12px;
  background-color: #ffff;
  padding: 0 0.2em;
}

.input {
  font-size: 12px !important;
}

.inputDisabled {
  background-color: #fafafa;
  pointer-events: none;
  color: #949290 !important;
}

.labelFixedDisabled {
  padding: 0 !important;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.2em;
  font-weight: 600;
}

.borderInput {
  font-size: 14px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #a9cff4;
}

.isInvalidInput:focus {
  border: solid 1px rgba(204, 21, 21, 0.583) !important;
  transition: ease-in all 0.1s !important;
  box-shadow: 0 0 2px 1px rgba(204, 21, 21, 0.583) !important;
}
</style>