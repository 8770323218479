const TypeErrors = {
  NO_SAFE_REGISTER_OPEN: "no_safe_register_open",
  NOT_FOUND_SOFTWARE_PLAN: "not_found_software_plan",
  NOT_FOUND_FISCAL_DOCUMENTS: "not_found_fiscal_documents",
  NO_PERMISSIONS: "no_permissions",
  EXIST_BILLS_PAYD: "exist_bills_payd",
  EXIST_FISCAL_DOCUMENT: "exist_fiscal_document",
  PLAN_WITHOUT_PERMISSION: "plan_without_permission",
  ALREADY_EXISTS: "already_exists",
  EXPIRED_TOKEN: "expired_token",
  IS_NOT_ALLOWED: "is_not_allowed",
  ITEM_NF_INVALID: "item_nf_invalid",
}

export { TypeErrors }