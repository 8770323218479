import Joi from "joi";

export const ValidateEmailResetPassword = (email) => {
  const schema = Joi.string().email({ minDomainSegments: 2, tlds: { allow: ['com'] } })

  const { error } = schema.validate(email, {
    allowUnknown: true,
  })

  return error?.details[0]?.message
}
