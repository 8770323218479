<template>
  <b-modal id="modalVehicle" centered hide-header size="lg">
    <template #modal-footer>
      <div style="width: 100%; display: flex; justify-content: space-between">
        <button
          class="btnsModal"
          variant="info"
          size="sm"
          @click="handleCloseModalAndClear"
        >
          Fechar
        </button>
        <button
          class="btnsModal btnSave"
          variant="info"
          size="sm"
          @click="saveOrUpdateVehicle"
        >
          Salvar
        </button>
      </div>
    </template>

    <div class="mt-3">
      <div class="titleCard">
        <p>Cadastro de Veículos</p>
      </div>
      <div class="rowContent">
        <div class="input-group">
          <input
            v-model="dataVehicle.placa"
            ref="placa"
            class="input borderInput"
            placeholder="Informar 7 caracteres Ex: THR1234"
          />
          <label class="user-label"> Placa </label>
        </div>
        <div class="input-group">
          <input
            v-model="dataVehicle.tara"
            ref="tara"
            class="input borderInput"
          />
          <label class="user-label"> Tara </label>
        </div>
      </div>
      <div class="rowContent">
        <div class="selectPlus">
          <label class="fixedSelect2">Tipo Rodado</label>
          <b-form-select
            value-field="value"
            class="borderInput shadow-none"
            text-field="text"
            v-model="dataVehicle.tipo_rodado"
            :options="tipo_rodado"
            ref="tipoRodado"
          >
          </b-form-select>
        </div>
        <div class="selectPlus">
          <label class="fixedSelect2">Tipo Carroceria</label>
          <b-form-select
            value-field="value"
            class="borderInput shadow-none"
            text-field="text"
            v-model="dataVehicle.tipo_carroceria"
            :options="tipo_carroceria"
            ref="tipoCarroceria"
          >
          </b-form-select>
        </div>
        <div class="selectPlus">
          <label class="fixedSelect2">UF de Licenciamento</label>
          <b-form-select
            class="borderInput shadow-none"
            value-field="value"
            text-field="text"
            :options="ufs"
            v-model="dataVehicle.uf_licenciamento"
            ref="uf"
          >
          </b-form-select>
        </div>
      </div>

      <table class="table table-sm mt-5">
        <thead>
          <tr style="width: 100%">
            <th style="width: 20%; font-size: 14px; font-weight: 600">Placa</th>
            <th style="width: 20%; font-size: 14px; font-weight: 600">Tara</th>
            <th style="width: 20%; font-size: 14px; font-weight: 600">
              UF Lic.
            </th>
            <th style="width: 20%; font-size: 14px; font-weight: 600">
              Tipo Carroceria
            </th>
            <th style="width: 20%; font-size: 14px; font-weight: 600">
              Tipo Rodado
            </th>
            <th
              style="
                width: 20%;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
                padding: 0 10px;
              "
            >
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="vehicle in allVehicles" :key="vehicle.id">
            <td style="font-size: 14px">{{ vehicle.placa }}</td>
            <td style="font-size: 14px">{{ vehicle.tara }}</td>
            <td style="font-size: 14px">{{ vehicle.uf_licenciamento }}</td>
            <td style="font-size: 14px">{{ vehicle.tipo_carroceria }}</td>
            <td style="font-size: 14px">{{ vehicle.tipo_rodado }}</td>
            <td
              style="
                text-align: center;
                padding: 0 !important;
                display: flex;
                height: 100%;
                justify-content: center;
                gap: 20px;
                padding: 0.5rem;
              "
            >
              <b-icon-pencil
                @click="findById(vehicle.id)"
                scale="1"
                role="button"
              />
              <b-icon-x-circle
                @click="deleteVehicle(vehicle.id)"
                role="button"
                style="color: red"
                scale="1"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-modal>
</template>

<script>
import ServiceVehicle from "../../services/serviceVehicle";
import { ufs } from "../../common/index";

export default {
  data() {
    return {
      dataVehicle: {
        id: "",
        placa: "",
        tara: "",
        tipo_rodado: "",
        tipo_carroceria: "",
        uf_licenciamento: "",
      },
      allVehicles: [],
      ufs: ufs,
      tipo_rodado: [
        { value: "01", text: "Truck" },
        { value: "02", text: "Toco" },
        { value: "03", text: "Cavalo Mecânico" },
        { value: "04", text: "VAN" },
        { value: "05", text: "Utilitário" },
        { value: "06", text: "Outros" },
      ],
      tipo_carroceria: [
        { value: "00", text: "não aplicável" },
        { value: "01", text: "Aberta" },
        { value: "02", text: "Fechada/Baú" },
        { value: "03", text: "Granelera" },
        { value: "04", text: "Porta Container" },
        { value: "05", text: "Sider" },
      ],
    };
  },
  methods: {
    async saveOrUpdateVehicle() {
      this.dataVehicle.id === "" ? await this.save() : await this.update();
      this.clear();
    },
    async save() {
      try {
        await ServiceVehicle.save(this.dataVehicle);
        this.getAllVehicle();
        this.$emit("getNewVehicle");

        return this.$toast.open({
          message: "Salvo com sucesso!",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message:
            `${error.response.data.erros}` || "Ocorreu um erro o salvar!",
          type: "info",
        });
      }
    },
    async update() {
      try {
        await ServiceVehicle.update(this.dataVehicle);
        this.getAllVehicle();
        this.$emit("getNewVehicle");

        return this.$toast.open({
          message: "Atualizado com sucesso!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um problema ao deletar!",
          type: "info",
        });
      }
    },

    async deleteVehicle(id) {
      try {
        await ServiceVehicle.delete(id);
        this.getAllVehicle();
        this.$emit("getNewVehicle");

        return this.$toast.open({
          message: "deletado com sucesso!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um problema ao deletar!",
          type: "info",
        });
      }
    },

    async findById(id) {
      const result = await ServiceVehicle.findById(id);
      Object.assign(this.dataVehicle, result);
      this.$refs.placa.valueInput = result.placa;
      this.$refs.tara.valueInput = result.tara;
      this.$refs.tipoRodado.valueInput = result.tipo_rodado;
      this.$refs.uf.valueInput = result.uf;
      this.$refs.tipoCarroceria.valueInput = result.tipo_carroceria;
    },

    async getAllVehicle() {
      const result = await ServiceVehicle.fillCombobox();
      this.allVehicles = result;
    },

    async saveOrUpdate() {
      this.dataVehicle.id === "" ? await this.save() : await this.update();
      this.clear();
    },

    clear() {
      this.dataVehicle.id = "";
      this.dataVehicle.tara = "";
      this.dataVehicle.placa = "";
      this.dataVehicle.tipo_carroceria = "";
      this.dataVehicle.tipo_rodado = "";
      this.dataVehicle.uf_licenciamento = "";
    },

    handleCloseModalAndClear() {
      this.clear();
      this.$bvModal.hide("modalVehicle");
    },
  },
  mounted() {
    this.getAllVehicle();
  },
};
</script>


<style scoped>
.styleHoverDelete:hover {
  background-color: #ff6347;
  border: solid 1px black;
}

.tabsContentSearch {
  overflow-y: scroll;
  max-height: 300px;
}
.borderInput {
  font-size: 12px !important;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px !important;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  box-shadow: none !important;
  border: 1px solid #505050;
}

.btnsModal {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  height: 28px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;
}

.btnSave {
  background-color: transparent;
  border: solid 1px #0fbf4d;
  color: #0fbf4d;
}

.titleCard {
  margin-bottom: 32px !important;
  font-weight: 600;
  color: #666360;
  font-size: 16px;
}
.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}
.form-control-sm {
  height: 28px;
  padding: 0.25rem 0.25rem;
  font-size: 12px;
  line-height: 1.3;
  border-radius: 0.2rem;
}

.custom-select-sm {
  height: 28px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 12px;
}

.form-group {
  margin-bottom: 0.2rem;
}

span {
  font-weight: bold;
  cursor: context-menu;
}

.user-label {
  position: absolute;
  left: 4px;
  font-size: 11px;
  bottom: 7px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  z-index: 10;
}

.input {
  font-size: 11px !important;
  width: 100%;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
}

.selectPlus {
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 9px;
  bottom: 24px;
  left: 11px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: white;
}
</style>