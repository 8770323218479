<template>
    <div>
        <Schedule/>
    </div>
</template>

<script>
import Schedule from '../../components/Schedule/Index-Schedule.vue'
export default{
    components:{
        Schedule,
    },
    data(){
        return{

        }
    }
}

</script>