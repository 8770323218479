<template>
  <b-card>
    <div style="font-size: 12px">
      <div class="title">
        <span>Cadastro de Serviço</span>
        <span class="requiredFields">* Campos Obrigatórios</span>
      </div>
      <div class="rowContainer">
        <span>
          <b-icon-person-lines-fill></b-icon-person-lines-fill> Informações
          Gerais</span
        >
        <div class="rowContent">
          <b-form-input
            hidden
            class="col-sm-1"
            v-model="dataProvision.id"
          ></b-form-input>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataProvision.nome"
            />
            <label class="user-label">
              Nome <span class="validRed">*</span></label
            >
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              :value="maskCurrency(dataProvision.valor)"
              @input="handleValue($event)"
              type="text"
            />
            <label class="user-label">
              Valor <span class="validRed">*</span></label
            >
          </div>
          <!-- <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataProvision.refFiscal"
            />
            <label class="user-label">
              REF <span class="validRed">*</span></label
            >
          </div> -->
          <div class="selectPlus">
            <label class="fixedSelect2"
              >Refs Fiscais <span class="validRed">*</span></label
            >
            <b-form-select
              class="borderInput shadow-none"
              v-model="dataProvision.refFiscal"
              value-field="ref"
              text-field="descricao"
              :options="refsNfse"
            >
            </b-form-select>
            <div class="iconSelectBarCode" @click="openModalServiceTax">
              <div role="button">
                <b-icon-plus scale="2"></b-icon-plus>
              </div>
            </div>
          </div>
        </div>
        <div class="rowContent">
          <div class="contentDescription">
            <label class="description">Descrição / Observações </label>

            <textarea
              class="inputTextArea"
              v-model="dataProvision.observacoes"
              rows="3"
              max-rows="6"
            />
          </div>
        </div>
        <div class="btnsContainer">
          <b-button class="btnsSaveNeutral" @click="clear"
            >Novo <b-icon-arrow-clockwise class="ml-1"></b-icon-arrow-clockwise
          ></b-button>
          <b-button class="btnsSave" @click="saveOrUpdate"
            >{{ dataProvision.id ? "Atualizar" : "Salvar" }}
            <b-icon-check2-circle class="ml-1"></b-icon-check2-circle
          ></b-button>
        </div>
      </div>
    </div>

    <ModalServiceTax :refsNfse="refsNfse" @baseActionsRef="findAllRefs" />
  </b-card>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import ModalServiceTax from "../../components/Modals/ModalServiceTax.vue";
import ServiceToBeProvided from "../../services/serviceToBeProvided";
import ServiceTaxNfse from "../../services/serviceTaxNfse";
import { mascaraMoeda } from "../../utils/maskMoney";
export default {
  components: {
    ModalServiceTax,
  },
  data() {
    return {
      dataProvision: {
        id: "",
        nome: "",
        valor: "",
        refFiscal: "",
        observacoes: "",
      },
      textPesquisa: "",
      dataAllItens: [],
      page: 1,
      dataLength: 0,
      tabIndexSected: 1,
      refsNfse: [],
      eventServiceProvision: false,
    };
  },

  methods: {
    ...mapMutations([
      "SET_IS_SEARCH_REGISTERS",
      "ALTER_SIZE_MODAL_REGISTER",
      "ALTER_TYPE_REGISTER",
      "SET_COLAPSE_REGISTER",
      "ALTER_MODAL_CONTAINER_REGISTER",
      "SET_DATA_SEARCH_FROM_SERVICE",
    ]),
    async save() {
      try {
        const result = await ServiceToBeProvided.save(this.dataProvision);
        this.dataProvision.id = result;
        this.searchServices();
        this.$emit(
          "provisionServiceFromCombobox",
          (this.eventServiceProvision = !this.eventServiceProvision)
        );
        this.SET_IS_SEARCH_REGISTERS();

        this.clear();
        return this.$toast.open({
          message: "Salvo com sucesso!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: `${error.response.data.erros}`,
            type: "warning",
          });
      }
    },

    async update() {
      try {
        await ServiceToBeProvided.update(this.dataProvision);
        this.searchServices();
        this.SET_IS_SEARCH_REGISTERS();

        this.clear();
        return this.$toast.open({
          message: "Atualizado com sucesso!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ocorreu um erro ao atualizar as informações",
            type: "info",
          });
      }
    },

    async getItemForGrid(idService) {
      const result = await ServiceToBeProvided.getItemForGrid(idService);

      Object.assign(this.dataProvision, result);
      this.tabIndexSected = 0;
    },

    async searchServices() {
      const result = await ServiceToBeProvided.searchServices(
        this.textPesquisa,
        this.page
      );
      this.dataAllItens = result.data;
    },

    async saveOrUpdate() {
      this.dataProvision.id === "" ? await this.save() : await this.update();
    },

    async findAllRefs() {
      try {
        const refs = await ServiceTaxNfse.findAllRefs();
        if (refs && refs.length) {
          this.refsNfse = refs.map((e) => {
            return {
              ...e,
              descricao: `${e.ref} - ${e.descricao}`,
            };
          });
        } else {
          this.refsNfse = [];
        }
      } catch (error) {
        return this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao buscar os REFS",
          type: "info",
        });
      }
    },

    maskCurrency(valor, locale = "pt-BR") {
      if (!valor) {
        valor = 0;
      }

      return parseFloat(valor).toLocaleString(locale, {
        style: "currency",
        currency: "BRL",
      });
    },

    handleValue(event) {
      this.dataProvision.valor = mascaraMoeda(event.target.value);
    },

    openModalServiceTax() {
      this.$bvModal.show("modalServiceTax");
    },

    assingEditService() {
      this.dataProvision.id = this.dataSearchService.id;
      this.dataProvision.nome = this.dataSearchService.nome;
      this.dataProvision.valor = this.dataSearchService.valor;
      this.dataProvision.observacoes = this.dataSearchService.observacoes;
      this.dataProvision.refFiscal = this.dataSearchService.refFiscal;
    },

    clear() {
      this.dataProvision.id = "";
      this.dataProvision.nome = "";
      this.dataProvision.valor = "";
      this.dataProvision.refFiscal = "";
    },
  },
  mounted() {
    this.searchServices();
    this.findAllRefs();
    if (this.dataSearchService.id) {
      this.assingEditService();
    }
  },
  computed: {
    ...mapState({
      dataSearchService: (state) => state.dataSearchService,
    }),
  },
};
</script>

<style scoped>
.styleHoverDelete:hover {
  background-color: #ff6347;
  border: solid 1px black;
}

.buttonPagePrevious {
  background-color: transparent !important;
  border: none !important;
  padding-left: 0px !important;
}

.buttonPageNext {
  background-color: transparent !important;
  border: none !important;
}

span {
  font-weight: bold;
  cursor: context-menu;
}

.user-label {
  position: absolute;
  left: 10px;
  font-size: 11px;
  bottom: 7px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.user-label-ob {
  position: absolute;
  left: 40px;
  font-size: 11px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.input {
  font-size: 12px !important;
  width: 100%;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
}

.selectPlus {
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect {
  position: absolute;
  margin-bottom: 0px;
  font-size: 11px;
  bottom: 30px;
  left: 0px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: transparent;
}
.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 11px;
  bottom: 22px;
  left: 11px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: white;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 11px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: none;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.borderInput {
  font-size: 14px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #505050;
}

.validRed {
  font-size: 13px;
  color: #ff6347;
}

.title {
  font-size: 16px;
  color: rgb(95, 95, 95);
  display: flex;
  margin-bottom: 20px;
  margin-right: 20px;
  border-bottom: 1px solid rgb(230, 230, 230);
  justify-content: space-between;
}

.rowContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 5px;
}

.requiredFields {
  font-size: 11px;
  color: #bd6859;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}

.rowObservation {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  height: 40px;
}

.btnsContainer {
  gap: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.btnsSave {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;
}

.btnsSaveNeutral {
  border-radius: 5px;
  background-color: #d7d7d7;
  width: 165px;
  border: none;
  margin-top: 30px;
  color: rgb(0, 0, 0);
  font-size: 12px;
}

.closeModal {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
  color: rgb(255, 153, 153);
  cursor: pointer;
  border: 1px solid rgb(238, 238, 238);
  padding: 2px 3px;
  border-radius: 4px;
}

.closeModal:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}

.iconSelectBarCode {
  position: absolute;
  right: 0px !important;
  z-index: 10;
  background-color: #c1c1c1;
  color: black;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
}

.input-group {
  width: 30%;
}

.input-group-ob {
  width: 100%;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 1rem 0 1rem;
}

.card {
  border: none !important;
}

.contentDescription {
  width: 100%;
  position: relative;
}

.description {
  position: absolute;
  top: -2px;
  left: 3px;
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  pointer-events: none;
  transform: translateY(-50%) scale(0.8);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  height: 12px;
  background-color: #ffff;
  padding: 0 0.2em;
  z-index: 10;
}

.inputTextArea {
  width: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: white;
  color: #5c5855;
  border-radius: 5px;
  height: 60px;
  padding: 5px;
  border: solid 1px #d9d9d9;
  resize: none;
  font-size: 12px;
}

@media screen and (max-width: 700px) {
  .rowContent {
    display: flex;
    width: 100%;
    padding: 5px 0 5px 0;
    justify-content: space-between;
    gap: 20px;
    flex-direction: column;
  }

  .input-group {
    width: 100%;
  }

  .input-group-ob {
    width: 50%;
  }

  .selectPlus {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }
}
</style>