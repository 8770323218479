export const INITIAL_STATE_SHIPPING_COMPANY = {
  id: "",
  nome: "",
  cpfCnpj: "",
  ie: "",
  endereco: "",
  numero: "",
  complemento: "",
  bairro: "",
  cidade: "",
  uf: "",
  cep: "",
  email: "",
  telefone: "",
  celular: "",
  observacao: "",
  placa: "",
  uf_veiculo: "",
  rntc: "",
  seguro: 0,
}