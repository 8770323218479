import { removeReference } from "../utils/removeReference";

export const formatObjectToSaveAndUpdate = (dataSale) => {
  let bills = [];
  let newDataSale;

  newDataSale = removeReference(dataSale)

  const newProd = dataSale?.products?.map((prod) => {
    return {
      ...prod
    }
  })

  const products = formatValuesProducts(newProd);

  if (dataSale.bills && dataSale.bills.length) {
    bills = removeReference(dataSale.bills)
  }

  Reflect.deleteProperty(newDataSale, "products");
  Reflect.deleteProperty(newDataSale, "bills");

  const sale = removeReference(newDataSale)

  return {
    products,
    sale,
    bills
  };
}

const formatValuesProducts = (products) => {
  const prodSelected = products?.filter((prod) => prod.idProduto);
  if (prodSelected?.length) {
    const formattedProducts = products.map((product) => {
      Reflect.deleteProperty(product, 'idList')

      return {
        ...product,
        valorUnitario: product.valorUnitario,
        desconto: product.desconto,
      };
    });

    return formattedProducts;
  }

  return null;
}