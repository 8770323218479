<template>
  <Cashier />
</template>

<script>
import Cashier from "../../components/Cashier/Cashier.vue";

export default {
  components: {
    Cashier,
  },
};
</script>