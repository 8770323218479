<template>
  <b-modal
    size="lg"
    id="modalListFiles"
    hide-footer
    hide-header
    @hidden="closeModal"
  >
    <div class="contentTitle">
      <span class="title">
        <b-icon-paperclip scale="1.3" />
        <p>Visualização de arquivos</p>
      </span>
      <span class="contentIconClose">
        <b-icon-x class="iconClose" scale="1.5" @click="closeModal" />
      </span>
    </div>

    <section class="containerFilles">
      <div class="titlesFles">
        <span> Tipo do arquivo </span>
        <span> Descrição </span>
        <span> Arquivo </span>
        <span style="text-align: center"> Ações </span>
      </div>

      <div style="display: flex; flex-direction: column; gap: 1rem">
        <div class="contentFiles" v-for="file in listFiles" :key="file.id">
          <span>
            {{ file.tipoArquivo }}
          </span>

          <span>
            <p class="fileNameAndDescription">
              {{ file.descricao }}
            </p>
          </span>

          <span>
            <p class="fileNameAndDescription">
              {{ file.arquivo }}
            </p>
          </span>

          <div class="fileActions">
            <div
              class="contentIcons downloadFile"
              @click="openFile(file.urlArquivo)"
            >
              <b-icon-download scale="1.3" style="color: white" />
            </div>

            <div
              class="contentIcons removeFile"
              @click="removeFile(file.id, file.id_mensagem)"
            >
              <b-icon-x scale="1.6" style="color: white" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </b-modal>
</template>

<script>
import ServiceAccountingFiles from "../../services/serviceAccountingFiles";

export default {
  props: {
    listFiles: {
      typeof: Array,
    },
  },
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("modalListFiles");
      this.$emit("modalClosed");
    },
    async removeFile(id, idMensagem) {
      try {
        await ServiceAccountingFiles.delete(id);

        this.$toast.open({
          message: "Arquivo removido!",
          type: "success",
        });
        this.$emit("removedFile", idMensagem);
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao remover o arquivo!",
          type: "info",
        });
      }
    },
    openFile(url) {
      window.open(url);
    },
  },
};
</script>

<style>
.contentTitle {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgb(95, 95, 95);
  margin-bottom: 1rem;
}

.title p {
  margin: 0;
}

.contentIconClose {
  position: absolute;
  top: -25px;
  right: -25px;
  cursor: pointer;
}

.iconClose {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  background-color: red;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.357);
}

.containerFilles {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem 0.5rem 0.5rem;
  max-height: 350px;
  overflow-y: auto;
}

.titlesFles {
  display: flex;
  gap: 1rem;
  padding: 0 1rem;
  margin-bottom: 3px;
}

.titlesFles span {
  width: 100%;
  font-size: 12px;
}

.contentFiles {
  width: 100%;
  display: flex;
  gap: 1rem;
  background-color: #eeeeee;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.357);
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-size: 11px;
}

.contentFiles span {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: default;
}

.fileActions {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0.8rem;
}

.contentIcons {
  width: 35px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.357);
}

.removeFile {
  text-align: end;
  background-color: red;
  cursor: pointer;
  transition: ease-in all 0.1s;
}

.removeFile:hover {
  background-color: rgb(154, 1, 1);
}

.downloadFile {
  text-align: start;
  background-color: rgb(100, 207, 253);
  cursor: pointer;
  transition: ease-in all 0.1s;
}

.downloadFile:hover {
  background-color: rgb(84, 177, 217);
}

.fileNameAndDescription {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (max-width: 470px) {
  .fileNameAndDescription {
    max-width: 60px;
  }
}
</style>