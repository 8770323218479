import api from './axios'

class Login {
  async logar(dataLogin) {
    const { data } = await api.post("/login", dataLogin)
    return data
  }

  async joinMoreCompanies(logins) {
    const { data } = await api.post("/login/join/more/companies", logins)
    return data
  }
}

export default new Login()