<template>
  <div class="input-group">
    <input
      autocomplete="one-time-code"
      required
      :type="typeInput ? 'password' : 'text'"
      :class="` ${isInvalidInput} ${borderInvalid && 'borderInvalid'}  ${
        large ? 'large-input-focus' : 'input'
      } col-sm-12 col-md-12 col-lg-12 col-xl-12 borderInput ${
        large ? 'large' : 'normalSize'
      } ${textAlign} 
      ${isDisabled && 'inputDisabled'} ${large && 'large'}`"
      v-model="valueInput"
      @input="valueSelected"
      ref="inputPassword"
      @blur="clearInvalidInput"
    />

    <label
      :class="`${labelFixed ? 'labelFixed' : 'user-label'}  ${
        !label && 'labelFixedDisabled'
      } ${large && 'user-label-large'}`"
    >
      {{ label }}
    </label>

    <div :class="`enablePassword`" @click="alterTypeInput">
      <div role="button">
        <b-icon-eye-slash-fill scale="1.3" v-if="!typeInput" />
        <b-icon-eye-fill scale="1.3" v-else />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
    },
    labelFixed: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    dataInput: {
      type: [String, Number, Function],
    },
    mask: {
      type: [String, Number],
    },
    isDisabled: {
      type: Boolean,
    },
    isDiscountAllItems: {
      type: Boolean,
    },
    isDiscountToIndividuallyItem: {
      type: Boolean,
    },
    alterTotalValue: {
      type: [String, Number],
    },
    textAlign: {
      type: String,
    },
    change: {
      type: Function,
    },
    large: {
      type: Boolean,
      default: false,
    },
    isInvalidInput: {
      type: String,
    },
    borderInvalid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueInput: "",
      typeInput: true,
    };
  },
  created() {
    this.valueInput = this.dataInput;
  },
  methods: {
    valueSelected() {
      this.$emit("update:dataInput", this.valueInput);
      this.$emit("calculateTotal", this.valueInput);
      this.$emit("changed");
    },
    alterTypeInput() {
      this.typeInput = !this.typeInput;
    },
    clearInvalidInput() {
      this.$emit("clearInvalidInput");
    },
  },
};
</script>

<style scoped>
.user-label {
  position: absolute;
  left: 3px;
  bottom: 7px;
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.user-label-large {
  bottom: 1.4rem;
  margin-bottom: 0;
  left: 1rem;
  font-size: 14px;
  background-color: #ffff;
  font-weight: normal !important;
  font-family: Poppins-Medium, sans-serif !important;
  color: #666360c6;
}

.labelFixed {
  position: absolute;
  left: 3px;
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  bottom: 13px;
  pointer-events: none;
  transform: translateY(-50%) scale(0.8);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  height: 12px;
  background-color: #ffff;
  padding: 0 0.2em;
}

.input {
  font-size: 12px !important;
  padding-right: 35px !important;
}

.inputDisabled {
  background-color: #fafafa;
  pointer-events: none;
  color: #949290 !important;
}

.labelFixedDisabled {
  padding: 0 !important;
}

.large-input-focus:focus ~ label,
.large-input-focus:valid ~ label {
  transform: translateY(-100%) !important;
  background-color: #ffffff !important;
  padding: 0 0.5rem !important;
  font-size: 12px !important;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.2em;
  font-weight: 600;
}

.borderInput {
  position: relative;
  font-size: 14px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #a9cff4;
}

.enablePassword {
  position: absolute;
  right: 1px !important;
  top: 1px;
  z-index: 10;
  background-color: #c1c1c1;
  color: whitesmoke;
  height: 96%;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
}

.normalSize {
  height: 30px;
}

.large {
  height: 50px;
}

.isInvalidInput:focus {
  border: solid 1px rgba(204, 21, 21, 0.583) !important;
  transition: ease-in all 0.1s !important;
  box-shadow: 0 0 2px 1px rgba(204, 21, 21, 0.583) !important;
}

.borderInvalid {
  border: solid 1px red !important;
  box-shadow: 0 0 2px 0 rgba(255, 0, 0, 0.613) !important;
}
</style>