<template>
  <b-modal size="lg" id="modalCreateCustomer" hide-footer hide-header>
    <div class="summaryForm">
      <button class="closeModal" @click="$bvModal.hide('modalCreateCustomer')">
        <b-icon-x scale="1.3"></b-icon-x>
      </button>
      <Customer
        @getAllCustomer="createdNewCustomer"
        :enableHeader="false"
        :idCustomerFromSearch="idCustomerFromSearch"
      />
    </div>
  </b-modal>
</template>

<script>
import Customer from "../Customer/Customer.vue";
export default {
  props: {
    idCustomerFromSearch: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  components: {
    Customer,
  },
  methods: {
    createdNewCustomer() {
      this.$emit("getAllNewCustomer");
    },
  },
  computed: {},
};
</script>

<style scoped>
.summaryForm {
  display: flex;
  flex-direction: column;
  color: #00486e;
}

.summaryForm label {
  margin-bottom: 0px;
  font-weight: bold;
}

.btnBack {
  border-radius: 5px;
  background-color: #00395d;
  border: none;
  margin-top: 30px;
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  width: 150px;
  font-size: 18px;
  margin-top: 0;
}

.closeModal {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
  color: rgb(255, 153, 153);
  cursor: pointer;
  border: 1px solid rgb(238, 238, 238);
  padding: 2px 3px;
  border-radius: 4px;
}

.closeModal:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}
</style>