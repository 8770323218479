<template>
  <div>
    <b-modal id="modalImportXml" size="xl" title="">
      <template #modal-title>
        <div class="headerModal">
          <span class="titleModal">Importação de XML</span>
        </div>
      </template>
      <div>
        <b-row class="inputXml">
          <b-form-file
            style="width: 70%"
            placeholder="Selecione um XML ou arraste e solte aqui..."
            drop-placeholder="Solte o arquivo aqui..."
            size="sm"
            id="fileXml"
            v-model="xmlFile"
            browse-text="Arquivo"
          ></b-form-file>
          <b-button class="linkXml" @click="converXmlAndReturnObjectAndSave"
            >Ler Xml</b-button
          >
        </b-row>
        <div class="tableProducts">
          <span class="titleProducts">Produtos contidos no XML</span>
          <div class="headProducts">
            <p style="width: 40%">Nome</p>
            <p style="width: 10%">Quant</p>
            <p style="width: 10%">Vl. Unitário</p>
            <p style="width: 10%">Vl. Total</p>
            <p style="width: 20%">Produto Existente</p>
            <p style="width: 10%">Vincular</p>
          </div>
          <div
            class="rowProducts"
            v-for="(item, index) in productsListGrid"
            :key="item.uuid"
          >
            <p style="width: 40%">{{ item.nome }}</p>
            <p style="width: 10%">{{ item.quantidade }}</p>
            <p style="width: 10%">
              {{
                item.valor.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </p>
            <p style="width: 10%">
              {{
                (item.valor * item.quantidade).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </p>
            <div class="selectPlus" style="width: 20%">
              <b-form-select
                class="borderInput shadow-none"
                text-field="nome"
                value-field="id"
                v-model="idProductForSelectBox[index]"
                size="sm"
                :options="productsForSelectBox"
              ></b-form-select>
            </div>
            <b-button
              :disabled="!idProductForSelectBox[index] || isProductLinked(item)"
              style="width: 10%"
              class="linkProduct"
              @click="assingProducts(item, idProductForSelectBox[index])"
              >Vincular</b-button
            >
          </div>
        </div>
      </div>
      <template #modal-footer="{ cancel }">
        <div class="content-btn">
          <b-button @click="cancel" class="CancelBtn">Cancelar</b-button>
          <b-button @click="saveAllPurchase" class="saveBtn"
            >Salvar produtos</b-button
          >
        </div>
      </template>
    </b-modal>
    <b-modal id="modal-center3" hide-footer hide-header centered title="assign">
      <div class="contentNot">
        <div class="contentNot">
          <img class="alertImg" :src="alert" />
          <span>Já existe uma nota importada com essa chave</span>
          <span>Deseja prosseguir ?</span>
        </div>
        <div class="btnModal">
          <button class="btnContent" @click="assignValuesToTheObjectPurchase">
            Continuar
          </button>
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modal-center3')"
          >
            Não, Obrigado!
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-bill" hide-footer hide-header centered title="bill">
      <div class="contentNot">
        <div class="contentNot">
          <img class="alertImg" :src="alert" />
          <span>Deseja Realizar o lançamento dessa conta ?</span>
        </div>
        <div class="btnModal">
          <button class="btnContent" @click="generateDuplicate">
            Ir para o Financeiro
          </button>
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modal-bill')"
          >
            Não, Obrigado!
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import api from "../../services/axios";
import ConvertXml from "../../services/serviceConvertXml";
import AssigningValuesToTheObject from "../../services/assigningValues​ToTheObject";
import alert from "../../assets/alert.svg";
import { v4 as uuidv4 } from "uuid";
import ServiceImportMovimentPurchase from "../../services/serviceImportMovimentPurchase";
import serviceProducts from "../../services/serviceProducts";
import { mapMutations } from "vuex";
import toastAlertErros from "../../utils/toastAlertErros";
import { HandleErrors } from "../../utils/handleErrors";
export default {
  props: {
    cfopSelect: {
      type: String,
    },
  },
  data() {
    return {
      alert: alert,
      xmlFile: null,
      objectPurchase: null,
      objectPurchaseTotal: {},
      productsListGrid: [],
      allPurchase: [],
      productsSelected: [],
      productsForSelectBox: [],
      idProductForSelectBox: [],
      idProduct: "",
      productsXml: [],
      objectGenerateDuplicate: {},
    };
  },

  methods: {
    ...mapMutations(["SET_DATA_SEARCH_FROM_BILL"]),
    async converXmlAndReturnObjectAndSave() {
      if (this.xmlFile.type !== "text/xml") {
        return;
      }
      const xmlConverted = await this.convertXml();
      this.returnPurchaseObject(xmlConverted);
    },

    async convertXml() {
      try {
        if (this.xmlFile === null) {
          this.$toast.open({
            message: "Selecione o arquivo XML!",
            type: "warning",
          });
          return null;
        }

        const xmlConvertedToString = await ConvertXml.xmlString(this.xmlFile);

        if (xmlConvertedToString === null) {
          this.$toast.open({
            message: "O arquivo deve ser um XML VALIDO",
            type: "error",
          });
          return null;
        } else {
          const { data } = await api.post("/purchasenote", {
            xmlString: xmlConvertedToString,
          });
          return data;
        }
      } catch (error) {
        console.log(error, "ocorreu um erro ao converter o arquivo XML");
      }
    },

    returnPurchaseObject(xmlConverted) {
      try {
        if (xmlConverted === null) {
          return;
        }
        this.objectPurchase = ConvertXml.ObjectPurchase(xmlConverted);
        this.assignValuesToTheObjectPurchase(this.objectPurchase);
      } catch (error) {
        console.log(
          error,
          "error ao atribuir objetos do xml ao objeto da nota de compra"
        );
      }
    },

    async findAllPurchase(object) {
      const { data } = await api.get("/purchase/search/all/purchase/keys");
      this.allPurchase = data.data;
      const teste = this.allPurchase.find(
        (item) => item.chaveNfe == object.key
      );
      if (teste) {
        this.$bvModal.show("modal-center3");
      } else {
        this.assignValuesToTheObjectPurchase(this.objectPurchase);
      }
    },

    assignValuesToTheObjectPurchase(objectPurchase) {
      this.objectPurchaseTotal =
        AssigningValuesToTheObject.assigningValues(objectPurchase);
      this.productsListGrid = this.objectPurchaseTotal.products.map((item) => {
        return {
          ...item,
          uuid: uuidv4(),
        };
      });
    },

    async saveAllPurchase() {
      try {
        if (Object.values(this.objectPurchaseTotal).length === 0) {
          return;
        }

        const dataAllPurchase = {
          products: this.objectPurchaseTotal.products,
          supplier: this.objectPurchaseTotal?.supplier,
          purchase: this.objectPurchaseTotal?.purchase,
          key: this.objectPurchaseTotal?.key,
        };

        dataAllPurchase.purchase.cfop = this.cfopSelect;
        const { data } = await ServiceImportMovimentPurchase.save(
          dataAllPurchase
        );

        this.assingDataForStoreBill(data, dataAllPurchase);

        this.$emit("dataProduct", dataAllPurchase.products);
        this.$emit("idSupplierForSelectBox", data.idProvider);
        this.$emit("modalIdForPurchase", data.idPurchase);
        this.$emit("idMovimentPurchase", data.id);

        this.productsListGrid = [];
        this.$bvModal.show("modal-bill");
        this.$bvModal.hide("modalImportXml");
        return this.$toast.open({
          message: "Importado com sucesso!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;

        const typeError = HandleErrors(message);
        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        } else
          toastAlertErros.validateErroDoesNotContainFor(error, this.$toast);
      }
    },

    async getProductsForSelectBox() {
      try {
        const products = await serviceProducts.getProductsForSelectBox();
        this.productsForSelectBox = products.data.map((item) => {
          return {
            ...item,
            uuid: uuidv4(),
          };
        });
      } catch (error) {
        return this.$toast.open({
          message: "Ocorreu um erro ao listar os produtos!",
          type: "error",
        });
      }
    },

    isProductLinked(product) {
      return this.productsXml.some((p) => p.id === product.id);
    },

    async assingProducts(productXml, idProductExist) {
      try {
        if (this.isProductLinked(productXml)) {
          this.$toast.open({
            message: "Este produto já está vinculado.",
            type: "error",
          });
          return;
        }
        const data = await serviceProducts.getProductById(idProductExist);
        productXml.nome = data.nome;
        productXml.id = data.id;
        this.productsXml.push(productXml);
        Object.assign(this.objectPurchaseTotal.products, this.productsXml);
        this.$toast.open({
          message: "Vinculo realizado com sucesso",
          type: "success",
        });
      } catch (error) {
        console.log(error);
      }
    },

    assingDataForStoreBill(data, dataAllPurchase) {
      this.objectGenerateDuplicate.valorTotal = dataAllPurchase.products.reduce(
        (total, product) =>
          total + (product.quantidade * product.valor - product.desconto),
        0
      );
      dataAllPurchase;
      this.objectGenerateDuplicate.idFornecedor = data.idProvider;
      this.objectGenerateDuplicate.descricao = `Referente a nota de compra N°${dataAllPurchase.purchase.numeroNfe}`;
    },

    async generateDuplicate() {
      this.$toast.open({
        message: "Elaborado uma duplicata a partir de uma nota de compra!",
        type: "success",
      });

      this.SET_DATA_SEARCH_FROM_BILL(this.objectGenerateDuplicate);

      return this.$router.push({
        path: `/financeiro`,
      });
    },
  },

  mounted() {
    this.getProductsForSelectBox();
  },
};
</script>

<style scoped>
.inputXml {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  font-size: 13px;
  text-align: center;
  background: #fffcfc;
}

.headerModal {
  font-size: 16px !important;
  font-weight: bold;
}

.alertModal {
  font-size: 11px;
  color: rgb(115, 0, 0);
  padding-left: 40px;
}

.tableProducts {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px 10px 10px 10px;
  width: 100%;
  font-size: 13px;
  height: 60vh;
  max-height: 60vh;
  overflow: scroll;
  text-align: center;
  background: #fffcfc;
}

.titleProducts {
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
  cursor: context-menu;
}

.custom-file-label::after {
  background: red !important;
}

.headProducts {
  display: flex;
  border-bottom: 2px solid #d5d3d3;
  font-size: 12px;
  font-weight: bold;
}
.headProducts p {
  margin-bottom: 0rem !important;
}

.rowProducts p {
  margin-bottom: 0rem !important;
}

.rowProducts {
  display: flex;
  gap: 5px;
  height: 40px;
  border-bottom: 1px solid #f1efef;
  width: 100%;
  align-items: center;
}

.linkProduct {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background: #00486e;
  color: white;
  border-radius: 3px;
  border: none;
  width: 100px;
  height: 28px;
  gap: 10px;
  box-shadow: 0px 1px 1px 0px black;
}

.linkProduct:hover {
  background: #002a40;
  color: whitesmoke;
}

.linkXml {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background: #00486e;
  border: none;
  color: white;
  border-radius: 3px;
  width: 100px;
  height: 28px;
  gap: 10px;
  box-shadow: 0px 1px 1px 0px black;
}

.linkXml:hover {
  background: #002a40;
  color: whitesmoke;
}

.saveBtn {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background: rgb(255, 255, 255);
  border: 1px solid green;
  color: green;
  border-radius: 3px;
  width: 180px;
  height: 28px;
  box-shadow: 0px 1px 1px 0px black;
}

.saveBtn:hover {
  background: whitesmoke;
  border: 1px solid green;
  color: green;
}

.CancelBtn {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background: rgb(255, 255, 255);
  border: 1px solid red;
  color: red;
  border-radius: 3px;
  width: 180px;
  height: 28px;
  box-shadow: 0px 1px 1px 0px black;
}

.CancelBtn:hover {
  background: whitesmoke;
  border: 1px solid red;
  color: red;
}

.selectPlus {
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect {
  position: absolute;
  font-size: 12px;
  bottom: 12px;
  left: 10px;
  background-color: #ffffff;
  padding: 0 0.4em;
}

.borderInput {
  font-size: 12px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #505050;
}

.contentNot {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.alertImg {
  width: 100px;
  height: 100px;
}

.btnModal {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.btnContent {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentReceive {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.content-btn {
  display: flex;
  align-items: center;
  justify-content: space-around !important;
  width: 100%;
}

.custom-select {
  padding: 0.375rem 0.25rem 0.375rem 0.75rem;
  line-height: 1.5;
  background: none;
}

@media screen and (max-width: 700px) {
  .tableProducts {
    padding: 0px;
    font-size: 11px;
  }

  .headProducts {
    font-size: 11px;
  }
}
</style>
