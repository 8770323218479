<template>
  <div class="cardGraphicComponent" id="grapic2" />
</template>

<script>
import * as echarts from "echarts";
import api from "../../services/axios";
import moment from "moment";
import "moment/locale/pt";

export default {
  data() {
    return {
      graphicReceived: [],
      graphicPayabled: [],
      dataBill: [],
      chart: null,
      option: {
        series: [{ data: [] }, { data: [] }],
      },
    };
  },
  methods: {
    async readBills(startDate, endDate) {
      try {
        const { data } = await api.get(
          `/bills/search/per/week/sum/bill/by/dashboard/grapic?startDate=${startDate}&endDate=${endDate}`
        );

        this.dataBill = data.data;
        this.graphicReceived = this.dataBill.groupReceive;
        this.graphicPayabled = this.dataBill.groupPayable;

        const receivedData = [];
        const payableData = [];

        for (const date in this.dataBill.totalReceive) {
          receivedData.push([date, this.dataBill.totalReceive[date]]);
        }

        for (const date in this.dataBill.totalPayable) {
          payableData.push([date, this.dataBill.totalPayable[date]]);
        }

        this.option.series[0].data = receivedData;
        this.option.series[1].data = payableData;
      } catch (error) {
        console.error(error);
      }
    },

    getDays() {
      const currentDate = moment();
      const days = [currentDate.format("YYYY-MM-DD")];

      for (let i = 1; i <= 6; i++) {
        const previousDay = currentDate.clone().subtract(i, "days");
        days.push(previousDay.format("YYYY-MM-DD"));
      }

      return days.reverse();
    },

    convertData(data) {
      data = data.sort(
        (a, b) => moment(a[0]).valueOf() - moment(b[0]).valueOf()
      );

      return data.map((item) => [
        moment(item[0]).format("YYYY-MM-DD"),
        item[1] || 0,
      ]);
    },
  },
  async mounted() {
    const days = this.getDays();
    await this.readBills(days[0], days[6]);

  if (this.graphicReceived && this.graphicPayabled) {
    const sortedReceivedData = this.convertData(this.graphicReceived);
    const sortedPayableData = this.convertData(this.graphicPayabled);

    this.option.series[0].data = sortedReceivedData;
    this.option.series[1].data = sortedPayableData;
  }

    var chartDom2 = document.getElementById("grapic2");
    var myChart2 = echarts.init(chartDom2);

    var option2 = {
      title: {
        left: "center",
        padding: 20,
        text: "Contas a Pagar e Contas a Receber",
        textStyle: {
          fontSize: 13,
        },
      },
      legend: {
        padding: 10,
        top: "bottom",
        data: ["A receber", "A pagar"],
      },
      tooltip: {
        triggerOn: "none",
        position: function (pt) {
          return [pt[0], 130];
        },
      },
      xAxis: {
        type: "time",
        axisPointer: {
          value: moment().format("YYYY-MM-DD"),
          snap: true,
          lineStyle: {
            color: "#7581BD",
            width: 1,
          },
          label: {
            show: true,
            fontSize: 12,
            formatter: function (params) {
              return moment(params.value).format("DD/MM/YYYY");
            },
            backgroundColor: "#7581BD",
          },
          handle: {
            show: true,
            color: "#7581BD",
            size: 25,
            margin: 40,
            // icon:'image://http://echarts.baidu.com/images/favicon.png',
          },
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: "value",
        axisTick: {
          inside: true,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          inside: true,
          formatter: "{value}\n",
        },
        z: 10,
      },
      grid: {
        top: 60,
        left: 15,
        right: 15,
        height: 150,
      },
      dataZoom: [
        {
          type: "inside",
          throttle: 40,
        },
      ],
      series: [
        {
          name: "A receber",
          type: "line",
          smooth: true,
          symbol: "circle",
          symbolSize: 5,
          sampling: "average",
          itemStyle: {
            color: "#0770FF",
          },
          stack: "a",
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgba(58,77,233,0.4)",
              },
              {
                offset: 1,
                color: "rgba(58,77,233,0.1)",
              },
            ]),
          },
          data: this.option.series[0].data,
        },
        {
          name: "A pagar",
          type: "line",
          smooth: true,
          stack: "a",
          symbol: "circle",
          symbolSize: 5,
          sampling: "average",
          itemStyle: {
            color: "#F2597F",
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgba(213,72,120,0.4)",
              },
              {
                offset: 1,
                color: "rgba(213,72,120,0.1)",
              },
            ]),
          },
          data: this.option.series[1].data,
        },
      ],
    };

    option2 && myChart2.setOption(option2);
  },
};
</script>
<style scoped>
.cardGraphicComponent {
  height: 100%;
}
</style>