import api from "./axios";

class ServiceProductsComposition {
  async save(composition) {
    const { data } = await api.post('/product-composition', composition)
    return data.id
  }

  async update(dataProducts) {
    await api.put(`/product-composition`, dataProducts)
  }

  async findByProductId() {
    const { data } = await api.get("/product-composition");
    return data
  }

  async findByIdRefProduct(id) {
    const { data } = await api.get(`/product-composition/compositions/${id}`);
    return data
  }

  async deleteById(id) {
    await api.delete(`/product-composition/${id}`);
  }
}

export default new ServiceProductsComposition