class ConvertXml {
  async xmlString(xml) {
    if (xml.type === 'text/xml') {
      const result = await new Promise((resolve) => {
        const xmlString = xml;
        let reader = new FileReader();
        reader.readAsText(xmlString);

        reader.onload = function (e) {
          return resolve(e.target.result);
        };
      });
      return result;
    } else {
      return null
    }
  }

  ObjectPurchase(object) {
    const objectPurchase = {
      fornecedor: {},
      produtos: {},
      purchase: {},
      key: {},
      impostos: {},
    }

    objectPurchase.fornecedor = object.data.nfeProc ? object.data.nfeProc.NFe[0].infNFe[0].emit : object.data?.NFe.infNFe[0].emit
    objectPurchase.produtos = object.data.nfeProc ? object.data.nfeProc.NFe[0].infNFe[0].det : object.data?.NFe.infNFe[0].det
    objectPurchase.purchase = object.data.nfeProc ? object.data.nfeProc.NFe[0].infNFe[0].ide : object.data?.NFe.infNFe[0].ide
    objectPurchase.key = object.data.nfeProc?.protNFe[0]?.infProt[0]?.chNFe || 0
    objectPurchase.impostos = object.data.nfeProc ? object.data.nfeProc.NFe[0]?.infNFe[0]?.total[0].ICMSTot : object.data?.NFe.infNFe[0].total[0].ICMSTot

    return objectPurchase
  }
}

export default new ConvertXml()

