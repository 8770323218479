import api from "./axios";

class ServicePaymentForm {
  async getPaymentFindById(idTipoPagamento) {
    const { data } = await api.get(`/payments/${idTipoPagamento}`);
    return data;
  }

  async getAllPayment() {
    const { data } = await api.get(`/payments/combobox`);
    return data;
  }
}

export default new ServicePaymentForm()