<template>
  <div>
    <div class="ContentTable">
      <table style="width: 100%">
        <thead class="headTable">
          <tr style="width: 100%">
            <th style="width: 15%">Dt. de Vencimento</th>
            <th>Cliente</th>
            <th>Qt. Parcelas</th>
            <th>Situação</th>
            <th>Valor Parcela</th>
            <th style="width: 15%">Dt. Recebimento</th>
            <th>Valor Recebido</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody v-if="this.dataLength > 0">
          <tr
            v-for="bill in receiveFilter"
            :key="bill.id"
            style="text-align: center; color: #3a4750"
          >
            <td>{{ bill.data | moment }}</td>
            <td>{{ bill.nome || bill.nomeFantasia }}</td>
            <td>{{ bill.numeracao }}/{{ bill.totalContas }}</td>
            <td
              class="contentStatus"
              v-if="bill.situacao === billSituation.pago"
            >
              <span class="statusPayment">{{ bill.situacao }}</span>
            </td>
            <td
              class="contentStatus"
              v-else-if="bill.situacao === 'Pago Parcial'"
            >
              <span class="statusPaymentPartial">{{ bill.situacao }}</span>
            </td>
            <td
              class="contentStatus"
              v-else-if="bill.situacao === billSituation.perda"
            >
              <span class="statusPaymentLoss">{{ bill.situacao }}</span>
            </td>
            <td
              class="contentStatus"
              v-else-if="bill.situacao === billSituation.vencido"
            >
              <span class="statusPaymentLate">{{ bill.situacao }}</span>
            </td>
            <td class="contentStatus" v-else>
              <span class="statusPaymentOpen">{{ bill.situacao }}</span>
            </td>
            <td>
              {{
                bill.valorTotal.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </td>
            <td v-if="bill.dataPagamento != null">
              {{
                bill.dataPagamento.split(" ")[0].split("-").reverse().join("/")
              }}
            </td>
            <td class="paymentEmpty" v-else>x</td>
            <td>
              {{
                bill.valorTotalRegistros.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </td>
            <td>
              <div class="btnActions">
                <b-dropdown class="btnActions" size="sm" variant="none">
                  <template #button-content>
                    <b-icon-gear-fill class="contentActions"></b-icon-gear-fill>
                  </template>
                  <b-dropdown-item @click="findById(bill)">
                    Editar Recebimento
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="bill.situacao !== billSituation.pago"
                    @click="findByIdAndOpenBillDetails(bill)"
                  >
                    Informar Recebimento
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="openModalChangeSituationToLoss(bill)"
                    v-if="bill.situacao === billSituation.emAberto"
                  >
                    Marcar como Perda
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="changeSituationToOpen(bill.id)"
                    v-if="bill.situacao === billSituation.perda"
                  >
                    Habilitar recebimento
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="bill.situacao === billSituation.pago"
                    @click="soFindById(bill)"
                  >
                    Gerar Recibo
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="
                      bill.situacao === billSituation.pago ||
                      bill.situacao === billSituation.pagoParcial
                    "
                    @click="findDetailedBillAndOpenModalReversal(bill)"
                  >
                    Estornar
                  </b-dropdown-item>
                  <b-dropdown-item @click="openModalConfirmDelete(bill.id)">
                    Excluir
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-b-modal.modal-allBill
                    @click="findByIdUnion(bill)"
                  >
                    Ver Parcelas
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <td colspan="8">
            <div class="defaultSearch">
              <!-- <img style="width: 25%" loading="lazy" :src="Search" /> -->
              <span>Não há dados para exibir ...</span>
            </div>
          </td>
        </tbody>
        <div v-if="spinGeneral" class="loadingLazy">
          <div class="loader"></div>
        </div>
      </table>
      <b-modal
        size="lg"
        id="changeSituationToLoss"
        hide-footer
        hide-header
        centered
      >
        <div>
          <h6 class="titleModalPerda">Marcar recebimento como perda</h6>

          <div style="padding: 15px 0px">
            <p>
              A conta com o valor restante de {{ remainingValue }} sera marcada
              como perda.
            </p>

            <p style="margin: 0">
              Esse valor não será contabilizado e aparecerá somente no relatório
              específico para contas marcadas como perda.
            </p>
          </div>

          <div class="btnsModalActions">
            <button class="btnCancel" @click="closeModalChangeSituationToLoss">
              Cancelar
            </button>
            <button class="btnConfirm" @click="changeSituationToLoss">
              Confirmar
            </button>
          </div>
        </div>
      </b-modal>

      <b-modal
        id="ModalDeleteBillOrReversal"
        hide-footer
        hide-header
        centered
        @hidden="closeReversalOrDeleteBillModal"
      >
        <div>
          <div class="contentTitle">
            <h6 class="titleModalPerda">
              {{ isDeleteBill ? "Excluir lançamento" : "Estornar" }}
            </h6>
            <h6 class="titleModalPerda" v-if="!isDeleteBill">
              {{ valueIsPaidAndAmountBill.amount }}
            </h6>
          </div>

          <section
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              gap: 20px;
              margin-top: 20px;
              margin-bottom: 15px;
              padding: 0 10px;
            "
          >
            <div style="width: 100%">
              <p class="subTitle" v-if="!isDeleteBill" style="margin: 0">
                A conta com o valor pago de
                {{ valueIsPaidAndAmountBill.paid }} esta prestes a ser
                estornada!
              </p>

              <p class="subTitle" v-else style="margin: 0">
                A conta selecionada esta prestes a ser excluida!
              </p>
            </div>

            <div style="width: 100%">
              <b-form-checkbox
                class="footerTitle"
                v-if="!isDeleteBill"
                size="sm"
                id="checkbox-1"
                v-model="confirmReversalOrDeleteBill"
                name="checkbox-1"
              >
                Estou ciente e desejo estornar o valor
                {{ valueIsPaidAndAmountBill.paid }} e seus lançamentos!
              </b-form-checkbox>

              <b-form-checkbox
                class="footerTitle"
                v-else
                size="sm"
                id="checkbox-1"
                v-model="confirmReversalOrDeleteBill"
                name="checkbox-1"
              >
                Estou ciente e desejo excluir essa conta.
              </b-form-checkbox>
            </div>
          </section>

          <div class="btnsModalActions">
            <button class="btnCancel" @click="closeReversalOrDeleteBillModal">
              Não
            </button>
            <button
              :class="`btnConfirm ${
                !confirmReversalOrDeleteBill && 'btnDisabled'
              }`"
              @click="handleBillReversalOrDeleteBill"
              style="position: relative"
            >
              {{ isDeleteBill ? " Sim, excluir" : " Sim, estornar" }}
              <span
                v-if="spinLoadingActionsButton"
                style="position: absolute; right: 5px"
                ><b-spinner small
              /></span>
            </button>
          </div>
        </div>
      </b-modal>

      <b-modal
        id="modal-center4"
        hide-footer
        hide-header
        centered
        title="Excluir"
      >
        <div>
          <h6 class="titleModal">Ver detalhes</h6>
          <div class="btnModal">
            <button>imprimir</button>
          </div>
        </div>
      </b-modal>

      <b-modal
        id="modal-allBill"
        hide-footer
        hide-header
        centered
        size="lg"
        title="Excluir"
      >
        <div>
          <div class="titleModalBill">
            <h6 class="textModalBill">Resumo das Duplicatas</h6>
            <div class="closeModalBill" @click="$bvModal.hide('modal-allBill')">
              X
            </div>
          </div>
          <div class="ContentTableBill">
            <div class="noContentTable" v-if="dataAllBills == ''">
              Não há duplicatas pagas registradas!
            </div>
            <table style="border-spacing: 1px 0" v-else>
              <thead
                style="
                  color: whitesmoke;
                  background: #3a4750;
                  padding: 10px;
                  height: 35px;
                  text-align: center;
                  border-spacing: 0;
                "
              >
                <tr style="width: 100%">
                  <th style="width: 16%">Data</th>
                  <th style="width: 10%">Tipo</th>
                  <th style="width: 26%">Status</th>
                  <th style="width: 16%">Vl Total</th>
                  <th style="width: 16%">Vl Pago</th>
                  <th style="width: 10%">Excluir</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style="
                    text-align: center;
                    height: 35px;
                    border-bottom: 1px solid rgb(218, 218, 218);
                  "
                  v-for="(dataUnBill, index) in dataAllBills"
                  :key="dataUnBill.id"
                >
                  <td style="width: 16%">
                    {{
                      dataUnBill.data
                        .split(" ")[0]
                        .split("-")
                        .reverse()
                        .join("/")
                    }}
                  </td>
                  <td style="width: 10%">{{ dataUnBill.tipo }}</td>
                  <td
                    class="contentStatusAll"
                    v-if="dataUnBill.situacao === 'Em aberto'"
                  >
                    <b-icon-x-circle-fill
                      class="statusPaymentOpenAll"
                    ></b-icon-x-circle-fill>
                    <span>Em aberto</span>
                  </td>
                  <td
                    v-else-if="dataUnBill.situacao === 'Pago'"
                    class="contentStatusAll"
                  >
                    <b-icon-check-circle-fill
                      class="statusPaymentAll"
                    ></b-icon-check-circle-fill>
                    <span>Pago</span>
                  </td>
                  <td
                    v-else-if="dataUnBill.situacao === 'Pago Parcial'"
                    class="contentStatusAll"
                  >
                    <b-icon-check-circle-fill
                      class="statusPartialPaid"
                    ></b-icon-check-circle-fill>
                    <span>Pago Parcial</span>
                  </td>
                  <td v-else class="contentStatusAll">
                    <b-icon-dash-circle-fill class="statusExpired" />
                    <span>Vencido</span>
                  </td>
                  <td style="width: 16%">
                    {{
                      dataUnBill.valorTotal.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }}
                  </td>
                  <td style="width: 16%">
                    {{
                      dataUnBill.valorPago.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }}
                  </td>
                  <td style="width: 10%">
                    <b-icon-x-circle
                      scale="1.5"
                      class="delBill"
                      @click="deleteUnionBill(dataUnBill, index)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="ctnDelAll">
            <button
              class="btnContentWhiteDelAll"
              @click="$bvModal.hide('modal-center4')"
            >
              Fechar
            </button>
            <button class="btnContentDelAll" v-b-modal.modal-excluir>
              Excluir Todas
            </button>
          </div>
        </div>
      </b-modal>

      <b-modal
        id="modal-excluir"
        hide-footer
        hide-header
        centered
        size="md"
        title="Excluir"
      >
        <div>
          <h6 class="textModalBill">Deseja excluir todas as duplicatas?</h6>
          <div class="ctnLoadDelAll" v-if="spinDelete">
            <b-spinner class="spin"></b-spinner>
            <span>Aguarde ...</span>
          </div>
          <div v-else class="ctnDelAll">
            <button
              class="btnContentConfirmedWhiteDelAll"
              @click="$bvModal.hide('modal-excluir')"
            >
              Não, obrigado!
            </button>
            <button
              class="btnContentConfirmedDelAll"
              @click="deleteAllUnionBills"
            >
              Sim, quero excluir!
            </button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import ServiceBill from "../../services/serviceBill";
import toastAlertErros from "../../utils/toastAlertErros";
import { BillSituation } from "../../utils/enums/BillSituation";
import printReport from "../../reports/sale";
import moment from "moment";

export default {
  props: {
    receiveFilter: {},
    dataLength: {
      type: Number,
    },
  },
  components: {},
  data() {
    return {
      kind: "entrada",
      page: 1,
      spinGeneral: false,
      spinDelete: false,
      dataBill: [],
      dataAllBills: [],
      spinLoading: false,
      remainingValue: "",
      idBillToChangeToLoss: "",
      idBillToDelete: "",
      valuesToModalBillReversal: [],
      confirmReversalOrDeleteBill: false,
      spinLoadingActionsButton: false,
      isDeleteBill: false,
      billSituation: BillSituation,
    };
  },
  methods: {
    ...mapMutations([
      "SET_DATA_SEARCH_FROM_BILL",
      "ALTER_SIZE_MODAL_FINANCIAL",
      "SET_COLAPSE_FINANCIAL",
      "ALTER_TYPE_FINANCIAL",
      "SET_IS_SEARCH_BILLS",
      "ALTER_MODAL_CONTAINER_FINANCIAL",
    ]),

    async findById(bill) {
      try {
        const result = await ServiceBill.findById(bill.id);
        result.totalContas = bill.totalContas;

        this.openModalToReceive(result);
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ocorreu um eror ao buscar a conta!",
            type: "error",
          });
      }
    },
    async findByIdUnion(bill) {
      try {
        this.dataAllBills = await ServiceBill.findByIdUnion(bill.idUnion);
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ocorreu um eror ao buscar a conta!",
            type: "error",
          });
      }
    },
    async findByCasherEdit(id) {
      const result = await ServiceBill.findById(id);
      if (result.tipo == "entrada") {
        const totalContas = await this.dataBill.find(
          (bill) => bill.id == result.id
        );
        result.totalContas = totalContas.totalContas;
        this.openModalToReceive(result);
      } else {
        this.openModalToPayable(result);
      }
    },
    async soFindById(bill) {
      try {
        const result = await ServiceBill.findById(bill.id);
        this.printProofOfAcquittance(result.id);
      } catch (error) {
        return this.$toast.open({
          message: "Ocorreu um erro ao buscar a conta!",
          type: "error",
        });
      }
    },

    openModalToReceive(dataBill) {
      this.SET_DATA_SEARCH_FROM_BILL(dataBill);

      const nameType = "toReceive";
      this.ALTER_TYPE_FINANCIAL(nameType);
      this.ALTER_SIZE_MODAL_FINANCIAL("ModalGeneral");
      this.SET_COLAPSE_FINANCIAL(true);
      this.ALTER_MODAL_CONTAINER_FINANCIAL("modalContainer");
    },

    openModalToPayable(dataBill) {
      this.SET_DATA_SEARCH_FROM_BILL(dataBill);

      const nameType = "toPay";
      this.ALTER_TYPE_FINANCIAL(nameType);
      this.ALTER_SIZE_MODAL_FINANCIAL("ModalGeneral");
      this.SET_COLAPSE_FINANCIAL(true);
      this.ALTER_MODAL_CONTAINER_FINANCIAL("modalContainer");
    },

    printProofOfAcquittance(idBill) {
      printReport("proofOfAcquittance", idBill);
    },

    async findDetailedBillById(id) {
      const detailedBill = await ServiceBill.findDetailedBillById(id);
      return detailedBill;
    },

    async findByIdAndOpenBillDetails(dataBill) {
      const bill = await this.findDetailedBillById(dataBill.id);
      bill.totalContas = dataBill.totalContas;
      this.openBillDetails(bill);
    },

    openBillDetails(dataBill) {
      this.SET_DATA_SEARCH_FROM_BILL(dataBill);

      const nameType = "billDetails";
      this.ALTER_TYPE_FINANCIAL(nameType);
      this.ALTER_SIZE_MODAL_FINANCIAL("ModalGeneral");
      this.SET_COLAPSE_FINANCIAL(true);
      this.ALTER_MODAL_CONTAINER_FINANCIAL("modalContainer");
    },

    async changeSituationToLoss() {
      try {
        await ServiceBill.changeSituationToLoss(this.idBillToChangeToLoss);
        this.closeModalChangeSituationToLoss();
        this.SET_IS_SEARCH_BILLS();
        return this.$toast.open({
          message: "Situação da conta alterada para perda com sucesso!",
          type: "success",
        });
      } catch (error) {
        return toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async changeSituationToOpen(id) {
      try {
        await ServiceBill.changeSituationToOpen(id);
        this.closeModalChangeSituationToLoss();
        this.SET_IS_SEARCH_BILLS();
        return this.$toast.open({
          message: "Conta habilitada com sucesso!",
          type: "success",
        });
      } catch (error) {
        return toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    openModalChangeSituationToLoss(bill) {
      this.remainingValue = bill.valorRestante.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });

      this.idBillToChangeToLoss = bill.id;

      this.$bvModal.show("changeSituationToLoss");
    },

    closeModalChangeSituationToLoss() {
      this.idBillToChangeToLoss = "";
      this.$bvModal.hide("changeSituationToLoss");
    },

    async findDetailedBillAndOpenModalReversal(bill) {
      this.valuesToModalBillReversal = await this.findDetailedBillById(bill.id);
      this.valuesToModalBillReversal.totalContas = bill.totalContas;

      this.openDeleteBillOrReversalModal();
    },

    async handleBillReversalOrDeleteBill() {
      if (this.isDeleteBill) {
        return await this.deleteBill();
      }

      await this.billReversal();
    },

    async billReversal() {
      try {
        this.spinLoadingActionsButton = true;
        await ServiceBill.billReversal(this.valuesToModalBillReversal.id);

        this.closeReversalOrDeleteBillModal();
        this.SET_IS_SEARCH_BILLS();

        this.confirmReversalOrDeleteBill = false;
        return this.$toast.open({
          message: "Conta estornada com sucesso!",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        return this.$toast.open({
          message: "Ocorreu um erro ao estornar a conta!",
          type: "error",
        });
      } finally {
        this.spinLoadingActionsButton = false;
      }
    },

    async deleteBill() {
      try {
        await ServiceBill.deleteAllRelationshipsFromDuplicate(
          this.idBillToDelete
        );

        this.closeReversalOrDeleteBillModal();
        this.SET_IS_SEARCH_BILLS();
        return this.$toast.open({
          message: "Conta excluida com sucesso!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ocorreu um erro ao excluir essa conta!",
            type: "error",
          });
      }
    },

    async deleteUnionBill(bill, index) {
      try {
        await ServiceBill.deleteAllRelationshipsFromDuplicate(bill.id);
        this.dataAllBills.splice(index, 1);
        this.SET_IS_SEARCH_BILLS();
        return this.$toast.open({
          message: "Conta excluida com sucesso!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ocorreu um erro ao excluir essa conta!",
            type: "error",
          });
      }
    },

    async deleteAllUnionBills() {
      try {
        this.spinDelete = true;
        for (let i = 0; i < this.dataAllBills.length; i++) {
          await ServiceBill.deleteAllRelationshipsFromDuplicate(
            this.dataAllBills[i].id
          );
          this.dataAllBills.splice(i, 1);
          i--;
        }
        this.SET_IS_SEARCH_BILLS();
        this.$bvModal.hide("modal-excluir");
        this.$bvModal.hide("modal-center4");
        return this.$toast.open({
          message: "Contas excluídas com sucesso!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ocorreu um erro ao excluir essa conta!",
            type: "error",
          });
      } finally {
        this.spinDelete = false;
      }
    },

    openModalConfirmDelete(id) {
      this.isDeleteBill = true;
      this.idBillToDelete = id;

      this.openDeleteBillOrReversalModal();
    },

    openDeleteBillOrReversalModal() {
      this.$bvModal.show("ModalDeleteBillOrReversal");
    },

    closeReversalOrDeleteBillModal() {
      this.valuesToModalBillReversal = [];
      this.idBillToDelete = "";
      this.isDeleteBill = false;
      this.$bvModal.hide("ModalDeleteBillOrReversal");
      this.confirmReversalOrDeleteBill = false;
    },
  },
  computed: {
    ...mapState({
      isSearchBills: (state) => state.isSearchBills,
    }),
    valueIsPaidAndAmountBill() {
      return {
        paid: this.valuesToModalBillReversal?.valorPago?.toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        ),
        amount: `${this.valuesToModalBillReversal?.numeracao}/${this.valuesToModalBillReversal?.totalContas}`,
      };
    },
  },
  created() {
    if (this.$route.params.id) {
      this.findByCasherEdit(this.$route.params.id);
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.ContentTable {
  display: flex;
  flex-direction: column;
  height: 50vh;
  max-height: 50vh;
  overflow: auto;
  padding: 10px;
  width: 100%;
  max-width: 100%;
}

.headTable {
  color: whitesmoke;
  background: #3a4750;
  text-align: center;
  height: 29px;
}

tr:nth-child(even) {
  background: #f2f3f3;
}

th {
  font-size: 12px;
  font-weight: normal;
}

td {
  text-transform: capitalize;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.contentStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  height: 28px;
}

.statusPayment {
  color: #48b865;
}

.statusPaymentOpen {
  color: rgb(107, 106, 106);
}

.statusPaymentPartial {
  color: #403b74;
}

.statusPaymentLoss {
  color: #f2a83e;
}

.statusPaymentLate {
  color: #b00404;
}

.paymentEmpty {
  width: 30px;
  background: transparent;
  color: rgb(132, 132, 132);
}

.btnActions {
  height: 25px;
  display: flex;
  justify-content: center;
}

.dropdown-menu {
  font-size: 12px;
  min-width: 5rem !important;
  width: 5rem !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  color: red;
  background: red;
  text-align: center;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu li {
  font-size: 12px;
  width: 100% !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  display: flex;
  text-align: center;
  list-style: none;
  border: none;
}

.searchFilter {
  width: 150px;
  border-right: none;
  height: 30px;
  font-size: 11px;
  text-align: center;
  font-family: Poppins-Regular, sans-serif !important;
}

.searchFilter:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

.custom-select {
  left: 4px;
  position: relative;
  border-right: none;
  font-weight: 600;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid rgb(195, 193, 193);
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.titleModalBill {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 5px 0px;
  border-bottom: 1px solid #e6e7e8;
}

.textModalBill {
  font-weight: bold;
  width: 95%;
  text-align: center;
}

.closeModalBill {
  cursor: pointer;
}

.closeModalBill:hover {
  color: red;
}

.ContentTableBill {
  display: flex;
  flex-direction: column;
  overflow: auto;
  font-size: 13px;
  width: 100%;
  max-width: 100%;
  height: 70vh;
  margin-top: 10px;
  padding: 0 0 10px 0;
  background: #fbfbfb;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 5px;
}

.noContentTable {
  background: #edebeb;
  padding: 10px;
  height: 100%;
  text-align: center;
  letter-spacing: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentStatusAll {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 35px;
}

.contentStatusAll span {
  cursor: context-menu;
}

.ctnDelAll {
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
}

.ctnLoadDelAll {
  padding-top: 20px;
  display: flex;
  font-size: 14px;
  gap: 20px;
  flex-direction: column;
  align-items: center;
}

.btnContentDelAll {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 33%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentWhiteDelAll {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 33%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: #eff0f0;
}

.btnContentConfirmedDelAll {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 43%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentConfirmedWhiteDelAll {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 43%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: #eff0f0;
}

.statusPaymentAll {
  background: #eff0f0;
  color: green;
}

.statusPaymentOpenAll {
  background: #eff0f0;
  color: rgb(132, 132, 132);
}

.statusPartialPaid {
  background: #eff0f0;
  color: orange;
}

.statusExpired {
  background: #eff0f0;
  color: red;
}

.delBill {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  border: none;
  cursor: pointer;
}

.custom-select2 {
  left: 4px;
  position: relative;
  border-right: none;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid rgb(195, 193, 193);
  -moz-appearance: none;
  appearance: none;
  background: none;
  font-weight: bold;
}

.loadingLazy {
  position: absolute;
  bottom: 20px;
  height: 50vh;
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
  opacity: 0.5;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #403b74;
  box-shadow: 30px 0 0 #f2a83e;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #3e66ae;
  box-shadow: 30px 0 0 #48b865;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f2a83e;
  }

  50% {
    box-shadow: 0 0 0 #f2a83e;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f2a83e;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #48b865;
  }

  50% {
    box-shadow: 0 0 0 #48b865;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #48b865;
    margin-top: 0;
  }
}

.defaultSearch {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30vh;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.defaultSearch span {
  font-size: 1rem;
  font-weight: bold;
  color: gray;
}

.titleModalPerda {
  font-family: Poppins-Regular, sans-serif !important;
  margin: 0;
}

.btnConfirm {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnCancel {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.btnsModalActions {
  margin-top: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btnDisabled {
  background-color: #9492907e;
  pointer-events: none;
  color: #656464 !important;
  border: none;
}

.contentTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  padding: 10px;
  background-color: #00496e;
  border-radius: 5px;
  color: white;
  margin: 0;
}

.subTitle {
  color: #767676;
  font-size: 14px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  margin: 0;
}

.footerTitle {
  color: #00496e;
  font-size: 13px;
  font-family: Poppins-Regular, sans-serif !important;
  margin: 0;
}

#date {
  font-family: Poppins-Regular, sans-serif !important;
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .ContentTable {
    padding: 5px 5px 0 0px;
    height: auto;
    max-height: none;
  }
  .headTable {
    height: 25px;
  }
}
</style>