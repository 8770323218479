<template>
  <div class="d-flex justify-content-end w-100 container-geral-settings">
    <div class="mt-5" style="width: 90%">
      <Company />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Company from "./Company.vue";

export default {
  components: {
    Company,
  },
  computed: {
    ...mapState({
      width: (state) => state.widthCards,
    }),
  },
};
</script>
<style scoped>
.container-geral-settings {
  width: 95% !important;
}

@media screen and (max-width: 650px) {
  .container-geral-settings {
    width: 100% !important;
  }
  .justify-content-end {
    justify-content: center !important;
  }
}
</style>
