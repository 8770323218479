<template>
  <div>
    <b-modal
      id="modalCorrectionLetter"
      size="lg"
      title="Realizar carta de correção"
      ok-title="Salvar"
      centered
      @hidden="clear"
    >
      <template #modal-footer>
        <div
          class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-between"
        >
          <div>
            <b-button
              :disabled="description.length <= 15"
              variant="info"
              size="sm"
              @click="correctionLetter"
              >Prosseguir</b-button
            >
          </div>

          <div v-if="spinLoading">
            <b-spinner
              style="width: 2rem; height: 2rem"
              variant="primary"
            ></b-spinner>
          </div>
        </div>
      </template>
      <b-row class="d-flex">
        <b-form-group
          id="input-group-1"
          label="Descrição da correção!"
          label-for="input-1"
          class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
        >
          <b-form-input
            placeholder="Min: 15 caracteres"
            size="md"
            v-model="description"
          ></b-form-input>
        </b-form-group>
      </b-row>

      <div class="correctionLetterNotice">
        <ul>
          <p>O que NÃO é permitido corrigir com a carta de correção?</p>
          <li>
            Valores como base de cálculo, alíquota, diferença de preço e
            quantidade.
          </li>
          <li>
            Dados cadastrais que implique mudança do remetente ou do
            destinatário.
          </li>
          <li>A data de emissão ou de saída.</li>
          <li>Série e número da nota fiscal.</li>
        </ul>
      </div>
    </b-modal>
  </div>
</template>

<script>
import serviceNotaFiscal from "../../services/serviceNotaFiscal";
export default {
  props: {
    idNota: {
      type: String,
    },
  },
  data() {
    return {
      description: "",
      spinLoading: false,
      alterValueEvent: false,
    };
  },
  methods: {
    async correctionLetter() {
      try {
        this.spinLoading = true;
        const result = await serviceNotaFiscal.correctionLetter(
          { correcao: this.description },
          this.idNota
        );

        this.$emit("getNotaAfterCorrection", !this.alterValueEvent);
        window.open(`${result}`, "_blank");

        this.$toast.open({
          message: "Nota Corrigida!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message: error.response.data.message,
          type: "error",
        });
      } finally {
        this.spinLoading = false;
        this.$bvModal.hide("modalCorrectionLetter");
      }
    },
    clear() {
      this.description = "";
    },
  },
};
</script>

<style scoped>
.correctionLetterNotice {
  font-size: 11px;
  background-color: #d9edf7;
  padding: 1rem 0;
  border-radius: 5px;
}

.correctionLetterNotice p {
  font-weight: bold;
}

p {
  margin: 0;
}
</style>