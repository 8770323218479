<template>
  <OrderOfService />
</template>

<script>
import OrderOfService from "../../components/OrderOfService/Index-OrderService.vue";
export default {
  components: {
    OrderOfService,
  },
};
</script>