<template>
  <div class="d-flex home justify-content-center">
    <Reports />
  </div>
</template>

<script>
import Reports from "../../components/ReportsGeneral/index-Reports";

export default {
  components: {
    Reports,
  }
};
</script>