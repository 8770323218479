<template>
  <div>
    <IndexAccounting />
  </div>
</template>

<script>
import IndexAccounting from "../../components/Accounting/IndexAccounting.vue";
export default {
  components: {
    IndexAccounting,
  },
};
</script>