<template>
  <Tester />
</template>

<script>
import Tester from "../../components/Login/Tester.vue";
export default {
  components: {
    Tester,
  },
};
</script>