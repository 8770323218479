<template>
  <div>
    <div :class="isEnableStyleCard && 'rowContainer'">
      <div style="display: flex; justify-content: space-between">
        <span>
          <b-icon-ui-checks />
          Composições do item
        </span>

        <span class="btnAddMoreItems" @click="addMoreItems">+ Adicionar</span>
      </div>

      <div class="contentComposition">
        <div
          class="rowContent"
          v-for="(composition, i) in compositionItems"
          :key="composition.idList"
        >
          <div class="rowContent" style="width: 40%">
            <InputSelect
              label="item"
              :items="productsFromSelectBox"
              textField="nome"
              valueField="id"
              :dataSelect.sync="compositionItems[i].idProduto"
              @changed="getComposition"
              ref="refProductComposition"
            />
          </div>

          <div class="rowContent" style="width: 20%">
            <Input
              label="Quantidade"
              labelFixed
              type="number"
              :dataInput.sync="compositionItems[i].quantidade"
              ref="quantidade"
              @changed="getComposition"
            />
          </div>

          <div class="rowContent" style="width: 40%">
            <Input
              label="Informações adicionais"
              labelFixed
              :dataInput.sync="compositionItems[i].dadosAdicionais"
              ref="dadosAdicionais"
              @changed="getComposition"
            />
          </div>

          <div class="rowContent" style="width: auto">
            <div @click="handleRemoveItens(composition.idList, composition.id)">
              <b-icon-x-circle style="color: red" role="button" scale="1.3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

import Input from "../UI/CustomInputs/Input.vue";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";

import ServiceProducts from "../../services/serviceProducts";
import ServiceProductsComposition from "../../services/serviceProductsComposition";

import { INITIAL_STATE_COMPOSITION } from "../../initialStates/composition";
import { removeReference } from "../../utils/removeReference";

export default {
  props: {
    isEnableStyleCard: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Composições",
    },
    clear: {
      type: Boolean,
    },
    compositionAfterEditProduct: {
      type: null,
    },
  },
  components: {
    Input,
    InputSelect,
  },
  data() {
    return {
      productsFromSelectBox: [],
      compositionItems: [INITIAL_STATE_COMPOSITION],
    };
  },
  methods: {
    async fillCombobox() {
      const result = await ServiceProducts.fillCombobox();
      this.productsFromSelectBox = result.data;
    },

    async remove(id) {
      try {
        await ServiceProductsComposition.deleteById(id);
        this.$toast.open({
          message: "Composição removida do produto!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            `${error.response.data.message}` ||
            "Ocorreu um erro ao remover a composição!",
          type: "info",
        });
      }
    },

    async handleRemoveItens(idList, idComposition) {
      if (idComposition) {
        try {
          await this.remove(idComposition);
        } catch (error) {
          this.$toast.open({
            message:
              `${error.response.data.message}` ||
              "Ocorreu um erro ao remover o produto!",
            type: "info",
          });
        }
      }

      this.removeItensInArray(idList);
      this.getComposition();
    },

    addMoreItems() {
      this.compositionItems = [
        ...this.compositionItems,
        { ...INITIAL_STATE_COMPOSITION, idList: uuidv4() },
      ];
    },

    removeItensInArray(idList) {
      if (this.compositionItems.length > 1) {
        this.compositionItems = this.compositionItems.filter(
          (item) => item.idList !== idList
        );

        return;
      }

      this.compositionItems = [
        { ...INITIAL_STATE_COMPOSITION, idList: uuidv4() },
      ];
    },

    resetComposition() {
      this.compositionItems = [
        { ...INITIAL_STATE_COMPOSITION, idList: uuidv4() },
      ];
    },

    getComposition() {
      this.$emit("findCompositions", this.compositionItems);
    },
  },
  watch: {
    clear() {
      this.resetComposition();
    },
    compositionAfterEditProduct() {
      if (
        !this.compositionAfterEditProduct ||
        !this.compositionAfterEditProduct?.length
      ) {
        return this.resetComposition();
      }

      this.compositionItems = removeReference(this.compositionAfterEditProduct);

      this.$nextTick(() => {
        this.compositionItems.forEach((composition, i) => {
          this.$refs.refProductComposition[i].valueItems =
            composition.idProduto;
        });
      });
    },
  },
  mounted() {
    this.fillCombobox();
    this.compositionItems = this.compositionItems.map((item) => {
      return {
        ...item,
        idList: uuidv4(),
      };
    });
  },
};
</script>

<style scoped>
.rowContainer {
  position: relative;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 10px;
  transition: ease-in all 0.3s;
}

.rowContainer span {
  font-weight: 600;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  transition: ease-in all 0.3s;
}

.btnAddMoreItems {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  padding: 0 0.3rem;
  color: black;
  cursor: pointer !important;
  background-color: #c3c3c3;
  border-radius: 5px;
  user-select: none;
}

.btnAddMoreItems:hover {
  background-color: #b6b6b6;
}

.contentComposition {
  max-height: 250px;
  overflow-y: auto;
  padding-right: 1rem;
  margin-top: 1rem;
  transition: ease-in all 0.3s;
}
</style>