import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./actions";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    widthCards: "80%",
    dataModalDialogGeneral: {
      nameModal: "",
      title: "",
      subTitle: "",
      footerTitle: "",
      leftButtonText: "",
      rightButtonText: "",
    },
    dataConfig: {
      id: null,
      caixa_automatico: {
        idResponsavel: null,
        utilizar: false,
        valorInicial: 0.0,
        horaAbertura: null,
        horaFechamento: null,
      },
    },
    dataCompany: {},
    dataContract: true,
    navItemSelected: "",
    dataBudgetForSale: {},
    sizeModalBigBox: "disable",
    sizeModal: "disable",
    sizeModalRegister: "disable",
    sizeModalFinancial: "disable",
    sizeModalCashier: "disable",
    dataSearchFromBoxFront: {},
    dataSearchFromSale: {},
    dataSearchFromSquareMeter: {},
    dataSearchFromBill: {},
    dataSearchFromProvisionService: {},
    dataSearchFromMdfe: {},
    dataSearchFromPurchase: {},
    dataSearchCustomer: {},
    dataSearchEmployee: {},
    dataSearchProduct: {},
    dataSearchSupplier: {},
    dataSearchService: {},
    colapseBigBox: false,
    colapseGeneral: false,
    colapseFinancial: false,
    colapseRegister: false,
    isSearchBills: false,
    isSearchRegisters: false,
    colapseCashier: false,
    colapsePrint: false,
    colapseFiscal: false,
    modalContainerBigBox: "contentModalDisable",
    modalContainerCashier: "contentModalDisable",
    modalContainerFinancial: "contentModalDisable",
    modalContainer: "contentModalDisable",
    modalContainerGeneral: "contentModalDisable",
    dataSafeIsOpen: {
      safeIsOpen: false,
    },
    remainingValue: null,
    dataNFe: {},
    typeDocument: {
      frenteDeCaixa: false,
      orcamento: false,
      venda: false,
      orcamentoMtq: false,
      vendaMtq: false,
    },
    typeMeterDocument: {
      orcamentoMtq: false,
      vendaMtq: false,
      orcamentoMtl: false,
      vendaMtl: false,
    },
    typeRegister: {
      customer: false,
      employee: false,
      products: false,
      supplier: false,
      service: false,
    },
    typeFiscal: {
      nfe: false,
      nfce: false,
      nfse: false,
      mdfe: false,
    },
    enableModalsFinancial: {
      toPay: false,
      toReceive: false,
      billDetails: false,
    },
    enableModalsSettings: {
      accessControl: false,
      fiscalSettings: false,
      logsScreen: false,
    },
    enableModalAccountingConversation: false,
    accountingConversations: [],
    numberOfNewMessages: null,
    overflow: "auto",
    dataPlan: {
      ativo: true
    },
    enableMtq: false,
    intervalMachineId: "",
  },
  actions,
  mutations,
});
export default store;
