const validateCnpj = (cnpj, toast) => {
  const defaultMessage = {
    isValid: false,
    alert: () => toast.open({
      message: 'CNPJ inválido!',
      type: 'info'
    })
  };

  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === '' || cnpj.length !== 14 || /^(\d)\1+$/g.test(cnpj)) {
    return defaultMessage
  }

  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);

  let soma = 0;
  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;

    if (pos < 2) {
      pos = 9;
    }
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado !== parseInt(digitos.charAt(0))) {
    return defaultMessage
  }

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;

    if (pos < 2) {
      pos = 9;
    }
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado !== parseInt(digitos.charAt(1))) {
    return defaultMessage
  }

  return {
    isValid: true,
    message: null
  };
}

const validateCpf = (cpf, toast) => {
  const defaultMessage = {
    isValid: false,
    alert: () => toast.open({
      message: 'CPF inválido!',
      type: 'info'
    })
  };

  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf === '' || cpf.length !== 11 || /^(\d)\1+$/g.test(cpf)) {
    return defaultMessage
  }

  let soma = 0;
  let resto;

  for (let i = 1; i <= 9; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }

  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== parseInt(cpf.substring(9, 10))) {
    return defaultMessage
  }

  soma = 0;

  for (let i = 1; i <= 10; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }

  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== parseInt(cpf.substring(10, 11))) {
    return defaultMessage
  }

  return {
    isValid: true,
    message: null
  };
}

export const handleCpfOrCnpj = (cpfOrCnpj, toast) => {
  if (cpfOrCnpj) {
    if (cpfOrCnpj.length === 14) {
      return validateCpf(cpfOrCnpj, toast)
    }

    return validateCnpj(cpfOrCnpj, toast)
  }
}