<template>
  <div class="containerGeneral">
    <div class="contentTable">
      <div class="contentUnicFile">
        <div
          v-if="file.id"
          class="contentNameFile"
          @click="downloadFile(file.urlArquivo)"
        >
          <p ref="refKeyAws" class="nameFile">
            {{ file.keyAws.split(".")[1] }}
          </p>
        </div>

        <div class="contentInputFile" v-else>
          <label
            for="inputFile"
            class="labelInputFile"
            @dragover="handleDragOver"
            @drop="handleDrop($event)"
          >
            <span class="textSelectFile">
              <b-icon-paperclip scale="1.5" />
              <p class="m-0 p-0 textDropFile">
                {{ getNameFile }}
              </p>

              <p v-if="numberOfFilesSelected" class="numberOfFiles">
                {{ numberOfFilesSelected }}
              </p>
            </span>
          </label>
          <input
            type="file"
            multiple
            id="inputFile"
            name="inputFile"
            ref="fileInput"
            @change="handleFileChange($event)"
          />
        </div>

        <div style="width: 100%">
          <InputSelect
            label="Tipo de anexo"
            valueField="value"
            textField="value"
            :items="typeFile"
            :dataSelect.sync="file.tipoArquivo"
            :enableIconCreate="false"
            :arrowLeft="true"
            ref="refTipoArquivo"
            :isDisabled="file.id && disabledInput ? true : false"
            labelBackground="#f5f5f5"
            backgroundInput="white"
          />
        </div>

        <div style="width: 100%">
          <Input
            label="Descrição"
            type="text"
            :dataInput.sync="file.descricao"
            :labelFixed="true"
            ref="refDescricao"
            :isDisabled="file.id && disabledInput ? true : false"
            labelBackground="#f5f5f5"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "../UI/CustomInputs/Input.vue";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";
import { tipoArquivo } from "../../common/index";

export default {
  components: {
    Input,
    InputSelect,
  },
  props: {
    disabledInput: {
      type: Boolean,
      default: false,
    },
    isClear: {
      type: Boolean,
    },
  },
  data() {
    return {
      file: {
        id: "",
        file: [],
        tipoArquivo: "",
        descricao: "",
        keyAws: "",
        urlArquivo: "",
      },
      typeFile: null,
    };
  },
  methods: {
    handleDragOver(event) {
      event.preventDefault();
    },

    handleDrop(event) {
      event.preventDefault();
      this.file.file = this.handleFiles(event.dataTransfer.files);
    },

    handleFileChange(event) {
      this.file.file = this.handleFiles(event.target.files);
    },

    handleFiles(listFiles) {
      const files = Object.keys(listFiles).map((key) => {
        return listFiles[key];
      });

      return files;
    },

    downloadFile(url) {
      window.open(url);
    },

    assingValues() {
      this.typeFile = tipoArquivo;
    },

    clearValues() {
      this.file = {
        id: "",
        file: [],
        tipoArquivo: "",
        descricao: "",
        keyAws: "",
        urlArquivo: "",
      };

      this.$refs.refTipoArquivo.valueItems = "";
      this.$refs.refDescricao.valueInput = "";
      this.$refs.fileInput.value = "";
    },
  },
  computed: {
    getNameFile() {
      if (this.file.file.length) {
        return this.file.file[0].name;
      }

      return "Escolha seus arquivos";
    },
    numberOfFilesSelected() {
      if (this.file.file.length && this.file.file.length > 1) {
        return `+${this.file.file.length - 1}`;
      }

      return null;
    },
  },
  created() {
    this.assingValues();
  },
  watch: {
    isClear() {
      this.clearValues();
    },
  },
};
</script>

<style scoped>
.containerGeneral {
  padding: 1rem 1rem;
  width: 100%;
  height: 100%;
}

.contentTable {
  width: 100%;
  height: 100%;
}

.contentUnicFile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.3rem;
}

.btnAddNewFile {
  display: flex;
  align-items: center;
  gap: 3px;
  border-radius: 5px;
  background-color: #00395d;
  border: none;
  color: white;
  font-size: 10px;
  border: solid 1px rgba(63, 143, 178, 0.975);
  color: rgba(63, 143, 178, 0.975);
  background-color: whitesmoke;
  padding: 3px 5px;
}

.btnAddNewFile p {
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

/* TABLE */

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 10px;
}

th {
  padding: 5px 15px;
  font-size: 11px;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.tdFile {
  width: 120px;
  padding-left: 0px;
}

.tdTypeFile {
  width: 200px;
}

.tdAddNewFile {
  padding: 0px;
}

td {
  padding: 5px 10px;
}

/* INPUT FILE */

input[type="file"] {
  display: none;
}

.labelInputFile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
}

.textSelectFile {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  background-color: #c1c1c156;
  border-radius: 5px;
  font-weight: bold;
  font-size: 11px;
  padding: 5.75px 9px;
}

.textSelectFile p {
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.numberOfFiles {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: rgb(172, 55, 55);
  border-radius: 50%;
  color: white;
  font-weight: normal !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.357);
}

.textDropFile {
  max-width: 130px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contentInputFile {
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
}

.contentNameFile {
  cursor: pointer;
}

.nameFile {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
  margin: 0px;
  color: rgba(63, 143, 178, 0.975);
  font-weight: bold;
}

.contentIconRemoveFile {
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 4px;
  color: rgb(255, 51, 51);
}

.contentBtnAdd {
  padding: 0 0 0 1rem;
}

@media screen and (max-width: 991px) {
  .contentTable {
    overflow-x: auto;
  }

  .containerGeneral {
    width: 100%;
  }

  .contentBtnAdd {
    margin-top: 1rem;
  }

  table {
    width: 770px !important;
  }
}
</style>