<template>
  <div class="cardGraphicComponent" id="grapic1" />
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
import api from "../../services/axios";

export default {
  data() {
    return {
      startDate: "",
      endDate: "",
      dataSales: {},
      chart: null,
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: ["Frente de Caixa", "Vendas", "Orçamentos"],
        },

        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Frente de Caixa",
            type: "bar",
            barGap: 0,
            label: this.labelOption,
            emphasis: {
              focus: "series",
            },
            data: [],
          },
          {
            name: "Vendas",
            type: "bar",
            label: this.labelOption,
            emphasis: {
              focus: "series",
            },
            data: [],
          },
          {
            name: "Orçamentos",
            type: "bar",
            label: this.labelOption,
            emphasis: {
              focus: "series",
            },
            data: [],
          },
        ],
      },
    };
  },
  methods: {
    obterDatas() {
      const dataAtual = moment();

      const dataAntes4Meses = moment().subtract(4, "months");
      this.startDate = dataAntes4Meses.format("YYYY-MM-DD");
      this.endDate = dataAtual.format("YYYY-MM-DD");

      this.readSales(this.startDate, this.endDate);
    },
    async readSales(initial, finaly) {
      try {
        const { data } = await api.get(
          `/sales/search/per/month/sum/sales/by/dashboard/grapic?startDate=${initial}&endDate=${finaly}`
        );

        this.dataSales = data.data;
        this.updateChart();
      } catch (error) {
        console.error(error);
      }
    },
    updateChart() {
      const months = Object.keys(this.dataSales);
      const seriesData = Object.values(this.dataSales);
      const monthNames = months.map((month) =>
        moment(`${month}-01`, "MM").format("MMMM")
      );

      this.option.xAxis[0].data = monthNames;
      this.option.series[0].data = seriesData.map(
        (monthData) => monthData["Frente de caixa"] || 0
      );
      this.option.series[1].data = seriesData.map(
        (monthData) => monthData["Venda"] || 0
      );
      this.option.series[2].data = seriesData.map(
        (monthData) => monthData["Orçamento"] || 0
      );

      this.chart && this.chart.setOption(this.option);
    },
  },
  async mounted() {
    this.obterDatas();
    this.chart = echarts.init(document.getElementById("grapic1"));
    this.option && this.chart.setOption(this.option);
  },
};
</script>
<style scoped>
.cardGraphicComponent {
  height: 100%;
}
</style>