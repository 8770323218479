<template>
  <div
    :class="`contentTab ${newStyleTabs}`"
    :style="noPadding ? '' : 'padding: 16px 32px'"
  >
    <div class="tabs">
      <div v-for="tab in tabs" :key="tab.name">
        <button
          :style="`font-size: ${fontSizeTitle}`"
          :class="`customTabs ${cloneTabs[tab.name] ? 'tabSelected' : ''}`"
          @click="selectTab(tab.name)"
          :ref="`refTab-${tab.name}`"
        >
          {{ tab.text }}
        </button>
      </div>
    </div>

    <div
      v-for="(tab, idx) in tabs"
      :key="tab.name"
      :hidden="!cloneTabs[tab.name]"
      class="contentItem"
      :style="`${
        idx === 0
          ? 'border-radius: 0px 5px 5px 5px!important'
          : 'border-radius: 5px!important'
      }`"
    >
      <div :hidden="!cloneTabs[tab.name]" :ref="`refTab-${tab.name}`">
        <slot :name="tab.name"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
    },
    newStyleTabs: {
      type: String,
      Default: "",
    },
    tabSelected: {
      type: Number,
    },
    actionToAlterTab: {
      type: Boolean,
    },
    fontSizeTitle: {
      type: String,
      default: "14px",
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cloneTabs: {},
    };
  },
  methods: {
    selectTab(name) {
      Object.keys(this.cloneTabs).forEach((key) => {
        this.cloneTabs = {
          ...this.cloneTabs,
          [key]: key === name ? true : false,
        };
      });
    },
    handleSelectTab() {
      if (this.tabSelected > Object.keys(this.cloneTabs).length) {
        return;
      }

      const nameTab = Object.keys(this.cloneTabs).map((item) => item)[
        this.tabSelected
      ];

      this.selectTab(nameTab);
    },
  },
  mounted() {
    this.tabs.forEach((item) => {
      this.cloneTabs = { ...this.cloneTabs, [item.name]: false };
    });

    Object.keys(this.cloneTabs).forEach((item, index) => {
      if (!index) {
        this.cloneTabs[item] = true;
      }
    });

    this.handleSelectTab();
  },
  watch: {
    actionToAlterTab() {
      this.handleSelectTab();
    },
  },
};
</script>

<style scoped>
.contentTab {
  display: flex;
  gap: 15px;
  flex-direction: column;
  width: 100%;
}

.tabs {
  display: flex;
  gap: 10px;
  width: 100%;
  border-bottom: solid 1px #6663605a;
  flex-wrap: wrap;
  align-items: center;
}

.customTabs {
  background-color: transparent;
  border: none;
  padding: 0 1rem;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
  color: #666360;
}

.newStyleTabs .tabs {
  border-bottom: none !important;
  background-color: white;
  gap: 0;
}

.newStyleTabs .tabSelected {
  padding: 0.3rem 1rem;
  background-color: rgb(0 58 93);
  border: none !important;
  border-radius: 5px 5px 0 0;
  color: white !important;
}

.newStyleTabs .customTabs {
  color: rgb(0 58 93);
  font-weight: normal !important;
}

.newStyleTabs .contentItem {
  background-color: rgb(241 238 238);
  padding: 0 1rem 1rem 1rem;
}

.newStyleTabs {
  padding: 0 0 1rem 0 !important;
  gap: 0 !important;
}

.tabSelected {
  color: rgba(84, 188, 233, 0.975);
  border-bottom: solid 3px rgba(63, 143, 178, 0.975);
}
</style>