<template>
  <section class="generalContainer">
    <span class="title">
      <b-icon-chat-right-text />
      <p>Envie uma nova mensagem</p>
    </span>

    <div class="rowContainerBackground">
      <div class="containerConversation">
        <div class="conversation">
          <div class="contentConversation" ref="refMessages">
            <div
              v-for="conversation in accountingConversations"
              :key="conversation.id"
            >
              <div :class="`${handleContentMessage(conversation.nivel)}`">
                <div class="enableFiles">
                  <span
                    class="contentUser"
                    :style="`${
                      conversation.nivel === getLoggedUser &&
                      'justify-content: flex-end'
                    }`"
                  >
                    <p>
                      {{ handleHours(conversation.data) }}
                    </p>
                  </span>

                  <div class="message">
                    <span
                      :class="`generalMessages ${handleColorMessage(
                        conversation.nivel
                      )}`"
                    >
                      <p
                        class="nameUser"
                        :style="`${
                          conversation.nivel === getLoggedUser &&
                          'text-align: end'
                        }`"
                      >
                        {{ handleUsers(conversation.nivel) }}
                      </p>

                      <div
                        :class="`${handleContentUsersAndMessage(
                          conversation.nivel
                        )}`"
                      >
                        <p class="messageUser">
                          {{ conversation.mensagem }}
                        </p>
                        <p :class="`${handleDateMessage(conversation.nivel)} `">
                          {{ handleDate(conversation.data) }}
                        </p>
                      </div>
                    </span>
                  </div>

                  <div
                    v-if="
                      conversation.totalArquivos &&
                      conversation.totalArquivos > 0
                    "
                    :class="`${handleEnableFileMessage(conversation.nivel)}`"
                    @click="getMessageFiles(conversation.id)"
                  >
                    <span class="contentIconAttachMessage">
                      <b-icon-paperclip
                        scale="1"
                        class="iconDropDown"
                        style="color: white"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            :class="`selectAttachedFiles ${
              attachFilesIsEnable ? 'enableAttach' : 'disableAttach'
            }`"
          >
            <AttachUnicFile
              ref="attachedUnicFile"
              :isClear="attachFilesIsEnable"
            />
          </div>
        </div>

        <div class="sendMessage">
          <span
            class="contentIconFile"
            :style="`${
              attachFilesIsEnable &&
              'width: auto; background-color: rgb(172 55 55)'
            }`"
            @click="enableAttachFiles"
          >
            <b-icon-paperclip
              v-if="!attachFilesIsEnable"
              scale="1.3"
              style="color: rgb(39, 216, 240)"
            />

            <p
              style="
                margin: 0;
                font-size: 11px;
                padding: 0 0.3rem;
                color: white;
              "
              v-else
            >
              Cancelar
            </p>
          </span>

          <div class="rowCustomMessages" style="width: 100%">
            <span
              class="contentInputText"
              :style="`height: ${heightToSendMessage}px`"
            >
              <textarea
                placeholder="Digite aqui sua mensagem..."
                class="inputTextArea"
                v-model="accountingMessage.message"
                @keypress="handlePress"
              />
            </span>
          </div>

          <span>
            <button
              :style="spinFromSendMessage ? 'pointer-events: none' : ''"
              size="sm"
              class="btnGeneral"
              variant="none"
              @click="send"
            >
              <b-icon-cursor-fill
                class="iconSendMessage"
                scale="1.7"
                v-if="!spinFromSendMessage"
              />

              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
                v-else
              >
                <b-spinner class="spin" />
              </div>
            </button>
          </span>
        </div>
      </div>
    </div>

    <ModalMessageFilesVue
      :listFiles="listMessageFiles"
      @modalClosed="clearListMessageFiles"
      @removedFile="getMessageFiles($event)"
    />
  </section>
</template>

<script>
import AttachUnicFile from "../AttachFiles/AttachUnicFile.vue";
import ModalMessageFilesVue from "./ModalMessageFiles.vue";

import { mapState, mapMutations } from "vuex";
import ServiceAccountingMessage from "../../services/serviceAccountingMessages";

export default {
  props: {
    idSolicitation: {
      type: String,
    },
  },
  components: {
    AttachUnicFile,
    ModalMessageFilesVue,
  },
  data() {
    return {
      accountingMessage: {
        id_solicitacao: null,
        messageFiles: [],
        message: null,
      },
      heightToSendMessage: null,
      attachFilesIsEnable: false,
      listMessageFiles: [],
      spinFromSendMessage: false,
      intervalMessagesId: null,
    };
  },
  methods: {
    ...mapMutations(["SET_ACCOUTING_CONVERSATIONS"]),
    async send() {
      try {
        this.spinFromSendMessage = true;
        const formData = this.handlePayloadBeforeSend();
        await ServiceAccountingMessage.save(formData);
        this.$toast.open({
          message: "Mensagem enviada!",
          type: "success",
        });
        this.getNewMessages();
        this.$emit("sendNewMessage");
        this.clearAccountingMessage();
        this.attachFilesIsEnable = false;
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.erros ??
            error?.response?.data?.message ??
            "Ocorreu um erro ao registrar a mensagem",
          type: "info",
        });
      } finally {
        this.spinFromSendMessage = false;
      }
    },

    async getNewMessages() {
      const { messages } = await ServiceAccountingMessage.findByIdSolicitation(
        this.idSolicitation
      );

      this.SET_ACCOUTING_CONVERSATIONS(messages.reverse());
    },

    handlePayloadBeforeSend() {
      this.accountingMessage.id_solicitacao = this.idSolicitation;

      const formData = new FormData();
      const files = this.getFiles.file;
      const messageFiles = JSON.parse(JSON.stringify(this.getFiles));
      Reflect.deleteProperty(messageFiles, "file");

      for (let i = 0; i < files.length; i++) {
        if (this.getFiles.tipoArquivo && this.getFiles.file) {
          this.accountingMessage.messageFiles.push(messageFiles);
        }

        formData.append(`files`, files[i]);
      }

      Reflect.deleteProperty(this.accountingMessage.messageFiles, "file");

      this.accountingMessage.message = this.accountingMessage.message.trim();

      formData.append(
        `accountingMessage`,
        JSON.stringify(this.accountingMessage)
      );

      return formData;
    },

    async handlePress(event) {
      if (event.keyCode == 13 && event.shiftKey) {
        this.heightToSendMessage
          ? (this.heightToSendMessage += 18)
          : (this.heightToSendMessage = 36);
      } else if (event.keyCode === 13 && !this.spinFromSendMessage) {
        event.preventDefault();
        await this.send();
      }
    },

    async getMessageFiles(id) {
      this.listMessageFiles =
        await ServiceAccountingMessage.listMessageFilesById(id);
      this.openModal("modalListFiles");
    },

    handleScroll() {
      this.accountingMessage.message = "";
      this.$nextTick(() => {
        this.$refs.refMessages.scrollTop = this.$refs.refMessages.scrollHeight;
      });
    },

    clearAccountingMessage() {
      this.accountingMessage = {
        id_solicitacao: null,
        messageFiles: [],
        message: null,
      };
    },

    enableAttachFiles() {
      this.attachFilesIsEnable = !this.attachFilesIsEnable;
    },

    handleUsers(userMessageLevel) {
      if (this.getLoggedUser === userMessageLevel) return "Você";
      else if (userMessageLevel === "accountant") return "Contabilidade";
      else if (userMessageLevel === "user") return "Usuário";
      else if (userMessageLevel === "adm") return "Adm";
    },

    handleHours(hours) {
      return hours.split(" ")[1].split(":").splice(0, 2).join(":");
    },

    handleDate(date) {
      return date.split(" ")[0].split("-").reverse().join("/");
    },

    handleContentMessage(messageUserLevel) {
      if (messageUserLevel !== this.getLoggedUser) return "contentMessage";
      return "contentMessageAccounting";
    },

    handleColorMessage(messageUserLevel) {
      if (messageUserLevel === this.getLoggedUser) return "colorMessageUser";
      return "colorMessageAccounting";
    },

    handleContentUsersAndMessage(messageUserLevel) {
      if (messageUserLevel === this.getLoggedUser) return "userAndMessage";
      return "accoutingAndMessage";
    },

    handleDateMessage(messageUserLevel) {
      if (messageUserLevel === this.getLoggedUser) return "dateMessageUser";
      return "dateMessageAccounting";
    },

    handleEnableFileMessage(messageUserLevel) {
      if (messageUserLevel === this.getLoggedUser) return "attachMessage";
      return "attachMessageAccounting";
    },

    clearListMessageFiles() {
      this.listMessageFiles = [];
    },

    openModal(name) {
      this.$bvModal.show(name);
    },
  },
  computed: {
    ...mapState({
      accountingConversations: (state) => state.accountingConversations,
    }),
    getFiles() {
      return this.$refs.attachedUnicFile.file;
    },
    handleMessage() {
      return this.accountingMessage.message;
    },
    getLoggedUser() {
      return localStorage.getItem("userLevel");
    },
  },
  watch: {
    handleMessage() {
      if (!this.accountingMessage.message) {
        this.heightToSendMessage = 36;
      }
    },
    accountingConversations() {
      this.handleScroll();
    },
  },
  mounted() {
    this.handleScroll();

    this.intervalMessagesId = setInterval(() => {
      this.getNewMessages();
    }, 180000);
  },
  beforeDestroy() {
    clearInterval(this.intervalMessagesId);
  },
};
</script>

<style scoped>
.generalContainer {
  padding: 1rem 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.rowContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 5px;
}

.rowContainerBackground {
  background-color: #3a4750;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.238);
}
.rowCustomMessages {
  display: flex;
  gap: 20px;
  padding: 15px 0;
}

.inputTextArea {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: transparent;
  color: black;
  resize: none;
  font-size: 12px;
}

.contentInputText {
  width: 100%;
  /* border: solid 1px #d9d9d9; */
  border-radius: 8px;
  padding: 0.5rem;
  display: flex;
  height: 36px;
  max-height: 130px;
  align-items: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.357);
  background-color: #eeeeee;
  cursor: text;
}

.btnGeneral {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 12px;
  font-weight: normal;
  border: none;
  outline: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.179) !important;
  background-color: rgb(72, 184, 101);
  border-radius: 50%;
  transition: ease-in all 0.2s;
  cursor: pointer;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}

.btnGeneral p {
  margin: 0;
  font-weight: normal;
  color: white;
}

.btnGeneral:hover {
  background-color: rgb(59, 149, 83) !important;
  color: white;
}

.containerConversation {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.selectAttachedFiles {
  position: absolute;
  bottom: 10px;
  width: 200px;
  left: 10px;
  height: 170px;
  background-color: whitesmoke;
  border-radius: 8px;
  z-index: 5;
}

.enableAttach {
  display: block;
}

.disableAttach {
  display: none;
}

.conversation {
  position: relative;
  padding: 5px 5px 0 0;
  height: 340px;
  max-height: 340px;
}

.contentConversation {
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  padding: 0 0.5rem 0.5rem 0.5rem;
  border-radius: 8px;
}

.inputTextArea::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.inputTextArea::-webkit-scrollbar-track {
  background: transparent;
}

.inputTextArea::-webkit-scrollbar-thumb {
  background-color: #3a4750;
  border-radius: 2px;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 2px;
}

.sendMessage {
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  padding: 0 0.5rem;
  background-color: aliceblue;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
}

.iconSendMessage {
  color: white;
  transform: rotate(45deg);
}

.spin {
  right: 5px;
  height: 20px;
  width: 20px;
  color: white;
}

.contentUser {
  display: flex;
  gap: 1rem;
  padding: 0 0.2rem;
}

.contentUser p {
  font-size: 10px;
  color: #adadad;
}

.nameUser {
  font-size: 10px;
  color: #adadad;
  padding: 0.1rem 0.4rem;
  width: 100%;
}

.accoutingAndMessage {
  width: 100%;
  display: flex;
}

.userAndMessage {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.dateMessageAccounting {
  font-size: 10px;
  color: #adadad;
  display: flex;
  align-items: flex-end;
  padding: 0 0.5rem 0 0;
}

.dateMessageUser {
  font-size: 10px;
  color: #adadad;
  display: flex;
  align-items: flex-end;
  padding: 0 0 0 0.5rem;
}

.contentMessage {
  display: flex;
  gap: 1rem;
}

.message {
  position: relative;
  cursor: default;
  display: flex;
  flex-direction: column;
}

.generalMessages {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  border-radius: 8px;
  font-size: 12px;
  color: white;
}

.contentMessageAccounting {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.colorMessageUser {
  background-color: rgba(72, 184, 102, 0.702);
}

.messageUser {
  padding: 0 0.5rem 0.8rem 0.5rem;
  white-space: pre-line;
}

.colorMessageAccounting {
  background-color: #5751a0da;
}

.contentIconFile {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
  width: 35px;
  height: 35px;
  background-color: #eeeeee;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.357);
  transition: ease-in all 0.1s;
}

.contentIconFile:hover {
  background-color: #c9c9c969;
}

.enableFiles {
  cursor: default;
  position: relative;
}

.attachMessage {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 18px;
  left: 3px;
  /* background-color: red; */
  border-radius: 5px;
  background-color: rgb(60, 132, 84);
  backdrop-filter: blur(5px);
  cursor: pointer !important;
  z-index: 9999;
}

.attachMessageAccounting {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 18px;
  right: 3px;
  /* background-color: red; */
  border-radius: 5px;
  background-color: rgb(60, 132, 84);
  backdrop-filter: blur(5px);
  cursor: pointer;
  z-index: 9999;
}

.contentIconAttachMessage {
  text-align: center;
  cursor: pointer !important;
}

.dropDownCustom {
  cursor: pointer !important;
  font-size: 10px;
  text-align: center;
  padding: 0.3rem 0;
  display: block;
  position: absolute;
  width: 80px;
  top: 22px;
  left: 15px;
  background-color: white;
  z-index: 9999 !important;
  border-radius: 0 8px 8px 8px;
}

.dropDownCustom {
  margin: 0;
}

.dropDownCustomLeft {
  cursor: pointer !important;
  font-size: 10px;
  text-align: center;
  padding: 0.3rem 0;
  display: block;
  position: absolute;
  width: 80px;
  top: 22px;
  right: 15px;
  background-color: white;
  z-index: 9999 !important;
  border-radius: 0 8px 8px 8px;
}

.dropDownCustomLeft {
  margin: 0;
}

.inputCheck {
  width: 0;
  height: 0;
  color: transparent;
  background-color: transparent;
  border: none;
  outline: none;
}

.labelInputCheck {
  margin: 0;
  width: 25px;
  text-align: center;
  height: 100%;
  cursor: pointer;
}

span {
  cursor: default !important;
}

p {
  margin: 0;
}
</style>