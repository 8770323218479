<template>
  <div class="General">
    <div class="ContainerBtn">
      <b-button @click="modalHeight" class="Btn" style="background: #3e66ae">
        <b-icon-plus scale="1.5"></b-icon-plus>
        Nova Ordem de serviço</b-button
      >
    </div>

    <div class="lineSearch">
      <div class="searchByMonth">
        <div>Resumo Do Mês</div>
        <div class="pagMonth">
          <b-icon-chevron-left
            class="Arrows"
            id="btn-month-prev"
            @click="updateMonth('prev')"
          ></b-icon-chevron-left>
          <b-form-select
            class="searchMonth custom-select3"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="monthSelected"
            :options="filterMonth"
            @change="monthHasChanged"
          ></b-form-select>
          <b-form-select
            class="searchYear custom-select3"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="yearSelected"
            :options="filterYear"
            @change="monthHasChanged"
          ></b-form-select>
          <b-icon-chevron-right
            class="Arrows"
            style="margin: 0 0 0 7px"
            id="btn-month-next"
            @click="updateMonth('next')"
          ></b-icon-chevron-right>
        </div>
      </div>
    </div>

    <div class="Summary">
      <div
        class="ItemSummary"
        @click="setStatusByClickingCards('Cancelado')"
        style="border: 3px ridge #403b74"
      >
        <span class="titleSummary">Canceladas</span>
        <span class="monetaryValusSummary">
          {{
            this.TotalCanceled.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </span>
      </div>
      <div
        class="ItemSummary"
        @click="setStatusByClickingCards('Pendente')"
        style="border: 3px ridge #3e66ae"
      >
        <span class="titleSummary">Pendentes</span>
        <span class="monetaryValusSummary">
          {{
            this.TotalPending.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </span>
      </div>
      <div
        class="ItemSummary"
        @click="setStatusByClickingCards('Em andamento')"
        style="border: 3px ridge #48b865"
      >
        <span class="titleSummary">Em Andamento</span>
        <span class="monetaryValusSummary">
          {{
            this.TotalInProgress.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </span>
      </div>
      <div
        class="ItemSummary"
        @click="setStatusByClickingCards('Finalizado')"
        style="border: 3px ridge #f2a83e"
      >
        <span class="titleSummary">Finalizadas</span>
        <span class="monetaryValusSummary">
          {{
            this.TotalFinished.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </span>
      </div>
      <div
        class="ItemSummary"
        @click="setStatusByClickingCards('Faturado')"
        style="border: 3px ridge #222124"
      >
        <span class="titleSummary">Faturadas</span>
        <span class="monetaryValusSummary">
          {{
            this.TotalInvoiced.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </span>
      </div>
    </div>

    <div class="ContainerSearchInput">
      <div class="textRegister">
        <span>Ordens Registradas</span>
      </div>
      <div class="searchContainer">
        <div class="searchAvancedContent">
          <button
            v-if="!isAdvancedSearch"
            class="searchAvanced"
            @click="enableAdvancedSearch"
          >
            Realizar Pesquisa Avançada
          </button>
          <button
            v-if="isAdvancedSearch"
            class="searchAvanced"
            @click="enableAdvancedSearch"
          >
            Pesquisa por mês
          </button>
        </div>
        <div class="searchInput" v-if="isAdvancedSearch">
          <b-form-select
            class="searchFilterData"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="typeDate"
            :options="filterDataCombobox"
          ></b-form-select>
          <div class="dateInput">
            <input
              type="date"
              id="date"
              v-model="startDate"
              style="
                padding: 0.25rem 0.25rem 0.25rem 0.5rem;
                cursor: pointer;
                border-radius: 5px;
                box-shadow: none;
              "
            />
            <input
              type="date"
              id="date"
              v-model="endDate"
              style="padding: 0.25rem 1.25rem 0.25rem 0.25rem; cursor: pointer"
            />
          </div>
        </div>
        <div class="searchInput">
          <b-form-select
            class="searchFilter"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="type"
            :options="filterCombobox"
          ></b-form-select>
          <div>
            <b-input
              placeholder="Pesquisar por..."
              v-model="textPesquisa"
              @keyup.enter="filterPerMonth()"
            ></b-input>
          </div>
          <b-button size="sm" @click="filterPerMonth()" class="searchBtn">
            <b-icon-search></b-icon-search>
          </b-button>
        </div>
        <button class="searchAll" @click="searchAll()">ver tudo</button>
      </div>
    </div>
    <ProvisionServiceTable
      :page="page"
      :dataFilterNfse="dataFilterNfse"
      :typeDocument="nfseModel.OS"
      :searchPerStatus="statusToSearch"
      @openToEdit="handleEdit"
      @tableLength="dataLength = $event"
    />

    <div class="setBtn">
      <button class="itemBtn" :disabled="page == 1" @click="previousPage(page)">
        <b-icon-chevron-left></b-icon-chevron-left>
      </button>
      <button class="centerItemBtn">{{ page }}</button>
      <button
        class="itemBtn"
        :disabled="this.dataLength < 7"
        @click="nextPage(page)"
      >
        <b-icon-chevron-right></b-icon-chevron-right>
      </button>
    </div>
    <div :class="sizeModal">
      <div :class="modalContainer" v-if="colapseModal">
        <b-button class="closeModal" @click="modalHeight"> X </b-button>
        <ProvisionService :model="nfseModel.OS" style="margin-top: 30px" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import { DateTime } from "luxon";

import ProvisionService from "../ProvisionService/ProvisionService.vue";
import ProvisionServiceTable from "../FiscalIssuance/ProvisionServiceTable.vue";
import api from "../../services/axios";

import { NfseModel } from "../../utils/enums/NfseModel";
import { nfseSituationsNfse } from "../../common";
import printReport from "../../reports/sale";

export default {
  components: {
    ProvisionService,
    ProvisionServiceTable,
  },
  data() {
    return {
      year: DateTime.local().year,
      month: DateTime.local(),
      textPesquisa: "",
      type: "",
      startDate: "",
      endDate: "",
      monthSelected: "",
      yearSelected: "",
      dataFilterNfse: [],
      spinGeneral: false,
      isAdvancedSearch: false,
      TotalCanceled: 0,
      TotalPending: 0,
      TotalInProgress: 0,
      TotalFinished: 0,
      TotalInvoiced: 0,
      idToRemoveOrderService: "",
      colapseModal: false,
      modalContainer: "contentModalDisable",
      page: 1,
      dataLength: 0,
      typeDate: "emissao",
      filterDataCombobox: [
        { value: "execucao", text: "Data Execução" },
        { value: "emissao", text: "Data Emissão" },
      ],
      filterCombobox: [
        { value: "nomeCliente", text: "Cliente" },
        { value: "nomeFuncionario", text: "Funcionário" },
        { value: "status", text: "Status" },
        { value: "referencia", text: "Referência" },
        { value: "", text: "Selecione..." },
      ],
      filterMonth: [
        { value: "1", text: "Janeiro" },
        { value: "2", text: "Fevereiro" },
        { value: "3", text: "Março" },
        { value: "4", text: "Abril" },
        { value: "5", text: "Maio" },
        { value: "6", text: "Junho" },
        { value: "7", text: "Julho" },
        { value: "8", text: "Agosto" },
        { value: "9", text: "Setembro" },
        { value: "10", text: "Outubro" },
        { value: "11", text: "Novembro" },
        { value: "12", text: "Dezembro" },
      ],
      filterYear: [
        { value: "2021", text: "2021" },
        { value: "2022", text: "2022" },
        { value: "2023", text: "2023" },
        { value: "2024", text: "2024" },
        { value: "2025", text: "2025" },
        { value: "2026", text: "2026" },
        { value: "2027", text: "2027" },
        { value: "2028", text: "2028" },
        { value: "2029", text: "2029" },
        { value: "2030", text: "2030" },
        { value: "2031", text: "2031" },
        { value: "2032", text: "2032" },
      ],
      nfseModel: NfseModel,
      nfseSituationsNfse: nfseSituationsNfse,
      statusToSearch: null,
    };
  },
  methods: {
    ...mapMutations([
      "ALTER_SIZE_MODAL",
      "SET_DATA_SEARCH_FROM_PROVISION_SERVICE",
    ]),

    async filterPerMonth() {
      try {
        const { data } = await api.get(
          `/nfse/per/month/search?month=${this.month.month}&year=${this.year}&model=${NfseModel.OS}&q=${this.textPesquisa}&type=${this.type}&page=${this.page}&isAdvancedSearch=${this.isAdvancedSearch}&startDate=${this.startDate}&endDate=${this.endDate}&typeDate=${this.typeDate}`
        );
        this.dataLength = data.data.length;
        this.dataFilterNfse = data.data;
        this.TotalCanceled = this.getTotalOrderByType(data.data, "Cancelado");
        this.TotalPending = this.getTotalOrderByType(data.data, "Pendente");
        this.TotalInProgress = this.getTotalOrderByType(
          data.data,
          "Em andamento"
        );
        this.TotalFinished = this.getTotalOrderByType(data.data, "Finalizado");
        this.TotalInvoiced = this.getTotalOrderByType(data.data, "Faturado");
      } catch (error) {
        console.log(error);
      }
    },

    async enableAdvancedSearch() {
      this.isAdvancedSearch = !this.isAdvancedSearch;
      if (!this.isAdvancedSearch) {
        this.startDate = "";
        this.endDate = "";
      }
    },

    monthHasChanged() {
      this.month = this.month.set({ month: parseInt(this.monthSelected) });
      this.year = this.yearSelected;
      this.filterPerMonth();
    },

    async updateMonth(direction) {
      if (direction === "prev") {
        this.month = this.month.minus({ months: 1 });
        this.year = this.yearSelected;
        this.monthSelected = this.month.month;
      } else if (direction === "next") {
        this.month = this.month.plus({ months: 1 });
        this.year = this.yearSelected;
        this.monthSelected = this.month.month;
      }
      await this.filterPerMonth();
      this.clearSearch();
    },

    searchAll() {
      this.clearSearch();
      this.filterPerMonth();
    },

    getTotalOrderByType(orders, type) {
      return orders
        .filter((order) => order.status === type)
        .reduce((total, order) => total + order.valorTotal, 0);
    },

    getIdToRemoveOrderService(id) {
      this.idToRemoveOrderService = id;
    },

    handleEdit() {
      this.modalHeight();
    },

    setStatusByClickingCards(type) {
      this.statusToSearch = type;
    },

    async modalHeight() {
      this.colapseModal = !this.colapseModal;
      this.colapseModal
        ? this.ALTER_SIZE_MODAL("ModalGeneral")
        : this.ALTER_SIZE_MODAL("disable");
      this.modalContainer = this.modalContainer
        ? "modalContainer"
        : "contentModalDisable";
      if (!this.colapseModal && this.sizeModal === "disable") {
        this.SET_DATA_SEARCH_FROM_PROVISION_SERVICE({});
      }

      await this.filterPerMonth();
    },

    async printReportOrder(id) {
      printReport("reportOrder", id);
    },

    clearSearch() {
      this.textPesquisa = "";
      this.type = "";
      this.startDate = "";
      this.endDate = "";
    },

    nextPage() {
      this.filterPerMonth((this.page += 1));
    },

    previousPage() {
      if (this.page === 1) {
        return;
      } else {
        this.filterPerMonth((this.page -= 1));
      }
    },
  },
  computed: {
    ...mapState({
      sizeModal: (state) => state.sizeModal,
    }),
    monthName() {
      return this.month.toLocaleString({ month: "long", locale: "pt-BR" });
    },
  },
  beforeDestroy() {
    this.ALTER_SIZE_MODAL("disable");
    this.SET_DATA_SEARCH_FROM_PROVISION_SERVICE({});
  },
  created() {
    this.filterPerMonth();
  },
  async mounted() {
    this.monthSelected = this.month.month;
    this.yearSelected = this.year;
    await this.filterPerMonth();
  },
};
</script>
<style scoped>
.General {
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  width: calc(100% - 100px);
  height: 100vh;
  font-size: 12px;
}

.ContainerBtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  padding: 0px 0px 5px 0px;
  margin: 70px 0px 0px 22px;
}

.Btn {
  width: 30%;
  border: none;
  height: 35px;
  border-radius: 3px;
  font-size: 12px;
  box-shadow: 0px 12px 4px -10px rgba(142, 142, 142, 0.78);
}

.Summary {
  display: flex;
  height: 70px;
  width: 100%;
  padding-bottom: 5px;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid rgb(235, 235, 235);
}

.ItemSummary {
  width: 18%;
  height: 60px;
  border-radius: 5px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 100;
  box-shadow: 0px 12px 4px -10px rgba(142, 142, 142, 0.78);
  cursor: pointer !important;
}

.ItemSummary span {
  cursor: context-menu;
}

.lineSearch {
  display: flex;
  align-items: center;
  padding: 0px 0px 10px 0px;
}

.searchByMonth {
  width: 30%;
  display: flex;
  align-items: center;
  margin: 10px 0px 10px 20px;
  border-radius: 5px;
  justify-content: space-around;
  border: 1px solid #e3e0e0;
}

.pagMonth {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.searchMonth {
  width: 100px;
  height: 30px;
  border-radius: 5px 0px 0px 5px;
  font-size: 11px;
  text-align: center;
  font-family: Poppins-Regular, sans-serif !important;
}

.searchMonth:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

.searchYear {
  width: 50px;
  height: 30px;
  border-radius: 0px 5px 5px 0px;
  border: 1px solid rgb(195, 193, 193);
  font-size: 11px;
  text-align: center;
  font-family: Poppins-Regular, sans-serif !important;
}

.searchYear:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

.custom-select3 {
  left: 4px;
  -moz-appearance: none;
  appearance: none;
  background: none;
  font-weight: bold;
  padding: 0 !important;
}

.Arrows {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.Arrows:hover {
  background: whitesmoke;
}

.searchFilter {
  width: 110px;
  border-right: none;
  height: 30px;
  font-size: 11px;
  text-align: center;
}

.searchFilter:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

.searchFilterData {
  width: 130px;
  border-right: none;
  height: 30px;
  font-size: 11px;
  text-align: center;
}

.searchFilterData:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

.custom-select {
  left: 4px;
  position: relative;
  -moz-appearance: none;
  appearance: none;
}

.ContainerSearchInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 0 20px 0 0;
  max-width: 100%;
}

.textRegister {
  font-weight: bold;
  padding-left: 20px;
}

.searchContainer {
  display: flex;
  gap: 5px;
  width: 85%;
  justify-content: flex-end;
  align-items: center;
}

.searchAvancedContent {
  display: flex;
  padding: 3px 0px 3px 0;
  align-items: center;
  justify-content: flex-end;
}

.searchAvanced {
  justify-content: center;
  color: #3a4750;
  font-size: 12px;
  height: 30px;
  gap: 10px;
  padding: 0px 20px;
  border: solid 1px #e3e0e0;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.searchAvanced:hover {
  background: rgb(205, 205, 205);
}

.searchInput {
  display: flex;
  margin: 5px;
}

.form-control-sm {
  padding: 0.25rem 1.5rem;
  height: 30px;
}

.form-control {
  font-size: 13px;
}

.dateInput {
  display: flex;
  gap: 10px;
}

input {
  border: 1px solid #e3e0e0;
  border-radius: 5px;
  height: 30px;
}

.searchBtn {
  right: 10px;
  height: 30px;
  position: relative;
  background: whitesmoke;
  color: black;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
  border-color: #e3e0e0;
}

input:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

button:focus {
  box-shadow: none;
}

.btnPeriod {
  border-radius: 5px 0px 0px 5px;
  border: 1px solid rgb(235 235 235);
  padding: 0 10px;
  height: 30px;
  font-size: 11px;
  cursor: context-menu;
}

.searchAll {
  justify-content: center;
  color: #3a4750;
  font-size: 13px;
  height: 30px;
  padding: 0px 20px;
  font-weight: bold;
  border: solid 1px #e3e0e0;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.searchAll:hover {
  background: rgb(205, 205, 205);
}

.ContentTable {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  padding: 20px;
  padding: 10px 20px 0 20px;
  width: 100%;
  max-width: 100%;
}

.headTable {
  color: whitesmoke;
  background: #3a4750;
  text-align: center;
  height: 29px;
}

.defaultSearch {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30vh;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.defaultSearch span {
  font-size: 1rem;
  font-weight: bold;
  color: gray;
}

.loadingLazy {
  position: absolute;
  bottom: 20px;
  height: 50vh;
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
  opacity: 0.5;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #403b74;
  box-shadow: 30px 0 0 #f2a83e;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #3e66ae;
  box-shadow: 30px 0 0 #48b865;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f2a83e;
  }

  50% {
    box-shadow: 0 0 0 #f2a83e;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f2a83e;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #48b865;
  }

  50% {
    box-shadow: 0 0 0 #48b865;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #48b865;
    margin-top: 0;
  }
}

tr:nth-child(even) {
  background: #f1f1f1;
}

th {
  font-weight: normal;
  font-size: 12px;
}

td {
  text-transform: capitalize;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}
.titleModal {
  font-size: 12px;
  border-bottom: 1px solid #e6e7e8;
}

.btnModal {
  margin-top: 10px;
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.btnContent {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentReceive {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.setBtn {
  display: flex;
  flex-direction: row;
  margin: 0 1rem 1rem 0;
  gap: 10px;
  bottom: 0;
  right: 0;
  position: absolute;
}

.itemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #3a4750;
}

.itemBtn:hover {
  background: #3a4750;
  color: whitesmoke;
}

.itemBtn:disabled {
  opacity: 0;
}

.centerItemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #1b3159;
  cursor: auto;
}
.ModalGeneral {
  position: fixed !important;
  z-index: 900;
  top: 53px;
  left: 61px;
  height: calc(100% - 53px);
  max-height: calc(100% - 53px);
  overflow: auto;
  width: calc(100% - 62px);
  background: #ffff;
  transition: all 0.7s ease-in-out;
}

.disable {
  position: absolute;
  width: calc(100% - 62px);
  height: 0;
  left: 61px;
  top: 100vh;
  background: #b0b0b000;
  visibility: hidden;
  transition: all 0.7s ease-in-out;
}

.closeModal {
  position: fixed;
  top: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  z-index: 900;
  font-size: 18px;
  height: 30px;
  background: white;
  border: none;
  color: rgb(6, 6, 6);
}

.closeModal:hover {
  background: whitesmoke;
  color: red;
}

.closeModal:focus {
  background: red;
  border: none;
  box-shadow: none;
}

.contentModalDisable {
  display: none;
  background: white;
  opacity: 0;
}

.modalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.btnActions {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-menu {
  font-size: 12px;
  min-width: 5rem !important;
  width: 5rem !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  color: red;
  background: red;
  text-align: center;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu li {
  font-size: 12px;
  width: 100% !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  display: flex;
  text-align: center;
  list-style: none;
  border: none;
}

.typeSale {
  color: #403b74;
  font-weight: bold;
}

.summaryMonth {
  font-weight: 600;
}

.month {
  font-size: 12px;
  text-transform: capitalize;

  font-weight: 600;
}

.titleSummary {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .General {
    margin-left: 60px;
    width: calc(100% - 60px);
    font-size: 11px;
  }
  .ContainerBtn {
    padding: 0px;
    margin: 70px 0px 0px 8px;
  }
  .Btn {
    width: 33%;
    height: 30px;
    font-size: 11px;
  }
  .searchByMonth {
    width: 35%;
    margin: 10px 0px 10px 8px;
  }
  .month {
    font-size: 11px;
  }
  .lineSearch {
    padding: 0px;
  }
  .Summary {
    padding-bottom: 10px;
  }
  .ItemSummary {
    width: 18%;
    height: 50px;
    gap: 0px;
    cursor: pointer !important;
  }
  .searchAll {
    font-size: 11px;
    width: 70px;
  }
  .searchFilter {
    width: 80px;
  }
  .textRegister {
    margin: 10px 10px 0px 0px;
  }
  .textRegister span {
    padding-left: 5px;
  }
  .searchInput {
    margin: 10px 10px 0px 0px;
  }
  .ContentTable {
    padding: 10px 10px 0 0px;
  }
  .headTable {
    height: 25px;
  }
  .setBtn {
    margin: 0 1rem 10px 0;
  }
  .itemBtn {
    width: 18px;
    height: 18px;
  }
  .centerItemBtn {
    width: 18px;
    height: 18px;
  }
  .FooterPagination {
    padding: 0px;
  }
  .form-control-sm {
    padding: 0.25rem 1rem;
  }
  .inputSearch {
    width: 50%;
  }
}

@media screen and (max-width: 700px) {
  .General {
    margin-left: 0px;
    width: 100%;
    height: 100vh;
    font-size: 10px;
  }

  .ContainerBtn {
    justify-content: center;
    margin: 55px 0px 0px 10px;
  }

  .Btn {
    width: 80%;
    height: 30px;
  }

  .lineSearch {
    align-items: center;
    justify-content: center;
  }

  .searchByMonth {
    width: 80%;
    margin: 5px 0px 5px 20px;
  }

  .ItemSummary {
    width: 19%;
    text-align: center;
    height: 70px;
    gap: 0px;
    align-items: center;
    justify-content: center;
    cursor: pointer !important;
  }

  .ContainerSearchInput {
    flex-direction: column;
    height: 70px;
    margin-top: 10px;
  }

  .searchFilter {
    width: 80px;
  }

  .searchInput {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 3px;
  }

  .searchAll {
    width: 50px;
  }

  input {
    width: 120px !important;
  }

  .ModalGeneral {
    top: 53px;
    left: 0;
    width: 100%;
  }

  .disable {
    width: 100%;
    height: 0;
    left: 0;
  }
  .ContentTable {
    padding: 10px 3px 0 3px;
  }
}
</style>