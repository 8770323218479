import api from "./axios";

class ServiceTaxNfse {
  async save(taxes){
    const { data } = await api.post('/impostos-nfse', taxes)
    return data
  }

  async update(taxes) {
    await api.put(`/impostos-nfse/${taxes.id}`, taxes)
 }

  async delete(tax) {
     await api.delete(`/impostos-nfse/${tax.id}?classImposto=${tax.ref}`)
  }

  async findAllRefs() {
    const { data } = await api.get('/impostos-nfse')
    return data
  }

  async findById(id) {
    const { data } = await api.get(`/impostos-nfse/${id}`)
    return data
  }
}

export default new ServiceTaxNfse()