export const INITIAL_STATE_PRODUCTS = {
  id: "",
  idVenda: "",
  idFornecedor: "",
  idProduto: "",
  quantidade: 0,
  valorUnitario: 0,
  valorTotal: 0,
  desconto: 0,
  dadosAdicionais: "",
  tipo: "Venda",
  tipoProduto: "normal",
  idProdutoComposicao: ""
}