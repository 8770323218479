import api from "./axios";

class ServiceSettings {
  async save(settings) {
    const { data } = await api.post("/settings", settings)
    return data
  }

  async update(settings) {
    await api.put(`/settings/${settings.id}`, settings)
  }

  async saveAtm(settings) {
    const { data } = await api.post("/settings/save-atm", settings)
    return data
  }

  async updateAtm(atm) {
    await api.patch(`/settings/${atm.id}`, atm)
  }

  async updateVirtualManager(idSettings, virtualManager) {
    await api.patch(`/settings/virtual-manager/${idSettings}`, virtualManager)
  }

  async findSettingsAtm() {
    const { data } = await api.get("/settings/get/config")
    return data
  }

  async updateSettings(data) {
    await api.patch(`/settings/update/settings/financer/${data.dataSettings.id}`, { settings: data.dataFinancer })
  }

  async updateFiscal(data) {
    const id = data.dataSettings.id;
    const settingsFiscal = data.dataFiscal;
    await api.patch(`/settings/update/fiscal/${id}`, settingsFiscal);
  }
}

export default new ServiceSettings()