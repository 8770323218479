var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"generalContainer"},[_c('span',{staticClass:"title"},[_c('b-icon-chat-right-text'),_c('p',[_vm._v("Envie uma nova mensagem")])],1),_c('div',{staticClass:"rowContainerBackground"},[_c('div',{staticClass:"containerConversation"},[_c('div',{staticClass:"conversation"},[_c('div',{ref:"refMessages",staticClass:"contentConversation"},_vm._l((_vm.accountingConversations),function(conversation){return _c('div',{key:conversation.id},[_c('div',{class:("" + (_vm.handleContentMessage(conversation.nivel)))},[_c('div',{staticClass:"enableFiles"},[_c('span',{staticClass:"contentUser",style:(("" + (conversation.nivel === _vm.getLoggedUser &&
                    'justify-content: flex-end')))},[_c('p',[_vm._v(" "+_vm._s(_vm.handleHours(conversation.data))+" ")])]),_c('div',{staticClass:"message"},[_c('span',{class:("generalMessages " + (_vm.handleColorMessage(
                      conversation.nivel
                    )))},[_c('p',{staticClass:"nameUser",style:(("" + (conversation.nivel === _vm.getLoggedUser &&
                        'text-align: end')))},[_vm._v(" "+_vm._s(_vm.handleUsers(conversation.nivel))+" ")]),_c('div',{class:("" + (_vm.handleContentUsersAndMessage(
                        conversation.nivel
                      )))},[_c('p',{staticClass:"messageUser"},[_vm._v(" "+_vm._s(conversation.mensagem)+" ")]),_c('p',{class:((_vm.handleDateMessage(conversation.nivel)) + " ")},[_vm._v(" "+_vm._s(_vm.handleDate(conversation.data))+" ")])])])]),(
                    conversation.totalArquivos &&
                    conversation.totalArquivos > 0
                  )?_c('div',{class:("" + (_vm.handleEnableFileMessage(conversation.nivel))),on:{"click":function($event){return _vm.getMessageFiles(conversation.id)}}},[_c('span',{staticClass:"contentIconAttachMessage"},[_c('b-icon-paperclip',{staticClass:"iconDropDown",staticStyle:{"color":"white"},attrs:{"scale":"1"}})],1)]):_vm._e()])])])}),0),_c('div',{class:("selectAttachedFiles " + (_vm.attachFilesIsEnable ? 'enableAttach' : 'disableAttach'))},[_c('AttachUnicFile',{ref:"attachedUnicFile",attrs:{"isClear":_vm.attachFilesIsEnable}})],1)]),_c('div',{staticClass:"sendMessage"},[_c('span',{staticClass:"contentIconFile",style:(("" + (_vm.attachFilesIsEnable &&
            'width: auto; background-color: rgb(172 55 55)'))),on:{"click":_vm.enableAttachFiles}},[(!_vm.attachFilesIsEnable)?_c('b-icon-paperclip',{staticStyle:{"color":"rgb(39, 216, 240)"},attrs:{"scale":"1.3"}}):_c('p',{staticStyle:{"margin":"0","font-size":"11px","padding":"0 0.3rem","color":"white"}},[_vm._v(" Cancelar ")])],1),_c('div',{staticClass:"rowCustomMessages",staticStyle:{"width":"100%"}},[_c('span',{staticClass:"contentInputText",style:(("height: " + _vm.heightToSendMessage + "px"))},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.accountingMessage.message),expression:"accountingMessage.message"}],staticClass:"inputTextArea",attrs:{"placeholder":"Digite aqui sua mensagem..."},domProps:{"value":(_vm.accountingMessage.message)},on:{"keypress":_vm.handlePress,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.accountingMessage, "message", $event.target.value)}}})])]),_c('span',[_c('button',{staticClass:"btnGeneral",style:(_vm.spinFromSendMessage ? 'pointer-events: none' : ''),attrs:{"size":"sm","variant":"none"},on:{"click":_vm.send}},[(!_vm.spinFromSendMessage)?_c('b-icon-cursor-fill',{staticClass:"iconSendMessage",attrs:{"scale":"1.7"}}):_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('b-spinner',{staticClass:"spin"})],1)],1)])])])]),_c('ModalMessageFilesVue',{attrs:{"listFiles":_vm.listMessageFiles},on:{"modalClosed":_vm.clearListMessageFiles,"removedFile":function($event){return _vm.getMessageFiles($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }