import api from "./axios";

class ServiceRooms {
  async save(room) {
    const { data } = await api.post("/rooms", room)
    return data.id;
  }

  async update(room) {
    await api.put(`/rooms/${room.id}`, room);
  }

  async delete(id) {
    await api.delete(`/rooms/${id}`);
  }

  async findById(id) {
    const { data } = await api.get(`/rooms/${id}`);
    return data;
  }

  async fillComobobox() {
    const { data } = await api.get(`/rooms/combobox`);
    return data;
  }
}

export default new ServiceRooms()