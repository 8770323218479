<template>
  <BoxFront />
</template>

<script>
import BoxFront from "./BoxFront.vue";
export default {
  components: {
    BoxFront,
  },
};
</script>

<style>
</style>