import api from "./axios";

class ServiceNfse {
  async save(idNfse) {
    const { data } = await api.post("/nfse", idNfse)
    return data
  }

  async update(data) {
    await api.put(`/nfse/${data.id}`, data);
  }

  async updateStatus(id, status) {
    await api.patch(`/nfse/${id}`, status);
  }

  async delete(idNfse) {
    await api.delete(`/nfse/${idNfse}`)
  }

  async deleteAll(idNfse) {
    await api.delete(`/nfse/all/${idNfse}`)
  }

  async findById(id) {
    const { data } = await api.get(`/nfse/${id}`)
    return data;
  }

  async search(text, type, page = 1, startDate, endDate, model = "os") {
    const { data } = await api.get(`/nfse?q=${text}&type=${type}&page=${page}&startDate=${startDate}&endDate=${endDate}&model=${model}`)
    return data;
  }

  async send(idNfse) {
    const { data } = await api.get(`/nfse/send/${idNfse}`)
    return data
  }

  async cancelNfse(id, motivo) {
    const { data } = await api.post(`/nfse/cancel/${id}`, {motivo})
    return data
  }
}
export default new ServiceNfse()