<template>
  <div class="containerGeneral">
    <div class="contentRegisters">
      <div style="height: 100%">
        <div class="containerRegisterList">
          <div class="contentRegisterList">
            <div
              style="
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1rem;
              "
            >
              <div style="display: flex; align-items: center">
                <span> <b-icon-clock scale="1.5" /> </span>

                <span>
                  <p class="titleRegister">Registro de Ponto</p>
                  <p class="lastRecord" v-if="pointRecords.lastPoint">
                    Ult. Registro: {{ pointRecords.lastPoint }}
                  </p>
                  <p class="lastRecord" v-else>Ult. Registro: --</p>
                </span>
              </div>
              <p class="titleMonthRegister">{{ this.dateRegister }}</p>
            </div>

            <div @click="exit" class="contentExit">
              <b-icon-box-arrow-left scale="1.7" style="color: white" />
            </div>
          </div>
        </div>

        <div class="containerRegisterDayAndHours">
          <span class="contentTitlePointRecords">
            <span>
              <p v-if="this.userName" class="userName">
                {{ this.userName }}
              </p>
              <p class="titlePointRecords">Registros</p>
            </span>
            <span
              :class="`btnRegister ${loadingRegister && 'isLoading'}`"
              @click="register"
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
                v-if="loadingRegister"
              >
                <b-spinner class="spin" />
              </div>
              <div v-else>Registrar</div>
            </span>
          </span>

          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;
            "
          >
            <div class="contentCardsRegister" ref="infiniteScroll">
              <div
                style="display: flex; flex-direction: column; gap: 1rem"
                v-if="Object.keys(pointRecords.points).length"
              >
                <div
                  v-for="month in Object.keys(pointRecords.points)"
                  :key="month"
                >
                  <p
                    style="
                      margin: 0 0 5px 0;
                      font-weight: 600;
                      letter-spacing: 0.1rem;
                      color: rgb(62, 102, 174);
                      font-size: 14px;
                    "
                  >
                    Mes {{ month }}
                  </p>

                  <div
                    style="display: flex; flex-direction: column; gap: 0.5rem"
                  >
                    <span
                      style="display: flex; flex-direction: column; gap: 0.5rem"
                    >
                      <div
                        :class="`contentRegisterDayAndHours ${handleTypeColor(
                          point
                        )}`"
                        v-for="point in pointRecords.points[month]"
                        :key="point.id"
                      >
                        <span
                          style="display: flex; justify-content: space-between"
                        >
                          <p class="date">{{ point.date }}</p>
                          <p :class="`date ${handleTextTypePoint(point)}`">
                            {{ point.type ? point.type.toUpperCase() : "" }}
                          </p>
                        </span>

                        <span
                          style="display: flex; justify-content: space-between"
                        >
                          <p class="day">{{ formatDay(point.day) }}</p>
                          <p class="hours">{{ point.hours }}</p>
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div v-else class="contentNotPointRecords">
                <span> <b-icon-dash-circle scale="1.5" /> </span>
                <p style="margin: 0">Nenhum registro informado...</p>
              </div>
            </div>
          </div>

          <span class="backTop" @click="backTop" v-if="isEnableBtnBackTop">
            <b-icon-arrow-up-short scale="2" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import ServiceUser from "../../services/serviceUser";
import ServicePointRecords from "../../services/servicePointRecords";

import { geolocationErrors } from "../../utils/geolocationErrors";
import { HandleErrors } from "../../utils/handleErrors";
import { clearTokens } from "../../utils/clearTokens";

export default {
  components: {},
  data() {
    return {
      userName: "",
      dateRegister: "",
      idInterval: "",
      pointRecords: {
        points: [],
        lastPoint: null,
      },
      loadingRegister: false,
      page: 1,
      isEnableBtnBackTop: false,
    };
  },
  methods: {
    async findUserName() {
      this.userName = await ServiceUser.findUserName();
    },

    async register() {
      try {
        this.loadingRegister = true;

        const position = await this.getLocaltion();
        const lat = position.coords.latitude;
        const long = position.coords.longitude;

        await ServicePointRecords.save({ lat, long });

        this.page = 1;
        this.findByIdUser(true);
        this.backTop();

        this.$toast.open({
          message: "Ponto Registrado!",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (geolocationErrors[error.code]) {
          this.$toast.open({
            message:
              geolocationErrors[error.code] ||
              "Erro inesperado ao obter a localização.",
            type: "info",
          });
        } else if (typeError) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao registrar o ponto",
          type: "info",
        });
      } finally {
        this.loadingRegister = false;
      }
    },

    async findByIdUser(isSave = false) {
      const result = await ServicePointRecords.findByIdUser(this.page);

      if (isSave) {
        this.pointRecords = result;
        return;
      }

      if (Object.keys(result.points).length) {
        if (Object.keys(this.pointRecords.points).length) {
          this.pointRecords.lastPoint = result.lastPoint;

          Object.keys(result.points).forEach((keyResult) => {
            if (this.pointRecords.points[keyResult]) {
              this.pointRecords.points[keyResult] = [
                ...this.pointRecords.points[keyResult],
                ...result.points[keyResult],
              ];
            } else {
              this.pointRecords.points[keyResult] = result.points[keyResult];
            }
          });
        } else {
          this.pointRecords = result;
        }
      }
    },

    exit() {
      clearTokens();

      this.$router.push({
        path: "/",
      });
    },

    getLocaltion() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    },

    handleScroll(event) {
      const element = event.target;

      this.isEnableBtnBackTop = element.scrollTop > 0 ? true : false;

      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.page += 1;
        this.findByIdUser();
      }
    },

    addEventScroll() {
      this.$refs.infiniteScroll.addEventListener("scroll", this.handleScroll);
    },

    removeEventScroll() {
      this.$refs.infiniteScroll.removeEventListener(
        "scroll",
        this.handleScroll
      );
    },

    backTop() {
      this.$refs.infiniteScroll.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    formatDay(day) {
      if (day) return day.split("-").join(" ");
    },

    handleTypeColor(point) {
      if (point.type === "entrada") {
        return "entryRegister";
      } else {
        return "exitRegister";
      }
    },

    handleTextTypePoint(point) {
      if (point.type === "entrada") {
        return "textEntryRegister";
      } else {
        return "textExitRegister ";
      }
    },

    handleDateRegister() {
      this.idInterval = setInterval(() => {
        this.dateRegister = DateTime.local().toFormat("HH:mm:ss");
      }, 1000);
    },
  },
  computed: {},
  mounted() {
    this.findUserName();
    this.handleDateRegister();
    this.findByIdUser();

    this.addEventScroll();
  },
  beforeDestroy() {
    if (this.idInterval) clearInterval(this.idInterval);

    this.removeEventScroll();
  },
};
</script>

<style scoped>
.containerGeneral {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 1rem 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  background-color: rgb(236, 236, 236);
}

.title {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin: 0;
  color: #7b7b7b;
}

.userName {
  color: rgb(62, 102, 174);
}

.dateRegister,
.userName {
  margin: 0;
  letter-spacing: 0.2px;
  font-weight: 600;
  font-size: 12px;
}

.containerRegisterList {
  padding: 0.5rem 1rem;
}

.contentRegisterList {
  width: 100%;
  height: 73px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.155);
  background-color: white;
  border-radius: 5px;
}

.titleRegister {
  margin: 0;
  font-size: 16px;
  color: #363636;
  font-weight: 600;
  padding: 0 1rem;
  border-radius: 50px;
}

.lastRecord {
  margin: 0;
  font-size: 11px;
  color: #7e7e7e;
  padding: 0 1rem;
  border-radius: 50px;
}

.titleMonthRegister {
  text-align: end;
  margin: 0;
  font-size: 12px;
  color: rgb(62, 102, 174);
  width: 100px;
  font-weight: 600;
}

.contentExit {
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: brown;
  padding: 0.5rem;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 36%) -1px 0px 3px 0px;
  cursor: pointer;
}

.contentRegisters {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 0.8rem;
}

.containerRegisterDayAndHours {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.contentCardsRegister {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  max-height: calc(100vh - 160px);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  padding-top: 1rem;
  overflow: auto;
}

.contentCardsRegister::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.contentCardsRegister::-webkit-scrollbar-track {
  background: transparent;
}

.contentCardsRegister::-webkit-scrollbar-thumb {
  background-color: rgb(56, 214, 98);
  border-radius: 5px;
}

.contentRegisterDayAndHours {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.155);
}

.exitRegister {
  border-left: solid 5px red;
  color: red;
}

.textExitRegister {
  color: rgb(211, 10, 10) !important;
}

.entryRegister {
  border-left: solid 5px rgb(62, 102, 174);
  color: rgb(62, 102, 174) red;
}

.textEntryRegister {
  color: rgb(62, 102, 174) !important;
}

.contentTitlePointRecords {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 2rem 0 0 0;
  padding: 0 1.3rem 0.5rem 1rem;
}

.userName {
  margin: 0;
  font-size: 11px;
  font-weight: 600;
  color: rgb(62, 102, 174);
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.titlePointRecords {
  margin: 0;
  font-weight: bold;
  font-size: 15px;
  color: #363636;
}

.btnRegister {
  width: 87px;
  height: 25.5px;
  margin-bottom: 0.15rem;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: rgb(56, 214, 98);
  color: white;
  border-radius: 50px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  transition: ease-in all 0.1s;
}

.btnRegister:hover {
  background-color: rgb(72, 184, 101);
  transition: ease-in all 0.1s;
}

.backTop {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 10px;
  left: 50%;
  border-radius: 50%;
  /* background-color: rgb(72, 184, 101);
  color: white; */
  background-color: rgba(72, 184, 102, 0.496);
  color: rgba(255, 255, 255, 0.515);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.608);
  border: solid 1px rgba(255, 255, 255, 0.46);
  cursor: pointer;
  transition: ease-in all 0.1s;
}

.backTop:hover {
  background-color: rgb(72, 184, 101);
  color: white;
  transition: ease-in all 0.1s;
}

.date {
  margin: 0 0 0 0;
  font-weight: 600;
  color: rgb(62, 102, 174);
  font-size: 12px;
}

.hours {
  display: flex;
  align-items: center;
  box-shadow: 0 0 1px 1px rgb(72, 184, 101);
  padding: 0 0.3rem;
  border-radius: 50px;
  color: rgb(72, 184, 101) !important;
  font-weight: normal !important;
  font-size: 11px;
  margin: 0;
  letter-spacing: 0.3px;
}

.day {
  font-size: 13px;
  font-weight: normal;
  margin: 0;
  display: flex;
  align-items: flex-end;
  font-weight: 600;
  color: #7e7e7e;
  border-bottom: solid 1px white;
  text-transform: capitalize;
}

.contentNotPointRecords {
  padding: 1rem;
  height: 100%;
  max-height: calc(100vh - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #7e7e7e;
}

.spin {
  width: 20px;
  height: 20px;
  color: rgb(56, 214, 98);
}

.isLoading {
  padding: 0;
  background-color: transparent !important;
  box-shadow: none;
  transition: ease-in all 0.1s;
}

@media screen and (min-width: 700px) {
  .contentRegisters {
    justify-content: center;
    padding: 1rem 4rem 1rem calc(60px + 4rem);
    margin-top: 50px;
  }

  .contentCardsRegister {
    max-height: calc(100vh - 240px);
  }

  .titleMonthRegister,
  .userName {
    font-size: 14px;
  }
}

@media screen and (max-width: 700px) {
  .contentCardsRegister {
    max-height: calc(100vh - 185px);
  }
}

@media screen and (max-width: 360px) {
  .titleRegister {
    font-size: 14px;
  }
}
</style>