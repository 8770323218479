<template>
  <b-modal
    size="xl"
    id="modalServiceTax"
    hide-footer
    hide-header
    @hidden="clear"
  >
    <div>
      <button class="closeModal" @click="$bvModal.hide('modalServiceTax')">
        <b-icon-x scale="1.3" />
      </button>

      <div class="title">
        <span>Cadastro de informações fiscais</span>
      </div>

      <div class="containerTabs">
        <Tabs
          :tabs="tabs"
          :tabSelected="tabSelected"
          :actionToAlterTab="actionToAlterTab"
        >
          <template v-slot:general>
            <div class="contentDescription">
              <div class="description">
                <p>Descrição</p>
                <p style="color: red">*</p>
              </div>

              <textarea
                :class="`inputTextArea ${isInvalidInput}`"
                rows="3"
                max-rows="6"
                v-model="payload.descricao"
                ref="descricao"
                @blur="clearInvalidInput"
              />

              <p class="descriptionObs">
                Descreva a classificação desse imposto para melhor
                gerenciamento.
              </p>
            </div>
          </template>

          <template v-slot:service>
            <div class="rowContainer">
              <div class="rowContent">
                <div class="row-1">
                  <Input
                    label="Cód. CNAE"
                    type="text"
                    labelFixed
                    ref="codigo_cnae"
                    :dataInput.sync="payload.refObject.codigo_cnae"
                    :isInvalidInput="isInvalidInput"
                    @clearInvalidInput="clearInvalidInput"
                  />
                </div>

                <div class="row-1">
                  <Input
                    label="Cód. do Serviço *"
                    type="text"
                    labelFixed
                    ref="codigo_servico"
                    :dataInput.sync="payload.refObject.codigo_servico"
                    :isInvalidInput="isInvalidInput"
                    @clearInvalidInput="clearInvalidInput"
                  />
                </div>

                <div class="row-1">
                  <Input
                    label="Cód. Trib no município"
                    type="text"
                    labelFixed
                    ref="codigo_tributacao_municipio"
                    :dataInput.sync="
                      payload.refObject.codigo_tributacao_municipio
                    "
                    :isInvalidInput="isInvalidInput"
                    @clearInvalidInput="clearInvalidInput"
                  />
                </div>
              </div>

              <div class="rowContent customSm">
                <div class="row-2">
                  <InputSelect
                    label="Natureza da Operação *"
                    labelFixed
                    valueField="value"
                    textField="text"
                    :enableIconCreate="false"
                    :items="operationOptions"
                    :dataSelect.sync="payload.refObject.natureza_operacao"
                    ref="natureza_operacao"
                    :isInvalidInput="isInvalidInput"
                    @clearInvalidInput="clearInvalidInput"
                  />
                </div>

                <div class="row-2">
                  <InputSelect
                    label="Exigibilidade ISS *"
                    labelFixed
                    valueField="value"
                    textField="text"
                    :enableIconCreate="false"
                    :items="issOptions"
                    :dataSelect.sync="payload.refObject.exigibilidade_iss"
                    ref="exigibilidade_iss"
                    :isInvalidInput="isInvalidInput"
                    @clearInvalidInput="clearInvalidInput"
                  />
                </div>

                <div class="row-2">
                  <Input
                    label="Deduções %"
                    type="text"
                    labelFixed
                    :dataInput.sync="payload.refObject.deducao"
                    ref="deducao"
                    :isInvalidInput="isInvalidInput"
                    @clearInvalidInput="clearInvalidInput"
                  />
                </div>
                <div class="row-2">
                  <InputSelect
                    label="ISS Retido *"
                    labelFixed
                    valueField="value"
                    textField="text"
                    :enableIconCreate="false"
                    :items="issRetidoOptions"
                    :dataSelect.sync="payload.refObject.iss_retido"
                    ref="iss_retido"
                    :isInvalidInput="isInvalidInput"
                    @clearInvalidInput="clearInvalidInput"
                    @selectedIten="handleSelectedItem"
                  />
                </div>
              </div>
              <div
                class="rowContent customSm"
                v-if="payload.refObject.iss_retido === '1'"
              >
                <div class="row-3">
                  <InputSelect
                    label="Responsavel Retenção iss *"
                    labelFixed
                    valueField="value"
                    textField="text"
                    :enableIconCreate="false"
                    :items="respRetencaoIssOptions"
                    :dataSelect.sync="payload.refObject.responsavel_retencao"
                    ref="responsavel_retencao"
                    :isInvalidInput="isInvalidInput"
                    @clearInvalidInput="clearInvalidInput"
                  />
                </div>
              </div>
            </div>
          </template>

          <template v-slot:tax>
            <div class="rowContainer">
              <div class="rowContent">
                <InputPercentage
                  label="ISS"
                  type="text"
                  labelFixed
                  :dataInput.sync="payload.refObject.iss"
                  ref="iss"
                  :isInvalidInput="isInvalidInput"
                  @clearInvalidInput="clearInvalidInput"
                />

                <InputPercentage
                  label="PIS"
                  type="text"
                  labelFixed
                  :dataInput.sync="payload.refObject.pis"
                  ref="pis"
                  :isInvalidInput="isInvalidInput"
                  @clearInvalidInput="clearInvalidInput"
                />

                <InputPercentage
                  label="COFINS"
                  type="text"
                  labelFixed
                  :dataInput.sync="payload.refObject.cofins"
                  ref="cofins"
                  :isInvalidInput="isInvalidInput"
                  @clearInvalidInput="clearInvalidInput"
                />
              </div>

              <div class="rowContent">
                <InputPercentage
                  label="INSS"
                  type="text"
                  labelFixed
                  :dataInput.sync="payload.refObject.inss"
                  ref="inss"
                  :isInvalidInput="isInvalidInput"
                  @clearInvalidInput="clearInvalidInput"
                />

                <InputPercentage
                  label="IR"
                  type="text"
                  labelFixed
                  :dataInput.sync="payload.refObject.ir"
                  ref="ir"
                  :isInvalidInput="isInvalidInput"
                  @clearInvalidInput="clearInvalidInput"
                />

                <InputPercentage
                  label="CSLL"
                  type="text"
                  labelFixed
                  :dataInput.sync="payload.refObject.csll"
                  ref="csll"
                  :isInvalidInput="isInvalidInput"
                  @clearInvalidInput="clearInvalidInput"
                />
              </div>
            </div>
          </template>

          <template v-slot:search>
            <div class="rowContainer">
              <div class="rowContent contentTable">
                <table style="width: 100%">
                  <thead
                    style="
                      color: whitesmoke;
                      background: #3a4750;
                      padding: 10px;
                      height: 35px;
                      text-align: center;
                      border-spacing: 0;
                      font-size: 14px;
                    "
                  >
                    <tr style="width: 100%">
                      <th>REF</th>
                      <th>Descrição</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody style="font-size: 13px; text-align: center">
                    <tr v-for="ref in refsNfse" :key="ref.id">
                      <td>{{ ref.ref }}</td>
                      <td>{{ ref.descricao }}</td>
                      <td class="delBill">
                        <button
                          class="btnRef"
                          style="color: #56aafe"
                          @click="edit(ref.id)"
                        >
                          <b-icon-pencil-square
                            scale="1.3"
                          ></b-icon-pencil-square>
                        </button>
                        <button
                          @click="remove(ref)"
                          style="color: red"
                          class="btnRef"
                        >
                          <b-icon-trash-fill scale="1.3"></b-icon-trash-fill>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </Tabs>

        <div class="generalLoading" v-if="generalSpin">
          <b-spinner class="generalSpin" />
        </div>
      </div>

      <div class="contentButtons">
        <b-button class="defaultBtn btnNew" @click="clear"
          >Novo <b-icon-arrow-clockwise class="ml-1"
        /></b-button>

        <b-button class="defaultBtn" @click="handleSaveOrUpdate">
          {{ payload.id ? "Atualizar" : "Salvar" }}
          <b-icon-check2-circle class="ml-1"
        /></b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Tabs from "../UI/Tabs/Tab.vue";
import Input from "../UI/CustomInputs/Input.vue";
import InputPercentage from "../UI/CustomInputs/InputPercentage.vue";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";
import ServiceTaxNfse from "../../services/serviceTaxNfse";
import { ValidadeTaxService } from "../../validators/taxNfse";
import { INITIAL_STATE_DATA_REF } from "../../initialStates/serviceTax";

export default {
  props: {
    refsNfse: {
      type: Array,
    },
  },
  components: {
    Tabs,
    Input,
    InputPercentage,
    InputSelect,
  },
  data() {
    return {
      payload: {
        id: "",
        descricao: "",
        ref: "",
        refObject: {
          codigo_servico: "",
          natureza_operacao: "",
          exigibilidade_iss: "",
          iss_retido: "",
          codigo_cnae: "",
          codigo_tributacao_municipio: "",
          responsavel_retencao: "",
          tipo: "nfse",
          iss: 0,
          pis: 0,
          cofins: 0,
          inss: 0,
          ir: 0,
          csll: 0,
          deducao: "",
        },
      },
      tabs: [
        { name: "general", text: "Geral" },
        { name: "service", text: "Serviço" },
        { name: "tax", text: "Impostos" },
        { name: "search", text: "Pesquisar" },
      ],
      operationOptions: [
        { value: "", text: "--- Selecione uma opção ---" },
        { value: "1", text: "Tributação no município" },
        { value: "2", text: "Tributação fora do município" },
        { value: "3", text: "Isenção" },
        { value: "4", text: "Imune" },
        { value: "5", text: "Exigibilidade suspensa por decisão judicial" },
        {
          value: "6",
          text: "Exigibilidade suspensa por procedimento administrativo",
        },
      ],
      issOptions: [
        { value: "", text: "--- Selecione uma opção ---" },
        { value: "1", text: "Exigível" },
        { value: "2", text: "Não incidência" },
        { value: "3", text: "Isenção" },
        { value: "4", text: "Exportação" },
        { value: "5", text: "Imunidade" },
        { value: "6", text: "Exigibilidade suspensa por decisão judicial" },
        {
          value: "7",
          text: "Exigibilidade suspensa por processo administrativo",
        },
      ],
      issRetidoOptions: [
        { value: "", text: "--- Selecione uma opção ---" },
        { value: "1", text: "Sim" },
        { value: "2", text: "Não" },
      ],
      respRetencaoIssOptions: [
        { value: "", text: "--- Selecione uma opção ---" },
        { value: "1", text: "Tomador" },
        { value: "2", text: "Intermediário" },
      ],
      tabSelected: 0,
      actionToAlterTab: false,
      isInvalidInput: "",
      generalSpin: false,
    };
  },

  methods: {
    async save() {
      try {
        this.generalSpin = true;

        const isNotValid = ValidadeTaxService(this.payload);

        if (isNotValid && Object.keys(isNotValid).length) {
          this.handleSelectTab(isNotValid.keyError);

          await this.$nextTick();
          this.handleFocus(isNotValid.keyError);

          return this.$toast.open({
            message: isNotValid.message,
            type: "info",
          });
        }

        const id = await ServiceTaxNfse.save(this.payload);
        this.payload.id = id;

        this.clear();
        this.$toast.open({
          message: "Novo imposto registrado",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message: error?.response?.data?.message,
          type: "info",
        });
      } finally {
        this.generalSpin = false;
      }
    },

    async update() {
      try {
        this.generalSpin = true;

        const isNotValid = ValidadeTaxService(this.payload);
        if (isNotValid && Object.keys(isNotValid).length) {
          this.handleSelectTab(isNotValid.keyError);

          await this.$nextTick();
          this.handleFocus(isNotValid.keyError);

          return this.$toast.open({
            message: isNotValid.message,
            type: "info",
          });
        }

        await ServiceTaxNfse.update(this.payload);

        this.$toast.open({
          message: "Imposto atualizado",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um erro ao atualizar",
          type: "info",
        });
      } finally {
        this.generalSpin = false;
      }
    },

    async edit(id) {
      try {
        this.payload = await ServiceTaxNfse.findById(id);
        this.assingValues();
        this.tabSelected = 0;
        this.actionToAlterTab = !this.actionToAlterTab;
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao buscar esse REF",
          type: "info",
        });
      }
    },

    async remove(dataRef) {
      try {
        this.generalSpin = true;
        await ServiceTaxNfse.delete(dataRef);
        this.$emit("baseActionsRef");

        this.$toast.open({
          message: "REF removido!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao deletar esse REF",
          type: "info",
        });
      } finally {
        this.generalSpin = false;
      }
    },

    async handleSaveOrUpdate() {
      this.payload.id ? await this.update() : await this.save();
      this.$emit("baseActionsRef");
    },

    assingValues() {
      Object.keys(this.$refs).forEach((key) => {
        if (this.$refs[key]) {
          if (key === "descricao") {
            this.$refs[key].valueInput = this.payload[key];
          } else {
            if ("valueItems" in this.$refs[key])
              this.$refs[key].valueItems = this.payload.refObject[key];

            if ("valueInput" in this.$refs[key])
              this.$refs[key].valueInput = this.payload.refObject[key];
          }
        }
      });
    },

    handleFocus(refName) {
      if (refName === "descricao") {
        this.$refs[refName].focus();
      } else if ("textField" in this.$refs[refName]) {
        this.$refs[refName].$children[0].focus();
      } else {
        this.$refs[refName].$el.children[0].focus();
      }

      this.isInvalidInput = "isInvalidInput";
    },

    handleSelectTab(keyError) {
      if (keyError === "descricao") {
        this.tabSelected = 0;
      } else if (
        keyError === "iss" ||
        keyError === "pis" ||
        keyError === "cofins" ||
        keyError === "inss" ||
        keyError === "ir" ||
        keyError === "csll"
      ) {
        this.tabSelected = 2;
      } else {
        this.tabSelected = 1;
      }

      this.actionToAlterTab = !this.actionToAlterTab;
    },

    clearInvalidInput() {
      this.isInvalidInput = "";
    },

    clear() {
      this.payload = JSON.parse(JSON.stringify(INITIAL_STATE_DATA_REF));
      this.clearRefs();
    },

    clearRefs() {
      Object.keys(this.$refs).forEach((key) => {
        if (this.$refs[key]) {
          if (this.$refs[key].valueItems) this.$refs[key].valueItems = "";
          if (this.$refs[key].valueInput) this.$refs[key].valueInput = "";
        }
      });
    },
    handleSelectedItem(selectedValue) {
      if (selectedValue === "2") {
        delete this.payload.refObject.responsavel_retencao;
      }
    },
  },
  watch: {},
  computed: {},
};
</script>

<style scoped>
.closeModal {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
  color: rgb(255, 153, 153);
  cursor: pointer;
  border: 1px solid rgb(238, 238, 238);
  padding: 2px 3px;
  border-radius: 4px;
}

.closeModal:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}

.title {
  font-size: 16px;
  color: rgb(95, 95, 95);
  margin-bottom: 20px;
  font-weight: 600;
}

.btnBack {
  border-radius: 5px;
  background-color: #00395d;
  border: none;
  margin-top: 30px;
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  width: 150px;
  font-size: 18px;
  margin-top: 0;
}

.contentDescription {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 0.5rem;
}

.description {
  display: flex;
  gap: 5px;
}

.description p {
  margin: 0px;
  font-size: 13px;
  color: #666360;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.descriptionObs {
  color: #666360a3;
  font-size: 12px;
}

.inputTextArea {
  width: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: white;
  color: #5c5855;
  border-radius: 5px;
  height: 80px;
  padding: 5px;
  border: solid 1px #d9d9d9;
  resize: none;
  font-size: 12px;
}

.containerTabs {
  position: relative;
  border: solid 1px rgba(128, 128, 128, 0.39);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.316) !important;
  border-radius: 5px !important;
  height: 240px;
}

.rowContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.rowContent {
  display: flex;
  gap: 1rem;
}

.row-1 {
  width: 100%;
}

.row-2 {
  width: 100%;
}

.contentButtons {
  display: flex;
  gap: 1rem;
}

.defaultBtn {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;
  font-weight: normal;
}

.defaultBtn:hover {
  background-color: #002841;
}

.defaultBtn:focus {
  background-color: #00395d;
}

.btnNew {
  background-color: rgb(182, 182, 182);
}

.btnNew:hover {
  background-color: rgb(157, 157, 157);
}

.btnNew:focus {
  background-color: rgb(157, 157, 157);
}

.isInvalidInput:focus {
  border: solid 1px rgba(204, 21, 21, 0.583) !important;
  transition: ease-in all 0.1s;
  box-shadow: 0 0 2px 1px rgba(204, 21, 21, 0.583) !important;
}

.delBill {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.btnRef {
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.contentTable {
  max-height: 160px;
  overflow-y: auto;
}

.generalSpin {
  width: 60px;
  height: 60px;
}

.generalLoading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(128, 128, 128, 0.294);
  z-index: 9999;
}

@media screen and (max-width: 991px) {
  .customSm {
    flex-wrap: wrap;
  }

  .rowContainer {
    gap: 1rem;
  }

  .containerTabs {
    height: auto;
  }
}

@media screen and (max-width: 500px) {
  .rowContent {
    flex-wrap: wrap;
  }
}
</style>