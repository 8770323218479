<template>
  <div
    class="d-flex justify-content-center container-geral"
    :style="`overflow: ${overflow}`"
  >
    <div class="container-components">
      <Accounting />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Accounting from "./Accounting.vue";
export default {
  components: {
    Accounting,
  },
  computed: {
    ...mapState({
      overflow: (state) => state.overflow,
    }),
  },
};
</script>

<style scoped>
.container-geral {
  width: 100% !important;
  height: 100vh;
}

.container-components {
  width: 85vw;
  margin-top: 20px;
  margin-left: 5vw;
}

.hiddenOverflow {
  overflow: hidden;
}
</style>