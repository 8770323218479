import api from "../services/axios";

class ServiceTax {
  async save(taxes){
    const { data } = await api.post('/impostos', taxes)
    return data
  }

  async update(taxes) {
    await api.put(`/impostos/${taxes.id}`, taxes)
 }

  async delete(tax) {
     await api.delete(`/impostos/${tax.id}?classImposto=${tax.ref}`)
  }

  async findAllRefs() {
    const { data } = await api.get('/impostos')
    return data
  }

  async findById(id) {
    const { data } = await api.get(`/impostos/${id}`)
    return data
  }
}

export default new ServiceTax()