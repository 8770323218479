import axios from 'axios'

class ServiceGestao {
  async updatePassword(newPassword, token) {
    const { data } = await axios.post('/gestao/update/password/user', { newPassword }, {
      baseURL: process.env.VUE_APP_URL_BACKEND,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return data
  }

  async findTerms(token) {
    const { data } = await axios.get('/gestao/find/terms', {
      baseURL: process.env.VUE_APP_URL_BACKEND,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return data
  }

  async updateTerms(token , contract) {
    const { data } = await axios.post('/gestao/restore/terms/management', { contract }, {
      baseURL: process.env.VUE_APP_URL_BACKEND,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return data
  }
}

export default new ServiceGestao
