import moment from "moment"

const initialStateNfeNfce = {
  id: "",
  ID: "", // Controle das solicitações de emissão por pedido ou ID de processamento.
  operacao: "1",
  natureza_operacao: "Venda de mercadoria",
  modelo: 1, //2 para NFC-e
  ambiente: "2", //2 para Homologação,1 para Produção
  idCliente: "",
  finalidade: 1,
  data_nfe: moment().format('YYYY-MM-DDTHH:mm'),
  id_webmania: "",
  response: "",
  response_cancelamento: "",
  status: "",
  nfe_referenciada: "",
  pagamento: 0,
  forma_pagamento: "01",
  presenca: 1,
  modalidade_frete: 9,
  frete: "0,00",
  desconto: "",
  total: "",
  intermediador: "",
  cnpj_intermediador: "",
  id_intermediador: "",
}



export { initialStateNfeNfce }