<template>
  <Login />
</template>

<script>
import Login from "../../components/Login/Index-Login.vue";
export default {
  components: {
    Login,
  },
};
</script>