<template>
  <div class="containe">
    <div class="forms-containe">
      <div class="signin-signup">
        <form action="#" class="sign-in-form" @submit.prevent>
          <img src="../../assets/logoBG.png" class="image logoIntro" alt="" />
          <div
            style="
              padding-top: 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 5px;
              width: 100%;
            "
          >
            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.nome"
              />
              <label class="user-label"> Nome completo * </label>
            </div>
            <div class="dividerInput">
              <div class="selectPlus" style="width: 30%">
                <b-form-select
                  class="borderInput shadow-none"
                  value-field="value"
                  text-field="text"
                  :options="tipo"
                  v-model="company.type"
                >
                </b-form-select>
              </div>

              <div v-if="company.type === 1" class="input-group">
                <input
                  required
                  class="input borderInput"
                  v-model="company.cpfcnpj"
                  v-mask="maskcpfcnpj"
                  maxlength="14"
                />
                <label class="user-label"> CPF * </label>
              </div>

              <div v-if="company.type === 2" class="input-group">
                <input
                  required
                  class="input borderInput"
                  v-model="company.cpfcnpj"
                  v-mask="maskcpfcnpj"
                  maxlength="18"
                />
                <label class="user-label"> CNPJ * </label>
              </div>
            </div>
            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.companyName"
              />
              <label class="user-label">Razão Social Nome da Empresa * </label>
            </div>
            <div class="dividerInput">
              <div class="selectPlus" style="width: 30%">
                <label class="fixedSelect2"> UF </label>
                <b-form-select
                  class="borderInput shadow-none"
                  value-field="value"
                  text-field="text"
                  :options="ufs"
                  v-model="company.uf"
                >
                </b-form-select>
              </div>

              <div class="input-group">
                <input
                  required
                  class="input borderInput"
                  v-model="company.cidade"
                />
                <label class="user-label"> Cidade * </label>
              </div>
            </div>
            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.telefone"
                v-mask="maskCelular"
              />
              <label class="user-label">Whatsapp * </label>
            </div>

            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="company.email"
              />
              <label class="user-label">Email * </label>
            </div>

            <div class="input-group">
              <input
                required
                class="input borderInput"
                v-model="logger.password"
              />
              <label class="user-label">Crie uma Senha * </label>
            </div>

            <div class="content-login-form-btn" @click="newCompany">
              <b-button class="btn solid">Acessar Versão Teste!</b-button>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                font-size: 11px;
                text-align: center;
                color: blue;
              "
            >
              <span>Tem alguma dúvida?</span>
              <span> <strong>Entre em contato:</strong></span>
              <span> <strong> (31) 3891 9414</strong></span>
            </div>
          </div>
          <div class="social-media">
            <img
              src="../../assets/imgCircle.png"
              class="image logoFooter"
              alt=""
            />
          </div>
        </form>
      </div>
    </div>

    <div class="leftBkg">
      <img style="width: 100%; height: 100%" :src="loginLeft" />
    </div>
    <div class="rightBkg">
      <img style="width: 100%; height: 100%" :src="loginRight" />
    </div>

    <b-modal id="modal-loader" hide-footer hide-header centered title="loader">
      <div v-if="hourglass" class="contentLoader">
        <div class="alertClock">
          <div class="hourglassBackground">
            <div class="hourglassContainer">
              <div class="hourglassCurves"></div>
              <div class="hourglassCapTop"></div>
              <div class="hourglassGlassTop"></div>
              <div class="hourglassSand"></div>
              <div class="hourglassSandStream"></div>
              <div class="hourglassCapBottom"></div>
              <div class="hourglassGlass"></div>
            </div>
          </div>
        </div>
        <span>Aguarde , estamos realizando seu cadastro!</span>
      </div>
      <div v-if="isCheck" class="contentLoader">
        <img class="loaderCheck" :src="Check" />
        <span>Cadastro realizado com sucesso</span>
        <span>Aguarde , estamos redirecionando para o sistema!</span>
      </div>
      <div v-if="isWarning" class="contentLoader">
        <img class="loaderCheck" :src="WarningLoader" />
        <span class="loaderTextWarning"
          >Nao foi possivel realizar o cadastro. Verifique os dados e tente
          novamente!</span
        >
        <div class="loaderList">
          <span>Possíveis Erros:</span>
          <ul>
            <li>CNPJ incorreto ou já cadastrado.</li>
            <li>Email incorreto ou já cadastrado.</li>
            <li>Celular incorreto.</li>
          </ul>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import api from "../../services/axios";
import moment from "moment";
import { mapState } from "vuex";
import { HandleErrors } from "../../utils/handleErrors";
import { ufs } from "../../common";
import LoginService from "../../services/serviceLogin";

import loginLeft from "../../assets/loginLeft.png";
import loginRight from "../../assets/loginRight.png";
import Check from "../../assets/register-check.svg";
import WarningLoader from "../../assets/register-warning.svg";

export default {
  data() {
    return {
      loginLeft: loginLeft,
      loginRight: loginRight,
      Check: Check,
      WarningLoader: WarningLoader,
      typeWaveAnimation: "",
      visibilitySaibaMais: "hideLogin",
      visibilityLogin: "showLogin",
      datainiciouso: moment().format("YYYY-MM-DD"),
      isCheck: false,
      isWarning: false,
      hourglass: true,
      infoNewClient: {},
      login: {
        email: "",
        password: "",
      },
      companyTotal: { companyCad: {}, companyLogger: {} },
      company: {
        nome: "",
        telefone: "",
        email: "",
        cpfcnpj: "",
        uf: "",
        cidade: "",
        type: 1,
        companyName: "",
        datainiciouso: "",
        datafimuso: "",
      },
      logger: {
        nome: "",
        email: "",
        password: "",
      },
      sectionsEnable: {
        login: true,
        tester: false,
        forgotMyPassword: false,
      },
      tipo: [
        { value: 1, text: "CPF" },
        { value: 2, text: "CNPJ" },
      ],
      emailToResetPassword: "",
      spinResetPassword: false,
      invalidMessage: "",
      invalidinputEmailToResetPassword: "",
      open: true,
      ufs: ufs,
    };
  },
  methods: {
    async sendLogin() {
      try {
        if (this.login.email === "" || this.login.password === "") {
          return this.$toast.open({
            message: "O campo login e senha devem ser preenchidos",
            type: "info",
          });
        }

        const { token, permissions, userLevel } = await LoginService.logar(
          this.login
        );

        localStorage.setItem("token", token);
        localStorage.setItem("permissions", JSON.stringify(permissions));
        localStorage.setItem("userLevel", userLevel);

        const tokenList = JSON.parse(localStorage.getItem("tokenList"));
        if (tokenList && tokenList.length) {
          tokenList.push({
            token,
            userLevel,
            permissions,
            nameCompany: this.dataCompany.nome,
          });

          localStorage.setItem("tokenList", JSON.stringify(tokenList));
        }
      } catch (error) {
        return this.$toast.open({
          message:
            error.response.data.message ||
            "Verifique se o login e senha estão corretos!",
          type: "info",
        });
      }
    },

    async sendMail() {
      await api.post("/mail/info/client", this.infoNewClient);
    },

    handleInputEmailToResetPassword() {
      this.$refs.emailToResetPassword.focus();
      this.invalidinputEmailToResetPassword =
        "invalidinputEmailToResetPassword";
      this.invalidMessage = "* Campo Email deve ser um email válido!";
    },

    clearInvalidInput() {
      this.invalidinputEmailToResetPassword = "";
      this.invalidMessage = "";
    },

    clearAllInputs() {
      this.company = {
        nome: "",
        telefone: "",
        email: "",
        cpfcnpj: "",
        type: 1,
        companyName: "",
        datainiciouso: "",
        datafimuso: "",
        uf: "",
        cidade: "",
      };

      this.logger = {
        nome: "",
        email: "",
        password: "",
      };

      this.emailToResetPassword = "";
    },

    assingValueCadCompany() {
      let currentDate = moment();
      let dateAfter30Days = currentDate.add(30, "days");
      let dateFormatted = dateAfter30Days.format("YYYY-MM-DD");
      this.logger.nome = this.company.nome;
      this.logger.email = this.company.email;
      this.company.datainiciouso = this.datainiciouso;
      this.company.datafimuso = dateFormatted;
      this.companyTotal.companyCad = this.company;
      this.companyTotal.companyLogger = this.logger;
      this.infoNewClient = this.companyTotal;
    },

    async redirectAndLogger() {
      this.login.email = this.logger.email;
      this.login.password = this.logger.password;

      this.$bvModal.hide("modal-loader");
      await this.sendLogin();
    },

    async newCompany() {
      try {
        this.$bvModal.show("modal-loader");
        this.assingValueCadCompany();
        this.companyTotal.companyCad.cpfcnpj =
          this.companyTotal.companyCad.cpfcnpj.replace(/[^\d]/g, "");
        await api.post("/gestao/tester", this.companyTotal);
        this.hourglass = false;
        this.isCheck = true;
      } catch (error) {
        this.hourglass = false;
        this.isCheck = false;
        this.isWarning = true;
        const typeError = HandleErrors(error?.response?.data?.message);
        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao salvar o novo usuario",
          type: "info",
        });
      }
    },
  },
  computed: {
    ...mapState(["dataCompany"]),
    maskCelular() {
      return "(##) #####-####";
    },
    maskcpfcnpj() {
      if (this.company.cpfcnpj === null) {
        return "";
      }
      if (this.company.cpfcnpj.length <= 14) {
        return "###.###.###-##";
      }
      if (this.company.cpfcnpj.length > 14) {
        return "##.###.###/####-##";
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap"); */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: monospace;
}

body,
input {
  font-family: monospace;
}

.containe {
  position: relative;
  width: 100vw !important;
  background-color: white;
  min-height: 100vh;
  overflow: hidden;
}

.forms-containe {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signin-signup {
  width: 400px;
  height: 95%;
  padding: 20px 20px 5px 20px;
  border: 1px solid #dadada;
  border-radius: 8px;
  display: flex;
  background: rgba(255, 255, 255, 0.775);
  justify-content: center;
  align-content: space-between;
  flex-wrap: wrap;
  z-index: 100;
}

form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.leftBkg {
  width: 40%;
  height: 55%;
  background: transparent;
  position: absolute;
  bottom: -5px;
  left: -5px;
  z-index: 20;
}

.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 10px;
  bottom: 29px;
  left: 11px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: white;
  font-weight: 600;
}

.rightBkg {
  width: 45%;
  height: 55%;
  background: transparent;
  position: absolute;
  top: -70px;
  right: -60px;
  z-index: 20;
}

.selectPlus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.input-group {
  width: 100%;
  padding: 2px 0;
}

.user-label {
  position: absolute;
  left: 5px;
  font-size: 11px;
  z-index: 30;
  bottom: 19px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  background-color: transparent;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #f4f4f4;
  padding: 0 0.1em;
  font-weight: 600;
}

.borderInput {
  width: 100%;
  font-size: 11px;
  background-color: #f4f4f4;
  height: 35px;
  border: none;
  padding: 0 10px;
  border-radius: 10px;
}

.dividerInput {
  display: flex;
  width: 100%;
  gap: 5px;
}

.invalidinputEmailToResetPassword {
  border: solid 1px red !important;
  box-shadow: 0 0 2px 0 rgba(255, 0, 0, 0.613);
}

.title {
  font-size: 2rem;
  color: #444;
  margin: 10px 0;
}

.input-field {
  max-width: 380px;
  width: 100%;
  border: 1px solid #cecece;
  height: 35px;
  padding: 0 10px 0 0;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 15% 85%;
  position: relative;
  align-items: center;
}

.input-field i {
  text-align: center;
  line-height: 35px;
  color: #a9a9a9;
  transition: 0.5s;
  font-size: 12px;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 12px;
  color: #333;
}

.input-field input::placeholder {
  color: #aaa;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px whitesmoke inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #575757 !important;
  height: 35px;
}

.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.social-media {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.btn {
  width: 250px !important;
  background-color: #0051a9;
  border: none;
  outline: none;
  height: 35px;
  border-radius: 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  margin: 5px 0;
  cursor: pointer;
  transition: 0.5s;
}

.btn:hover {
  background-color: #2f5cb0;
}

.image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.logo {
  width: 500px !important;
  height: 350px !important;
}

.logoIntro {
  width: 190px !important;
  height: 70px !important;
}

.logoFooter {
  width: 70px !important;
  height: 70px !important;
}

.contentLoader {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.loaderCheck {
  width: 130px;
  height: 130px;
}

.loaderTextWarning {
  text-align: center;
}

.loaderList {
  font-size: 11px;
  display: flex;
  width: 100%;
  padding: 0 0 10px 30px;
  flex-direction: column;
}

.hourglassBackground {
  position: relative;
  background-color: #0051a9;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  margin: 30px auto;
}

.hourglassContainer {
  position: absolute;
  top: 30px;
  left: 40px;
  width: 50px;
  height: 70px;
  -webkit-animation: hourglassRotate 2s ease-in 0s infinite;
  animation: hourglassRotate 2s ease-in 0s infinite;
  transform-style: preserve-3d;
  perspective: 1000px;
}

.hourglassContainer div,
.hourglassContainer div:before,
.hourglassContainer div:after {
  transform-style: preserve-3d;
}

@-webkit-keyframes hourglassRotate {
  0% {
    transform: rotateX(0deg);
  }

  50% {
    transform: rotateX(180deg);
  }

  100% {
    transform: rotateX(180deg);
  }
}

@keyframes hourglassRotate {
  0% {
    transform: rotateX(0deg);
  }

  50% {
    transform: rotateX(180deg);
  }

  100% {
    transform: rotateX(180deg);
  }
}

.hourglassCapTop {
  top: 0;
}

.hourglassCapTop:before {
  top: -25px;
}

.hourglassCapTop:after {
  top: -20px;
}

.hourglassCapBottom {
  bottom: 0;
}

.hourglassCapBottom:before {
  bottom: -25px;
}

.hourglassCapBottom:after {
  bottom: -20px;
}

.hourglassGlassTop {
  transform: rotateX(90deg);
  position: absolute;
  top: -16px;
  left: 3px;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  background-color: #999999;
}

.hourglassGlass {
  perspective: 100px;
  position: absolute;
  top: 32px;
  left: 20px;
  width: 10px;
  height: 6px;
  background-color: #999999;
  opacity: 0.5;
}

.hourglassGlass:before,
.hourglassGlass:after {
  content: "";
  display: block;
  position: absolute;
  background-color: #999999;
  left: -17px;
  width: 44px;
  height: 28px;
}

.hourglassGlass:before {
  top: -27px;
  border-radius: 0 0 25px 25px;
}

.hourglassGlass:after {
  bottom: -27px;
  border-radius: 25px 25px 0 0;
}

.hourglassCurves:before,
.hourglassCurves:after {
  content: "";
  display: block;
  position: absolute;
  top: 32px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #333;
  animation: hideCurves 2s ease-in 0s infinite;
}

.hourglassCurves:before {
  left: 15px;
}

.hourglassCurves:after {
  left: 29px;
}

@-webkit-keyframes hideCurves {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hideCurves {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.hourglassSandStream:before {
  content: "";
  display: block;
  position: absolute;
  left: 24px;
  width: 3px;
  background-color: white;
  -webkit-animation: sandStream1 2s ease-in 0s infinite;
  animation: sandStream1 2s ease-in 0s infinite;
}

.hourglassSandStream:after {
  content: "";
  display: block;
  position: absolute;
  top: 36px;
  left: 19px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  animation: sandStream2 2s ease-in 0s infinite;
}

@-webkit-keyframes sandStream1 {
  0% {
    height: 0;
    top: 35px;
  }

  50% {
    height: 0;
    top: 45px;
  }

  60% {
    height: 35px;
    top: 8px;
  }

  85% {
    height: 35px;
    top: 8px;
  }

  100% {
    height: 0;
    top: 8px;
  }
}

@keyframes sandStream1 {
  0% {
    height: 0;
    top: 35px;
  }

  50% {
    height: 0;
    top: 45px;
  }

  60% {
    height: 35px;
    top: 8px;
  }

  85% {
    height: 35px;
    top: 8px;
  }

  100% {
    height: 0;
    top: 8px;
  }
}

@-webkit-keyframes sandStream2 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  91% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes sandStream2 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  91% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.hourglassSand:before,
.hourglassSand:after {
  content: "";
  display: block;
  position: absolute;
  left: 6px;
  background-color: white;
  perspective: 500px;
}

.hourglassSand:before {
  top: 8px;
  width: 39px;
  border-radius: 3px 3px 30px 30px;
  animation: sandFillup 2s ease-in 0s infinite;
}

.hourglassSand:after {
  border-radius: 30px 30px 3px 3px;
  animation: sandDeplete 2s ease-in 0s infinite;
}

@-webkit-keyframes sandFillup {
  0% {
    opacity: 0;
    height: 0;
  }

  60% {
    opacity: 1;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 17px;
  }
}

@keyframes sandFillup {
  0% {
    opacity: 0;
    height: 0;
  }

  60% {
    opacity: 1;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 17px;
  }
}

@-webkit-keyframes sandDeplete {
  0% {
    opacity: 0;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  1% {
    opacity: 1;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  24% {
    opacity: 1;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  25% {
    opacity: 1;
    top: 41px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  50% {
    opacity: 1;
    top: 41px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  90% {
    opacity: 1;
    top: 41px;
    height: 0;
    width: 10px;
    left: 20px;
  }
}

@keyframes sandDeplete {
  0% {
    opacity: 0;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  1% {
    opacity: 1;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  24% {
    opacity: 1;
    top: 45px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  25% {
    opacity: 1;
    top: 41px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  50% {
    opacity: 1;
    top: 41px;
    height: 17px;
    width: 38px;
    left: 6px;
  }

  90% {
    opacity: 1;
    top: 41px;
    height: 0;
    width: 10px;
    left: 20px;
  }
}
@media (max-width: 768px) {
  .content-img {
    display: none;
  }

  .content-login {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .content-form {
    gap: 30px;
  }

  .content-reset-password {
    width: 400px;
  }
}

@media screen and (max-width: 700px) {
  .rightBkg {
    display: none;
  }
  .leftBkg {
    width: 100%;
  }
}

@media screen and (min-width: 1800px) {
  .signin-signup {
    width: 35%;
    height: 90%;
  }
  form {
    width: 100%;
  }
  .logoIntro {
    width: 300px !important;
    height: 130px !important;
  }
  .btn {
    width: 50% !important;
    font-size: 20px;
    height: 50px;
  }
  .input-field {
    max-width: none;
    width: 70%;
    height: 60px;
  }
  .input-field i {
    font-size: 22px;
  }
  input:-webkit-autofill {
    height: 50px;
  }
  .sign-in-form span {
    font-size: 20px !important;
  }
  .logoFooter {
    width: 100px !important;
    height: 100px !important;
  }
}
</style>
