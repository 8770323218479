import Joi from "joi";

export const ValidadeTaxService = (data) => {
  const schema = Joi.object({
    descricao: Joi.string().required().messages({
      "string.empty": "(Descrição) deve ser informado!",
      "any.required": "(Descrição) deve ser informado!"
    }),
    refObject: Joi.object({
      codigo_cnae: Joi.string().required().messages({
        "string.empty": "(Cód. CNAE) deve ser informado!",
        "any.required": "(Cód. CNAE) deve ser informado!"
      }),
      codigo_servico: Joi.string().required().messages({
        "string.empty": "(Cód. do Serviço) deve ser informado!",
        "any.required": "(Cód. do Serviço) deve ser informado!"
      }),
      codigo_tributacao_municipio: Joi.string().required().messages({
        "string.empty": "(Cód. Trib no município) deve ser informado!",
        "any.required": "(Cód. Trib no município) deve ser informado!"
      }),
      natureza_operacao: Joi.string().valid('1', '2', '3', '4', '5', '6').required().messages({
        "string.empty": "(Natureza da Operação) deve ser informada!",
        "any.required": "(Natureza da Operação) deve ser informada!",
        "any.only": "(Natureza da Operação) deve ser informada!"
      }),
      exigibilidade_iss: Joi.string().valid('1', '2', '3', '4', '5', '6', '7').required().messages({
        "string.empty": "(Exigibilidade ISS) deve ser informado!",
        "any.required": "(Exigibilidade ISS) deve ser informado!",
        "any.only": "(Exigibilidade ISS) deve ser informado!"
      }),
      deducao: Joi.number().min(0).optional().allow(null, '').messages({
        "number.base": "(Deduções) deve ser um numero e não pode ser vazio!",
        "any.required": "(Deduções) deve ser um numero e não pode ser vazio!",
      }),
      iss_retido: Joi.string().valid('1', '2').required().messages({
        "string.empty": "(ISS Retido) deve ser informado!",
        "any.required": "(ISS Retido) deve ser informado!",
        "any.only": "(ISS Retido) deve ser informado!"
      }),
      iss: Joi.number().min(0).required().messages({
        "number.base": "(ISS) deve ser um numero e não pode ser vazio!",
        "any.required": "(ISS) deve ser um numero e não pode ser vazio!",
      }),
      pis: Joi.number().min(0).required().messages({
        "number.base": "(PIS) deve ser um numero e não pode ser vazio!",
        "any.required": "(PIS) deve ser um numero e não pode ser vazio!",
      }),
      cofins: Joi.number().min(0).required().messages({
        "number.base": "(COFINS) deve ser um numero e não pode ser vazio!",
        "any.required": "(COFINS) deve ser um numero e não pode ser vazio!",
      }),
      inss: Joi.number().min(0).required().messages({
        "number.base": "(INSS) deve ser um numero e não pode ser vazio!",
        "any.required": "(INSS) deve ser um numero e não pode ser vazio!",
      }),
      ir: Joi.number().min(0).required().messages({
        "number.base": "(IR) deve ser um numero e não pode ser vazio!",
        "any.required": "(IR) deve ser um numero e não pode ser vazio!",
      }),
      csll: Joi.number().min(0).required().messages({
        "number.base": "(CSLL) deve ser um numero e não pode ser vazio!",
        "any.required": "(CSLL) deve ser um numero e não pode ser vazio!",
      }),
    })
  })

  const { error } = schema.validate(data, {
    allowUnknown: true,
  })

  if (error?.details[0]?.message || error?.details[0]?.path[0]) {
    if(error?.details[0].path.length > 1) {
      return {
        message: error?.details[0]?.message,
        keyError: error?.details[0]?.path[1]
      }
    }
    
    return {
      message: error?.details[0]?.message,
      keyError: error?.details[0]?.path[0]
    }
  }
}