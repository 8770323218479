<template>
  <div class="d-flex home justify-content-center">
    <Stock />
  </div>
</template>

<script>
import Stock from "../../components/Stock/Stock.vue";

export default {
  components: {
    Stock,
  },
};
</script>