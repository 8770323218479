<template>
  <Registers />
</template>

<script>
import Registers from "./Registers.vue";
export default {
  components: {
    Registers,
  },
};
</script>