<template>
  <div>
    <Sidebar />
  </div>
</template>

<script>
import Sidebar from "./Side-Bar.vue";
export default {
  name: "App",
  components: {
    Sidebar,
  },
};
</script>
