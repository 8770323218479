<template>
  <div>
    <Settings />
  </div>
</template>

<script>
import Settings from "../../components/Settings/Index-Settings.vue";
export default {
  components: {
    Settings,
  },
};
</script>