import api from "./axios";

class ServiceSituation {
  async getAllSituation() {
    const { data } = await api.get(`/situations/combobox`);
    return data;
  }

  async findById(id) {
    const { data } = await api.get(`/situations/find/${id}`);
    return data;
  }
}

export default new ServiceSituation()