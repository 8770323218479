import api from "./axios";
class ServiceProductSale {
  async save(product) {
    const { data } = await api.post(`/products-of-sale/`, product)
    return data
  }

  async update(id, product) {
    const { data } = await api.put(`/products-of-sale/${id}`, product)
    return data
  }

  async findByIdSale(idSale) {
    const { data } = await api.get(`/products-of-sale/by/sale/${idSale}`)
    return data
  }
  
  async delete(id) {
    await api.delete(`/products-of-sale/${id}`)
  }

  async deleteComposition(id) {
    await api.delete(`/products-of-sale/composition/${id}`)
  }

  async replaceAll(currentProdId, prevProdId, saleId, priceType) {
    await api.get(`/products-of-sale/replace-all/${currentProdId}/${prevProdId}/${saleId}?priceType=${priceType}`)
  }
}

export default new ServiceProductSale()