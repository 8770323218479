import api from "./axios";

class ServiceProductLogs {
  async save(productLogs) {
    const { data } = await api.post('/produtos-logs', productLogs)
    return data
  }

  async delete(id) {
    await api.delete(`/produtos-logs/${id}`)
  }
}

export default new ServiceProductLogs()