export const debounce = (fn, delay = 500) => {
  var timeoutRef = null

  const debounceFn = (...params) => {
    clearTimeout(timeoutRef);

    timeoutRef = setTimeout(() => {
      fn(...params);
    }, delay);
  };

  return debounceFn;
};
