import api from "./axios";

class ServiceProductsPurchase {
  async save(productsPurchase) {
    return await api.post("/purchase-products", productsPurchase)
  }

  async delete(idProduct, id) {
    await api.delete(`/purchase-products/delete-product-movement/${idProduct}/${id}`)
  }

  async deleteAll(id) {
    const { data } = await api.delete(`/purchase-products/all/${id}`)
    return data
  }

  async getProductsForGrid(id) {
    const { data } = await api.get(`/purchase-products/${id}`)
    return data
  }
}
export default new ServiceProductsPurchase()