import api from "./axios";

class ServiceColors {
  async save(color) {
    const { data } = await api.post("/colors", color)
    return data.id;
  }

  async update(color) {
    await api.put(`/colors/${color.id}`, color);
  }

  async delete(id) {
    await api.delete(`/colors/${id}`);
  }

  async findById(id) {
    const { data } = await api.get(`/colors/${id}`);
    return data;
  }

  async fillComobobox() {
    const { data } = await api.get(`/colors/combobox`);
    return data;
  }
}

export default new ServiceColors()