import api from "./axios";

class ServiceNfseItem {
  async save(idService) {
    const { data } = await api.post("/nfse-item", idService)
    return data
  }

  async update(data) {
    await api.put(`/nfse-item/${data.id}`, data);
  }

  async delete(idService, idNfse = null) {
    await api.delete(`/nfse-item/${idService}/${idNfse}`)
  }

  async getItemForGrid(id) {
    const { data } = await api.get(`/nfse-item/items/${id}`)
    return data;
  }
}
export default new ServiceNfseItem()