import api from "./axios";

class ServiceCategory {
  async save(category) {
    const { data } = await api.post("/category", category)
    return data.id;
  }

  async update(category) {
    await api.put(`/category/${category.id}`, category);
  }

  async delete(id) {
    await api.delete(`/category/${id}`);
  }

  async findById(id) {
    const { data } = await api.get(`/category/${id}`);
    return data;
  }

  async fillComobobox() {
    const { data } = await api.get(`/category/combobox`);
    return data;
  }
}

export default new ServiceCategory()