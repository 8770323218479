export default function printReportGeneralMtq(name, type, startDate, endDate) {
  const token = localStorage.getItem("token");
  const actions = {
    allSales: printSalesPerType,
    salePerComposition: printSalesPerComposition,
    client: printSalesPerClient,
    employee: printSalesPerEmployee,
  };

  if (actions[name]) {
    actions[name](token, name, type, startDate, endDate);
  } else {
    console.log("invalid name");
  }
}

function printSalesPerType(token, name, type, startDate, endDate) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales-square-meter/gerar/relatorio/geral/todas/vendasMtq/por/periodo/group/A4/?auth=${token}&name=${name}&type=${type}&startDate=${startDate}&endDate=${endDate}`
  );
}
function printSalesPerComposition(token, name, type, startDate, endDate) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales-square-meter/gerar/relatorio/geral/todas/vendasMtq/por/periodo/group/A4/?auth=${token}&name=${name}&type=${type}&startDate=${startDate}&endDate=${endDate}`
  );
}
function printSalesPerClient(token, name, type, startDate, endDate) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales-square-meter/gerar/relatorio/geral/todas/vendasMtq/por/periodo/group/A4/?auth=${token}&name=${name}&type=${type}&startDate=${startDate}&endDate=${endDate}`
  );
}
function printSalesPerEmployee(token, name, type, startDate, endDate) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales-square-meter/gerar/relatorio/geral/todas/vendasMtq/por/periodo/group/A4/?auth=${token}&name=${name}&type=${type}&startDate=${startDate}&endDate=${endDate}`
  );
}