import api from "./axios";

class ServiceRegisterBillPayment {
  async findByIdBill(id){
    const { data } = await api.get(`/bill-register/${id}`)
    return data
  }

  async findByIdRegister(id){
    const { data } = await api.get(`/bill-register/register/${id}`)
    return data
  }

  async registerReversal(id, idBill){
    const { data } = await api.get(`/bill-register/reversal/${id}/${idBill}`)
    return data
  }
}

export default new ServiceRegisterBillPayment

