import axios from "axios";
import { DateTime } from "luxon";
import { HandleErrors } from "../utils/handleErrors"
import { TypeErrors } from "../utils/enums/TypeErrors"
import { clearTokens } from "../utils/clearTokens";

const api = axios.create({
  baseURL: process.env.VUE_APP_URL_BACKEND,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    timezone: DateTime.local().zoneName
  }
});

api.interceptors.request.use((value) => {
  value.headers = {
    ...value.headers,
    Authorization: `Bearer ${localStorage.getItem('token')}`
  }

  return value
})

api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  const errorMessage = error?.response?.data?.message
  if (errorMessage) {
    const typeError = HandleErrors(errorMessage)
    if (typeError && typeError.nameError === TypeErrors.EXPIRED_TOKEN) {
      clearTokens()
      window.location.href = process.env.VUE_APP_URL_FRONT
    }
  }

  return Promise.reject(error);
});

export default api;