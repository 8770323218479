<template>
  <div class="containerGeralPedidoVenda">
    <div>
      <!-- <b-toast
        class="stockToast"
        id="stock-toast"
        variant="primary"
        no-close-button
        static
        auto-hide-delay="5000"
      >
        <span> O Estoque está Zerado ou Negativo. </span>
      </b-toast> -->
      <b-row
        class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="align-items: center; justify-content: space-between"
      >
        <div class="titleHeader">
          <p class="title">
            NOVA VENDA Nº {{ numberSale || saleDataFromSaleModal.number }}
          </p>
        </div>

        <div
          style="
            margin-right: 16px;
            display: flex;
            align-items: center;
            gap: 10px;
          "
        >
          <label
            :style="`color: ${alterColorSituation}`"
            class="title m-0 p-0"
            >{{ getNameSituantionById(dataSale.idSituacao) }}</label
          >
        </div>
      </b-row>

      <b-row class="mt-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2">
          <Input
            ref="data"
            label="Data"
            type="date"
            :dataInput.sync="dataSale.data"
            :labelFixed="true"
          />
        </div>

        <div class="col-sm-6 col-md-6 col-lg-5 col-xl-6 infinite" size="sm">
          <InputInfinit
            label="Cliente"
            :isEdit="dataSale.idCliente"
            :dataInput.sync="dataSale.idCliente"
            :onSearch="fillCustomerComboBoxWithPaginate"
            :openCreateModal="() => openModals('modalCreateCustomer')"
            :findAll="createdNewCustomer"
            :clearSelected="newOrder"
            @selectItem="handleDataCustomers"
          />
        </div>

        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4" size="sm">
          <InputSelect
            label="Funcionário"
            ref="refResponsavel"
            :items="dataEmployee"
            valueField="id"
            textField="nomeFuncionario"
            :dataSelect.sync="dataSale.idFuncionario"
            :openCreateModal="() => openModals('modalCreateEmployee')"
          />
        </div>
      </b-row>
    </div>

    <hr />

    <div class="contentItens">
      <div class="responsiveItems">
        <div class="containerItens">
          <b-row
            class="col-sm-12 col-md-12 col-lg-12 col-xl-12 contentTitleItens"
          >
            <div class="titleBillsPayments">
              <p class="title">Informações dos Itens</p>
            </div>
          </b-row>

          <div
            style="
              width: 100%;
              display: flex;
              justify-content: space-between;
              gap: 1rem;
              margin-top: 2.5rem;
            "
          >
            <div style="width: 100%">
              <b-row class="itemsSale" style="align-items: normal">
                <div
                  v-if="lineProduct.tipoProduto === 'normal'"
                  class="containerInputItem"
                  size="sm"
                >
                  <div
                    style="
                      position: absolute;
                      top: -23px;
                      right: 0;
                      z-index: 0;
                      display: flex;
                      gap: 0.5rem;
                    "
                  >
                    <div
                      v-if="lineProduct.idProduto"
                      style="display: flex; align-items: center; gap: 0.5rem"
                    >
                      <div
                        style="
                          position: relative;
                          position: relative;
                          width: 130px;
                          height: 100%;
                        "
                      >
                        <div class="radio-input">
                          <label>
                            <input
                              type="radio"
                              name="value-radio"
                              value="P1"
                              v-model="lineProduct.tipoPreco"
                              @change="updateProductValue('P1')"
                            />
                            <span>VL . 1</span>
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="value-radio"
                              value="P2"
                              v-model="lineProduct.tipoPreco"
                              @change="updateProductValue('P2')"
                            />
                            <span>VL . 2</span>
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="value-radio"
                              value="P3"
                              v-model="lineProduct.tipoPreco"
                              @change="updateProductValue('P3')"
                            />
                            <span>VL . 3</span>
                          </label>
                          <span class="selection"></span>
                        </div>
                      </div>

                      <b-icon-bookmarks-fill
                        v-if="!selectedItemIdToReplace"
                        tag="span"
                        class="btnReplaceAll"
                        @click="handleItemSelected(lineProduct.idProduto)"
                        v-b-tooltip.hover.top="'Substituir todos!'"
                        ref="replaceAll"
                      />

                      <div
                        v-else-if="selectedItemIdToReplace"
                        style="display: flex; gap: 0.5rem"
                      >
                        <b-icon-check-square-fill
                          @click="applyReplaceAll"
                          scale="1.2"
                          style="background-color: green"
                          class="btnReplaceAll"
                          v-b-tooltip.hover.top="'Confirmar Substituição!'"
                        />

                        <b-icon-x-square-fill
                          @click="cancelReplaceAll"
                          scale="1.2"
                          style="background-color: red"
                          class="btnReplaceAll"
                          v-b-tooltip.hover.top="'Cancelar Substituição!'"
                        />
                      </div>
                    </div>

                    <button
                      class="btnsComposition"
                      @click="
                        handleShowCompositionItem(
                          lineProduct.idProduto,
                          lineProduct.composicao
                        )
                      "
                    >
                      Composições
                    </button>
                  </div>

                  <div class="inputItem" size="sm">
                    <div class="infinite" style="padding-right: 18px">
                      <InputInfinit
                        label="Itens"
                        :isEdit="lineProduct.id"
                        :dataInput.sync="lineProduct.idProduto"
                        :onSearch="fillProductsComboBoxWithPaginate"
                        :openCreateModal="
                          () =>
                            handleModalCreateProduct(
                              'modalCreateProduct',
                              lineProduct.idProduto
                            )
                        "
                        :findAll="createdNewProducts"
                        :clearSelected="toggleClearSelect"
                        :searchFields="['nome', 'codRef']"
                        @selectItem="selectProductSetDefaultValue($event, true)"
                      />
                      <span v-if="lineProduct.estoque >= 0" class="alertStock"
                        >Estoque Atual : {{ lineProduct.estoque }}</span
                      >
                      <span
                        v-if="lineProduct.estoque < 0"
                        class="alertStockNegative"
                        >Estoque Atual : {{ lineProduct.estoque }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="inputItemQnt">
                  <InputQuant
                    label="Qtd."
                    :labelFixed="true"
                    ref="refQtd"
                    type="text"
                    :dataInput.sync="lineProduct.quantidade"
                    @calculateTotal="handleCalculateTotal"
                    :decimalPlaces="decimalPlaces"
                    isSelectAll
                  />
                </div>

                <div class="inputItemVlUnit">
                  <InputMoney
                    label="Vl. Unit"
                    labelFixed
                    ref="refVlUnit"
                    type="text"
                    :dataInput.sync="lineProduct.valorUnitario"
                    @calculateTotal="handleCalculateTotal"
                    isSelectAll
                  />
                </div>

                <div class="inputItemVlTot">
                  <InputMoney
                    label="Vl. Total"
                    labelFixed
                    ref="valorTotal"
                    type="text"
                    isDisabled
                    :dataInput.sync="lineProduct.valorTotal"
                    @calculateTotal="handleCalculateTotal"
                  />
                </div>

                <div class="inputItemDesc">
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      position: relative;
                    "
                  >
                    <InputMoney
                      v-if="!discountIsCheked"
                      label="Desc"
                      :labelFixed="true"
                      type="text"
                      :dataInput.sync="lineProduct.desconto"
                      ref="discount"
                      @calculateTotal="handleCalculateTotal"
                      isSelectAll
                    />

                    <InputPercentage
                      v-else
                      label="Desc"
                      :labelFixed="true"
                      type="text"
                      :dataInput.sync="lineProduct.desconto"
                      ref="discount"
                      :isDisabled="discountToAllItems"
                      @calculateTotal="handleCalculateTotal"
                      isSelectAll
                    />
                  </div>
                </div>

                <div class="itemToggleDesc" style="width: 52px; height: 30px">
                  <label class="switch col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <input
                      type="checkbox"
                      ref="typeDiscount"
                      @click="alterDiscountIsCheked"
                      v-model="discountIsCheked"
                      :disabled="validatorDesc"
                    />
                    <div class="slider">
                      <span>R$</span>
                      <span>%</span>
                    </div>
                  </label>

                  <div
                    style="display: none"
                    ref="enableLabelApplyAll"
                    class="tooltipDiscount"
                  >
                    <div class="tooltipItems">
                      <div>
                        <label for="apllyAll">Aplicar em todos</label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="apllyAll"
                          v-model="discountToAllItems"
                          @change="alterAllChecksDiscount"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="inputItemObs">
                  <Input
                    label="Observação"
                    :labelFixed="true"
                    type="text"
                    :dataInput.sync="lineProduct.dadosAdicionais"
                    ref="dadosAdicionais"
                  />
                </div>

                <div
                  class="btnAddMoreItems"
                  :style="productLoading && 'align-items: flex-end'"
                >
                  <button
                    v-if="!productLoading"
                    style="width: 100px; margin-top: 0.5rem"
                    class="btnsSale btnAddMoreItems"
                    @click="handleAddMoreItens"
                  >
                    {{ lineProduct.id ? "Atualizar" : "Adicionar" }}
                  </button>

                  <div
                    v-else
                    style="
                      width: 30px;
                      height: 30px;
                      background-color: #00395d;
                      border-radius: 50%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <b-spinner class="spinProduct" />
                  </div>
                </div>
              </b-row>
            </div>
          </div>

          <section class="containerTableItens">
            <div class="contentTableItens">
              <table
                class="tableProducts"
                style="width: 100%"
                v-if="tableProducts.length"
              >
                <thead>
                  <tr>
                    <th style="width: 200px">Nome</th>
                    <th style="width: 60px">Qnt</th>
                    <th style="width: 120px">Vl. Unit</th>
                    <th style="width: 99px">Desc</th>
                    <th style="width: 120px">Vl. Total</th>
                    <th style="width: 40px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="tableItem"
                    v-for="(product, idx) in tableProducts"
                    :key="product.id"
                    @click="editProductsOfSale(product)"
                  >
                    <td style="width: 200px">
                      <div
                        style="display: flex; gap: 0.5rem; align-items: center"
                      >
                        <span class="contentNumberItem">{{ idx + 1 }}</span>

                        <div style="width: calc(100% - 20px)">
                          <p class="textEllipsis" style="margin: 0">
                            {{ product.nome }}
                          </p>

                          <p
                            class="textEllipsis"
                            style="margin: 0; font-size: 9px; color: gray"
                          >
                            <span
                              v-b-tooltip.hover.top="product.dadosAdicionais"
                            >
                              {{ product.dadosAdicionais }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </td>
                    <td style="width: 60px">{{ product.quantidade }}</td>
                    <td style="width: 120px">
                      {{ maskCurrency(product.valorUnitario) }}
                    </td>
                    <td style="width: 100px">
                      {{
                        dataSale.tipoDesconto === "Porcentagem"
                          ? maskPercentage(product.desconto)
                          : maskCurrency(product.desconto)
                      }}
                    </td>
                    <td style="width: 115px">
                      <p class="textEllipsis" style="margin: 0">
                        {{ handleCalculateTotalInTable(product) }}
                      </p>
                    </td>
                    <td style="width: 40px; position: relative; padding: 0">
                      <span
                        :class="`contentRemoveItem ${
                          lineProduct.id === product.id && 'blockRemove'
                        }`"
                        @click.stop="removeItens(product.id)"
                        v-b-tooltip.hover.top="'Excluir'"
                        ><b-icon-trash style="color: white" scale="1.5"
                      /></span>
                      <div class="contentCloneItem">
                        <span
                          v-b-tooltip.hover.top="'Clonar'"
                          @click.stop="handleDuplicate(product)"
                          style="
                            width: 25px;
                            height: 25px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <b-icon-back style="color: #a8a8a8" scale="1.5" />
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div v-else class="contentNotItems">
                <p>Nenhum produto adicionado...</p>
              </div>
            </div>
          </section>
        </div>
      </div>

      <hr />

      <b-row class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="contentDataTotals col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="contentTotals">
            <span class="inputTotals">
              <p>Total de Itens (R$)</p>
              <input disabled type="text" v-model="totalOfItens" />
            </span>

            <span class="inputTotals">
              <p>Desconto (R$)</p>
              <input disabled type="text" v-model="totalOfDiscount" />
            </span>

            <span class="dashed"></span>

            <span class="inputTotals contentFreteTotals">
              <p>Frete (R$)</p>
              <input
                style="color: #5c5855"
                type="text"
                :value="maskCurrency(dataSale.frete)"
                @input="handleFreteMonetaryValue($event)"
              />
            </span>

            <span class="inputTotals contentFreteTotals">
              <p>Outros (R$)</p>
              <input
                style="color: #5c5855"
                type="text"
                :value="maskCurrency(dataSale.outrosValores)"
                @input="handleOtherValuesMonetaryValue($event)"
              />
            </span>

            <span class="inputTotals">
              <p>Total da Venda (R$)</p>
              <input disabled type="text" v-model="totalValue" />
            </span>
          </div>
        </div>
      </b-row>

      <hr />

      <div
        class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        v-if="dataSale.status === 'Venda'"
      >
        <b-row
          class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
          style="align-items: center"
        >
          <div class="titleBillsPayments">
            <p class="title">Informações de Pagamento</p>
          </div>
        </b-row>

        <b-row class="contentBills mt-3" size="sm">
          <div class="selectPlus col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <label class="fixedSelect">Forma de pagamento</label>
            <b-form-select
              value-field="id"
              text-field="tipo"
              class="borderInput shadow-none"
              :options="allPaymentsTypes"
              v-model="dataBillBySale.idFormaPagamento"
              size="sm"
              @change="selectPayment"
            >
            </b-form-select>
          </div>

          <div class="receiptAccount col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <InputSelect
              label="Conta de recebimento"
              valueField="id"
              textField="nome"
              :isDisabled="this.blocked"
              :items="allAccountReceiptBills"
              :dataSelect.sync="dataBillBySale.idContaRecebimento"
              :openCreateModal="() => openModals('modalContaRecebimento')"
              ref="refContaDeRecebimento"
            />
          </div>

          <div class="paymentInterval col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <Input
              label="Interval. de pagamento"
              :labelFixed="true"
              type="text"
              :dataInput.sync="intervaloDias"
              ref="refIntervaloDePagamento"
              :isDisabled="paymentMoneyIsSelected"
            />
          </div>

          <div class="fistBill col-sm-6 col-md-3 col-lg-2 col-xl-2">
            <Input
              label="Venc. 1º parcela"
              :labelFixed="true"
              type="date"
              :dataInput.sync="dataBillBySale.data"
              ref="refVencimentoPrimeiraParcela"
              :isDisabled="paymentMoneyIsSelected"
            />
          </div>

          <div class="amountBills col-sm-6 col-md-3 col-lg-2 col-xl-1">
            <Input
              label="Parcelas"
              :labelFixed="true"
              type="text"
              :dataInput.sync="totalParcelas"
              ref="refTotalParcelas"
              :isDisabled="paymentMoneyIsSelected"
            />
          </div>
        </b-row>

        <b-row class="contentBills">
          <div
            class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-end"
          >
            <button
              class="btnsSale btnGenerateBills"
              @click="handleGenerateBillsAndOpenModalBills"
            >
              {{ verifyIfBillIsRegistred }}
            </button>
          </div>
        </b-row>
      </div>

      <hr />

      <b-row class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div style="display: flex; gap: 3px; align-items: center">
            <p class="obsGeneral">Observações Gerais</p>

            <p class="observacoes">
              (Insira aqui observações que serão importantes para você)
            </p>
          </div>

          <textarea
            class="inputTextArea"
            rows="3"
            max-rows="6"
            v-model="dataSale.dadosAdicionais"
          />
        </div>

        <div class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div style="display: flex; gap: 3px; align-items: center">
            <p class="obsGeneral">Observações de Nota Fiscal</p>

            <p class="observacoes">
              (Insira aqui, observações que sairão na Nota Fiscal do cliente)
            </p>
          </div>

          <textarea
            class="inputTextArea"
            rows="3"
            max-rows="6"
            v-model="dataSale.observacoesNf"
          />
        </div>
      </b-row>

      <hr />

      <b-row class="mb-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div
          class="col-sm-12 col-md-12 col-lg-12 col-xl-12 contentButtonsActions"
        >
          <div>
            <button
              v-if="
                dataSale.id &&
                dataSale.idSituacao !==
                  getSpecificSituantionId(saleTypeSituation.cancelado)
              "
              class="btnsSale btnCancelSale"
              @click="cancelSale"
            >
              Cancelar Venda
            </button>

            <button
              class="btnsSale btnReopenSale"
              v-if="
                dataSale.id &&
                dataSale.idSituacao ===
                  getSpecificSituantionId(saleTypeSituation.cancelado)
              "
              @click="reOpenSale"
            >
              Reabrir Venda
            </button>
          </div>

          <div style="display: flex; gap: 10px">
            <div>
              <button
                class="btnsSale btnConfirm"
                @click="handleDataToSendModalSale"
              >
                Finalizar
              </button>
            </div>
          </div>
        </div>
      </b-row>
    </div>

    <ModalCreateAccountReceiptBill
      @getNewReceiptBill="createdNewAccountReceiptBill = $event"
    />
    <ModalBills
      :saleIsCanceled="saleIsCanceled"
      :amountBills="billsFromModal"
      :totalValue="totalValue"
      :paymentSelected="paymentSelected"
      :receiptBillSelected="receiptBillSelected"
      :rangeOfDays="intervaloDias"
      :verifyBillIsPaid="verifyBillIsPaid"
      @isDropBills="isDropBills = $event"
      @clearBillsDataAfterRemove="isRemovedBills = $event"
      @confirmedBills="dataSale.bills = $event"
    />
    <ModalSale
      :paymentsDataInfo="paymentsDataInfo"
      :dataSale="dataSale"
      :dataSaleForModalSale="dataSaleForModalSale"
      :totals="objectTotal"
      :numberSale="numberSale"
      @isPaydBill="isPaydBill = $event"
      @saleSaved="handleSavedSale($event)"
      @clearAllSale="clearAll"
    />
    <ModalCreateCustomer
      @getAllNewCustomer="handleCreatedNewCustomer"
      :idCustomerFromSearch="dataSale.idCliente"
    />
    <ModalCreateEmployees
      @getAllNewEmployee="createdNewEmployee = $event"
      :idEmployeeFromSearch="dataSale.idFuncionario"
    />
    <ModalCreateProducts
      @getAllNewProduct="handleCreatedNewProduct"
      :idProductFromSearch="idProductFromSearch"
    />
    <ModalComposition
      :compositions="compositionItems"
      :idProduct="lineProduct.idProduto"
      :forceUpdateSelect="toggleToUpdate"
      @findCompositions="handleItemFromModalComposition($event)"
    />
  </div>
</template>

<script>
import ModalComposition from "./Modal-Composition.vue";
import ModalCreateCustomer from "../Modals/ModalCreateCustomer.vue";
import ModalCreateEmployees from "../Modals/ModalCreateEmployees.vue";
import ModalCreateProducts from "../Modals/ModalCreateProduct.vue";
import ModalCreateAccountReceiptBill from "../Modals/ModalCreateAccountReceiptBill.vue";
import ModalBills from "./Modal-Bills.vue";
import ModalSale from "./Modal-Sale.vue";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";
import Input from "../UI/CustomInputs/Input.vue";
import InputMoney from "../UI/CustomInputs/InputMoney.vue";
import InputQuant from "../UI/CustomInputs/InputQuant.vue";
import InputPercentage from "../UI/CustomInputs/InputPercentage.vue";
import InputInfinit from "../../components/UI/CustomInputs/InputInfinit.vue";

import { formatObjectToSaveAndUpdate } from "../../helpers/saleAndBudget";

import api from "../../services/axios";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import ServiceCustomer from "../../services/serviceCustomer";
import ServiceProducts from "../../services/serviceProducts";
import ServiceSale from "../../services/serviceSale";
import ServiceProductSale from "../../services/serviceProductSale";
import ServicePaymentForm from "../../services/servicePaymentForm";
import ServiceAccountReceiptBill from "../../services/serviceAccountReceiptBill";
import ServiceSituation from "../../services/serviceSituation";

import { ValidateBillsWhenGenerating } from "../../validators/bills";
import { ValidadeProductOfSale } from "../../validators/productOfSale";

import { INITIAL_STATE_PRODUCTS } from "../../initialStates/sale";
import toastAlertErros from "../../utils/toastAlertErros";
import {
  mascaraMoeda,
  maskCurrency,
  maskPercentage,
} from "../../utils/maskMoney";
import { SaleTypeSituation } from "../../utils/enums/SalesSituationType";
import { mapState, mapMutations } from "vuex";
import serviceSettings from "../../services/serviceSettings";
import { removeReference } from "../../utils/removeReference";

export default {
  components: {
    ModalBills,
    ModalSale,
    InputSelect,
    Input,
    InputMoney,
    InputQuant,
    InputPercentage,
    InputInfinit,
    ModalCreateCustomer,
    ModalCreateEmployees,
    ModalCreateProducts,
    ModalCreateAccountReceiptBill,
    ModalComposition,
  },
  data() {
    return {
      dataSale: {
        id: "",
        idCliente: "",
        idFuncionario: "",
        idSituacao: "",
        data: moment().format("YYYY-MM-DD"),
        dadosAdicionais: "",
        observacoesNf: "",
        status: "Venda",
        tipo: "Venda", //Venda, Frente de caixa, Venda MTQ
        frete: 0,
        outrosValores: 0,
        tipoDesconto: "Valor Monetário", // ou Porcentagem
        comissao: 0,
        bills: [],
      },
      lineProduct: {
        id: "",
        idVenda: "",
        idFornecedor: "",
        idProduto: "",
        quantidade: 1,
        valorUnitario: 0,
        valorTotal: 0,
        desconto: 0,
        dadosAdicionais: "",
        idProdutoComposicao: "",
        tipoProduto: "normal", // ou composicao
        composicao: [],
        estoque: 0,
        tipoPreco: "P1",
      },
      blocked: false,
      dataCustomers: [],
      dataEmployee: [],
      allSituations: [],
      products: [],
      providers: [],
      comissao: 0,
      productUnitaryValue: "",
      fields: ["Produto", "Quantidade", "Valor"],
      dataBillBySale: {
        tipo: "entrada",
        idCliente: "",
        idFuncionario: "",
        idFormaPagamento: "",
        idContaRecebimento: "",
        idVenda: "",
        valorUnitario: 0.0,
        valorPago: 0.0,
        valorRestante: 0.0,
        data: moment().format("YYYY-MM-DD"),
      },
      totalParcelas: 1,
      intervaloDias: "",
      allPaymentsTypes: [],
      total: "0,00",
      totalOfItens: "R$ 0,00",
      totalOfDiscount: "R$ 0,00",
      additionalValues: "R$ 0,00",
      otherValues: "R$ 0,00",
      totalValue: 0,
      discountToAllItems: false,
      discountToIndividuallyItem: false,
      discountIsCheked: false,
      billsFromModal: [],
      allAccountReceiptBills: [],
      paymentSelected: "",
      receiptBillSelected: "",
      isPaydBill: "",
      isDropBills: false,
      saleDataFromSaleModal: {},
      dataSaleForModalSale: {
        customerSelected: {
          nome: "",
        },
        employeeSelected: {
          nomeFuncionario: "",
        },
        formattedProductsSale: [],
        data: "",
      },
      numberSale: "",
      verifyBillIsPaid: false,
      paymentMoneyIsSelected: false,
      paymentsDataInfo: {
        receiptAccount: "",
        typePayment: "",
        expiredFirst: "",
        installmentsNumber: 0,
        installmentsInterval: 0,
      },
      saleTypeSituation: SaleTypeSituation,
      saleIsCanceled: false,
      situationSelected: null,
      createdNewCustomer: false,
      createdNewEmployee: null,
      createdNewProducts: null,
      createdNewAccountReceiptBill: null,
      maskCurrency: maskCurrency,
      maskPercentage: maskPercentage,
      compositionItems: [],
      idProduct: "",
      toggleToUpdate: false,
      idProductFromSearch: "",
      isBlockStock: "",
      activecodRef: false,
      sendCodRef: false,
      codRef: "",
      productsSelected: [],
      selectedItemIdToReplace: null,
      productLoading: false,
      toggleClearSelect: false,
      tableProducts: [],
      newOrder: false,
      productAfterSelectItem: null,
    };
  },
  methods: {
    ...mapMutations(["SET_DATA_SEARCH_FROM_SALE"]),
    async handleAddMoreItens() {
      if (!this.dataSale.idCliente) {
        return this.$toast.open({
          message: "(Cliente) deve ser selecionado para prosseguir",
          type: "info",
        });
      }

      await this.saveSale();

      this.lineProduct.id
        ? await this.updateProductOfSale()
        : await this.saveProductOfSale();

      this.handleCalculateTotalsValues();
    },

    async saveProductOfSale() {
      try {
        this.lineProduct.idVenda = this.dataSale.id;

        if (!this.validadeProductOfSale(this.lineProduct)) return;

        await ServiceProductSale.save(this.lineProduct);
        await this.findTableProductsOfSale();
        this.clearLineProduct();

        return this.$toast.open({
          message: "Produto adicionado",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao salvar o produto",
          type: "info",
        });
      } finally {
        this.productLoading = false;
      }
    },

    async updateProductOfSale() {
      try {
        this.lineProduct.idVenda = this.dataSale.id;

        if (!this.validadeProductOfSale(this.lineProduct)) return;

        await ServiceProductSale.update(this.lineProduct.id, this.lineProduct);
        await this.findTableProductsOfSale();
        this.clearLineProduct();

        return this.$toast.open({
          message: "Produto atualizado",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao salvar o produto",
          type: "info",
        });
      } finally {
        this.productLoading = false;
      }
    },

    async editProductsOfSale(prod) {
      try {
        const newProd = removeReference(prod);
        this.assignProduct(newProd);
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao editar o produto",
          type: "info",
        });
      }
    },

    async findTableProductsOfSale() {
      const result = await ServiceProductSale.findByIdSale(this.dataSale.id);

      this.tableProducts = result || [];
    },

    assignProduct(prod) {
      this.lineProduct = prod;

      this.$refs.refQtd.valueInput = prod.quantidade;
      this.$refs.refVlUnit.valueInput = prod.valorUnitario;
      this.$refs.valorTotal.valueInput = prod.valorTotal;
      this.$refs.discount.valueInput = prod.desconto;
      this.$refs.dadosAdicionais.valueInput = prod.dadosAdicionais;

      this.handleCalculateTotal();
    },

    clearLineProduct() {
      this.lineProduct = {
        id: "",
        idVenda: "",
        idFornecedor: "",
        idProduto: "",
        quantidade: 1,
        valorUnitario: 0,
        valorTotal: 0,
        desconto: 0,
        dadosAdicionais: "",
        idProdutoComposicao: "",
        tipoProduto: "normal",
        composicao: [],
        estoque: 0,
        tipoPreco: "P1",
      };

      if (!this.discountToAllItems) {
        this.lineProduct.desconto = 0;
        this.$refs.discount.valueInput = 0;
      }

      this.$refs.refQtd.valueInput = 1;
      this.$refs.refVlUnit.valueInput = 0;
      this.$refs.valorTotal.valueInput = 0;
      this.$refs.dadosAdicionais.valueInput = "";
      this.toggleClearSelect = !this.toggleClearSelect;
      this.productAfterSelectItem = null;
    },

    async calculateComissao() {
      this.readComissao();
      this.handleCalculateTotalsValues();
      if (this.comissao) {
        const total =
          parseFloat(this.comissao) * (mascaraMoeda(this.totalValue) / 100);
        this.dataSale.comissao = total.toFixed(2);
      }
    },

    assignTotalItensValues() {
      const valorTotal = this.tableProducts.reduce(
        (oldValue, product) => oldValue + product.valorTotal,
        0
      );

      this.totalOfItens = parseFloat(valorTotal).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    assignTotalDiscount() {
      if (this.discountIsCheked) {
        this.totalOfDiscount = this.calculateTotalDiscount();
        return;
      }

      this.totalOfDiscount = this.calculateTotalDiscountWithMonetaryValue();
    },

    assignTotalValue() {
      const formattedTotal = mascaraMoeda(this.totalOfItens);
      const formattedTotalDiscount = mascaraMoeda(this.totalOfDiscount);
      const frete = this.dataSale.frete;
      const outrosValores = this.dataSale.outrosValores;

      const newTotal =
        formattedTotal -
        formattedTotalDiscount +
        (parseFloat(frete) || 0) +
        (parseFloat(outrosValores) || 0);

      this.totalValue = newTotal.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    calculateTotalDiscount() {
      const discounts = this.tableProducts.map((prod) => {
        const desc = prod.desconto;
        const totalValue = prod.valorTotal;

        return (totalValue * desc) / 100;
      });

      const totalValueWithDiscount = discounts.reduce(
        (oldValue, desc) => oldValue + desc,
        0
      );

      return totalValueWithDiscount.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    calculateTotalDiscountWithMonetaryValue() {
      const monetaryDiscounts = this.tableProducts.map((prod) => {
        if (prod.desconto) {
          const desc = prod.desconto;

          return desc;
        }

        return 0;
      });

      const totalMonetaryWithDiscount = monetaryDiscounts.reduce(
        (oldValue, desc) => oldValue + desc,
        0
      );

      return totalMonetaryWithDiscount.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    async cancelSale() {
      try {
        await ServiceSale.cancel(this.dataSale.id, this.dataSale.status);

        this.dataSale.idSituacao = this.getSpecificSituantionId(
          SaleTypeSituation.cancelado
        );

        this.saleIsCanceled = !this.saleIsCanceled;
        this.clearAllBills();
        return this.$toast.open({
          message: "Venda cancelada!",
          type: "success",
        });
      } catch (error) {
        toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async reOpenSale() {
      try {
        await ServiceSale.reOpen(this.dataSale.id);

        this.dataSale.idSituacao = this.getSpecificSituantionId(
          SaleTypeSituation.aprovado
        );

        return this.$toast.open({
          message: "Venda reaberta!",
          type: "success",
        });
      } catch (error) {
        toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    handleCalculateTotal() {
      const amount = Number(this.lineProduct.quantidade);
      const unitaryValue = this.lineProduct.valorUnitario;
      const totalValue = amount * unitaryValue;

      if (!this.$refs.valorTotal) return;

      this.lineProduct.valorTotal = totalValue;

      let discountValue = 0;

      if (this.dataSale.tipoDesconto === "Porcentagem") {
        const result = (
          this.lineProduct.valorTotal *
          (this.lineProduct.desconto / 100)
        ).toFixed(2);

        discountValue = parseFloat(result);
      } else {
        discountValue = this.lineProduct.desconto;
      }

      this.$refs.valorTotal.valueInput = totalValue - discountValue;
    },

    async removeItens(id) {
      try {
        await ServiceProductSale.delete(id);
        await this.findTableProductsOfSale();

        this.handleCalculateTotalsValues();
        return this.$toast.open({
          message: "Produto removido",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message:
            `${error.response.data.message}` ||
            "Ocorreu um erro ao remover o produto!",
          type: "info",
        });
      }
    },

    validadeProductOfSale(prod) {
      const isNotValid = ValidadeProductOfSale(prod);

      if (isNotValid && Object.keys(isNotValid).length) {
        this.$toast.open({
          message: isNotValid.message,
          type: "info",
        });

        return false;
      }

      return true;
    },

    cancelReplaceAll() {
      this.selectedItemIdToReplace = null;

      if (this.$refs.replaceAll) {
        this.$refs.replaceAll.style.display = "block";
      }
    },

    async applyReplaceAll() {
      try {
        await ServiceProductSale.replaceAll(
          this.lineProduct.idProduto,
          this.selectedItemIdToReplace,
          this.dataSale.id,
          this.lineProduct.tipoPreco
        );

        this.cancelReplaceAll();
        this.clearLineProduct();
        await this.findTableProductsOfSale();

        this.handleCalculateTotalsValues();
      } catch (error) {
        this.$toast.open({
          message:
            error?.responde?.data?.message ||
            "Ocorreu um problema ao substituir os itens",
          type: "info",
        });
      }
    },

    handleItemSelected(prevId) {
      this.selectedItemIdToReplace = prevId;

      if (this.$refs.replaceAll) {
        this.$refs.replaceAll.style.display = "none";
      }
    },

    async handleDuplicate(productOfSale) {
      try {
        const newProd = removeReference(productOfSale);
        newProd.id = "";

        await ServiceProductSale.save(newProd);
        await this.findTableProductsOfSale();

        this.$toast.open({
          message: "Item Clonado",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao clonar o item",
          type: "info",
        });
      }
    },

    handleCalculateTotalInTable(prod) {
      let newTotalValue = 0;

      if (this.dataSale.tipoDesconto === "Porcentagem") {
        const result = (prod.valorTotal * (prod.desconto / 100)).toFixed(2);

        newTotalValue = parseFloat(prod.valorTotal - result);
      } else {
        newTotalValue = prod.valorTotal - prod.desconto;
      }

      return maskCurrency(newTotalValue);
    },

    clearDataPaymentsSale() {
      this.dataBillBySale.idCliente = "";
      this.dataBillBySale.idFuncionario = "";
      this.dataBillBySale.idFormaPagamento = "";
      this.dataBillBySale.idContaRecebimento = "";
      this.dataBillBySale.idVenda = "";
      this.dataBillBySale.valorUnitario = "";
      this.dataBillBySale.valorPago = "";
      this.dataBillBySale.valorRestante = "";
      this.dataBillBySale.data = moment().format("YYYY-MM-DD");
      this.totalParcelas = 1;
      this.intervaloDias = 30;
      this.dataSale.bills = [];
    },

    async getProductById(idProduct) {
      const data = await ServiceProducts.getProductById(idProduct);
      return data;
    },

    async updateProductValue(selected) {
      const valueMapping = {
        P1: this.productAfterSelectItem.valorVenda,
        P2: this.productAfterSelectItem.valorVenda2,
        P3: this.productAfterSelectItem.valorVenda3,
      };

      const currencyValueProduct = valueMapping[selected];

      this.lineProduct.valorUnitario = currencyValueProduct;

      if (this.$refs.refVlUnit) {
        this.$refs.refVlUnit.valueInput = currencyValueProduct;
      }

      this.handleCalculateTotal();
    },

    async selectProductSetDefaultValue(product) {
      this.productAfterSelectItem = product;

      if (product) {
        this.lineProduct.estoque = await ServiceProducts.findStock(product.id);

        if (!this.lineProduct.id) {
          this.lineProduct.valorUnitario = product.valorVenda;

          this.$refs.refVlUnit.valueInput = product.valorVenda;

          const amount = Number(this.lineProduct.quantidade);
          const unitaryValue = this.lineProduct.valorUnitario;
          const total = amount * unitaryValue;

          this.lineProduct.valorTotal = total;
          this.$refs.valorTotal.valueInput = total;

          if (product?.composicao) {
            this.lineProduct.composicao = product?.composicao?.map((item) => {
              const newInitialState = {
                ...INITIAL_STATE_PRODUCTS,
                idList: uuidv4(),
                dadosAdicionais: item.dadosAdicionais,
                idProduto: item.idProduto,
                tipoProduto: "composicao",
                tipo: "Venda",
                quantidade: item.quantidade,
              };

              Reflect.deleteProperty(item, "idRefProduto");

              return {
                ...item,
                ...newInitialState,
              };
            });
          }

          this.updateProductValue(this.lineProduct.tipoPreco || "P1");
        }
      }
    },

    alterStatusSale() {
      this.dataSale.status = "Venda";
    },

    async fillProductsComboBoxWithPaginate(id, q) {
      try {
        return await ServiceProducts.fillComboBoxWithPaginate(id, q);
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao listar os produtos",
          type: "info",
        });
      }
    },

    async saveSale() {
      try {
        this.productLoading = true;
        if (this.dataSale.id) return;

        const result = await ServiceSale.createWithoutProduct({
          sale: this.dataSale,
        });

        this.dataSale.id = result.id;
        this.numberSale = result.number;
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um problema ao salvar",
          type: "info",
        });

        this.productLoading = false;
      }
    },

    async updateSale() {
      await ServiceSale.updateWithoutProduct({
        sale: this.dataSale,
      });
    },

    async fillCustomerComboBoxWithPaginate(id, q) {
      try {
        return await ServiceCustomer.fillComboBoxWithPaginate(id, q);
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao listar os clientes",
          type: "info",
        });
      }
    },

    async updateCompletedSale() {
      try {
        const dataSaleFormatted = formatObjectToSaveAndUpdate(this.dataSale);
        await ServiceSale.updateCompletedSale(dataSaleFormatted);
      } catch (error) {
        if (error.response.status === 422) {
          return this.$toast.open({
            message: "Verifique se os dados do cliente foram informados",
            type: "info",
          });
        }
      }
    },

    async listEmployeesSelectBox() {
      try {
        const { data } = await api.get("/employees/combobox/fill");
        this.dataEmployee = data.data;
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao listar os funcionários",
          type: "info",
        });
      }
    },

    readComissao() {
      const employee = this.dataEmployee.find(
        (emp) => this.dataSale.idFuncionario === emp.id
      );
      if (employee?.comissao) {
        this.comissao = employee.comissao;
      }
      this.dataBillBySale.idFuncionario = this.dataSale.idFuncionario;
    },

    async getProviders() {
      const { data } = await api.get("/providers/fill/combobox");
      this.providers = data;
    },

    handleGenerateBillsAndOpenModalBills() {
      if (
        (this.dataSale.bills.length && this.dataSale.bills[0]?.id) ||
        (this.billsFromModal.length && this.billsFromModal[0]?.id)
      ) {
        this.verifyBillIsPaid = !this.verifyBillIsPaid;
        this.getPaymentInformation();
        return this.openModals("modalBills");
      }

      const isNotValid = this.validateBills();
      if (isNotValid) {
        return this.$toast.open({
          message: isNotValid,
          type: "info",
        });
      }

      this.verifyBillIsPaid = !this.verifyBillIsPaid;
      this.handleGetTypePaymentAndReceiptBill();
      this.makingThePayment();
      this.getPaymentInformation();
      this.openModals("modalBills");
    },

    handleDataCustomers(event) {
      this.dataCustomers = [event];
    },

    handleCreatedNewCustomer() {
      this.createdNewCustomer = !this.createdNewCustomer;
    },

    handleGetTypePaymentAndReceiptBill() {
      if (this.dataBillBySale.idFormaPagamento) {
        const typePayment = this.allPaymentsTypes.filter(
          (payment) => payment.id === this.dataBillBySale.idFormaPagamento
        );

        const receiptBill = this.allAccountReceiptBills.filter(
          (receiptBills) =>
            receiptBills.id === this.dataBillBySale.idContaRecebimento
        );

        this.paymentSelected = typePayment[0].tipo;
        this.receiptBillSelected = receiptBill[0].nome;
      }
    },

    async verifyStockProducts() {
      for (const item of this.dataSale.products) {
        const product = await this.getProductById(item.idProduto);
        const verifyStock = item.estoque - item.quantidade;

        if (verifyStock < 0) {
          this.$toast.open({
            message: `O produto ${product.nome} não possui estoque.`,
            type: "info",
          });
          return false;
        }
      }
      return true;
    },

    async handleDataToSendModalSale() {
      if (this.isBlockStock === "2") {
        const stockVerified = await this.verifyStockProducts();
        if (!stockVerified) {
          return;
        }
      }
      this.assingValuesSaleForModalSale();
      this.getPaymentInformation();
      this.calculateComissao();
      this.openModals("modalSale");
    },

    assingValuesSaleForModalSale() {
      this.dataSaleForModalSale.formattedProductsSale = this.tableProducts;

      this.dataSaleForModalSale.customerSelected.nome =
        this.dataCustomers.filter(
          (customer) => customer.id === this.dataSale.idCliente
        )[0]?.nome;

      this.dataSaleForModalSale.employeeSelected.nomeFuncionario =
        this.dataEmployee.filter(
          (employee) => employee.id === this.dataSale.idFuncionario
        )[0]?.nomeFuncionario;

      this.dataSaleForModalSale.data = this.dataSale.data;
    },

    getPaymentInformation() {
      if (this.billsFromModal.length) {
        this.paymentsDataInfo.receiptAccount = this.allAccountReceiptBills.find(
          (receiptBills) =>
            receiptBills.id === this.billsFromModal[0].idContaRecebimento
        ).nome;

        this.paymentsDataInfo.typePayment = this.allPaymentsTypes.find(
          (payment) => payment.id === this.billsFromModal[0].idFormaPagamento
        ).tipo;

        this.paymentsDataInfo.expiredFirst = moment(
          this.billsFromModal.sort((a, b) => a.data - b.data)[0].data
        ).format("DD/MM/YYYY");

        this.paymentsDataInfo.installmentsNumber = this.billsFromModal.length;

        if (this.billsFromModal.length >= 2) {
          this.paymentsDataInfo.installmentsInterval = moment(
            this.billsFromModal[1].data
          ).diff(this.billsFromModal[0].data, "days");
        }
      }
    },

    clearPaymentsDataInfo() {
      this.paymentsDataInfo.receiptAccount = "";
      this.paymentsDataInfo.typePayment = "";
      this.paymentsDataInfo.expiredFirst = "";
      this.paymentsDataInfo.installmentsNumber = 0;
      this.paymentsDataInfo.installmentsInterval = 0;
    },

    handleModalCreateProduct(nameModal, idProduct) {
      this.idProductFromSearch = idProduct;
      this.openModals(nameModal);
    },

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    validateBills() {
      const dataFromGenerateBill = {
        idCliente: this.dataSale.idCliente,
        idFormaDePagamento: this.dataBillBySale.idFormaPagamento,
        idContaRecebimento: this.dataBillBySale.idContaRecebimento,
        intervaloDias: this.intervaloDias,
        vencimento: this.dataBillBySale.data,
        quantidadeParcelas: this.totalParcelas,
      };

      return ValidateBillsWhenGenerating(dataFromGenerateBill);
    },

    async handleSituation() {
      const allSituation = await this.getAllSituation();
      this.toAssignDefaultSituation(allSituation);
    },

    async getAllPayment() {
      const result = await ServicePaymentForm.getAllPayment();
      this.allPaymentsTypes = result.sort((a, b) => a.cod - b.cod);
    },

    async getAllSituation() {
      try {
        const result = await ServiceSituation.getAllSituation();
        return result;
      } catch (error) {
        return this.$toast.open({
          message: `${
            error.response.data.message ||
            "Ocorreu um erro ao carregar todas as situações"
          }`,
          type: "info",
        });
      }
    },

    async getAllReceiptBill() {
      const result = await ServiceAccountReceiptBill.fillComobobox();
      this.allAccountReceiptBills = result;
    },

    clearAllBills() {
      this.clearDataPaymentsSale();
      this.clearInputForGenerateBills();
      this.billsFromModal = [];
      this.dataSale.bills = [];
      this.paymentSelected = "";
      this.receiptBillSelected = "";
    },

    clearInputForGenerateBills() {
      this.$refs.refContaDeRecebimento.valueItems = "";
      this.$refs.refIntervaloDePagamento.valueInput = this.intervaloDias;
      this.$refs.refVencimentoPrimeiraParcela.valueInput =
        this.dataBillBySale.data;
      this.$refs.refTotalParcelas.valueInput = this.totalParcelas;
    },

    makingThePayment() {
      if (this.paymentMoneyIsSelected) {
        return this.handleMoneyPaymentSelected();
      }

      this.billsFromModal = [];
      const formattedTotalValue = mascaraMoeda(this.totalValue);

      const valorPorDuplicata = (formattedTotalValue / this.totalParcelas)
        .toFixed(2)
        .toLocaleString("pt-br", { minimumFractionDigits: 2 });

      for (let i = 0; i < this.totalParcelas; i++) {
        const dataVencimento =
          i == 0
            ? this.dataBillBySale.data
            : moment(dataVencimento)
                .add(this.intervaloDias, "days")
                .format("YYYY-MM-DD");
        this.billsFromModal.push({
          id: "",
          tipo: "entrada",
          idCliente: this.dataSale.idCliente,
          idFuncionario: this.dataSale.idFuncionario,
          idFormaPagamento: this.dataBillBySale.idFormaPagamento,
          idVenda: this.dataSale.id,
          valorTotal: valorPorDuplicata,
          valorPago: 0.0,
          valorRestante: valorPorDuplicata,
          data: dataVencimento,
          dataCompetencia: moment().format("YYYY-MM-DD"),
          dataPagamento: null,
          descricao: "",
          idContaRecebimento: this.dataBillBySale.idContaRecebimento,
        });
      }
    },

    async alterDiscountIsCheked() {
      this.discountIsCheked = !this.discountIsCheked;

      if (this.discountIsCheked) {
        this.dataSale.tipoDesconto = "Porcentagem";
        this.$refs.enableLabelApplyAll.style.display = "block";
      } else {
        this.dataSale.tipoDesconto = "Valor Monetário";
        this.$refs.enableLabelApplyAll.style.display = "none";
        this.discountToAllItems = false;
      }

      if (this.dataSale.id) await this.updateSale();

      this.handleCalculateTotalsValues();
      this.handleCalculateTotal();
    },

    alterAllChecksDiscount() {
      if (this.discountToAllItems) {
        this.$refs.typeDiscount.checked = true;

        this.assignTotalItensValues();
        this.assignTotalDiscount();
        this.assignTotalValue();
      }
    },

    handleSavedSale(saleSaved) {
      this.saleDataFromSaleModal = saleSaved;
    },

    handleCreatedNewProduct() {
      this.createdNewProducts = !this.createdNewProducts;
    },

    handleSaleValues() {
      this.handleBillsIfAny();
      this.handleSaleProductsIfAny();
      this.assignDataSearchFromSale();
    },

    handleBillsIfAny() {
      if (!this.dataSearchFromSale?.bills?.length) {
        return Reflect.deleteProperty(this.dataSearchFromSale, "bills");
      }
      this.billsFromModal = this.dataSearchFromSale.bills.map((bill) => {
        return {
          ...bill,
          data: bill.data.split(" ")[0],
          dataPagamento: bill.dataPagamento?.split(" ")[0],
        };
      });
    },

    handleSaleProductsIfAny() {
      if (!this.dataSearchFromSale?.products?.length) {
        Reflect.deleteProperty(this.dataSearchFromSale, "products");
      }
    },

    assignDataSearchFromSale() {
      Object.assign(this.dataSale, this.dataSearchFromSale);

      this.tableProducts = this.dataSearchFromSale.products || [];
      Reflect.deleteProperty(this.dataSale, "products");

      if (this.dataSale.tipoDesconto === "Porcentagem") {
        this.discountIsCheked = true;
        this.$nextTick(() => {
          this.$refs.enableLabelApplyAll.style.display = "block";
        });
        this.assignTotalDiscount();
      }

      this.numberSale = this.dataSearchFromSale.numero;

      this.$refs.refResponsavel.valueItems =
        this.dataSearchFromSale.idFuncionario;
      this.$refs.data.valueInput = this.dataSearchFromSale.data;
    },

    toAssignDefaultSituation(situations) {
      this.allSituations = situations.filter((situation) => {
        if (
          [
            SaleTypeSituation.aprovado,
            SaleTypeSituation.faturado,
            SaleTypeSituation.finalizado,
            SaleTypeSituation.cancelado,
          ].includes(situation.nome)
        ) {
          return situation;
        }
      });

      if (!this.dataSale.id) {
        this.dataSale.idSituacao = this.getSpecificSituantionId(
          SaleTypeSituation.aprovado
        );
      }
    },

    handleFreteMonetaryValue(event) {
      this.dataSale.frete = mascaraMoeda(event.target.value);
    },

    handleOtherValuesMonetaryValue(event) {
      this.dataSale.outrosValores = mascaraMoeda(event.target.value);
    },

    getSpecificSituantionId(saleTypeSituation) {
      const situation = this.allSituations.find(
        (situation) => situation.nome === saleTypeSituation
      );

      return situation?.id;
    },

    getNameSituantionById(idSituation) {
      const situation = this.allSituations.find(
        (situation) => situation.id === idSituation
      );

      return situation?.nome?.toUpperCase();
    },

    handleMoneyPaymentSelected() {
      this.billsFromModal = [];

      const formattedTotalValue = mascaraMoeda(this.totalValue);
      const valorPorDuplicata = formattedTotalValue.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });

      this.billsFromModal.push({
        id: "",
        tipo: "entrada",
        idCliente: this.dataSale.idCliente,
        idFuncionario: this.dataSale.idFuncionario,
        idFormaPagamento: this.dataBillBySale.idFormaPagamento,
        idVenda: this.dataSale.id,
        valorTotal: valorPorDuplicata,
        valorPago: 0.0,
        valorRestante: valorPorDuplicata,
        data: moment().format("YYYY-MM-DD"),
        dataPagamento: moment().format("YYYY-MM-DD"),
        dataCompetencia: moment().format("YYYY-MM-DD"),
        descricao: "",
        idContaRecebimento: this.dataBillBySale.idContaRecebimento,
      });
    },

    handleCalculateTotalsValues() {
      this.assignTotalItensValues();
      this.assignTotalDiscount();
      this.assignTotalValue();
    },

    selectPayment() {
      this.blocked = false;
      const moneyPayment = this.allPaymentsTypes.find(
        (formPayment) => formPayment.id === this.dataBillBySale.idFormaPagamento
      );

      if (moneyPayment.tipo === "Dinheiro") {
        this.blocked = true;
        const accountPayment = this.allAccountReceiptBills.find(
          (account) => account.nome === "CAIXA GAVETA"
        );

        this.dataBillBySale.idContaRecebimento = accountPayment.id;
        this.$refs.refContaDeRecebimento.valueItems = accountPayment.id;
      }
    },

    handleShowCompositionItem(idProduct, composition) {
      if (idProduct) {
        this.compositionItems = composition;
        this.toggleToUpdate = !this.toggleToUpdate;
        this.$bvModal.show("modalProductSaleComposition");
      }
    },

    handleItemFromModalComposition(payload) {
      this.lineProduct.composicao = payload.composition;
    },

    async getConfig() {
      const result = await serviceSettings.findSettingsAtm();
      if (Object.keys(result).length) {
        this.isBlockStock = result.venda.bloqueio_estoque;
      }
    },

    clearAll() {
      this.dataSale = {
        id: "",
        idCliente: "",
        idFuncionario: "",
        idSituacao: this.getSpecificSituantionId(SaleTypeSituation.aprovado),
        idNfse: null,
        data: moment().format("YYYY-MM-DD"),
        dadosAdicionais: "",
        observacoesNf: "",
        status: "Venda",
        tipo: "Venda",
        frete: 0,
        creditoUtilizado: 0,
        tipoDesconto: "Valor Monetário",
        comissao: 0,
        bills: [],
      };
      this.numberSale = "";
      this.saleDataFromSaleModal = {};
      this.tableProducts = [];

      this.totalOfItens = "R$ 0,00";
      this.totalOfDiscount = "R$ 0,00";
      this.additionalValues = "R$ 0,00";
      this.totalValue = "R$ 0,00";
      this.newOrder = !this.newOrder;

      this.clearAllBills();
      this.clearPaymentsDataInfo();
      this.clearAllRefs();
    },

    clearAllRefs() {
      this.$refs.refResponsavel.valueItems = "";
      this.$refs.valorTotal.valueInput = 0;
      this.$refs.refVlUnit.valueInput = 0;
    },
  },
  async mounted() {
    this.listEmployeesSelectBox();
    this.getProviders();
    this.getAllPayment();
    this.getAllReceiptBill();
    this.getConfig();
    await this.handleSituation();

    if (this.dataSearchFromSale.idCliente) {
      this.handleSaleValues();
    }

    this.assignTotalItensValues();
    this.assignTotalDiscount();
    this.assignTotalValue();

    if (
      !this.dataSale.dadosAdicionais &&
      this.dataConfig &&
      Object.keys(this.dataConfig.venda).length &&
      this.dataConfig.venda.dados_adicionais_padrao
    ) {
      this.dataSale.dadosAdicionais =
        this.dataConfig.venda.dados_adicionais_padrao;
    }
  },
  filters: {
    moment(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
  computed: {
    ...mapState({
      dataBudgetForSale: (state) => state.dataBudgetForSale,
      dataSearchFromSale: (state) => state.dataSearchFromSale,
      dataConfig: (state) => state.dataConfig,
    }),
    typePaymentSelected() {
      return this.dataBillBySale.idFormaPagamento;
    },
    decimalPlaces() {
      if (Object.keys(this.dataConfig).length) {
        return Number(this.dataConfig?.venda_mtq?.casas_decimais);
      }

      return 2;
    },
    freteToAdditionalValues() {
      return this.dataSale.frete;
    },
    otherValuesToAdditionalValues() {
      return this.dataSale.outrosValores;
    },
    objectTotal() {
      return {
        totalItems: this.totalOfItens,
        additionalValues: this.additionalValues,
        otherValues: this.otherValues,
        totalDiscount: this.totalOfDiscount,
        total: this.totalValue,
      };
    },
    disabledProducts() {
      return this.dataSale.status;
    },
    verifyIfBillIsRegistred() {
      if (this.billsFromModal.length && this.billsFromModal[0]?.id)
        return "Ver Parcelas";
      else return "Gerar Parcelas";
    },
    alterColorSituation() {
      const situation = this.allSituations.find(
        (situation) => situation.id === this.dataSale.idSituacao
      );

      if (situation && situation.nome === SaleTypeSituation.aprovado) {
        return "orange";
      } else if (situation && situation.nome === SaleTypeSituation.faturado) {
        return "#3e66ae";
      } else if (situation && situation.nome === SaleTypeSituation.finalizado) {
        return "blue";
      }

      return "#9f1313";
    },
    returnCustomerId() {
      return this.dataSale.idCliente;
    },
    validatorDesc() {
      let existsDiscount = false;

      if (this.tableProducts.length) {
        for (const product of this.tableProducts) {
          if (product.desconto) {
            existsDiscount = true;
            break;
          }
        }
      }

      return existsDiscount;
    },
  },
  watch: {
    typePaymentSelected() {
      if (this.typePaymentSelected) {
        const payment = this.allPaymentsTypes.find(
          (payment) => payment.id === this.typePaymentSelected
        );
        if (payment.tipo === "Dinheiro") {
          this.paymentMoneyIsSelected = true;
          this.intervaloDias = 0;
          this.dataBillBySale.data = moment().format("YYYY-MM-DD");
          this.totalParcelas = 1;
          this.$refs.refIntervaloDePagamento.valueInput = this.intervaloDias;
          this.$refs.refVencimentoPrimeiraParcela.valueInput =
            this.dataBillBySale.data;
          this.$refs.refTotalParcelas.valueInput = this.totalParcelas;
        } else {
          this.paymentMoneyIsSelected = false;
        }
      }
    },
    freteToAdditionalValues() {
      this.additionalValues = "R$ 0,00";
      const formattedFrete = this.dataSale.frete;

      if (formattedFrete) {
        this.additionalValues = parseFloat(formattedFrete).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        );
      }

      this.assignTotalValue();
    },
    otherValuesToAdditionalValues() {
      this.otherValues = "R$ 0,00";
      const formattedOtherVales = this.dataSale.outrosValores;

      if (formattedOtherVales) {
        this.otherValues = parseFloat(formattedOtherVales).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        );
      }

      this.assignTotalValue();
    },
    isDropBills() {
      this.clearAllBills();
      this.clearPaymentsDataInfo();
    },
    saleDataFromSaleModal() {
      if (this.saleDataFromSaleModal.id) {
        this.dataSale.id = this.saleDataFromSaleModal.id;
      }

      if (this.saleDataFromSaleModal.productsOfSale) {
        const newProductsFromTable =
          this.saleDataFromSaleModal.productsOfSale.map((prod, indice) => {
            return {
              ...prod,
              idList: this.dataSale.products[indice].idList,
            };
          });

        this.dataSale.products = newProductsFromTable;
      }

      if (this.saleDataFromSaleModal.billsOfSale) {
        const newBills = this.saleDataFromSaleModal.billsOfSale.map((bill) => {
          return { ...bill };
        });

        this.dataSale.bills = newBills;
        this.billsFromModal = newBills;
      }
    },
    createdNewEmployee() {
      this.listEmployeesSelectBox();
    },
    createdNewAccountReceiptBill() {
      this.getAllReceiptBill();
    },
  },
  beforeDestroy() {
    this.SET_DATA_SEARCH_FROM_SALE({});
  },
};
</script>

<style scoped>
#dadosVendaStyle {
  border: none !important;
}

.containerInputItem {
  width: 40%;
  position: relative;
  min-width: 320px;
}

.inputItem {
  width: 100%;
  min-width: 320px;
  position: relative;
}

.inputItemObs {
  width: 40%;
  margin-top: 0.5rem;
}

.inputItemQnt {
  width: 8%;
}

.inputItemVlUnit {
  width: 13%;
}

.inputItemVlTot {
  width: 13%;
}

.inputItemDesc {
  width: 10%;
}

.itemToggleDesc {
  width: 52px;
  position: relative;
}

.containerTableItens {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap-reverse;
}

.contentTableItens {
  width: 100%;
  height: 242px;
  border: dashed 1px #c1c1c1;
  border-radius: 5px;
  padding: 0.3rem 0.5rem;
}

.tableProducts {
  display: table;
  width: 100%;
  border-spacing: 0;
  margin-top: 0.5rem;
}

.tableProducts thead tr th {
  padding: 0 0.5rem;
  font-weight: normal;
}

.tableProducts thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tableItem {
  height: 50px;
  background-color: whitesmoke;
  box-shadow: 0 1px 3px rgb(0 0 0 / 24%);
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 0.5rem;
  position: relative;
  transition: ease 0.2s;
}

.tableItem:hover {
  background-color: rgb(218, 218, 218);
  transition: ease 0.2s;
}

.tableItem td {
  text-align: start !important;
  padding: 0 0.5rem;
}

.textEllipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.spinProduct {
  color: white;
  width: 20px;
  height: 20px;
}

.contentNumberItem {
  height: 18px;
  width: 18px;
  min-width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: gray;
  color: white;
}

.contentRemoveItem {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 5px 5px 0;
  background-color: brown;
  box-shadow: -1px 0 2px rgb(0 0 0 / 24%);
}

.blockRemove {
  background-color: rgb(144, 85, 85);
  pointer-events: none !important;
  cursor: default !important;
}

.contentCloneItem {
  position: absolute;
  right: 30px;
  top: 0;
  height: 100%;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentNotItems {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentNotItems p {
  font-size: 16px;
  color: #c1c1c1;
}

.infinite {
  padding-right: 30px;
  position: relative;
}

.cyberpunk-checkbox {
  appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid #555151;
  border-radius: 5px;
  background-color: transparent;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.cyberpunk-checkbox:before {
  content: "";
  background-color: #00486e;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 8px;
  height: 8px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.cyberpunk-checkbox:checked:before {
  transform: translate(-50%, -50%) scale(1);
}

.cyberpunk-checkbox-label {
  font-size: 12px;
  color: black;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}

.iconSelectBarCode {
  position: absolute;
  right: 0px !important;
  z-index: 10;
  background-color: #c1c1c1;
  color: black;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
}

.codRef {
  margin-left: 1rem;
  width: 30%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.containerCheckBox {
  display: flex;
  height: 32px !important;
  margin-top: 31px;
  align-items: center !important;
  background-color: #e9ecef !important;
  border: solid 1px rgb(216, 215, 215);
  border-radius: 5px;
  padding-left: 15px !important;
}

.chkVendaOrcamento {
  margin-top: 18px;
}

.containerGeralPedidoVenda {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 2rem;
  margin-left: 2rem;
}

.iconFormaPagamento {
  display: flex;
  justify-content: space-between;
}

.btnFormaPagamento {
  cursor: pointer;
}

p {
  margin: 0;
}

.titleHeader {
  margin-left: 16px;
}

.btnsComposition {
  border-radius: 5px;
  width: auto;
  height: 20px;
  border: none;
  color: white;
  font-size: 9px;
  background-color: #a8a8a8;
  transition: ease-in all 0.1s;
}

.radio-input input {
  display: none;
}

.radio-input {
  position: absolute;
  right: 0;
  top: -2px;
  display: flex;
  --container_width: 130px;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  color: #000000;
  width: var(--container_width);
  overflow: hidden;
  border: 1px solid rgba(53, 52, 52, 0.226);
}

.radio-input label {
  width: 100%;
  padding: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-size: 11px;
  margin-bottom: 0px !important;
}

.selection {
  display: none;
  position: absolute;
  height: 100%;
  width: calc(var(--container_width) / 3);
  z-index: 0;
  left: 0;
  top: 0;
  transition: 0.15s ease;
}

.radio-input label:has(input:checked) {
  color: #fff;
}

.radio-input label:has(input:checked) ~ .selection {
  background-color: #3a4750;
  display: inline-block;
}

.radio-input label:nth-child(1):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 0 / 3));
}

.radio-input label:nth-child(2):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 1 / 3));
}

.radio-input label:nth-child(3):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 2 / 3));
}

.btnsComposition:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.353);
  background-color: rgb(116, 116, 116);
  transition: ease-in all 0.1s;
}

.btnReplaceAll {
  border-radius: 5px;
  background-color: #a8a8a8;
  border: none;
  color: white;
  font-size: 12px;
  width: 20px;
  height: 20px;
  padding: 0.2rem;
  cursor: pointer;
  transition: ease-in all 0.1s;
}

.btnReplaceAll:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.353);
  background-color: rgb(116, 116, 116);
  transition: ease-in all 0.1s;
}

.btnsSale {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  height: 30px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;
}

.btnAddMoreItems {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnGenerateBills {
  background-color: transparent;
  border: solid 1px black;
  color: black;
}

.btnCancelSale {
  background-color: #ff3131;
}

.btnReopenSale {
  background-color: #cac64e;
}

.isDisabled {
  background-color: #e1e1e1;
}

.btnTransformInSale {
  font-size: 12px;
}

.btnConfirm {
  background-color: whitesmoke;
  border: solid 1px #0fbf4d;
  color: #0fbf4d;
}

.stockToast {
  position: absolute;
  left: 20px;
  display: flex;
}

.stockToast span {
  color: black;
}

.alertStock {
  font-size: 10px;
  color: #3e66ae;
  padding-left: 10px;
}

.alertStockNegative {
  font-size: 10px;
  color: #ff1d08;
  padding-left: 10px;
}

.inputDesc {
  width: 8.3% !important;
}

.labelInputs p {
  font-weight: 100 !important;
  color: #00395d;
  padding: 0;
}

.iconPlus {
  position: absolute;
  right: 20px;
  top: 0;
}

.switch {
  height: 100%;
  margin: 0;
  font-size: 14px;
  position: relative;
  display: inline-block;
}

.containerItens {
  margin-top: 1.5rem;
  padding: 0 15px;
}

.contentTitleItens {
  align-items: center !important;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #d9d9d9;
  color: #ffffff;
  border-radius: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.4s;
  width: 50px !important;
  height: 90%;
  font-size: 8px;
}

.slider:before {
  position: absolute;
  content: "R$";
  font-size: 10px;
  height: 82%;
  width: 44%;
  left: 6%;
  border-radius: 50px;
  background-color: #00aa62;
  color: white;
  display: grid;
  align-content: center;
  justify-content: center;
  transition: 0.4s;
  font-weight: bold;
}

.slider:after {
  content: "";
  position: absolute;
  z-index: -1;
}

.switch input:checked + .slider {
  background-color: #d9d9d9;
  color: white;
}

.switch input:checked + .slider:before {
  content: "%";
  background-color: #00aa62;
  transform: translateX(100%);
  color: white;
}

.switch input {
  display: none;
}

.contentDataTotals {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.contentFreteTotals input {
  background-color: white !important;
  border: solid 1px #d9d9d9;
}

.dashed {
  border-bottom: 1px dashed #b1aaa5;
  width: 100%;
  margin: 10px 0;
}

.inputTotals {
  display: flex;
  align-items: center;
  gap: 30px;
  color: #666360;
}

.inputTotals input {
  border: none;
  outline: none;
  text-align: end;
  padding-right: 5px;
  background-color: #d9d9d9;
  border-radius: 5px;
  height: 30px;

  border: solid 1px #d9d9d9;
}

.contentTotals {
  width: 370px;
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  font-size: 14px;
  gap: 5px;
}

.itemsSale {
  position: relative;
  display: flex;
  gap: 15px;
  margin-left: 0;
}

.observacoes {
  font-size: 12px;
  color: #d5d3d3;
  font-style: italic;
}

.title {
  font-size: 14px;
  color: #666360;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.contentIconRemoveItems {
  position: absolute;
  right: 60px;
  color: rgb(255, 51, 51);
}

.tooltipDiscount {
  z-index: 3;
  width: 150px;
  padding: 5px;
  font-size: 12px;
  position: absolute;
  color: black;
  border-radius: 5px;
  top: -25px;
  right: -10px;
}

.tooltipItems {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 886px) {
  .contentTableItens {
    width: 100%;
  }
}

@media screen and (max-width: 1300px) {
  .responsiveItems {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .inputItemObs {
    width: 270px;
  }

  .inputItemQnt {
    width: 80px;
  }

  .inputItemVlUnit {
    width: 100px;
  }

  .inputItemVlTot {
    width: 100px;
  }

  .inputItemDesc {
    width: 105px;
  }
}

@media screen and (max-width: 1199px) {
  .amountBills {
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 991px) {
  .fistBill {
    margin-top: 1rem !important;
  }
}

@media screen and (min-width: 769px) {
  .tableProducts tbody {
    display: block;
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 0.5rem;
    padding: 5px !important;
  }
}

@media screen and (max-width: 800px) {
  .containerInputItem {
    width: 72%;
  }

  .inputItemObs {
    width: 60%;
  }

  .inputItemQnt {
    width: 25%;
  }

  .inputItemVlUnit {
    width: 28%;
    margin-top: 0.5rem;
  }

  .inputItemVlTot {
    width: 28%;
    margin-top: 0.5rem;
  }

  .inputItemDesc {
    width: 28%;
    margin-top: 0.5rem;
  }

  .itemToggleDesc {
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .paymentInterval {
    margin-top: 1rem !important;
  }

  .tooltipDiscount {
    right: -85px;
    top: -28px;
  }

  .tableProducts tbody {
    display: block;
    height: 200px;
    margin-top: 0.5rem;
    padding: 5px !important;
  }

  .contentTableItens {
    overflow-x: auto;
  }
}

@media screen and (max-width: 575px) {
  .receiptAccount {
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 475px) {
  .containerInputItem,
  .inputItem {
    width: 100%;
    min-width: 100% !important;
  }
  .inputItemQnt {
    width: 31.6%;
    margin-top: 0.5rem;
  }

  .inputItemVlUnit {
    width: 31.6%;
    margin-top: 0.5rem;
  }

  .inputItemVlTot {
    width: 31.6%;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 400px) {
  .itemsSale {
    margin: 0 !important;
  }

  .btnAddMoreItems {
    width: 100% !important;
  }

  .inputItem {
    width: 100%;
    min-width: 100% !important;
  }

  .inputItemObs {
    width: 100%;
  }

  .inputItemQnt {
    width: 25%;
    margin-top: 0.5rem;
  }

  .inputItemVlUnit {
    width: 33%;
    margin-top: 0.5rem;
  }

  .inputItemVlTot {
    width: 33%;
    margin-top: 0.5rem;
  }

  .inputItemDesc {
    width: 62%;
    margin-top: 0.5rem;
  }

  .itemToggleDesc {
    margin-top: 0.5rem;
  }
}

.contentItens {
  margin-top: 1.5rem !important;
}

.contentButtonsActions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.titleBillsPayments {
  font-size: 20px;
}

.contentBills {
  margin-right: 16px;
}

.inputTextArea {
  width: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: white;
  color: #5c5855;
  border-radius: 5px;
  height: 60px;
  padding-left: 5px;
  border: solid 1px #d9d9d9;
  resize: none;
}

.inputSelectSituation {
  border: solid 1px #d5d3d3;
  padding: 3px;
  border-radius: 0.25rem;
  outline: none;
  color: #5c5855;
  font-size: 12px;
}

.obsGeneral {
  font-size: 14px;
  color: #666360;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.selectPlus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect {
  position: absolute;
  margin-bottom: 0px;
  bottom: 20px;
  left: 12px;
  z-index: 2;
  background-color: #ffff;
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  pointer-events: none;
  transform: translateY(-50%) scale(0.8);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  height: 12px;
  padding: 0 0.2em;
}

.custom-select:disabled {
  color: black;
  background-color: #d5d3d3;
}

.custom-select {
  background: none;
  text-transform: capitalize;
}

.borderInput {
  border-radius: 3px;
  height: 28px;
  font-size: 12px;
  padding: 0 10px;
  border: 1px solid #d5d3d3;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .containerGeralPedidoVenda {
    margin-left: 0rem;
  }

  .containerItens {
    padding: 0 30px;
  }

  .contentTitleItens {
    padding: 0 !important;
  }

  .responsiveItems {
    padding-top: 30px;
    padding-bottom: 10px;
    margin-top: 0rem !important;
  }

  .contentIconRemoveItems {
    right: 10px;
  }

  .contentItens {
    margin-top: 0rem !important;
  }

  .itemsSale {
    margin-left: 0px;
    margin-right: 0px;
  }

  .btnsSale {
    width: 140px;
    margin-top: 15px;
    font-size: 10px;
  }

  .contentTotals {
    font-size: 11px;
  }

  .col-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12,
  .col-xl-12 {
    padding-right: 0px;
    padding-left: 10px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
  }

  .col-md-6 {
    flex: 0 0 32%;
    max-width: 32%;
  }

  .col-md-5 {
    flex: 0 0 32%;
    max-width: 32%;
  }
}

@media (min-width: 576px) and (max-width: 1200px) {
  .col-sm-6 {
    flex: 0 0 32%;
    max-width: 32%;
  }
}

@media screen and (max-width: 700px) {
  .containerGeralPedidoVenda {
    margin-left: 0rem;
  }

  .containerTableItens {
    padding-left: 15px;
  }

  .containerItens {
    padding: 0;
    margin: 0 15px 0 0;
  }

  .contentTitleItens {
    margin-left: 0 !important;
    padding-left: 16px !important;
  }

  .tooltipDiscount {
    right: -15px;
    top: -23px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 0px;
    padding-left: 15px;
  }

  .infinite {
    padding-right: 15px;
  }

  .row {
    gap: 10px;
  }

  .responsiveItems {
    padding-top: 25px;
    padding-bottom: 5px;
    margin-top: 0rem !important;
  }

  .inputTotals {
    gap: 10px;
  }

  .obsGeneral {
    width: 80%;
  }

  .itemsSale {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px !important;
    padding-left: 16px;
    margin-right: 16px;
    width: 100%;
  }

  .contentIconRemoveItems {
    right: -10px;
  }
}

@media screen and (max-width: 547px) {
  .tooltipDiscount {
    right: -85px;
    top: -30px;
  }

  .itemToggleDesc,
  .inputItemObs {
    margin-top: 1rem;
  }
}
</style>
