<template>
  <div class="d-flex home justify-content-center general">
    <Dashaboard />
  </div>
</template>

<script>
import Dashaboard from "../../components/Dashboard/Index-Dashboard.vue";

export default {
  components: {
    Dashaboard,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.general{
  background-image: url(../../assets/back.svg);
  height: 100vh;
}
</style>