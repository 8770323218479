import api from "./axios";

class ServiceEmployees {
  async getEmployeesForSelectBox() {
    const { data } = await api.get("/employees/combobox/fill");
    return data
  }
  
  async findById(id) {
    const { data } = await api.get(`/employees/${id}`);
    return data;
  }
}

export default new ServiceEmployees()