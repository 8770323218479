import api from "./axios";

class ServicePointRecords {
  async save(locale) {
    const { data } = await api.post("/point-records", locale)
    return data;
  }

  async saveByAdm(pointRecord) {
    const { data } = await api.post("/point-records/adm", pointRecord)
    return data;
  }

  async update(pointRegister) {
    await api.put(`/point-records/${pointRegister.id}`, pointRegister);
  }

  async delete(id) {
    await api.delete(`/point-records/${id}`);
  }

  async findById(id) {
    const { data } = await api.get(`/point-records/${id}`);
    return data;
  }

  async findByIdUser(page = 1) {
    const { data } = await api.get(`/point-records/find/by/user-id?page=${page}`);
    return data;
  }

  async findAll(idEmployee, startDate, endDate, page = 1) {
    const { data } = await api.get(`/point-records/find/all?idEmployee=${idEmployee || ''}&startDate=${startDate || ''}&endDate=${endDate || ''}&page=${page}`);
    return data;
  }

  async findDetailsNow() {
    const { data } = await api.get(`/point-records/find/details/now`);
    return data;
  }

  async fillComobobox() {
    const { data } = await api.get(`/point-records/combobox`);
    return data;
  }

  async generateReport(idEmployee, startDate, endDate) {
    const { data } = await api.get(`/point-records/generate/report?idEmployee=${idEmployee  || ''}&startDate=${startDate  || ''}&endDate=${endDate  || ''}`);
    return data;
  }
}

export default new ServicePointRecords()