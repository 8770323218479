import api from "./axios";

class ServiceVehicle {
  async save(vehicle) {
    const { data } = await api.post("/vehicle", vehicle)
    return data.id;
  }

  async update(vehicle) {
    await api.put(`/vehicle/${vehicle.id}`, vehicle);
  }

  async delete(id) {
    await api.delete(`/vehicle/${id}`);
  }

  async findById(id) {
    const { data } = await api.get(`/vehicle/${id}`);
    return data;
  }

  async fillCombobox() {
    const { data } = await api.get(`/vehicle/combobox`);
    return data;
  }
}

export default new ServiceVehicle()