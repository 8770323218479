import api from "./axios";

class ServiceCategoryBill {
  async save(categoryBill) {
    const { data } = await api.post("/bill-category", categoryBill)
    return data.id;
  }

  async update(categoryBill) {
    await api.put(`/bill-category/${categoryBill.id}`, categoryBill);
  }

  async delete(id) {
    await api.delete(`/bill-category/${id}`);
  }

  async findById(id) {
    const { data } = await api.get(`/bill-category/${id}`);
    return data;
  }

  async fillComobobox() {
    const { data } = await api.get(`/bill-category/combobox`);
    return data;
  }
}

export default new ServiceCategoryBill()