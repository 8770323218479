<template>
  <Registers />
</template>

<script>
import Registers from '../../components/Registers/Index-Registers.vue'
export default {
    components:{
        Registers,
    }
}
</script>