import api from './axios'

class ServiceMail {
  async confirmCode(email) {
    const { data } = await api.post("/mail/confirm-code", email)
    return data
  }
}

export default new ServiceMail()

