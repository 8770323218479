<template>
  <div class="containerGeneral">
    <div class="contentTable">
      <div class="tableResponsive">
        <table>
          <thead :class="colorHeadTable">
            <tr>
              <th>Anexo</th>
              <th>Tipo de anexo</th>
              <th>Descrição</th>
            </tr>
          </thead>
          <tbody>
            <tr
              style="position: relative"
              v-for="(dataFile, index) in files"
              :key="dataFile.idList"
            >
              <td class="tdFile">
                <div
                  v-if="dataFile.id"
                  class="contentNameFile"
                  @click="downloadFile(dataFile.urlArquivo)"
                >
                  <p ref="refKeyAws" class="nameFile">
                    {{ files[index].keyAws.split(".")[1] }}
                  </p>
                </div>

                <div class="contentInputFile" v-else>
                  <label
                    :for="`inputFile-${index}`"
                    class="labelInputFile"
                    @dragover="handleDragOver"
                    @drop="handleDrop($event, index)"
                  >
                    <span :class="`textSelectFile ${backColorSelectFile}`">
                      <b-icon-paperclip scale="1.5" />
                      <p class="m-0 p-0">Escolha um arquivo</p>
                    </span>
                    <span :class="`textDropFile  ${colorTextSelectFile}`">{{
                      getNameFile(index)
                    }}</span>
                  </label>
                  <input
                    type="file"
                    :id="`inputFile-${index}`"
                    name="inputFile"
                    @change="handleFileChange($event, index)"
                  />
                </div>
              </td>
              <td class="tdTypeFile">
                <InputSelect
                  valueField="value"
                  textField="value"
                  :items="typeFile"
                  :dataSelect.sync="dataFile.tipoArquivo"
                  :enableIconCreate="false"
                  :arrowLeft="true"
                  ref="refTipoArquivo"
                  :isDisabled="dataFile.id && disabledInput ? true : false"
                  :colorText="colorTypeAttach"
                  :colorBackground="colorBackgroundInputs"
                />
              </td>
              <td>
                <div style="width: 90%">
                  <Input
                    type="text"
                    :dataInput.sync="dataFile.descricao"
                    :labelFixed="true"
                    ref="refDescricao"
                    :isDisabled="dataFile.id && disabledInput ? true : false"
                    :colorText="colorTypeAttach"
                    :colorBackground="colorBackgroundInputs"
                  />
                </div>
              </td>
              <div
                class="contentIconRemoveFile"
                @click="handleRemoveFile(dataFile.idList, dataFile.id)"
              >
                <b-icon-x-circle role="button" scale="1.3" />
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="contentBtnAdd">
      <button class="btnAddNewFile" @click="addMoreFiles">
        <b-icon-plus scale="1.5" />
        <p class="p-0 m-0">Adicionar anexo</p>
      </button>
    </div>
  </div>
</template>

<script>
import Input from "../UI/CustomInputs/Input.vue";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";
import { INITIAL_STATE_FILE } from "../../initialStates/files";
import { tipoArquivo } from "../../common/index";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    Input,
    InputSelect,
  },
  props: {
    disabledInput: {
      type: Boolean,
      default: false,
    },
    colorHeadTable: {
      type: String,
      default: "colorHeadDefault",
    },
    backColorSelectFile: {
      type: String,
    },
    colorTextSelectFile: {
      type: String,
    },
    colorTypeAttach: {
      type: String,
    },
    colorBackgroundInputs: {
      type: String,
    },
  },
  data() {
    return {
      files: [],
      typeFile: null,
    };
  },
  methods: {
    handleDragOver(event) {
      event.preventDefault();
    },

    handleDrop(event, index) {
      event.preventDefault();
      this.files[index].file = event.dataTransfer.files[0];
    },

    getNameFile(index) {
      if (this.files[index].file) {
        return this.files[index].file.name;
      }

      return "Ou arraste e solte aqui";
    },

    handleFileChange(event, index) {
      this.files[index].file = event.target.files[0];
    },

    async handleRemoveFile(idList, id) {
      if (id) {
        this.$emit("removeFile", id);
      }

      this.removeFileInArray(idList);
    },

    downloadFile(url) {
      window.open(url);
    },

    addMoreFiles() {
      const idList = uuidv4();
      const newFile = { ...INITIAL_STATE_FILE, idList };
      this.files.push(newFile);
    },

    removeFileInArray(idListInArray) {
      if (this.files.length > 1) {
        this.files = this.files.filter((file) => file.idList !== idListInArray);

        return;
      }

      const idList = uuidv4();
      const newFile = [{ ...INITIAL_STATE_FILE, idList }];
      this.files = newFile;
    },
    assingValues() {
      this.files = [{ ...INITIAL_STATE_FILE }];
      this.typeFile = tipoArquivo;
    },
  },
  computed: {
    teste() {
      return this.$refs.refTipoArquivo.valueItems;
    },
  },
  created() {
    this.assingValues();
  },
};
</script>

<style scoped>
.containerGeneral {
  padding: 1rem 1rem;
}

.contentTable {
  width: 100%;
}

.tableResponsive {
  max-height: 250px;
  overflow-y: auto;
  padding: 0 1rem;
}

.btnAddNewFile {
  display: flex;
  align-items: center;
  gap: 3px;
  border-radius: 5px;
  background-color: #00395d;
  border: none;
  color: white;
  font-size: 10px;
  border: solid 1px rgba(63, 143, 178, 0.975);
  color: rgba(63, 143, 178, 0.975);
  background-color: whitesmoke;
  padding: 3px 5px;
}

.btnAddNewFile p {
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

/* TABLE */

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 10px;
}

th {
  padding: 5px 15px;
  font-size: 11px;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.tdFile {
  width: 120px;
  padding-left: 0px;
}

.tdTypeFile {
  width: 200px;
}

.tdAddNewFile {
  padding: 0px;
}

td {
  padding: 5px 10px;
}

.colorHeadDefault {
  background-color: #c1c1c156;
}

.colorHeadWhite {
  background-color: whitesmoke;
}

/* INPUT FILE */

input[type="file"] {
  display: none;
}

.labelInputFile {
  width: 300px;
  display: flex;
  align-items: center;
  gap: 5px;
  border: dashed 1px #c1c1c1;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
}

.textSelectFile {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0;
  background-color: #c1c1c156;
  border-radius: 5px;
  font-weight: bold;
  font-size: 11px;
  padding: 5.75px 9px;
}

.backColorSelectFile {
  background-color: white;
}

.colorTextSelectFile {
  color: white;
}

.textSelectFile p {
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.textDropFile {
  max-width: 150px !important;
  overflow: hidden;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contentInputFile {
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
}

.contentNameFile {
  cursor: pointer;
}

.nameFile {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
  margin: 0px;
  color: rgba(63, 143, 178, 0.975);
  font-weight: bold;
}

.contentIconRemoveFile {
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 4px;
  color: rgb(255, 51, 51);
}

.contentBtnAdd {
  padding: 0 0 0 1rem;
}

@media screen and (max-width: 991px) {
  .contentTable {
    overflow-x: auto;
  }

  .containerGeneral {
    width: 100%;
  }

  .contentBtnAdd {
    margin-top: 1rem;
  }

  table {
    width: 770px !important;
  }
}
</style>