import api from "./axios";

class ServiceWebMania {
  async sendCertificado(certificateBase64) {
    await api.post(`/webmania/send/certificate`, certificateBase64);
  }

  async findCertificateExpirationTime() {
    const { data } = await api.get(`/webmania/find/certificate/expiration-time`);
    return data
  }
}

export default new ServiceWebMania()