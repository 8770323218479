<template>
  <Login />
</template>

<script>
import Login from "./Login.vue";
export default {
  components: {
    Login,
  },
};
</script>