<template>
  <b-modal
    size="lg"
    id="modalSendMessage"
    hide-header
    hide-footer
    @hidden="handleClearOrCloseModal"
  >
    <section class="generalContainer">
      <span class="title">
        <b-icon-card-text />
        <p>Realize sua solicitação</p>
      </span>

      <div class="rowContainer">
        <div class="rowCustomMessages">
          <span style="width: 100%">
            <Input
              type="date"
              label="Data"
              :labelFixed="true"
              :dataInput.sync="payload.solicitation.data"
              :isDisabled="true"
            />
          </span>

          <span style="width: 100%">
            <Input
              type="text"
              label="Assunto"
              :labelFixed="true"
              :dataInput.sync="payload.solicitation.assunto"
            />
          </span>

          <span style="width: 100%">
            <Input
              type="text"
              label="Solicitante"
              :labelFixed="true"
              :dataInput.sync="payload.solicitation.solicitante"
            />
          </span>

          <span style="width: 100%">
            <InputSelect
              label="Departamento"
              :enableIconCreate="false"
              colorBackground="#ffffff"
              :items="departaments"
              valueField="value"
              textField="text"
              :dataSelect.sync="payload.solicitation.departamento"
            />
          </span>
        </div>

        <div class="rowCustomMessages">
          <span style="width: 100%">
            <textarea
              class="inputTextArea"
              rows="3"
              max-rows="6"
              v-model="payload.accountingMessage.message"
            />
          </span>
        </div>

        <div class="rowCustomMessages">
          <span
            style="
              width: 350px;
              border: 1px dashed rgb(193 193 193);
              border-radius: 1rem;
            "
          >
            <AttachUnicFile ref="attachedUnicFile" />
          </span>
        </div>

        <span style="width: 120px">
          <button size="sm" class="btnGeneral" variant="none" @click="send">
            <p>Enviar</p>
          </button>
        </span>
      </div>
    </section>
  </b-modal>
</template>

<script>
import moment from "moment";
import Input from "../UI/CustomInputs/Input.vue";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";
import AttachUnicFile from "../AttachFiles/AttachUnicFile.vue";
import ServiceAccountingSolicitation from "../../services/serviceAccountingSolicitations";
import { AccountingSolicitationsStatus } from "../../utils/enums/AccountingSolicitationsStatus";
import { departaments } from "../../common/index";

export default {
  props: {
    idAccounting: {
      type: String,
    },
  },
  components: {
    Input,
    AttachUnicFile,
    InputSelect,
  },
  data() {
    return {
      payload: {
        solicitation: {
          id_contabilidade: null,
          data: moment().format("YYYY-MM-DD"),
          assunto: null,
          solicitante: null,
          departamento: null,
          status: null,
        },
        accountingMessage: {
          id_solicitacao: null,
          messageFiles: [],
          message: null,
        },
      },
      departaments: departaments,
    };
  },
  methods: {
    async send() {
      try {
        const formData = this.handlePayloadBeforeSend();
        await ServiceAccountingSolicitation.saveSolicitationAndMessage(
          formData
        );

        this.$toast.open({
          message: "Solicitação registrada com sucesso!",
          type: "success",
        });
        this.$emit("registeredNewSolicitation");
        this.handleClearOrCloseModal();
      } catch (error) {
        console.log(error);
        this.$toast.open({
          message:
            error?.response?.data?.erros ??
            error?.response?.data?.message ??
            "Ocorreu um erro ao registrar a mensagem",
          type: "info",
        });
      }
    },

    handlePayloadBeforeSend() {
      this.payload.solicitation.id_contabilidade = this.idAccounting;
      this.payload.solicitation.status =
        AccountingSolicitationsStatus.em_aberto;

      const formData = new FormData();
      const files = this.getFiles.file;
      const messageFiles = JSON.parse(JSON.stringify(this.getFiles));
      Reflect.deleteProperty(messageFiles, "file");

      files.forEach((file) => {
        if (this.getFiles.tipoArquivo && this.getFiles.file) {
          this.payload.accountingMessage.messageFiles.push(messageFiles);
        }

        formData.append(`files`, file);
      });

      formData.append(`payload`, JSON.stringify(this.payload));

      return formData;
    },

    handleClearOrCloseModal() {
      this.clearPayload();
      this.closeModal();
    },

    clearPayload() {
      this.payload = {
        solicitation: {
          id_contabilidade: null,
          data: null,
          assunto: null,
          solicitante: null,
          departamento: null,
          status: "Em aberto",
        },
        accountingMessage: {
          id_solicitacao: null,
          messageFiles: [],
          message: null,
        },
      };
    },
    closeModal() {
      this.$bvModal.hide("modalSendMessage");
    },
  },
  computed: {
    getFiles() {
      return this.$refs.attachedUnicFile.file;
    },
  },
};
</script>

<style scoped>
.generalContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgb(95, 95, 95);
  margin-bottom: 1rem;
}

.title p {
  margin: 0;
}

.customCard {
  width: 100%;
  padding: 1rem;
  background-color: red;
}

.rowContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 5px;
}

.rowCustomMessages {
  display: flex;
  gap: 20px;
  padding: 15px 0;
}

.inputTextArea {
  width: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: white;
  color: #5c5855;
  border-radius: 5px;
  height: 100px;
  padding-left: 5px;
  border: solid 1px #d9d9d9;
  resize: none;
  padding: 0.3rem;
  font-size: 12px;
}

.contentBtnSendMessage {
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.5rem;
}

.btnGeneral {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 12px;
  font-weight: normal;
  border: none;
  outline: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.179) !important;
  background-color: rgb(72, 184, 101);
  border-radius: 5px;
  transition: ease-in all 0.2s;
  cursor: pointer;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}

.btnGeneral p {
  margin: 0;
  font-weight: normal;
  color: white;
}

.btnGeneral:hover {
  background-color: rgb(59, 149, 83) !important;
  color: white;
}

span {
  cursor: default;
}
</style>