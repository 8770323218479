<template>
  <div>
    <b-modal
      id="modal-Agendamento"
      class="ScheduleModal"
      hide-header
      size="md"
    >
    <span class="titleNew"> <b-icon-plus/> Novo Agendamento</span>
      <template #modal-footer>
        <b-button class="mr-4" size="sm" style="font-size: 12px; width: 140px" variant="dark" @click="saveSchedule"
          >Salvar</b-button>
        <b-button
          style=" background: #bdbdbd;font-size: 12px;width: 140px"
          size="sm"
          variant="light"
          @click="clear"
          >Limpar </b-button>
      </template>
      <div class="bodyScheduleContent">
        <div class="selectPlus">
          <label class="fixedSelect2">Tipo do Agendamento</label>
          <b-form-select
            class="borderInput shadow-none"
            value-field="value"
            text-field="text"
            :options="options"
            v-model="teste.tipo"
          >
          </b-form-select>
        </div>

        <div class="input-group">
          <input
            type="datetime-local"
            class="input borderInput"
            v-model="teste.dataAgenda"
          />
          <label class="fixedSelect2"> Data Do Evento </label>
        </div>
        <div class="input-group">
          <input required class="input borderInput" v-model="teste.descricao" />
          <label class="fixedSelect2"> Descrição do Agendamento </label>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import api from "../../services/axios";
export default {
  props: {
    date: {
      type: String,
    },
  },
  data() {
    return {
      teste: {
        descricao: "",
        tipo: "",
        dataAgenda: "",
      },
      options: [
        { value: "Lembrete", text: "Lembrete" },
        { value: "Reunião", text: "Reunião" },
        { value: "aniversario", text: "Aniversario" },
        { value: "pagamento", text: "Pagamento" },
        { value: "recebimento", text: "Recebimento" },
        { value: "Outro", text: "Outro" },
      ],
      idSchedule: "",
    };
  },
  mounted() {
    this.teste.dataAgenda = this.date;
  },
  methods: {
    async saveSchedule() {
      try {
        const { data } = await api.post("/schedules", this.teste);
        this.idSchedule = data.id;
        this.$emit("dataSchedule", { id: this.idSchedule, ...this.teste });
        this.clear();
        this.$bvModal.hide("modal-Agendamento");
        this.$toast.open({
          message: "Agendamento salvo com Sucesso",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$toast.open({
          message: "Não foi possível salvar agendamento",
          type: "warning",
        });
      }
    },
    clear() {
      this.teste = {};
      this.idSchedule = "";
    },
  },
};
</script>
<style scoped>
.ScheduleModal {
  font-size: 13px;
}

.titleNew{
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}

.bodyScheduleContent{
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.input {
  font-size: 12px !important;
  width: 100%;
}

.selectPlus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 8.9px;
  bottom: 22px;
  left: 11px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: white;
  font-weight: 600;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 11px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: none;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.borderInput {
  font-size: 14px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #505050;
}

.user-label {
  position: absolute;
  left: 10px;
  font-size: 11px;
  bottom: 5px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}
</style>
