<template>
  <div class="demo-app">
    <ModalAgendamento @dataSchedule="dataSchedule = $event" :date="date" />
    <ModalVisualizar :dataReadSchedule="dataReadSchedule" />
    <FullCalendar class="demo-app-calendar" :options="calendarOptions">
    </FullCalendar>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ModalAgendamento from "./ModalAgendamento.vue";
import ModalVisualizar from "./ModalVisualizar.vue";
import moment from "moment";
import api from "../../services/axios";
export default {
  components: {
    FullCalendar,
    ModalAgendamento,
    ModalVisualizar,
  },

  data: function () {
    return {
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        buttonText: {
          today: "Hoje",
          month: "Mês",
          week: "Semana",
          day: "Dia",
        },
        views: {
          timeGridWeek: {
            titleFormat: { year: "numeric", month: "long", day: "numeric" },
          },
        },
        nowIndicator: true,
        slotMinTime: "08:00:00",
        hiddenDays: [0],
        slotMaxTime: "18:00:00",
        allDaySlot: false,
        initialView: "dayGridMonth",
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        events: [],
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        locale: "pt-br",
        eventColor: "#3a4750",
        eventBorderColor: "#262e34",
      },
      currentEvents: [],
      dataSchedule: {},
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
      dataReadSchedule: "",
    };
  },
  mounted() {
    this.readSchedule();
  },
  methods: {
    async readSchedule() {
      try {
        const { data } = await api.get("/schedules");
        data.map((resultado) => {
          this.calendarOptions.events.push(this.CreateObject(resultado));
        });
      } catch (error) {
        console.log(error);
      }
    },
    CreateObject(dados) {
      return {
        id: dados.id,
        title: dados.tipo,
        start: moment(dados.dataAgenda).format("YYYY-MM-DD HH:mm:ss"),
        description: dados.descricao,
      };
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },

    handleDateSelect() {
      this.$bvModal.show("modal-Agendamento");
    },

    handleEventClick(clickInfo) {
      this.dataReadSchedule = clickInfo.event.id;
      this.$bvModal.show("modal-Visualizar");
    },

    handleEvents(events) {
      this.currentEvents = events;
    },
  },
  watch: {
    dataSchedule() {
      this.calendarOptions.events.push(this.CreateObject(this.dataSchedule));
    },
  },
};
</script>


<style scoped>
.demo-app {
  display: flex;
  height: calc(100% - 50px);
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  background-color: #e9e9e9;
}
.demo-app-calendar {
  width: 65%;
  height: 90vh;
  max-width: 1100px;
  margin: 0 auto;
  padding: 1em;
  background: #ffffff91;
  color: black !important;
  text-transform: capitalize !important;
}
.fc,
.fc *,
.fc *:before,
.fc *:after {
  box-sizing: border-box;
  font-size: 13px;
}

@media screen and (max-width: 700px) {
  .demo-app-calendar {
    width: 100%;
    height: 90vh;
    margin: 0 auto;
    padding: 5px;
  }
  .fc,
  .fc *,
  .fc *:before,
  .fc *:after {
    font-size: 11px;
  }

  .fc .fc-toolbar-title {
    font-size: 13px;
    margin: 0;
    text-align: center;
  }

  .fc .fc-toolbar {
    display: flex;
    align-items: flex-end;
    flex-direction: column-reverse;
    justify-content: center;
  }
}
</style>
