<template>
  <PointRecordsVue />
</template>

<script>
import PointRecordsVue from "../../components/PointRecords/PointRecords.vue";

export default {
  components: {
    PointRecordsVue,
  },
};
</script>

<style>
</style>