import api from './axios'

class ServiceShippingCompany {
  async save(shippingCompany) {
    const { data } = await api.post("/shipping-company", shippingCompany)
    return data
  }

  async update(shippingCompany) {
    console.log(shippingCompany)
    const { data } = await api.put(`/shipping-company/${shippingCompany.id}`, shippingCompany)
    return data
  }

  async delete(id) {
    const { data } = await api.delete(`/shipping-company/${id}`)
    return data
  }

  async count() {
    const { data } = await api.get("/shipping-company")
    return data
  }

  async findById(id) {
    const { data } = await api.get(`/shipping-company/${id}`)
    return data
  }

  async fillCombobox() {
    const { data } = await api.get("/shipping-company/fill/combobox")
    return data
  }

  async search(text, type, page) {
    const { data } = await api.get(`/shipping-company/search/per/page?q=${text}&type=${type}&page=${page}`)
    return data
  }
}

export default new ServiceShippingCompany
