<template>
  <b-modal
    size="lg"
    class="modalShippingCompany"
    id="modalShippingCompany"
    hide-header
    hide-footer
  >
    <span class="actionClose"> <b-icon-x scale="1.3" /> </span>

    <Tabs
      :tabs="tabs"
      :tabSelected="tabSelected"
      :actionToAlterTab="editShippingCompany"
    >
      <template v-slot:shippingCompany>
        <div class="summaryForm mt-3">
          <ShippingCompany
            :enableHeader="false"
            @newRegistration="isNewRegistration = $event"
            :shippingCompanyFound="shippingCompanyFound"
          />
        </div>
      </template>

      <template v-slot:searchCompany>
        <div class="summaryForm marginFormSearch">
          <SearchCompany
            :enableHeader="false"
            :isForSearch="isNewRegistration"
            @shippingCompanyFound="alterTab($event)"
          />
        </div>
      </template>
    </Tabs>
  </b-modal>
</template>

<script>
import ShippingCompany from "../ShippingCompany/ShippingCompany.vue";
import SearchCompany from "../ShippingCompany/SearchCompany.vue";
import Tabs from "../UI/Tabs/Tab.vue";
export default {
  props: {},
  data() {
    return {
      tabs: [
        { name: "shippingCompany", text: "Cadastrar" },
        { name: "searchCompany", text: "Pesquisar" },
      ],
      isNewRegistration: false,
      shippingCompanyFound: {},
      tabSelected: 1,
      editShippingCompany: false,
    };
  },
  components: {
    ShippingCompany,
    SearchCompany,
    Tabs,
  },
  methods: {
    alterTab(event) {
      this.shippingCompanyFound = event;
      this.tabSelectedName = "shippingCompany";
      this.editShippingCompany = !this.editShippingCompany;
      this.tabSelected = 0;
    },
  },

  computed: {},
};
</script>

<style scoped>
.summaryForm {
  display: flex;
  flex-direction: column;
  color: #00486e;
}

.summaryForm label {
  margin-bottom: 0px;
  font-weight: bold;
}

.btnBack {
  border-radius: 5px;
  background-color: #00395d;
  border: none;
  margin-top: 30px;
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  width: 150px;
  font-size: 18px;
  margin-top: 0;
}

.modalShippingCompany {
  position: relative;
}

.actionClose {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
  color: rgb(255, 153, 153);
  cursor: pointer;
  border: 1px solid rgb(238, 238, 238);
  padding: 2px 3px;
  border-radius: 4px;
}

.actionClose:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}

.marginFormSearch {
  margin-top: -15px;
}
</style>