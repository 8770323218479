<template>
  <NotFoundPlan />
</template>

<script>
import NotFoundPlan from "../../../components/Plan/NotFoundPlan/NotFoundPlan.vue";
export default {
  components: {
    NotFoundPlan,
  },
};
</script>

<style>
</style>