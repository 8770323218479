<template>
  <FinancialMovements />
</template>

<script>
import FinancialMovements from "./FinancialMovements.vue";
export default {
  components: {
    FinancialMovements,
  },
};
</script>