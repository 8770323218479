import api from "./axios";

class ServiceAccountingSolicitations {
  async save(accountingSolicitation) {
    const { data } = await api.post('/accounting-solicitations', accountingSolicitation)
    return data
  }

  async update(accountingSolicitation, id) {
    await api.put(`/accounting-solicitations/${id}`, accountingSolicitation)
  }

  async findById(id) {
    const { data } = await api.get(`/accounting-solicitations/${id}`)
    return data
  }

  async findPerPagination(page = 1) {
    const { data } = await api.get(`/accounting-solicitations/find/per/pagination?page=${page}`)
    return data
  }

  async delete(id) {
    const { data } = await api.delete(`/accounting-solicitations/${id}`)
    return data
  }

  async saveSolicitationAndMessage(payload) {
    const { data } = await api.post('/accounting-solicitations/solicitation/and/message', payload)
    return data
  }
}

export default new ServiceAccountingSolicitations()