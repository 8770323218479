import api from "./axios";

class ServiceAccounting {
  async save(accounting) {
    const { data } = await api.post('/accounting', accounting)
    return data
  }

  async update(accounting, id) {
    await api.put(`/accounting/${id}`, accounting)
  }

  async findById(id) {
    const { data } = await api.get(`/accounting/${id}`)
    return data
  }

  async findActivated() {
    const { data } = await api.get(`/accounting`)
    return data
  }

  async delete(id) {
    const { data } = await api.delete(`/accounting/${id}`)
    return data
  }
}

export default new ServiceAccounting()