<template>
  <div style="font-size: 13px">
    <b-modal
      id="modalTaxInformation"
      size="xl"
      ok-title="Salvar"
      hide-header
      centered
    >
      <button class="closeModal" @click="$bvModal.hide('modalTaxInformation')">
        <b-icon-x scale="1.3"></b-icon-x>
      </button>
      <template #modal-footer>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex">
          <div class="contentBtnRef">
            <div style="display: flex; gap: 40px">
              <b-button
                style="
                  padding: 5px 30px;
                  border: none !important;
                  background-color: #3e66ae !important;
                "
                size="sm"
                @click="activeISSQN"
                >Habilitar ISSQN</b-button
              >
              <b-button
                style="
                  padding: 5px 30px;
                  border: none !important;
                  color: black;
                  background-color: #cbcbcb !important;
                "
                size="sm"
                @click="findAllRefs"
                v-b-modal.modal-center
                >Refs cadastradas</b-button
              >
            </div>
            <div style="display: flex; gap: 40px">
              <b-button
                style="
                  padding: 5px 30px;
                  border: none !important;
                  color: black;
                  background-color: #cbcbcb !important;
                "
                size="sm"
                @click="clear"
                >Novo REF</b-button
              >
              <b-button
                style="
                  padding: 5px 30px;
                  border: none !important;
                  background-color: #00395d !important;
                "
                @click="handleSaveOrUpdate"
                size="sm"
                >Salvar REF
              </b-button>
            </div>
          </div>
        </div>
      </template>
      <span class="titleTax">Informações Fiscais</span>
      <div class="heigthModal">
        <Tabs :tabs="tabs" :tabSelected="tabSelected">
          <template v-slot:geral>
            <b-card class="scrollIssqn">
              <b-form-group
                id="input-group-1"
                label="Descrição"
                label-for="textarea"
                size="sm"
                class="styleLabel"
              >
                <b-form-textarea
                  id="textarea"
                  placeholder="Descreva a classificação desse imposto para melhor gerenciamento. Ex: Classe de impostos para Saída de produtos de fabricação própria."
                  rows="6"
                  no-resize
                  v-model="infoFiscal.descricao"
                ></b-form-textarea>
              </b-form-group>
            </b-card>
          </template>

          <template v-slot:ICMS>
            <b-card class="scrollIssqn">
              <b-form-input
                v-model="infoFiscal.id"
                text-field="text"
                type="text"
                hidden
              ></b-form-input>
              <div class="rowContent">
                <div class="selectPlus">
                  <label class="fixedSelect2">Tipo de Tributação</label>
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.icms[0].tipo_tributacao"
                    :options="taxForSelectBoxes.icms.tipo_tributacao"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
                <div class="selectPlus">
                  <label class="fixedSelect2">Cenário</label>
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.icms[0].cenario"
                    :options="taxForSelectBoxes.icms.cenario"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
                <div class="selectPlus">
                  <label class="fixedSelect2">Tipo Pessoa</label>
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.icms[0].tipo_pessoa"
                    :options="taxForSelectBoxes.icms.tipo_pessoa"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
              </div>
              <div class="rowContentChk">
                <b-form-group
                  :hidden="infoFiscal.icms[0].tipo_pessoa !== 'juridica'"
                >
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="infoFiscal.icms[0].nao_contribuinte"
                    name="checkbox-1"
                    style="font-size: 12px; width: 100%"
                  >
                    Marque essa opção caso a configuração deste cenário seja
                    específico para Pessoa Jurídica não contribuinte do ICMS ou
                    Consumidor final.
                  </b-form-checkbox>
                </b-form-group>
              </div>
              <div class="rowContent">
                <div class="selectPlus">
                  <label class="fixedSelect2">Situação tributária</label>
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.icms[0].situacao_tributaria"
                    :options="taxForSelectBoxes.icms.situacao_tributaria"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
                <div class="input-group">
                  <input
                    required
                    class="input borderInput"
                    v-model="infoFiscal.icms[0].codigo_cfop"
                  />
                  <label class="user-label"> Código CFOP </label>
                </div>
              </div>
              <div class="rowContent">
                <div
                  class="input-group"
                  v-if="
                    infoFiscal.icms[0].situacao_tributaria &&
                    objectIcms[infoFiscal.icms[0].situacao_tributaria]
                      .aliquota_credito
                  "
                >
                  <input
                    required
                    class="input borderInput"
                    v-model="infoFiscal.icms[0].aliquota_credito"
                  />
                  <label class="user-label">
                    Alíquota aplicável de cálculo de crédito %
                  </label>
                </div>
                <div
                  class="input-group"
                  v-if="
                    infoFiscal.icms[0].situacao_tributaria &&
                    objectIcms[infoFiscal.icms[0].situacao_tributaria]
                      .aliquota_diferimento
                  "
                >
                  <input
                    required
                    class="input borderInput"
                    v-model="infoFiscal.icms[0].aliquota_diferimento"
                  />
                  <label class="user-label">
                    Alíquota do diferimento ICMS %
                  </label>
                </div>
                <div
                  class="input-group"
                  v-if="
                    infoFiscal.icms[0].situacao_tributaria &&
                    objectIcms[infoFiscal.icms[0].situacao_tributaria]
                      .aliquota_diferimento_fcp
                  "
                >
                  <input
                    required
                    class="input borderInput"
                    v-model="infoFiscal.icms[0].aliquota_diferimento_fcp"
                  />
                  <label class="user-label">
                    Alíquota do diferimento ICMS (FCP) %
                  </label>
                </div>
              </div>
              <div class="rowContent">
                <div
                  class="input-group"
                  v-if="
                    infoFiscal.icms[0].situacao_tributaria &&
                    objectIcms[infoFiscal.icms[0].situacao_tributaria]
                      .aliquota_mva
                  "
                >
                  <input
                    required
                    class="input borderInput"
                    v-model="infoFiscal.icms[0].aliquota_mva[0].aliquota"
                  />
                  <label class="user-label"> Alíquota MVA: % </label>
                </div>
                <div
                  class="selectPlus"
                  v-if="
                    infoFiscal.icms[0].situacao_tributaria &&
                    objectIcms[infoFiscal.icms[0].situacao_tributaria]
                      .aliquota_mva
                  "
                >
                  <label class="fixedSelect2">Aliquota MVA Estado</label>
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.icms[0].aliquota_mva[0].estado"
                    :options="taxForSelectBoxes.icms.estados"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
                <div
                  class="input-group"
                  v-if="
                    infoFiscal.icms[0].situacao_tributaria &&
                    objectIcms[infoFiscal.icms[0].situacao_tributaria]
                      .aliquota_reducao
                  "
                >
                  <input
                    required
                    class="input borderInput"
                    v-model="infoFiscal.icms[0].aliquota_reducao"
                  />
                  <label class="user-label">
                    Alíquota da redução da base de cálculo ICMS %
                  </label>
                </div>
              </div>
              <div class="rowContent">
                <div
                  class="selectPlus"
                  v-if="
                    infoFiscal.icms[0].situacao_tributaria &&
                    objectIcms[infoFiscal.icms[0].situacao_tributaria]
                      .motivo_desoneracao
                  "
                >
                  <label class="fixedSelect2"
                    >Motivo da Desoneração do ICMS</label
                  >
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.icms[0].motivo_desoneracao"
                    :options="taxForSelectBoxes.icms.motivo_desoneracao"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
                <div
                  class="input-group"
                  v-if="
                    infoFiscal.icms[0].situacao_tributaria &&
                    objectIcms[infoFiscal.icms[0].situacao_tributaria]
                      .aliquota_reducao_st
                  "
                >
                  <input
                    required
                    class="input borderInput"
                    v-model="infoFiscal.icms[0].aliquota_reducao_st"
                  />
                  <label class="user-label">
                    Alíquota da redução da base de cálculo ICMS-ST %
                  </label>
                </div>
                <div
                  class="selectPlus"
                  v-if="
                    infoFiscal.icms[0].situacao_tributaria &&
                    objectIcms[infoFiscal.icms[0].situacao_tributaria]
                      .motivo_desoneracao_st
                  "
                >
                  <label class="fixedSelect2"
                    >Motivo da Desoneração do ICMS-ST</label
                  >
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.icms[0].motivo_desoneracao_st"
                    :options="taxForSelectBoxes.icms.motivo_desoneracao_st"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
              </div>
              <div class="rowContent">
                <b-form-group
                  class="col-sm-12 col-md-12 col-lg-9 col-xl-9 mt-4"
                  v-if="
                    infoFiscal.icms[0].situacao_tributaria &&
                    objectIcms[infoFiscal.icms[0].situacao_tributaria]
                      .beneficio_fiscal
                  "
                >
                  <b-form-checkbox
                    id="checkbox-2"
                    v-model="enableBeneficioFiscal"
                    name="checkbox-1"
                  >
                    Habilitar Benefício fiscal
                  </b-form-checkbox>
                </b-form-group>
                <div class="selectPlus" v-if="enableBeneficioFiscal">
                  <label class="fixedSelect2">Benefício fiscal: Estado</label>
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.icms[0].beneficio_fiscal[0].estado"
                    :options="taxForSelectBoxes.icms.beneficioFiscalEstados"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
                <div class="input-group" v-if="enableBeneficioFiscal">
                  <input
                    required
                    class="input borderInput"
                    v-model="infoFiscal.icms[0].beneficio_fiscal[0].codigo"
                  />
                  <label class="user-label"> Código do Benefício Fiscal </label>
                </div>
              </div>
            </b-card>
          </template>
          <template v-slot:IPI>
            <b-card class="scrollIssqn">
              <b-row class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="rowContent">
                  <div class="selectPlus">
                    <label class="fixedSelect2">Tipo pessoa</label>
                    <b-form-select
                      class="borderInput shadow-none"
                      v-model="infoFiscal.ipi[0].tipo_pessoa"
                      :options="taxForSelectBoxes.ipi.tipo_pessoa"
                      value-field="value"
                      text-field="text"
                    >
                    </b-form-select>
                  </div>

                  <div class="selectPlus">
                    <label class="fixedSelect2">Cenário</label>
                    <b-form-select
                      class="borderInput shadow-none"
                      v-model="infoFiscal.ipi[0].cenario"
                      :options="taxForSelectBoxes.ipi.cenario"
                      value-field="value"
                      text-field="text"
                    >
                    </b-form-select>
                  </div>

                  <div class="selectPlus">
                    <label class="fixedSelect2">Situação tributária</label>
                    <b-form-select
                      class="borderInput shadow-none"
                      v-model="infoFiscal.ipi[0].situacao_tributaria"
                      :options="taxForSelectBoxes.ipi.situacao_tributaria"
                      value-field="value"
                      text-field="text"
                    >
                    </b-form-select>
                  </div>
                </div>
                <div class="rowContent">
                  <div class="input-group">
                    <input
                      required
                      class="input borderInput"
                      v-model="infoFiscal.ipi[0].codigo_enquadramento"
                    />
                    <label class="user-label"> Código de enquadramento </label>
                  </div>
                  <div class="input-group">
                    <input
                      required
                      class="input borderInput"
                      v-model="infoFiscal.ipi[0].aliquota"
                    />
                    <label class="user-label">Alíquota %</label>
                  </div>
                </div>
                <div class="rowContent">
                  <p
                    style="
                      color: red;
                      margin-bottom: 0 !important;
                      font-size: 12px;
                    "
                  >
                    ** Caso não possua um código de enquadramento, mantenha o
                    valor padrão 999.
                  </p>
                </div>
              </b-row>
            </b-card>
          </template>

          <template v-slot:PIS>
            <b-card class="scrollIssqn">
              <div class="rowContent">
                <div class="selectPlus">
                  <label class="fixedSelect2">Tipo pessoa</label>
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.pis[0].tipo_pessoa"
                    :options="taxForSelectBoxes.pis.tipo_pessoa"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
                <div class="selectPlus">
                  <label class="fixedSelect2">Cenário</label>
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.pis[0].cenario"
                    :options="taxForSelectBoxes.pis.cenario"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
                <div class="selectPlus">
                  <label class="fixedSelect2">Situação tributária</label>
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.pis[0].situacao_tributaria"
                    :options="taxForSelectBoxes.pis.situacao_tributaria"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
              </div>
              <div class="rowContent">
                <div class="input-group">
                  <input
                    required
                    class="input borderInput"
                    v-model="infoFiscal.pis[0].aliquota"
                  />
                  <label class="user-label"> Alíquota % </label>
                </div>
              </div>
            </b-card>
          </template>
          <template v-slot:COFINS>
            <b-card class="scrollIssqn">
              <div class="rowContent">
                <div class="selectPlus">
                  <label class="fixedSelect2">Tipo pessoa</label>
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.cofins[0].tipo_pessoa"
                    :options="taxForSelectBoxes.cofins.tipo_pessoa"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
                <div class="selectPlus">
                  <label class="fixedSelect2">Cenário</label>
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.cofins[0].cenario"
                    :options="taxForSelectBoxes.cofins.cenario"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
                <div class="selectPlus">
                  <label class="fixedSelect2">Situação tributária</label>
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.cofins[0].situacao_tributaria"
                    :options="taxForSelectBoxes.cofins.situacao_tributaria"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
              </div>
              <div class="rowContent">
                <div class="input-group">
                  <input
                    required
                    class="input borderInput"
                    v-model="infoFiscal.cofins[0].aliquota"
                  />
                  <label class="user-label"> Alíquota % </label>
                </div>
              </div>
            </b-card>
          </template>

          <template v-if="this.activeCtn" v-slot:ISSQN>
            <b-card class="scrollIssqn">
              <div class="rowContent">
                <div class="selectPlus">
                  <label class="fixedSelect2">Tipo pessoa</label>
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.issqn[0].tipo_pessoa"
                    :options="taxForSelectBoxes.issqn.tipo_pessoa"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
                <div class="selectPlus">
                  <label class="fixedSelect2">Cenário</label>
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.issqn[0].cenario"
                    :options="taxForSelectBoxes.issqn.cenario"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
                <div class="selectPlus">
                  <label class="fixedSelect2"
                    >Indicador de incentivo Fiscal</label
                  >
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.issqn[0].incentivo_fiscal"
                    :options="taxForSelectBoxes.issqn.incentivo_fiscal"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
              </div>
              <div class="rowContent">
                <div class="input-group">
                  <input
                    required
                    class="input borderInput"
                    v-model="infoFiscal.issqn[0].codigo_cfop"
                  />
                  <label class="user-label"> Código CFOP </label>
                </div>

                <div class="input-group">
                  <input
                    required
                    class="input borderInput"
                    v-model="infoFiscal.issqn[0].item_servico"
                  />
                  <label class="user-label"> Item da lista de serviços </label>
                </div>
                <div class="input-group">
                  <input
                    required
                    class="input borderInput"
                    v-model="infoFiscal.issqn[0].aliquota"
                  />
                  <label class="user-label"> Alíquota do ISSQN %</label>
                </div>
              </div>
              <div class="rowContent">
                <div class="selectPlus">
                  <label class="fixedSelect2"
                    >Indicador da exigibilidade do ISS</label
                  >
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="infoFiscal.issqn[0].exigibilidade"
                    :options="taxForSelectBoxes.issqn.exigibilidade"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </div>
              </div>
            </b-card>
          </template>
          <template v-slot:info>
            <b-card class="scrollIssqn">
              <b-form-group
                id="input-group-1"
                label="Informações ao Fisco"
                label-for="textarea"
                size="sm"
                class="styleLabel"
              >
                <b-form-textarea
                  id="textarea"
                  rows="3"
                  no-resize
                  v-model="infoFiscal.informacoes_fisco"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label="Informações Complementares ao Consumidor"
                label-for="textarea"
                size="sm"
                class="styleLabel"
              >
                <b-form-textarea
                  id="textarea"
                  rows="3"
                  no-resize
                  v-model="infoFiscal.informacoes_complementares"
                ></b-form-textarea>
              </b-form-group>
            </b-card>
          </template>
        </Tabs>
        <b-modal
          id="modal-center"
          hide-footer
          hide-header
          centered
          title="refs"
        >
          <table style="width: 100%">
            <thead
              style="
                color: whitesmoke;
                background: #3a4750;
                padding: 10px;
                height: 35px;
                text-align: center;
                border-spacing: 0;
                font-size: 14px;
              "
            >
              <tr style="width: 100%">
                <th>REF</th>
                <th>Descrição</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody style="font-size: 13px; text-align: center">
              <tr v-for="ref in this.refsFiscais" :key="ref.id">
                <td>{{ ref.ref }}</td>
                <td>{{ ref.descricao }}</td>
                <td class="delBill">
                  <button
                    class="btnRef"
                    style="color: #56aafe"
                    @click="edit(ref.id)"
                  >
                    <b-icon-pencil-square scale="1.3"></b-icon-pencil-square>
                  </button>
                  <button
                    @click="deleteTax(ref)"
                    style="color: red"
                    class="btnRef"
                  >
                    <b-icon-trash-fill scale="1.3"></b-icon-trash-fill>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </b-modal>
        <!-- <b-card no-body>
          <b-tabs pills card>
            <b-tab title="ISSQN" :hidden="enableISSQN">
              <template #title>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 90px;
                  "
                >
                  <div>ISSQN</div>

                  <div class="chkIssqn" @click="handleChangeIssqn">
                    <b-icon-check
                      scale="2"
                      :hidden="enableISSQN"
                    ></b-icon-check>
                  </div>
                </div>
              </template>
              <b-card-text><b-card class="scrollIssqn"> </b-card></b-card-text
            ></b-tab>
          </b-tabs>
        </b-card> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import mixinModalTaxInformations from "../../mixins/ModalTaxInformation/ModalTaxInformation";
import Tabs from "../UI/Tabs/Tab.vue";

// import Icms from "./Icms.vue";
export default {
  mixins: [mixinModalTaxInformations],
  components: {
    Tabs,
    // Icms
  },
};
</script>

<style scoped>
.chkIssqn {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: flex-end;
  border: solid 2px #f7f7f7;
  border-radius: 5px;
}

.scrollIssqn {
  max-height: 300px;
  overflow-x: scroll;
}

.styleLabel {
  color: #303030;
  font-size: 13px;
}

.form-control {
  font-size: 12px;
}

.delBill {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.btnRef {
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.heigthModal {
  width: 100%;
  min-height: 65vh;
}

.titleTax {
  font-size: 1.1rem;
  font-weight: bold;
  width: 100%;
  padding: 0 0 10px 0;
  display: flex;
  color: #4b4b4b;
  justify-content: center;
}

.closeModal {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
  color: rgb(255, 153, 153);
  cursor: pointer;
  border: 1px solid rgb(238, 238, 238);
  padding: 2px 3px;
  border-radius: 4px;
}

.closeModal:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}

.contentBtnRef {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  font-size: 14px;
  justify-content: space-between;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 15px;
  justify-content: space-between;
  gap: 10px;
}

.selectPlus {
  position: relative;
  width: 40%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 11px;
  font-weight: 600;
  bottom: 23px;
  left: 10px;
  z-index: 2;
  text-transform: capitalize;
  color: #666360;
  background-color: white;
  padding: 0 3px;
}

.user-label {
  position: absolute;
  left: 10px;
  font-size: 12px;
  bottom: 4px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.input {
  font-size: 12px !important;
  width: 100%;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
  font-weight: 600;
}

.input-group {
  width: 33%;
}

.input-group-mid {
  width: 45%;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 11px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: none;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.borderInput {
  font-size: 13px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #505050;
}
</style>