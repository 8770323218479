import { BillColumnsToSearchById } from "../utils/enums/BillColumnsToSearchById";
import api from "./axios";

class ServiceBill {
  async save(dataBill) {
    const { data } = await api.post("/bills", dataBill);
    return data
  }

  async saveWithoutFiles(bills) {
    const { data } = await api.post("/bills/without/files", bills);
    return data
  }

  async update(id, dataBill) {
    await api.put(`/bills/${id}`, dataBill);
  }

  async findById(id) {
    const { data } = await api.get(`/bills/${id}`);
    return data
  }

  async findByIdUnion(idUnion) {
    const { data } = await api.get(`/bills/search/all/duplicates/that/have/the/same/union/id/and/group/twelve/parameters/${idUnion}`);
    return data
  }

  async findDetailedBillById(id) {
    const { data } = await api.get(`/bills/find/detailed/bill/with/registers/${id}`);
    return data
  }

  async delete(id) {
    await api.delete(`/bills/${id}`)
  }

  async deleteAllRelationshipsFromDuplicate(id) {
    await api.delete(`/bills/relationships/${id}`)
  }

  async billReversal(id) {
    await api.delete(`/bills/remove/safe/register/${id}`)
  }

  async deleteByIdSale(idSale) {
    await api.delete(`/bills/delete/sale/${idSale}`)
  }

  async deleteByIdNfse(idNfse) {
    await api.delete(`/bills/delete/bill/of/nfse/${idNfse}`)
  }

  async deleteByIdSaleSquareMeter(idSaleSquareMeter) {
    await api.delete(`/bills/delete/bill/of/sale/square/meter/${idSaleSquareMeter}`)
  }

  async getAllBillsByParamsId(id, type = BillColumnsToSearchById.idVenda) {
    const { data } = await api.get(`/bills/all/bills/${id}?type=${type}`);
    return data;
  }

  async findBillPaidBySale(idSale) {
    const { data } = await api.get(`/bills/find/exists-bill-paid/paid/${idSale}`);
    return data;
  }

  async launchPayment(dataPayment) {
    await api.post("/bills/payment", dataPayment)
  }

  async changeSituationToLoss(id) {
    await api.get(`/bills/change/bill/situation/of/to/loss/bill/${id}`)
  }

  async changeSituationToOpen(id) {
    await api.get(`/bills/situation/change/from/lost/invoice/to/open/${id}`)
  }
}

export default new ServiceBill()