<template>
  <b-modal
    id="ModalSafeOpening"
    hide-footer
    hide-header
    centered
    @hidden="goBack"
    @show="listEmployeesSelectBox"
  >
    <div>
      <div class="header">
        <h6 class="titleModal">Abertura de caixa</h6>

        <img class="svgPadLock" :src="svgPadLockUnlocked" />
      </div>

      <section class="contentData">
        <div class="input-group">
          <input
            required
            type="date"
            class="input col-sm-12 col-md-6 col-lg-6 col-xl-6 borderInput"
            v-model="dateOpening"
            disabled
          />
          <label class="labelFixed"> Data </label>
        </div>

        <div class="input-group">
          <div class="contentInputSelect">
            <label class="labelFixed">Funcionário</label>
            <b-form-select
              class="borderInput shadow-none col-sm-12 col-md-6 col-lg-6 col-xl-6 iconSelect"
              v-model="dataSafeOpening.idFuncionario"
              :options="employeesFromSelectBox"
              value-field="id"
              text-field="nomeFuncionario"
            >
            </b-form-select>

            <div class="iconSelectPlus" @click="openModalCreateEmployee">
              <div role="button">
                <b-icon-plus scale="2"></b-icon-plus>
              </div>
            </div>
          </div>
        </div>

        <div class="input-group">
          <input
            v-if="!remainingValue"
            required
            type="text"
            class="`input col-sm-12 col-md-6 col-lg-6 col-xl-6 borderInput"
            :value="maskCurrency(dataSafeOpening.valorInicial)"
            @input="formatMoney($event, 'valorInicial')"
          />

          <input
            v-else
            required
            type="text"
            class="`input col-sm-12 col-md-6 col-lg-6 col-xl-6 borderInput"
            :value="maskCurrency(remainingValue)"
            disabled
          />
          <label class="labelFixed"> Fundo de Caixa Geral </label>
        </div>

        <div class="input-group">
          <textarea
            required
            type="date"
            rows="3"
            max-rows="6"
            class="inputTextArea col-sm-12 col-md-12 col-lg-12 col-xl-12 borderInput"
            v-model="dataSafeOpening.descricao"
          />
          <label class="labelObsGeral"> Observação Geral </label>
        </div>
      </section>

      <div class="btnModal">
        <div style="width: 45%">
          <button class="btnGoBack" @click="goBack">Voltar</button>
        </div>

        <div style="width: 45%">
          <button class="btnOpenSafe" @click="openSafe">
            <img
              :src="svgPadLockUnlocked"
              class="svgInButton"
              style="width: 20px"
            />
            Abrir Caixa
          </button>
        </div>
      </div>
    </div>
    <ModalCreateEmployees @getAllNewEmployee="listEmployeesSelectBox" />
  </b-modal>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import moment from "moment";

import ModalCreateEmployees from "../Modals/ModalCreateEmployees.vue";
import ServiceSafeRegister from "../../services/serviceSafeRegister";
import ServiceEmployees from "../../services/serviceEmployees";
import SvgPadLockUnlocked from "../../assets/padlock-unlocked.svg";
import { mascaraMoeda, maskCurrency } from "../../utils/maskMoney";

export default {
  props: {
    title: {
      type: String,
    },
    leftButtonText: {
      type: String,
    },
    rightButtonText: {
      type: String,
    },
  },
  components: {
    ModalCreateEmployees,
  },
  data() {
    return {
      svgPadLockUnlocked: SvgPadLockUnlocked,
      employeesFromSelectBox: [],
      dateOpening: moment().format("YYYY-MM-DD"),
      dataSafeOpening: {
        id: "",
        idFuncionario: "",
        descricao: "",
        valorInicial: "0,00",
      },
      maskCurrency: maskCurrency
    };
  },
  methods: {
    ...mapMutations(["SET_SAFE_REGISTER"]),
    async openSafe() {
      try {
        this.dataSafeOpening.valorInicial = !this.remainingValue
          ? this.dataSafeOpening.valorInicial
          : this.remainingValue;

        const safeRegister = await ServiceSafeRegister.toOpen(
          this.dataSafeOpening
        );

        this.SET_SAFE_REGISTER({ ...safeRegister, safeIsOpen: true });
        this.goBack();

        return this.$toast.open({
          message: "Abertura do caixa realizada com sucesso!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Erro ao abrir o caixa",
            type: "info",
          });
      }
    },
    async listEmployeesSelectBox() {
      try {
        const { data } = await ServiceEmployees.getEmployeesForSelectBox();
        this.employeesFromSelectBox = data;
      } catch (error) {
        console.log(error.response);
      }
    },

    openModalCreateEmployee() {
      this.$bvModal.show("modalCreateEmployee");
    },

    formatMoney(event, key) {
      this.dataSafeOpening[key] = mascaraMoeda(event.target.value);
    },

    goBack() {
      this.dataSafeOpening = {
        id: "",
        idFuncionario: "",
        descricao: "",
        valorInicial: "0,00",
      };

      this.$bvModal.hide("ModalSafeOpening");
    },
  },
  computed: {
    ...mapState({
      remainingValue: (state) => state.remainingValue,
    }),
  },
};
</script>

<style scoped>
.btnModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.btnGoBack {
  font-size: 12px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 100%;
  height: 28px;
  position: relative;
  color: #00486e;
  background-color: white;
}

.btnGoBack:hover {
  box-shadow: 0px 0px 4px 0px rgb(193, 193, 193);
}

.btnOpenSafe {
  position: relative;
  font-size: 12px;
  border: 1px solid #39c239;
  border-radius: 3px;
  width: 100%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background-color: #39c239;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnOpenSafe:hover {
  background-color: #2e9f2e;
  border: 1px solid #2e9f2e;
  box-shadow: 0px 0px 4px 0px rgb(193, 193, 193);
}

.titleModal {
  font-size: 1rem;
  margin: 0px;
  color: whitesmoke;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10px;
  background-color: #39c239;
  border-radius: 5px;
  margin-bottom: 20px;
}

.svgPadLock {
  position: absolute;
  right: 10px;
  padding-bottom: 2px;
  width: 35px;
}

.svgInButton {
  position: absolute;
  left: 40px;
}

.contentData {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px 0px;
}

/* INPUT */

.labelFixed {
  position: absolute;
  left: 0px;
  bottom: 30px;
  pointer-events: none;
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #00486e;
  font-size: 12px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  margin: 0px;
  padding: 0 0.2em;
}

.labelObsGeral {
  position: absolute;
  left: 0px;
  bottom: 60px;
  pointer-events: none;
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #00486e;
  font-size: 12px;

  font-weight: 600;
  margin: 0px;
  padding: 0 0.2em;
}

.input {
  font-size: 12px !important;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.inputDisabled {
  background-color: #fafafa;
  pointer-events: none;
  color: #949290 !important;
}

.borderInput {
  font-size: 12px !important;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  height: 30px;
  border: 2px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  padding-top: 0;
  padding-bottom: 0;
  color: #5c5855;
}

.inputTextArea {
  width: 100%;
  font-size: 12px !important;

  font-weight: 600;
  outline: none;
  text-decoration: none;
  background-color: white;
  color: #5c5855;
  border-radius: 5px;
  height: 60px;
  padding: 5px 5px;
  border: 2px solid #d5d3d3;
  resize: none;
}

.borderInput:focus {
  border: 2px solid #a9cff4;
}

/* INPUT SELECT */
.contentInputSelect {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.iconSelectPlus {
  position: absolute;
  z-index: 10;
  background-color: #c1c1c1;
  color: whitesmoke;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
}

.iconSelect {
  background: url(https://www.freeiconspng.com/thumbs/arrow-down-icon-png/black-arrow-down-icon-png-16.png)
    right 2.2rem center/8px 10px no-repeat;
}

/* MEDIAS */
@media screen and (max-width: 768px) {
  .iconSelectPlus {
    right: 0px !important;
  }
}

@media screen and (min-width: 768px) {
  .iconSelectPlus {
    left: 203px !important;
  }
}
</style>