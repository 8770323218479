<template>
  <div class="plan">
    <div class="cardPlanExpired">
      <div class="containerTitleAndImage">
        <div class="contentImgAndAlert">
          <img :src="expiredPlanImage" alt="teste" width="140" />

          <span class="contentTitle">
            <p class="title">Plano vencido ou desativado</p>

            <p class="subTitle">
              Lamentamos o transtorno, mas parece que o seu plano esta vencido
              ou desativado. Para que você possa continuar utilizando as demais
              funcionalidades do sistema, sugerimos que você realize a renovação
              ou ativação do seu plano !
            </p>
          </span>
        </div>
      </div>

      <span>
        <b-button class="btnUpdatedPlan">
          <p>Quero atualizar meu plano</p>
          <b-icon-patch-check-fill class="ml-3" />
        </b-button>
      </span>
    </div>
  </div>
</template>

<script>
import ExpiredImage from "../../../assets/expiredPlan.png";

export default {
  data() {
    return {
      expiredPlanImage: ExpiredImage,
    };
  },
};
</script>

<style scoped>
.plan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 80px;
  width: calc(100% - 100px);
  height: 100vh;
  font-size: 13px;
}

.cardPlanExpired {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 30px;
  background-color: whitesmoke;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.304);
}

.containerTitleAndImage {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 10px;
  cursor: default;
}

.contentImgAndAlert {
  display: flex;
  gap: 0.5rem;
}

.cardPlanExpired p {
  font-weight: bold;
  font-size: 24px;
  color: rgb(192, 22, 22);
}

.contentTitle {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(146, 18, 18);
  border-radius: 8px;
  padding: 5px;
  cursor: default;
}

.contentTitle .subTitle {
  font-size: 14px;
  font-weight: normal;
  color: white;
  text-align: center;
  letter-spacing: 1px;
}

.btnUpdatedPlan {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: rgb(72, 184, 101);
  border: none;
  outline: none;
  margin-top: 30px;
  margin-bottom: 10px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.946);
}

.btnUpdatedPlan p {
  color: white;
  font-size: 14px;
  font-weight: normal;
}

.btnUpdatedPlan:focus {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.946);
  background-color: rgb(55, 143, 79);
}

.btnUpdatedPlan:hover {
  background-color: rgb(55, 143, 79);
}

p {
  margin: 0;
}

@media screen and (max-width: 900px) {
  .contentImgAndAlert {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>