export const HandleErrors = (msgError) => {
  if(String(msgError).includes("Error:")) {
    msgError = String(msgError).replace("Error:", "")
  }

  if(msgError && String(msgError).includes("ERROR_NAME")) {
    const objectError = {
      message: String(msgError.split("-")[1]).trim(),
      nameError: String(msgError.split("-")[0].split(":")[1]).trim() 
    }

    return objectError
  }

  return null
}