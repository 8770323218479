import api from "./axios";

class ServiceUser {
  async save(user) {
    const { data } = await api.post("/user", user)
    return data
  }

  async update(id, user) {
    await api.put(`/user/${id}`, user)
  }

  async updatePermissions(id, data) {
    await api.patch(`/user/${id}`, data)
  }

  async delete(id, idUserGestao) {
    await api.delete(`/user/${id}/${idUserGestao}`)
  }

  async findById(id) {
    const { data } = await api.get(`/user/${id}`)
    return data
  }

  async findIntegrationsById(id) {
    const { data } = await api.get(`/user/integrations/${id}`)
    return data
  }

  async findAll() {
    const { data } = await api.get("/user/find/in/gestao")
    return data
  }

  async findUnserInGestao() {
    const { data } = await api.get("/user/find/user/in/gestao")
    return data
  }

  async findUserLevelByIdUserGestao(id) {
    const { data } = await api.get(`/user/user-level/${id}`)
    return data
  }

  async findUserWhereMtqIsEnable() {
    const { data } = await api.get(`/user/check/has/enable/mtq`)
    return data
  }

  async updatedCardMachine(id, cardMachines) {
    await api.patch(`/user/integrations/${id}`, { cardMachines })
  }

  async findUserName() {
    const { data } = await api.get(`/user/find/user/name`)
    return data
  }
}

export default new ServiceUser()