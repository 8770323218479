<template>
  <ScreenMetersVue />
</template>

<script>
import ScreenMetersVue from "../../components/ScreenMeters/ScreenMeters.vue";

export default {
  components: {
    ScreenMetersVue,
  },
};
</script>