import api from "./axios";

class ServiceRegisters {
  async countAll(){
    const { data } = await api.get('/register/counting/records')
    return data
  }
}

export default new ServiceRegisters

