import api from "./axios";

class ServiceToBeProvided {
  async save(idService) {
    const { data } = await api.post("/service", idService)
    return data
  }

  async update(data) {
    await api.put(`/service/${data.id}`, data);
  }

  async delete(idService) {
    await api.delete(`/service/${idService}`)
  }

  async getItemForGrid(id) {
    const { data } = await api.get(`/service/${id}`)
    return data;
  }

  async getServicesFromSelectBox() {
    const { data } = await api.get('/service/combobox')
    return data;
  }

  async findRefFiscalGroupBy() {
    const { data } = await api.get('/service/list/refs')
    return data;
  }

  async searchServices(textPesquisa, type, limit, page) {
    const { data } = await api.get(`/service/search/filter/service?q=${textPesquisa}&type=${type}&limit=${limit}&page=${page}`)
    return data;
  }
}

export default new ServiceToBeProvided()