import moment from "moment";
import serviceNotaFiscal from "../../services/serviceNotaFiscal";
import serviceNotaItem from "../../services/serviceNotaItem";

const searchNotaFiscal = {
  props: {
    getAllInvoices: {
      type: Boolean,
    },
  },
  data() {
    return {
      textPesquisa: "",
      type: "",
      startDate: "",
      endDate: "",
      spinGeneral: false,
      filterCombobox: [
        { value: "nomeCliente", text: "Cliente" },
        { value: "cpfCnpj", text: "CPF/CNPJ" },
        { value: "1", text: "NF-e" },
        { value: "2", text: "NFC-e" },

      ],
      tabIndex: 0,
      page: 1,
      idNotaFromModal: null,
      dataXml: {
        data_inicio: "2023-12-19",
        data_final: "2022-12-28",
        modelo: "nfe",
        relatorio: "xml",
        status: "canceladas",
      },
      linkDownloadXml: "",
    };
  },
  methods: {

    clearSearch() {
      this.textPesquisa = "";
      this.type = "";
      this.startDate = "";
      this.endDate = "";
    },

    async deleteNotaAndItem() {
      try {
        await serviceNotaItem.deleteNotaAndItem(this.idNotaFromModal);
        await this.filterNota(1);
        this.idNotaFromModal = null;

        this.$bvModal.hide("modalConfirmDeleteNota");
        return this.$toast.open({
          message: "Nota Fiscal deletada",
          type: "success",
        });
      } catch (error) {
        console.log(error);
      }
    },

    downloadXml() {
      try {
        this.$toast.open({
          message: "Aguarde, o seu relatório esta sendo gerado",
          type: "info",
        });

        const intervalId = setInterval(async () => {
          await this.resultDownloadXml(intervalId)
        }, 5000)
      } catch (error) {
        console.log(error)
      }
    },

    async resultDownloadXml(idInterval) {
      const result = await serviceNotaFiscal.downloadXml(this.dataXml)
      if (result.status === "concluido") {
        this.linkDownloadXml = result.url
        window.open(this.linkDownloadXml, "_blank")

        this.$toast.open({
          message: "Relatório gerado com sucesso",
          type: "success",
        });

        clearInterval(idInterval)
      }
    },

    editNota(idNota) {
      this.$emit("idNota", idNota);
      this.$emit("alterTabIndex", this.tabIndex);
    },

    nextPage() {
      this.filterNota((this.page += 1));
    },

    previousPage() {
      if (this.page === 1) {
        return;
      } else {
        this.filterNota((this.page -= 1));
      }
    },

    openModalDeleteNota(id) {
      this.$bvModal.show("modalConfirmDeleteNota");
      this.idNotaFromModal = id;
    },
    handleModelo(value) {
      if (value?.modelo) return value.modelo
      return ""
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
  watch: {
    async getAllInvoices() {
      await this.filterNota(1);
    }
  },
}

export default searchNotaFiscal