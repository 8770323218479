import api from "./axios";

class ServiceVoucher {
  async save(voucher) {
    const { data } = await api.post("/voucher", voucher)
    return data.id;
  }

  async update(voucher) {
    await api.put(`/voucher/${voucher.id}`, voucher);
  }

  async delete(id) {
    await api.delete(`/voucher/${id}`);
  }

  async findById(id) {
    const { data } = await api.get(`/voucher/${id}`);
    return data;
  }

  async fillComobobox() {
    const { data } = await api.get(`/voucher/combobox`);
    return data;
  }
}

export default new ServiceVoucher()