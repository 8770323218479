import Joi from "joi";

export const ValidadeMdfe = (data) => {
  const schema = Joi.object({
    ambiente: Joi.number().valid(1, 2).required().messages({
      "number.base": "(Ambiente) deve ser informado!",
      "any.only": "Campo (Ambiente) deve ser informado!",
      "any.required": "(Ambiente) é obrigatório!"
    }),
    emitente: Joi.number().valid(1, 2).required().messages({
      "number.base": "(Emitente) deve ser informado!",
      "any.only": "Campo (Emitente) deve ser informado!",
      "any.required": "(Emitente) é obrigatório!"
    }),
    uf_carregamento: Joi.string().required().messages({
      "string.empty": "(Estado de Carga) deve ser informado!",
      "any.required": "(Estado de Carga) deve ser informado!"
    }),
    uf_descarregamento: Joi.string().required().messages({
      "string.empty": "(Estado de Descarga) deve ser informado!",
      "any.required": "(Estado de Descarga) deve ser informado!"
    }),
    carregamento: Joi.array().items(Joi.object({
      codigo_municipio: Joi.string().required().messages({
        "string.empty": "(Código IBGE Carga) deve ser informado!",
        "any.required": "(Código IBGE Carga) deve ser informado!"
      }),
      nome_municipio: Joi.string().required().messages({
        "string.empty": "(Município de Carga) deve ser informado!",
        "any.required": "(Município de Carga) deve ser informado!"
      }),
    })).required().messages({
      "array.base": "(carregamento) deve ser um array de objetos!",
      "any.required": "(carregamento) é obrigatório!"
    }),
    descarregamento: Joi.array().items(Joi.object({
      codigo_municipio: Joi.string().required().messages({
        "string.empty": "(Código IBGE Descarga) deve ser informado!",
        "any.required": "(Código IBGE Descarga) deve ser informado!"
      }),
      nome_municipio: Joi.string().required().messages({
        "string.empty": "(Município de Descarga) deve ser informado!",
        "any.required": "(Município de Descarga) deve ser informado!"
      }),
      documentos_fiscais: Joi.array().items(Joi.object({
        chave: Joi.string().required().messages({
          "string.empty": "(Chave de referência) deve ser informada!",
          "any.required": "(Chave de referência) deve ser informada!"
        }),
      })).required().messages({
        "array.base": "(descarregamento.documentos_fiscais) deve ser um array de objetos!",
        "any.required": "(descarregamento.documentos_fiscais) é obrigatório!"
      }),
    })).required().messages({
      "array.base": "(descarregamento) deve ser um array de objetos!",
      "any.required": "(descarregamento) é obrigatório!"
    }),
    rodoviario: Joi.object({
      rntrc: Joi.string().required().messages({
        "string.empty": "(RNTRC) deve ser informado!",
        "any.required": "(RNTRC) deve ser informado!"
      }),
      idVeiculo: Joi.string().required().messages({
        "string.empty": "(Veiculo) deve ser preenchido!",
        "any.required": "(Veiculo) é obrigatório!"
      }),
    }).required().messages({
      "object.base": "(rodoviario) deve ser um objeto!",
      "any.required": "(rodoviario) é obrigatório!"
    }),
    unidade: Joi.string().required().messages({
      "string.empty": "(Unidade) deve ser informada!",
      "any.required": "(Unidade) deve ser informada!"
    }),
    valor_carga: Joi.number().required().messages({
      "number.base": "(Valor da carga) deve ser um número!",
      "any.required": "(Valor da carga) deve ser informado!"
    }),
    peso_bruto: Joi.number().required().messages({
      "number.base": "(Peso bruto) deve ser um número!",
      "any.required": "(Peso bruto) deve ser informado!"
    }),
    idCondutor: Joi.string().required().messages({
      "string.empty": "(Condutor) deve ser preenchido!",
      "any.required": "(Condutor) é obrigatório!"
    }),
  });

  const { error } = schema.validate(data, {
    allowUnknown: true,
  });

  if (error?.details[0]?.message || error?.details[0]?.path[0]) {
    return {
      message: error?.details[0]?.message,
      keyError: error?.details[0]?.path[0]
    };
  }
};
