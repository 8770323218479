<template>
  <div style="max-height: 100px">
    <b-modal id="modalConductor" centered hide-header>
      <template #modal-footer>
        <div style="width: 100%; display: flex; justify-content: space-between">
          <button
            class="btnsModal"
            variant="info"
            size="sm"
            @click="handleCloseModalAndClear"
          >
            Fechar
          </button>
          <button
            class="btnsModal btnSave"
            variant="info"
            size="sm"
            @click="saveOrUpdate"
          >
            Salvar
          </button>
        </div>
      </template>

      <div class="mt-3">
        <div class="titleCard">
          <p>Motoristas</p>
        </div>
        <div class="rowContent">
          <div class="input-group">
            <input
              v-model="dataConductor.nome"
              ref="conductorName"
              :class="`input borderInput`"
            />
            <label class="user-label"> Nome </label>
          </div>
          <div class="input-group">
            <input
              v-model="dataConductor.cpf"
              v-mask="maskCpf"
              ref="conductorCpf"
              :class="`input borderInput`"
            />
            <label class="user-label"> CPF </label>
          </div>
        </div>
        <table class="table table-sm mt-5">
          <thead>
            <tr style="width: 100%">
              <th style="width: 50%; font-size: 14px; font-weight: 600">
                Nome
              </th>
              <th style="width: 50%; font-size: 14px; font-weight: 600">CPF</th>
              <th
                style="
                  width: 50%;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 600;
                  padding: 0 10px;
                "
              >
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="conductor in allConductor" :key="conductor.id">
              <td style="font-size: 14px">{{ conductor.nome }}</td>
              <td style="font-size: 14px">{{ conductor.cpf }}</td>
              <td
                style="
                  text-align: center;
                  padding: 0 !important;
                  display: flex;
                  height: 100%;
                  justify-content: center;
                  gap: 20px;
                  padding: 0.5rem;
                "
              >
                <b-icon-pencil
                  @click="findById(conductor.id)"
                  scale="1"
                  role="button"
                />
                <b-icon-x-circle
                  @click="deleteConductor(conductor.id)"
                  role="button"
                  style="color: red"
                  scale="1"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ServiceConductor from "../../services/serviceConductor";

export default {
  data() {
    return {
      dataConductor: {
        id: "",
        nome: "",
        cpf: "",
      },
      allConductor: [],
    };
  },
  methods: {
    async save() {
      try {
        await ServiceConductor.save(this.dataConductor);
        this.getAllConductor();
        
        this.$emit("getNewConductor");

        return this.$toast.open({
          message: "Salvo com sucesso!",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message:
            `${error.response.data.erros}` || "Ocorreu um erro o salvar!",
          type: "info",
        });
      }
    },
    async update() {
      try {
        await ServiceConductor.update(this.dataConductor);
        this.getAllConductor();

        this.$emit("getNewConductor");

        return this.$toast.open({
          message: "Atualizado com sucesso!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um problema ao deletar!",
          type: "info",
        });
      }
    },

    async deleteConductor(id) {
      try {
        await ServiceConductor.delete(id);
        this.getAllConductor();

        this.$emit("getNewConductor");

        return this.$toast.open({
          message: "deletado com sucesso!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um problema ao deletar!",
          type: "info",
        });
      }
    },

    async findById(id) {
      const result = await ServiceConductor.findById(id);
      Object.assign(this.dataConductor, result);
      this.$refs.conductorName.valueInput = result.nome;
      this.$refs.conductorCpf.valueInput = result.cpf;
    },

    async getAllConductor() {
      const result = await ServiceConductor.fillCombobox();
      this.allConductor = result;
    },

    async saveOrUpdate() {
      this.dataConductor.id === "" ? await this.save() : await this.update();
      this.clear();
    },

    clear() {
      this.dataConductor.id = "";
      this.dataConductor.nome = "";
      this.dataConductor.cpf = "";
      this.$refs.conductorName.valueInput = "";
      this.$refs.conductorCpf.valueInput = "";
    },

    handleCloseModalAndClear() {
      this.clear();
      this.$bvModal.hide("modalConductor");
    },
  },
  computed: {
    maskCpf() {
      return "###.###.###-##";
    },
  },
  mounted() {
    this.getAllConductor();
  },
};
</script>

<style scoped>
.styleHoverDelete:hover {
  background-color: #ff6347;
  border: solid 1px black;
}

.tabsContentSearch {
  overflow-y: scroll;
  max-height: 300px;
}

.borderInput {
  font-size: 12px !important;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px !important;
}

.borderInput:focus {
  box-shadow: none !important;
  border: 1px solid #505050;
}

.btnsModal {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  height: 28px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;
}

.btnSave {
  background-color: transparent;
  border: solid 1px #0fbf4d;
  color: #0fbf4d;
}

.titleCard {
  margin-bottom: 32px !important;
  font-weight: 600;
  color: #666360;
  font-size: 16px;
}
.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}
.form-control-sm {
  height: 28px;
  padding: 0.25rem 0.25rem;
  font-size: 12px;
  line-height: 1.3;
  border-radius: 0.2rem;
}

.custom-select-sm {
  height: 28px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 12px;
}

.form-group {
  margin-bottom: 0.2rem;
}

span {
  font-weight: bold;
  cursor: context-menu;
}

.user-label {
  position: absolute;
  left: 4px;
  font-size: 11px;
  bottom: 7px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  z-index: 10;
}

.borderInput {
  font-size: 12px !important;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px !important;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  box-shadow: none !important;
  border: 1px solid #505050;
}

.input {
  font-size: 11px !important;
  width: 100%;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
}

.selectPlus {
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 9px;
  bottom: 24px;
  left: 11px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: white;
}
</style>