import Joi from "joi";

export const ValidatePointRecords = (data) => {
  const schema = Joi.object({
    idFuncionario: Joi.string().guid({ version: "uuidv4" }).required().messages({
      "string.empty": "(Funcionário) deve ser selecionado para prosseguir",
      "string.any": "(Funcionário) deve ser selecionado para prosseguir",
      "string.base": "(Funcionário) deve ser selecionado para prosseguir",
    }),
    tipo: Joi.valid('entrada', 'saida').required().messages({
      "string.empty": "(Tipo) deve ser informado para prosseguir",
      "string.base": "(Tipo) deve ser informado para prosseguir",
      "string.any": "(Tipo) deve ser informado para prosseguir",
      "any.only": "(Tipo) deve ser informado para prosseguir",
    }),
    dataRegistro: Joi.string().required().messages({
      "string.empty": "(Horário do Registro) deve ser informado para prosseguir",
      "string.any": "(Horário do Registro) deve ser informado para prosseguir",
      "string.base": "(Horário do Registro) deve ser informado para prosseguir",
    })
  })

  const { error } = schema.validate(data, {
    allowUnknown: true,
  })

  if (error?.details[0]?.message || error?.details[0]?.path[0]) {
    return {
      message: error?.details[0]?.message,
      keyError: error?.details[0]?.path[0]
    }
  }
}