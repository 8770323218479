<template>
  <div style="max-height: 100px">
    <b-modal id="modalContaRecebimento" centered hide-header>
      <template #modal-footer>
        <div style="width: 100%; display: flex; justify-content: space-between">
          <button
            class="btnsModal"
            variant="info"
            size="sm"
            @click="handleCloseModalAndClear"
          >
            Fechar
          </button>
          <button
            class="btnsModal btnSave"
            variant="info"
            size="sm"
            @click="saveOrUpdate"
          >
            Salvar
          </button>
        </div>
      </template>
      <div>
        <div class="titleCard">
          <p>Conta de Recebimento</p>
        </div>
        <div>
          <label style="font-size: 14px; color: #666360">
            Informe abaixo o nome da conta de recebimento
          </label>
          <div>
            <Input
              type="text"
              :dataInput.sync="dataReceiptBill.nome"
              ref="receiptBillName"
            />
          </div>
        </div>

        <table class="table table-sm mt-5">
          <thead style="background: #3a4750; color: white">
            <tr style="width: 100%">
              <th
                style="
                  width: 50%;
                  font-size: 14px;
                  font-weight: 600;
                  text-align: center;
                "
              >
                Nome
              </th>
              <th
                style="
                  width: 50%;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 600;
                "
              >
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="receiptBill in dataAllReceiptBill" :key="receiptBill.id">
              <td style="font-size: 14px; text-align: center">
                {{ receiptBill.nome }}
              </td>
              <td
                style="
                  text-align: center;
                  padding: 0 !important;
                  display: flex;
                  height: 100%;
                  justify-content: center;
                  gap: 20px;
                  padding: 0.5rem;
                "
              >
                <b-icon-pencil
                  @click="getReceiptBillFindById(receiptBill.id)"
                  scale="1"
                  role="button"
                />

                <b-icon-x-circle
                  @click="deleteReceiptBill(receiptBill.id)"
                  role="button"
                  style="color: red"
                  scale="1"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ServiceAccountReceiptBill from "../../services/serviceAccountReceiptBill";
import Input from "../UI/CustomInputs/Input.vue";

export default {
  data() {
    return {
      dataReceiptBill: {
        id: "",
        nome: "",
      },
      dataAllReceiptBill: [],
      tabIndexSected: 1,
      getReceiptBill: false,
    };
  },
  components: {
    Input,
  },
  methods: {
    async saveReceiptBill() {
      try {
        await ServiceAccountReceiptBill.save(this.dataReceiptBill);
        this.getAllReceiptBill();

        this.$emit("getNewReceiptBill", this.getReceiptBill);
        this.getReceiptBill = !this.getReceiptBill;

        return this.$toast.open({
          message: "Salvo com sucesso!",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message:
            `${error.response.data.erros}` || "Ocorreu um erro o salvar!",
          type: "info",
        });
      }
    },

    async updateReceiptBill() {
      try {
        await ServiceAccountReceiptBill.update(this.dataReceiptBill);
        this.getAllReceiptBill();
  
        this.$emit("getNewReceiptBill", this.getReceiptBill);
        this.getReceiptBill = !this.getReceiptBill;
  
        return this.$toast.open({
          message: "Atualizado com sucesso!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message: error?.response?.data?.message || "Ocorreu um erro ao atualizar a conta",
          type: "info"
        })
      }
    },

    async deleteReceiptBill(idReceiptBill) {
      try {
        await ServiceAccountReceiptBill.delete(idReceiptBill);
        this.getAllReceiptBill();

        this.$emit("getNewReceiptBill", this.getReceiptBill);
        this.getReceiptBill = !this.getReceiptBill;

        return this.$toast.open({
          message: "deletado com sucesso!",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message: error.response.data.message,
          type: "info",
        });
      }
    },

    async getReceiptBillFindById(idReceiptBill) {
      const result = await ServiceAccountReceiptBill.findById(idReceiptBill);
      Object.assign(this.dataReceiptBill, result);
      this.$refs.receiptBillName.valueInput = result.nome;
      this.tabIndexSected = 0;
    },

    async getAllReceiptBill() {
      const result = await ServiceAccountReceiptBill.fillComobobox();
      this.dataAllReceiptBill = result;
    },

    async saveOrUpdate() {
      this.dataReceiptBill.id === ""
        ? await this.saveReceiptBill()
        : await this.updateReceiptBill();
      this.clear();
    },

    clear() {
      this.dataReceiptBill.id = "";
      this.dataReceiptBill.nome = "";
      this.$refs.receiptBillName.valueInput = "";
    },

    handleCloseModalAndClear() {
      this.clear();
      this.$bvModal.hide("modalContaRecebimento");
    },
  },
  mounted() {
    this.getAllReceiptBill();
  },
};
</script>

<style scoped>
.styleHoverDelete:hover {
  background-color: #ff6347;
  border: solid 1px black;
}

.tabsContentSearch {
  overflow-y: scroll;
  max-height: 300px;
}

.btnsModal {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  height: 28px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;
}

.btnSave {
  background-color: transparent;
  border: solid 1px #0fbf4d;
  color: #0fbf4d;
}

.titleCard {
  margin-bottom: 32px !important;
  font-weight: 600;
  color: #666360;
  font-size: 16px;
}

.table thead th {
  border-bottom: solid 1px transparent;
}
</style>