<template>
  <div class="selectPlus">
    <label
      :style="`background-color: ${labelBackground}`"
      :class="`fixedSelect ${
        label && isDisabled ? 'fixedSelectNotDisabled' : ''
      } ${!label && 'fixedSelectDisable'}`"
      >{{ label }}</label
    >
    <b-form-select
      :style="`background-color: ${backgroundInput};${
        textCenter && 'text-align: center'
      }`"
      :class="`borderInput ${isInvalidInput} ${
        isDisabled && 'inputDisabled'
      } shadow-none ${
        arrowLeft ? 'custom-select-arrow-last' : 'custom-select'
      }`"
      size="sm"
      :text-field="textField"
      :value-field="valueField"
      :options="items"
      v-model="valueItems"
      @change="selectValue"
      @blur.native="clearInvalidInput"
    >
    </b-form-select>

    <div
      :class="`iconSelect ${isDisabled && 'iconDisabled'}`"
      v-if="enableIconCreate"
      @click="createModal"
    >
      <div role="button">
        <b-icon-plus scale="2"></b-icon-plus>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
    valueField: {
      type: String,
    },
    textField: {
      type: String,
    },
    label: {
      type: String,
    },
    openCreateModal: {
      type: Function,
    },
    enableIconCreate: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
    },
    arrowLeft: {
      type: Boolean,
      default: false,
    },
    labelBackground: {
      type: String,
    },
    backgroundInput: {
      type: String,
    },
    isInvalidInput: {
      type: String,
    },
    textCenter: {
      type: Boolean,
    },
  },
  data() {
    return {
      valueItems: "",
      invalidInput: "",
    };
  },
  methods: {
    selectValue() {
      this.$emit("update:dataSelect", this.valueItems);
      this.$emit("selectedIten", this.valueItems);
      this.$emit("changed");
    },
    createModal() {
      if (this.openCreateModal) return this.openCreateModal();

      return;
    },
    clearInvalidInput() {
      this.$emit("clearInvalidInput");
    },
  },
};
</script>

<style scoped>
.fixedSelect {
  position: absolute;
  left: 3px;
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  bottom: 13px;
  pointer-events: none;
  transform: translateY(-50%) scale(0.8);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  height: 12px;
  background-color: #ffff;
  padding: 0 0.2em;
}

.selectPlus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.borderInput {
  font-size: 12px;
  height: 30px;
  border: 1px solid #d5d3d3;
  color: #5c5855;
  padding-left: 5px;
  cursor: pointer;
}

.inputDisabled {
  background-color: #fafafa !important;
  pointer-events: none !important;
  color: #949290 !important;
}

.iconDisabled {
  pointer-events: none !important;
}

.fixedSelectDisable {
  padding: 0 !important;
}

.fixedSelectNotDisabled {
  padding: 0 0.2em;
}

.borderInput:focus {
  border: 1px solid #a9cff4;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.2em;
  color: #00395d;
}

.iconSelect {
  position: absolute;
  right: 0px !important;
  z-index: 10;
  background-color: #c1c1c1;
  color: whitesmoke;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 30px;
  padding: 0.375rem 1.2rem 0.375rem 0.75rem;
  font-size: 12px;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url(https://www.freeiconspng.com/thumbs/arrow-down-icon-png/black-arrow-down-icon-png-16.png)
    right 0.5rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select-arrow-last {
  display: inline-block;
  width: 100%;
  height: 30px;
  padding: 0.375rem 0.75rem 0.375rem 0.25rem;
  font-size: 12px;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url(https://www.freeiconspng.com/thumbs/arrow-down-icon-png/black-arrow-down-icon-png-16.png)
    right 0.25rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.isInvalidInput:focus {
  border: solid 1px rgba(204, 21, 21, 0.583) !important;
  transition: ease-in all 0.1s !important;
  box-shadow: 0 0 2px 1px rgba(204, 21, 21, 0.583) !important;
}
</style>