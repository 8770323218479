<template>
  <div class="general-container">
    <div class="container">
      <img
        src="https://cdn-icons-png.flaticon.com/512/619/619175.png"
        class="rocketImg"
      />

      <h4 style="margin-bottom: 4rem">Defina uma nova senha</h4>

      <p class="subtitle">
        Insira uma nova senha abaixo para alterar sua senha.
      </p>

      <div class="container-inputs">
        <div class="content-inputs">
          <span style="width: 100%">
            <InputPassword
              large
              label="Nova senha"
              :dataInput.sync="newPassword"
              :isInvalidInput="isInvalidInput"
              ref="password"
              @clearInvalidInput="clearInvalidInput"
              @changed="clearMessage"
              :borderInvalid="borderInvalid"
            />
          </span>

          <span style="width: 100%">
            <InputPassword
              large
              label="Confirme a nova senha"
              :dataInput.sync="confirmedPassword"
              :isInvalidInput="isInvalidInput"
              ref="confirmPassword"
              @clearInvalidInput="clearInvalidInput"
              @changed="clearMessage"
              :borderInvalid="borderInvalid"
            />
          </span>

          <p v-if="invalidMessage" class="invalidMessage">
            {{ invalidMessage }}
          </p>
        </div>

        <div style="width: 100%; height: 50px">
          <button
            class="btnGeneral"
            variant="none"
            @click="confirmResetPassword"
          >
            <p>Redefinir senha</p>
          </button>
        </div>
      </div>
    </div>

    <div class="container-back-login">
      <div class="content-back-login" @click="backToLogin">
        <b-icon-arrow-left-short scale="1.5" style="color: gray" />
        <p class="subtitle-back-login">Voltar ao login</p>
      </div>
    </div>
  </div>
</template>

<script>
import InputPassword from "../UI/CustomInputs/InputPassword.vue";
import ServiceGestao from "../../services/serviceGestao";
import { HandleErrors } from "../../utils/handleErrors";

export default {
  components: {
    InputPassword,
  },
  data() {
    return {
      newPassword: "",
      confirmedPassword: "",
      invalidMessage: "",
      isInvalidInput: "",
      borderInvalid: false,
    };
  },
  methods: {
    async confirmResetPassword() {
      try {
        if (!this.validInputs()) {
          return;
        }

        await ServiceGestao.updatePassword(this.newPassword, this.getToken);
        this.$router.push({
          path: `/`,
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }
      }
    },
    validInputs() {
      if (this.newPassword === "" || this.confirmedPassword === "") {
        this.invalidMessage = "* Os campos de senhas devem ser preenchidos";
        this.borderInvalid = true;
        return false;
      } else if (this.newPassword !== this.confirmedPassword) {
        this.invalidMessage = "* As senhas não coincidem";
        this.borderInvalid = true;
        return false;
      }

      return true;
    },
    clearMessage() {
      this.invalidMessage = "";
      this.borderInvalid = false;
    },
    clearInvalidInput() {
      this.isInvalidInput = "";
      this.borderInvalid = false;
    },
    backToLogin() {
      this.$router.push({
        path: "/",
      });
    },
  },
  computed: {
    getToken() {
      return this.$route.query.token;
    },
  },
};
</script>

<style scoped>
.general-container {
  width: 500px;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 0 5rem;
}

h4 {
  color: #002a3f;
  font-weight: 600;
  margin: 0;
}

p {
  margin: 0;
  color: #002a3f;
  font-weight: 100 !important;
}

.subtitle {
  text-align: center;
  font-size: 14px;
  font-family: Poppins-Medium, sans-serif !important;
  font-weight: normal !important;
}

.container-inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
  gap: 1.5rem;
}

.content-inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.btnGeneral {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: none;
  outline: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.179) !important;
  background-color: rgb(72, 184, 101);
  border-radius: 5px;
  transition: ease-in all 0.2s;
  cursor: pointer;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
  user-select: none;
}

.btnGeneral p {
  margin: 0;
  font-weight: normal;
  color: whitesmoke;
  font-size: 14px;
  font-weight: normal;
}

.invalidMessage {
  color: red;
  font-size: 12.5px;
  font-family: Poppins-Medium, sans-serif !important;
  font-weight: bold;
  margin: 0;
}

.subtitle-back-login {
  font-size: 13px;
  font-family: Poppins-Medium, sans-serif !important;
  font-weight: normal !important;
  color: gray;
}

.container-back-login {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 0.8rem;
}

.content-back-login {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
}

.content-back-login:hover {
  text-decoration: underline rgba(128, 128, 128, 0.65);
}

.rocketImg {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 50px;
  margin-top: 1rem;
}

@media screen and (min-height: 750px) {
  .general-container {
    justify-content: space-evenly;
  }

  .rocketImg {
    margin: 0;
  }
}

@media screen and (max-width: 535px) {
  .general-container {
    height: 100%;
    width: 100%;
  }

  .container {
    padding: 1rem 2rem;
  }
}
</style>