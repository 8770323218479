<template>
  <div class="containerGeneral">
    <div class="contentGeneral">
      <div class="containerHeader">
        <div class="contentTitleAndIconReport">
          <div style="display: flex; align-items: center; gap: 0.5rem">
            <p class="title">Controle de pontos</p>

            <div class="contentIconNewRecord" @click="openModalNewRecord">
              <p class="iconReport" style="padding-top: 2px; user-select: none">
                +
              </p>
            </div>
          </div>

          <div style="display: flex; align-items: center">
            <div class="contentIconReport" @click="printReport">
              <b-spinner class="spinReport" v-if="loadingReport" />
              <p class="iconReport" v-else>
                <b-icon-file-earmark-arrow-up-fill />
              </p>
            </div>
          </div>
        </div>

        <div class="contentHeader">
          <div class="cardPointDetails">
            <div class="contentEmployeeName">
              <div style="display: flex; gap: 1rem; align-items: center">
                <p class="employeeName">Totais de registros</p>
                <p class="arrow">{{ ">" }}</p>
              </div>

              <div class="footerCard">
                <div class="contentFooterLeft" v-if="loadingDetails">
                  <b-spinner class="spinLoadingDetails" />
                </div>

                <div class="contentFooterLeft" v-else>
                  <p>{{ details.totalPointsNow }}</p>
                  <p class="numbersDefault">/</p>
                  <p class="numbersDefault">{{ details.totalUsers }}</p>
                </div>

                <div>
                  <span class="circleImg"> </span>
                  <img class="cardIcon" :src="totalRegisters" />
                  <img class="cardIconPlus" :src="totalRegistersPlus" />
                  <img class="cardIconMouseClick" :src="mouseClick" />
                </div>
              </div>
            </div>
          </div>

          <div class="cardPointDetails">
            <div class="contentEmployeeName">
              <span style="display: flex; gap: 1rem; align-items: center">
                <p class="employeeName">Horas em atraso</p>
                <p class="arrow">{{ ">" }}</p>
              </span>

              <div class="footerCard">
                <div class="contentFooterLeft" v-if="loadingDetails">
                  <b-spinner class="spinLoadingDetails" />
                </div>

                <div
                  style="display: flex; align-items: center; gap: 0.3rem"
                  v-else
                >
                  <p
                    style="
                      margin: 0;
                      color: #cf3636;
                      font-weight: 600;
                      font-size: 20px;
                    "
                  >
                    -
                  </p>
                  <p class="dueHours">{{ details.dueHours }}</p>
                </div>

                <div>
                  <span class="circleImg"> </span>
                  <img class="cardIconLostTime" :src="lostTime" />
                  <img class="cardIconPlus" :src="lostTimeTop" />
                  <img class="cardIconMouseClick" :src="emojiLostTime" />
                </div>
              </div>
            </div>
          </div>

          <div class="cardPointDetails">
            <div class="contentEmployeeName">
              <span style="display: flex; gap: 1rem; align-items: center">
                <p class="employeeName">Horas extras</p>
                <p class="arrow">{{ ">" }}</p>
              </span>

              <div class="footerCard">
                <div class="contentFooterLeft" v-if="loadingDetails">
                  <b-spinner class="spinLoadingDetails" />
                </div>

                <div
                  style="display: flex; align-items: center; gap: 0.3rem"
                  v-else
                >
                  <p
                    style="
                      margin: 0;
                      color: rgb(72, 184, 101);
                      font-weight: 600;
                      font-size: 20px;
                    "
                  >
                    +
                  </p>
                  <p class="overtime">{{ details.overtime }}</p>
                </div>

                <div>
                  <span class="circleImg"> </span>
                  <img class="clockTimePlus" :src="clockSimplePlus" />
                  <img class="cardIconPlus" :src="plusTop" />
                  <img class="cardIconMouseClick" :src="emojiTimePlus" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="containerCards">
        <div
          style="
            display: flex;
            gap: 1rem;
            align-items: center;
            margin-bottom: 0.2rem;
          "
        >
          <p class="subtitle m-0">Lista de Registros</p>

          <div class="iconFilter">
            <label ref="lblFilter" class="lblFilter" @click="toggleCheckbox"
              ><b-icon-filter scale="1.3" style="color: white" />
            </label>

            <div
              class="boxFilter"
              ref="boxFilter"
              :style="`${
                isBoxFilterVisible ? 'display: flex' : 'display: none'
              }`"
            >
              <div class="contentFilters">
                <span style="display: flex">
                  <p class="titleFilters">Filtros</p>
                </span>
                <div class="rowContentInputs">
                  <span class="contentInputs">
                    <p>Funcionário</p>
                    <InputSelect
                      label=""
                      :enableIconCreate="false"
                      ref="idFuncionario"
                      :items="employees"
                      valueField="id"
                      textField="nomeFuncionario"
                      :dataSelect.sync="filters.id"
                    />
                  </span>
                </div>
              </div>

              <div class="contentFilters">
                <span style="display: flex">
                  <p class="titleFilters">Periodos</p>
                </span>
                <div class="rowContentInputs">
                  <span class="contentInputs">
                    <p>Inicio</p>
                    <Input
                      label=""
                      type="date"
                      :dataInput.sync="filters.startDate"
                      ref="startDate"
                    />
                  </span>

                  <span class="contentInputs">
                    <p>Fim</p>
                    <Input
                      label=""
                      type="date"
                      :dataInput.sync="filters.endDate"
                      ref="endDate"
                    />
                  </span>
                </div>
              </div>

              <div class="rowContentInputs">
                <span class="btnApplyFilter" @click="apllyFilters"
                  >Aplicar</span
                >

                <span class="btnClearFilter" @click="clearFilters">Limpar</span>
              </div>
            </div>
          </div>
        </div>

        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          "
        >
          <div class="contentCardsRegister" ref="infiniteScroll">
            <div
              style="display: flex; flex-direction: column; gap: 1rem"
              v-if="Object.keys(pointRecords.points).length"
            >
              <div
                v-for="month in Object.keys(pointRecords.points)"
                :key="month"
              >
                <p class="textMonth">Mes {{ month }}</p>

                <div style="display: flex; flex-direction: column; gap: 2rem">
                  <div
                    v-for="(employee, idx) in Object.keys(
                      pointRecords.points[month]
                    )"
                    :key="idx"
                  >
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        position: relative;
                      "
                    >
                      <div
                        style="
                          display: flex;
                          position: absolute;
                          top: -15px;
                          left: 1rem;
                        "
                      >
                        <p class="nameEmployee">
                          {{ employee }}
                        </p>
                      </div>

                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          gap: 0.5rem;
                        "
                      >
                        <span
                          style="
                            display: flex;
                            flex-direction: column;
                            gap: 0.5rem;
                          "
                        >
                          <div
                            v-for="point in pointRecords.points[month][
                              employee
                            ]"
                            :key="point.id"
                            @click="showModalDetails(point)"
                            :class="`contentRegisterDayAndHours ${handleTypeColor(
                              point
                            )}`"
                          >
                            <span
                              style="
                                display: flex;
                                justify-content: space-between;
                              "
                            >
                              <p class="date">{{ point.date }}</p>
                              <p :class="`date ${handleTextTypePoint(point)}`">
                                {{ point.type ? point.type.toUpperCase() : "" }}
                              </p>
                            </span>

                            <span
                              style="
                                display: flex;
                                justify-content: space-between;
                              "
                            >
                              <p class="day">{{ formatDay(point.day) }}</p>
                              <p class="hours">{{ point.hours }}</p>
                            </span>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="contentNotPointRecords">
              <span> <b-icon-dash-circle scale="1.5" /> </span>
              <p style="margin: 0">Nenhum registro informado...</p>
            </div>
          </div>
        </div>

        <span
          class="backTop"
          @click="backTop"
          v-if="isEnableBtnBackTop && Object.keys(pointRecords.points).length"
        >
          <b-icon-arrow-up-short scale="2" />
        </span>
      </section>
    </div>

    <b-modal
      id="modalDetailsPoint"
      hide-footer
      hide-header
      centered
      title="Excluir"
      no-close-on-backdrop
      @hidden="closeModalDetails"
    >
      <div>
        <span
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1.4rem;
          "
        >
          <span :class="`${handleColorPointType}`">
            {{ pointSelected.type }}
          </span>

          <span :class="`${handleColorPointPeriod}`">
            {{ pointSelected.period }}
          </span>
        </span>

        <div class="modalDetails">
          <div class="contentCardModalDetails">
            <div
              style="
                display: flex;
                gap: 1rem;
                flex-direction: column;
                align-items: center;
                padding: 0.5rem 1rem;
              "
            >
              <div class="rowModal">
                <span class="colModals">
                  <p>Funcionário</p>
                  <p>{{ pointSelected.nomeFuncionario }}</p>
                </span>

                <span class="colModals">
                  <p>Data</p>
                  <p>{{ pointSelected.date }}</p>
                </span>
              </div>

              <div class="rowModal">
                <span class="colModals">
                  <p style="margin: 0">Horário do registro</p>
                  <p style="margin: 0; color: black" v-if="!showEditRegister">
                    ({{ pointSelected.hours }})
                  </p>

                  <Input
                    label=""
                    type="datetime-local"
                    :dataInput.sync="registrationDateToUpdate"
                    ref="dataRegistro"
                    :isInvalidInput="isInvalidInput"
                    @clearInvalidInput="clearInvalidInput"
                    v-else
                  />
                </span>

                <span class="colModals" v-if="!urlMaps && pointSelected.lat">
                  <span
                    @click="openPointRegisterLocale"
                    class="btnApplyFilter"
                    style="color: white; font-weight: normal"
                    >Local <b-icon-geo-alt-fill scale="1.5"
                  /></span>
                </span>
              </div>

              <div class="rowModal">
                <span style="display: flex; gap: 1rem">
                  <span
                    @click="editRegister"
                    class="btnEdit"
                    v-if="!showEditRegister"
                    >Editar <b-icon-pen-fill scale="1"
                  /></span>

                  <span
                    @click="removeRegister"
                    class="btnCancelEdit"
                    v-if="!showEditRegister"
                    >Remover<b-icon-trash-fill scale="1.3"
                  /></span>

                  <span
                    @click="updateRegister"
                    class="btnEdit"
                    v-if="showEditRegister"
                    >Atualizar <b-icon-cloud-arrow-up-fill scale="1.5"
                  /></span>
                  <span
                    @click="cancelEdit"
                    class="btnCancelEdit"
                    v-if="showEditRegister"
                    >Cancelar <b-icon-box-arrow-in-left scale="1.5"
                  /></span>
                </span>
              </div>
            </div>
          </div>

          <div :style="`${loadMaps && 'height: 250px; width: 100%'}`">
            <iframe
              :src="urlMaps"
              width="100%"
              height="250"
              style="border: 0"
              loading="lazy"
              allowfullscreen
              referrerpolicy="no-referrer-when-downgrade"
              v-if="urlMaps && !loadMaps"
            >
            </iframe>

            <span
              style="
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
              v-else-if="!urlMaps && loadMaps"
            >
              <b-spinner class="spin" />
            </span>
          </div>
        </div>

        <div class="btnModal">
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modalDetailsPoint')"
          >
            Fechar
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modalNewRecord"
      hide-footer
      hide-header
      centered
      title="Excluir"
      @hidden="closeModalNewRecord"
    >
      <div>
        <span style="display: flex; align-items: center; margin-bottom: 1.5rem">
          <p class="textNewPoint">+ Novo Registro</p>
        </span>

        <div class="modalNewRecord">
          <div class="contentCardModalNewRecords">
            <div
              style="
                display: flex;
                gap: 1rem;
                flex-direction: column;
                align-items: center;
                padding: 0.5rem 1rem;
              "
            >
              <div class="rowModal">
                <span class="colModals" style="width: 100%">
                  <p style="margin: 0">Funcionários</p>

                  <InputSelect
                    label=""
                    :enableIconCreate="false"
                    ref="idFuncionarioToSave"
                    :items="employees"
                    valueField="id"
                    textField="nomeFuncionario"
                    :dataSelect.sync="recordsToSave.idFuncionario"
                    backgroundInput="white"
                    :isInvalidInput="isInvalidInput"
                    @clearInvalidInput="clearInvalidInput"
                  />
                </span>
              </div>

              <div class="rowModal">
                <span class="colModals" style="width: 50%">
                  <p style="margin: 0">Tipo</p>

                  <InputSelect
                    label=""
                    :enableIconCreate="false"
                    ref="tipoToSave"
                    :items="typesRegisters"
                    valueField="value"
                    textField="text"
                    :dataSelect.sync="recordsToSave.tipo"
                    backgroundInput="white"
                    :isInvalidInput="isInvalidInput"
                    @clearInvalidInput="clearInvalidInput"
                  />
                </span>

                <span class="colModals" style="width: 50%">
                  <p style="margin: 0">Horário do registro</p>

                  <Input
                    label=""
                    type="datetime-local"
                    :dataInput.sync="recordsToSave.dataRegistro"
                    ref="dataRegistroToSave"
                    :isInvalidInput="isInvalidInput"
                    @clearInvalidInput="clearInvalidInput"
                  />
                </span>
              </div>
            </div>
          </div>

          <div class="rowModal">
            <span style="display: flex; gap: 1rem">
              <span
                @click="saveNewRecord"
                style="
                  display: flex;
                  gap: 0.5rem;
                  align-items: center;
                  justify-content: center;
                  width: 70px;
                "
                class="btnApplyFilter"
                >Salvar</span
              >
              <span
                @click="$bvModal.hide('modalNewRecord')"
                class="btnClearFilter"
                style="
                  display: flex;
                  gap: 0.5rem;
                  align-items: center;
                  justify-content: center;
                  width: 70px;
                "
                >Fechar</span
              >
            </span>
          </div>
        </div>
      </div>
    </b-modal>

    <div class="contentPrintReport" v-if="isPrint">
      <iframe
        width="50%"
        height="50%"
        style="background-color: white"
        frameborder="0"
        id="printf"
        name="printf"
        loading="lazy"
        allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { DateTime } from "luxon";

import InputSelect from "../UI/CustomInputs/InputSelect.vue";
import Input from "../UI/CustomInputs/Input.vue";

import ServicePointRecords from "../../services/servicePointRecords";
import ServiceEmployees from "../../services/serviceEmployees";

import { ValidatePointRecords } from "../../validators/pointRecords";
import { HandleErrors } from "../../utils/handleErrors";

import totalRegisters from "../../assets/totalRegisters.svg";
import lostTime from "../../assets/lostTime.svg";
import totalRegistersPlus from "../../assets/totalRegistersPlus.svg";
import lostTimeTop from "../../assets/lostTimeTop.svg";
import plusTop from "../../assets/plusTop.svg";
import emojiTimePlus from "../../assets/emojiTimePlus.svg";
import mouseClick from "../../assets/mouseClick.svg";
import emojiLostTime from "../../assets/emojiLostTime.svg";
import clockSimplePlus from "../../assets/clockSimplePlus.svg";

export default {
  components: {
    InputSelect,
    Input,
  },
  data() {
    return {
      recordsToSave: {
        idFuncionario: null,
        dataRegistro: null,
        tipo: null,
        lat: null,
        long: null,
      },
      totalRegisters: totalRegisters,
      totalRegistersPlus: totalRegistersPlus,
      lostTimeTop: lostTimeTop,
      plusTop: plusTop,
      emojiTimePlus: emojiTimePlus,
      clockSimplePlus: clockSimplePlus,
      mouseClick: mouseClick,
      emojiLostTime: emojiLostTime,
      lostTime: lostTime,
      isEnableBtnBackTop: false,
      loadingRegister: false,
      employees: [],
      page: 1,
      pointRecords: {
        points: [],
      },
      pointSelected: {
        id: null,
        idEmpresa: null,
        idUsuario: null,
        dataRegistro: null,
        lat: null,
        long: null,
        idFuncionario: null,
        nomeFuncionario: null,
        period: "manha",
        type: null,
        date: null,
        day: null,
        hours: null,
      },
      isBoxFilterVisible: false,
      showEditRegister: false,
      registrationDateToUpdate: null,
      loadMaps: false,
      loadingDetails: false,
      loadingReport: false,
      urlMaps: null,
      filters: {
        id: null,
        startDate: null,
        endDate: null,
      },
      isInvalidInput: "",
      isPrint: false,
      details: {
        totalUsers: 0,
        totalPointsNow: 0,
        time: "",
      },
      typesRegisters: [
        { text: "Entrada", value: "entrada" },
        { text: "Saida", value: "saida" },
      ],
    };
  },
  methods: {
    async findAll(isApplyFilter = false) {
      try {
        const result = await ServicePointRecords.findAll(
          this.filters.id,
          this.filters.startDate,
          this.filters.endDate,
          this.page
        );

        if (isApplyFilter) {
          this.pointRecords = result;
          return;
        }

        if (Object.keys(result.points).length) {
          if (Object.keys(this.pointRecords.points).length) {
            Object.keys(result.points).forEach((keyResult) => {
              Object.keys(result.points[keyResult]).forEach(
                (keyNameEmployee) => {
                  if (!this.pointRecords.points[keyResult]) {
                    this.pointRecords.points[keyResult] = {
                      [keyNameEmployee]:
                        result.points[keyResult][keyNameEmployee],
                    };
                  } else if (
                    this.pointRecords.points[keyResult][keyNameEmployee]
                  ) {
                    this.pointRecords.points[keyResult][keyNameEmployee] = [
                      ...this.pointRecords.points[keyResult][keyNameEmployee],
                      ...result.points[keyResult][keyNameEmployee],
                    ];
                  } else {
                    this.pointRecords.points[keyResult][keyNameEmployee] =
                      result.points[keyResult][keyNameEmployee];
                  }
                }
              );
            });
          } else {
            this.pointRecords = result;
          }
        }
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao listar os registros",
          type: "info",
        });
      }
    },

    async openPointRegisterLocale() {
      try {
        this.loadMaps = true;
        const url = `${process.env.VUE_APP_KEY_BASE_URL_GEOLOCATION}?latlng=${this.pointSelected.lat},${this.pointSelected.long}&key=${process.env.VUE_APP_KEY_API_GOOGLE_MAPS}`;

        const { data } = await axios.get(url);
        if (data.status === "OK") {
          const address = data.results[0].formatted_address;
          this.urlMaps = `${process.env.VUE_APP_KEY_BASE_URL_MAPS}?key=${process.env.VUE_APP_KEY_API_GOOGLE_MAPS}&q=${address}&center=${this.pointSelected.lat},${this.pointSelected.long}`;
        }
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao buscar a localização",
          type: "info",
        });
      } finally {
        this.loadMaps = false;
      }
    },

    async findAllEmployees() {
      try {
        const result = await ServiceEmployees.getEmployeesForSelectBox();
        this.employees = result.data;
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao buscar os funcionários",
          type: "info",
        });
      }
    },

    async apllyFilters() {
      this.page = 1;
      this.removeEventScroll();
      this.backTop();

      this.pointRecords = { points: [] };
      await this.findAll(true);

      this.addEventScroll();
      this.isBoxFilterVisible = false;
    },

    async clearFilters() {
      this.clearFilterOptions();

      this.page = 1;
      this.removeEventScroll();
      this.backTop();

      this.pointRecords = { points: [] };
      await this.findAll(true);

      this.addEventScroll();
      this.isBoxFilterVisible = false;
    },

    async updateRegister() {
      try {
        if (!this.registrationDateToUpdate) {
          this.handleFocusToUpdate();
          return this.$toast.open({
            message: "Data do registro esta em um formato invalido",
            type: "info",
          });
        }

        await ServicePointRecords.update({
          dataRegistro: this.registrationDateToUpdate,
          id: this.pointSelected.id,
        });

        this.clearFilters();
        this.findDetailsNow();
        this.closeModalDetails();

        this.$toast.open({
          message: "Ponto Atualizado",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao atualizar o ponto",
          type: "info",
        });
      }
    },

    async saveNewRecord() {
      try {
        const isValid = ValidatePointRecords(this.recordsToSave);

        if (isValid) {
          this.handleFocusToSave(isValid.keyError);
          return this.$toast.open({
            message: isValid.message,
            type: "info",
          });
        }

        await ServicePointRecords.saveByAdm(this.recordsToSave);

        this.clearBeforeSave();

        this.$toast.open({
          message: "Novo Ponto Registrado",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);
        if (typeError) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao remover o registro",
          type: "info",
        });
      }
    },

    async removeRegister() {
      try {
        await ServicePointRecords.delete(this.pointSelected.id);

        this.clearFilters();
        this.findDetailsNow();
        this.closeModalDetails();

        this.$toast.open({
          message: "Registro Removido",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao remover o registro",
          type: "info",
        });
      }
    },

    async findDetailsNow() {
      try {
        this.loadingDetails = true;
        this.details = await ServicePointRecords.findDetailsNow();
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao buscar os detalhes dos pontos",
          type: "info",
        });
      } finally {
        this.loadingDetails = false;
      }
    },

    async printReport() {
      try {
        this.loadingReport = true;
        this.isPrint = true;

        const data = await ServicePointRecords.generateReport(
          this.filters.id,
          this.filters.startDate,
          this.filters.endDate
        );

        const iframe = document.getElementById("printf");
        const iframeDoc =
          iframe.contentDocument || iframe.contentWindow.document;
        iframeDoc.body.innerHTML = data;

        const iframeWindow = iframe.contentWindow;
        iframeWindow.focus();
        iframeWindow.print();
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (typeError) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao imprimir o relatório",
          type: "info",
        });
      } finally {
        this.loadingReport = false;
      }
    },

    handleFocusToUpdate() {
      this.$refs.dataRegistro.$el.children[0].focus();

      this.isInvalidInput = "isInvalidInput";
    },

    handleFocusToSave(refName) {
      const newRefName = `${refName}ToSave`;

      if ("textField" in this.$refs[newRefName]) {
        this.$refs[newRefName].$children[0].focus();
      } else {
        this.$refs[newRefName].$el.children[0].focus();
      }

      this.isInvalidInput = "isInvalidInput";
    },

    editRegister() {
      this.showEditRegister = true;
    },

    cancelEdit() {
      this.showEditRegister = false;
      const dateTime = DateTime.fromFormat(
        JSON.parse(JSON.stringify(this.pointSelected.dataRegistro)),
        "yyyy-MM-dd HH:mm:ss"
      );

      this.registrationDateToUpdate = dateTime.toISO({
        includeOffset: false,
      });
    },

    toggleCheckbox() {
      this.isBoxFilterVisible = !this.isBoxFilterVisible;
    },

    handleClickOutside(event) {
      if (this.$refs.lblFilter.contains(event.target)) return;

      if (!this.$refs.boxFilter.contains(event.target)) {
        this.isBoxFilterVisible = false;
      }
    },

    clearInvalidInput() {
      this.isInvalidInput = "";
    },

    clearFilterOptions() {
      this.filters = {
        id: null,
        startDate: null,
        endDate: null,
      };

      this.$refs.idFuncionario.valueItems = "";
      this.$refs.startDate.valueInput = null;
      this.$refs.endDate.valueInput = null;
    },

    showModalDetails(pointSelected) {
      this.$bvModal.show("modalDetailsPoint");
      this.pointSelected = pointSelected;

      const dateTime = DateTime.fromFormat(
        JSON.parse(JSON.stringify(this.pointSelected.dataRegistro)),
        "yyyy-MM-dd HH:mm:ss"
      );

      this.registrationDateToUpdate = dateTime.toISO({
        includeOffset: false,
      });
    },

    handleTypeColor(point) {
      if (point.type === "entrada") {
        return "entryRegister";
      } else {
        return "exitRegister ";
      }
    },

    handleTextTypePoint(point) {
      if (point.type === "entrada") {
        return "textEntryRegister";
      } else {
        return "textExitRegister ";
      }
    },

    closeModalDetails() {
      this.pointSelected = {
        id: null,
        idEmpresa: null,
        idUsuario: null,
        dataRegistro: null,
        lat: null,
        long: null,
        idFuncionario: null,
        nomeFuncionario: null,
        period: "manha",
        type: null,
        date: null,
        day: null,
        hours: null,
      };
      this.registrationDateToUpdate = null;

      this.urlMaps = null;
      this.showEditRegister = false;
      this.$bvModal.hide("modalDetailsPoint");
    },

    closeModalNewRecord() {
      this.recordsToSave = {
        idFuncionario: null,
        dataRegistro: null,
        tipo: null,
        lat: null,
        long: null,
      };

      this.$bvModal.hide("modalNewRecord");
    },

    clearBeforeSave() {
      this.pointRecords = { points: [] };
      this.findAll();
      this.findDetailsNow();
      this.closeModalNewRecord();
    },

    openModalNewRecord() {
      this.$bvModal.show("modalNewRecord");
    },

    formatDay(day) {
      if (day) return day.split("-").join(" ");
    },

    handleScroll(event) {
      const element = event.target;

      this.isEnableBtnBackTop = element.scrollTop > 0 ? true : false;

      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.page += 1;
        this.findAll();
      }
    },

    addEventScroll() {
      this.$refs.infiniteScroll.addEventListener("scroll", this.handleScroll);
    },

    removeEventScroll() {
      this.$refs.infiniteScroll.removeEventListener(
        "scroll",
        this.handleScroll
      );
    },
    backTop() {
      this.$refs.infiniteScroll.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  computed: {
    handleColorPointPeriod() {
      const values = {
        manha: "morning",
        tarde: "afternoon",
        noite: "night",
      };

      return values[this.pointSelected.period];
    },

    handleColorPointType() {
      const values = {
        entrada: "entry",
        saida: "exit",
      };

      return values[this.pointSelected.type];
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    this.findAll();
    this.findAllEmployees();
    this.findDetailsNow();

    this.addEventScroll();
  },
  beforeDestroy() {
    this.removeEventScroll();
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.containerGeneral {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden;
}

.contentGeneral {
  width: calc(100% - 60px);
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 0 1rem 0;
  background-color: #dbdbdb6e;
}

.contentPrintReport {
  display: none;
}

.contentTitleAndIconReport {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.title {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  color: black;
}

.subtitle {
  font-weight: 600;
  font-size: 14px;
}

.boxFilter {
  position: absolute;
  top: 28px;
  left: 10px;
  z-index: 200;
  transition: ease-in all 0.1s;
  padding: 0.5rem 0.8rem 0.7rem 0.8rem;
  flex-direction: column;
  gap: 1rem;
  background-color: white !important;
  height: auto;
  width: auto;
  border-radius: 0 10px 10px 10px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.155);
}

.boxFilter::before {
  content: "";
  position: absolute;
  left: 0;
  top: -2px;
  width: 6px;
  height: 5px;
  background-color: white;
  border-radius: 100% 50px 100% 100%;
  box-shadow: -2px -5px 5px 1px rgba(0, 0, 0, 0.066);
  transform: rotate(0);
}

.cardPointDetails {
  width: 230px;
  min-width: 230px;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.155);
  border-radius: 10px;
  padding: 0.5rem 1rem;
  cursor: pointer !important;
  position: relative;
  background-color: white;
}

.contentIconNewRecord {
  width: 23px;
  height: 23px;
  min-width: auto;
  padding: 0.3rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(72, 184, 101);
  box-shadow: #00000069 0px 1px 3px 1px;
  cursor: pointer !important;
  transition: ease-in all 0.1s;
}

.contentIconNewRecord:hover {
  background-color: rgb(56, 144, 79);
  transition: ease-in all 0.1s;
}

.contentIconReport {
  width: 30px;
  height: 30px;
  min-width: auto;
  padding: 0.3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(72, 184, 101);
  box-shadow: #00000069 0px 1px 3px 1px;
  cursor: pointer !important;
  transition: ease-in all 0.1s;
}

.contentIconReport:hover {
  background-color: rgb(56, 144, 79);
  transition: ease-in all 0.1s;
}

.employeeName {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: black;
  letter-spacing: 0.3px;
  margin: 0;
  font-weight: 600;
}

.iconReport {
  color: white;
  margin: 0;
  font-weight: 600;
}

.contentEmployeeName {
  display: flex;
  gap: 0.3rem;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.arrow {
  display: flex;
  align-items: flex-end;
  margin: 0;
  font-size: 18px;
  font-weight: normal !important;
  color: black;
}

.footerCard {
}

.contentFooterLeft {
  display: flex;
}

.contentFooterLeft > :first-child {
  margin: 0;
  font-size: 30px;
  display: flex;
  align-items: flex-end;
  font-weight: 600;
}

.dueHours {
  color: #cf3636 !important;
  font-size: 30px;
  margin: 0;
  font-weight: 600;
}

.overtime {
  color: rgb(72, 184, 101);
  font-size: 30px;
  margin: 0;
  font-weight: 600;
}

.numbersDefault {
  margin: 0;
  color: #a1a1a1;
  margin-top: 1.4rem;
}

.circleImg {
  width: 50px;
  height: 50px;
  background-color: #cccccc6f;
  border-radius: 50%;
  position: absolute;
  right: 1rem;
  bottom: 0.5rem;
}

.cardIcon {
  width: 45px;
  position: absolute;
  right: 32px;
  bottom: 0.3rem;
}

.cardIconLostTime {
  width: 45px;
  position: absolute;
  right: 40px;
  bottom: 0.5rem;
}

.clockTimePlus {
  width: 40px;
  position: absolute;
  right: 43px;
  bottom: 0.5rem;
}

.cardIconPlus {
  width: 30px;
  position: absolute;
  right: 10px;
  bottom: 2.4rem;
}

.cardIconMouseClick {
  width: 20px;
  position: absolute;
  right: 13px;
  bottom: 0.8rem;
}

.iconFilter {
  width: 25px;
  height: 20px;
  position: relative;
}

.lblFilter {
  width: 25px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(72, 184, 101);
  box-shadow: #00000069 0px 1px 3px 0px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
  transition: ease-in all 0.1s;
}

.lblFilter:hover {
  background-color: rgb(56, 144, 79);
  transition: ease-in all 0.1s;
}

.contentCardsRegister {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: calc(100vh - 1.5rem - 240px);
  max-height: calc(100vh - 1.5rem - 240px);
  padding-left: 0.2rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  overflow: auto;
}

.contentCardsRegister::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.contentCardsRegister::-webkit-scrollbar-track {
  background: transparent;
}

.contentCardsRegister::-webkit-scrollbar-thumb {
  background-color: rgb(56, 214, 98);
  border-radius: 5px;
}

.textMonth {
  margin: 0 1rem 1.2rem 0;
  font-weight: 600;
  letter-spacing: 0.1rem;
  color: rgb(62, 102, 174);
  font-size: 14px;
  text-align: end;
}

.contentRegisterDayAndHours {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding: 1rem 1rem;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.155);
  cursor: pointer;
}

.exitRegister {
  border-left: solid 6px red;
}

.textExitRegister {
  color: rgb(211, 10, 10) !important;
}

.entryRegister {
  border-left: solid 6px rgb(62, 102, 174);
  color: rgb(62, 102, 174);
}

.textEntryRegister {
  color: rgb(62, 102, 174) !important;
}

.userName {
  margin: 0;
  font-size: 11px;
  font-weight: 600;
  color: rgb(62, 102, 174);
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.titlePointRecords {
  margin: 0;
  font-weight: bold;
  font-size: 15px;
  color: #363636;
}

.btnRegister {
  width: 87px;
  height: 25.5px;
  margin-bottom: 0.15rem;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: rgb(56, 214, 98);
  color: white;
  border-radius: 50px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  transition: ease-in all 0.1s;
}

.btnRegister:hover {
  background-color: rgb(72, 184, 101);
  transition: ease-in all 0.1s;
}

.backTop {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 10px;
  left: 50%;
  border-radius: 50%;
  /* background-color: rgb(72, 184, 101);
  color: white; */
  background-color: rgba(72, 184, 102, 0.496);
  color: rgba(255, 255, 255, 0.515);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.608);
  border: solid 1px rgba(255, 255, 255, 0.46);
  cursor: pointer;
  transition: ease-in all 0.1s;
}

.backTop:hover {
  background-color: rgb(72, 184, 101);
  color: white;
  transition: ease-in all 0.1s;
}

.date {
  margin: 0 0 0 0;
  font-weight: 600;
  color: rgb(62, 102, 174);
  font-size: 12px;
}

.hours {
  display: flex;
  align-items: center;
  box-shadow: 0 0 1px 1px rgb(72, 184, 101);
  padding: 0 0.3rem;
  border-radius: 50px;
  color: rgb(72, 184, 101) !important;
  font-weight: normal !important;
  font-size: 11px;
  margin: 0;
  letter-spacing: 0.3px;
}

.day {
  font-size: 13px;
  font-weight: normal;
  margin: 0;
  display: flex;
  align-items: flex-end;
  font-weight: 600;
  color: #7e7e7e;
  border-bottom: solid 1px rgb(207, 208, 209);
  text-transform: capitalize;
}

.nameEmployee {
  margin: 0;
  letter-spacing: 0.1rem;
  padding: 0.3rem 0.5rem;
  font-size: 11px;
  color: white;
  box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.155);
  border-radius: 5px;
  background-color: rgb(62, 102, 174);
  text-align: start;
  border-radius: 10px 10px 0 0;
}

.nameEmployee::after {
  content: "";
  width: 100%;
  height: 5px;
  background-color: white;
  position: absolute;
  left: 0;
  bottom: -2px;
  border-radius: 100%;
}

.contentNotPointRecords {
  padding: 1rem;
  height: 100%;
  max-height: calc(100vh - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #7e7e7e;
}

.spin {
  width: 100px;
  height: 100px;
  color: rgb(56, 214, 98);
}

.spinReport {
  width: 20px;
  height: 20px;
  color: white;
}

.spinLoadingDetails {
  width: 35px;
  height: 35px;
  color: rgb(62, 102, 174);
  font-weight: normal !important;
}

.isLoading {
  padding: 0;
  background-color: transparent !important;
  box-shadow: none;
  transition: ease-in all 0.1s;
}

.rowContentInputs {
  display: flex;
  gap: 1rem;
}

.contentInputs {
  width: 100%;
}

.contentInputs p {
  margin: 0;
  font-size: 10px;
  font-weight: 600;
  color: black;
}

.btnApplyFilter {
  margin-top: 0.5rem;
  padding: 0.3rem 0.5rem;
  font-size: 11px;
  color: white;
  background-color: rgb(72, 184, 101);
  border-radius: 50px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.303);
  cursor: pointer;
  transition: ease-in all 0.1s;
}

.btnApplyFilter:hover {
  background-color: rgb(58, 152, 83);
  transition: ease-in all 0.1s;
}

.btnEdit {
  margin-top: 0.5rem;
  padding: 0.3rem 0.5rem;
  border-radius: 50px;
  box-shadow: 0 0 1px 1px rgb(62, 102, 174);
  cursor: pointer;
  transition: ease-in all 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 10px;
  letter-spacing: 0.1rem;
  color: rgb(62, 102, 174);
  font-weight: 600;
}

.btnEdit:hover {
  background-color: rgb(52, 85, 145);
  transition: ease-in all 0.1s;
  color: white;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.303);
  font-weight: normal;
}

.btnCancelEdit {
  margin-top: 0.5rem;
  padding: 0.3rem 0.5rem;
  font-size: 11px;
  box-shadow: 0 0 1px 1px rgb(187, 48, 48);
  border-radius: 50px;
  cursor: pointer;
  transition: ease-in all 0.1s;
  color: rgb(187, 48, 48);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 10px;
  letter-spacing: 0.1rem;
  font-weight: 600;
}

.btnCancelEdit:hover {
  background-color: brown;
  transition: ease-in all 0.1s;
  color: white;
  font-weight: normal;
}

.btnClearFilter {
  margin-top: 0.5rem;
  padding: 0.3rem 0.5rem;
  font-size: 11px;
  color: white;
  background-color: #575353;
  border-radius: 50px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.303);
  cursor: pointer;
  transition: ease-in all 0.1s;
}

.btnClearFilter:hover {
  background-color: black;
  transition: ease-in all 0.1s;
}

.contentFilters {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.titleFilters {
  margin: 0;
  font-size: 12px;
  color: rgb(62, 102, 174);
  font-weight: 600;
  border-radius: 50px;
}

.btnContentReceive {
  font-size: 15px;
  height: 40px;
  border: none;
  border-radius: 5px;
  width: 100%;
  position: relative;
  color: white;
  background: rgb(43, 43, 43);
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.272);
}

.titleModal {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.periodText {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0;
  font-size: 13px;
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 50px;
  background-color: white;
  padding: 0.2rem 0.5rem;
  font-size: 11px;
}

.entry {
  color: rgb(62, 102, 174);
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.exit {
  color: brown;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.morning {
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
}

.afternoon {
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
}

.night {
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
}

.modalDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.contentCardModalDetails {
  padding: 0.5rem;
  border-radius: 10px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.155);
  background-color: #e8e8e8;
}

.rowModal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.colModals {
  display: flex;
  flex-direction: column;
}

.colModals p {
  margin: 0;
}

.colModals > :first-child {
  font-size: 14px;
  color: rgb(62, 102, 174);
}

.colModals > :last-child {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.textNewPoint {
  font-weight: 600;
  font-size: 20px;
  margin: 0;
}

.modalNewRecord {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contentCardModalNewRecords {
  height: 100%;
  padding: 1.2rem 0.5rem;
  border-radius: 10px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.155);
  background-color: #e8e8e8;
}

.containerHeader {
  padding: 1rem 2rem 1rem 3rem;
}

.contentHeader {
  display: flex !important;
  gap: 1.5rem;
}

.containerCards {
  padding: 0px 0.5rem 1rem 3rem;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .boxFilter {
    left: -95px;
    border-radius: 10px;
  }

  .boxFilter::before {
    content: "";
    position: absolute;
    left: 0;
    top: -2px;
    width: 6px;
    height: 5px;
    background-color: transparent;
    border-radius: none !important;
    box-shadow: none !important;
    transform: rotate(0);
  }
}

@media screen and (max-width: 800px) {
  .contentHeader {
    padding: 0.5rem 0.5rem;
    overflow-x: auto;
  }

  .contentHeader::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .contentHeader::-webkit-scrollbar-track {
    background: transparent;
  }

  .contentHeader::-webkit-scrollbar-thumb {
    background-color: rgb(56, 214, 98);
    border-radius: 5px;
  }

  .contentCardsRegister {
    padding-bottom: 2rem;
  }
}

@media screen and (min-width: 700px) {
  .titleMonthRegister,
  .userName {
    font-size: 14px;
  }
}

@media screen and (max-width: 700px) {
  .containerGeneral {
    justify-content: flex-start;
    align-items: center;
  }

  .contentGeneral {
    width: 100%;
    height: 100%;
  }

  .contentCardsRegister {
    height: calc(100vh - 225px);
    max-height: calc(100vh - 225px);
  }

  .contentTitleAndIconReport {
    justify-content: flex-start;
    gap: calc(100% - 18rem);
  }

  .containerHeader {
    padding: 1rem 0;
  }

  .contentHeader {
    padding: 0.5rem;
  }

  .containerCards {
    padding: 0.5rem;
  }

  .title {
    padding: 0 1rem;
  }

  .boxFilter {
    width: 300px;
  }
}

@media screen and (max-height: 600px) {
  .employeeName {
    font-size: 10px;
  }

  .arrow {
    font-size: 13px;
  }

  .contentFooterLeft > :first-child {
    margin: 0;
    font-size: 25px;
    display: flex;
    align-items: flex-end;
  }

  .numbersDefault {
    font-size: 11px;
  }

  .circleImg {
    width: 45px;
    height: 45px;
    right: 1rem;
    bottom: 0.5rem;
  }

  .cardIcon {
    width: 35px;
    right: 40px;
    bottom: 0.3rem;
  }

  .clockTimePlus {
    width: 35px;
    right: 40px;
    bottom: 0.3rem;
  }

  .cardIconLostTime {
    width: 35px;
    right: 40px;
    bottom: 0.3rem;
  }

  .cardIconPlus {
    width: 22px;
    right: 18px;
    bottom: 2.5rem;
  }

  .cardIconMouseClick {
    width: 17px;
    right: 16px;
    bottom: 0.5rem;
  }

  .boxFilter {
    gap: 0.5rem !important;
  }

  .titleFilters {
    font-size: 11px;
  }

  .contentInputs p {
    font-size: 10px;
  }

  .title {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }

  .subtitle {
    font-size: 12px;
  }

  .contentGeneral {
    gap: 1rem;
  }

  .nameEmployee {
    font-size: 9px;
  }

  .textMonth {
    font-size: 12px;
  }

  .date {
    font-size: 11px;
  }

  .day {
    font-size: 12px;
  }

  .contentCardsRegister {
    height: calc(100vh - 1.5rem - 200px);
    max-height: calc(100vh - 1.5rem - 200px);
  }
}
</style>