<template>
  <div class="general-container">
    <div class="contentModal">
      <div
        class="rowContainer"
        style="align-items: flex-start"
        v-if="!enableCreateCardMachines"
      >
        <span class="subtitles">
          <b-icon-person-plus-fill style="color: #06c0eb" />
          <p class="m-0 p-0" style="color: #06c0eb">Novos Usuários</p>
        </span>

        <div class="contentRowContentHeader">
          <div
            style="
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              gap: 20px;
            "
          >
            <div class="rowContentHeader">
              <div class="inputResponsive" style="width: 30%">
                <InputSelect
                  label="Nível do usuário"
                  :items="defaultUsers"
                  valueField="value"
                  textField="nivel"
                  :enableIconCreate="false"
                  :dataSelect.sync="dataUser.nivel"
                  ref="userLevel"
                  @changed="clearInvalid"
                />
              </div>

              <div class="inputResponsive" style="width: 70%">
                <Input
                  label="Usuário"
                  labelFixed
                  :dataInput.sync="dataUser.userName"
                  ref="userName"
                  @changed="clearInvalid"
                />
              </div>
            </div>
            <div class="rowContentHeader">
              <div class="inputResponsive" style="width: 100%">
                <Input
                  label="Email"
                  labelFixed
                  :dataInput.sync="dataUser.login"
                  ref="userLogin"
                  type="email"
                  @changed="clearInvalid"
                />
              </div>
            </div>

            <div class="rowContentHeader" v-if="!enableEditPermission">
              <div class="inputResponsive" style="width: 50%">
                <InputPassword
                  label="Senha"
                  labelFixed
                  :dataInput.sync="dataUser.senha"
                  ref="userPassword"
                  @changed="clearInvalid"
                />
              </div>

              <div
                class="inputResponsive"
                style="width: 50%"
                v-if="!enableEditPermission"
              >
                <InputPassword
                  label="Confirmar Senha"
                  labelFixed
                  :dataInput.sync="confirmedPassword"
                  @changed="clearInvalid"
                  ref="confirmedPassword"
                />
              </div>
            </div>

            <div class="contentBtnUsersActions" v-if="!enableEditPermission">
              <b-button
                :class="`btnCreate ${isLoading && 'btnDisabled'}`"
                @click="handleSaveOrUpdate"
              >
                <p>
                  {{ dataUser.id ? "Atualizar Usuário" : "Criar Usuário" }}
                </p>
                <b-spinner v-if="isLoading" class="spin" />
              </b-button>

              <b-button class="btnCreate btnNewUser" @click="clearDataUser">
                <p>Limpar</p>
              </b-button>

              <b-button
                v-if="dataUser.id"
                class="btnCreate btnCancelPermissions"
                @click="clearDataUser"
              >
                Cancelar
              </b-button>
            </div>

            <div
              v-else
              style="
                display: flex;
                align-items: flex-end;
                width: 100%;
                gap: 15px;
              "
            >
              <b-button
                :class="`btnCreate ${isLoading && 'btnDisabled'}`"
                @click="updatePermissions"
              >
                <p>Atualizar Permissões</p>
                <b-spinner v-if="isLoading" class="spin" />
              </b-button>

              <b-button
                class="btnCreate btnCancelPermissions"
                @click="clearUpdatePermissions"
              >
                Cancelar
              </b-button>
            </div>

            <div
              style="
                width: 100%;
                height: 20px;
                diplay: flex;
                justify-content: flex-start;
              "
            >
              <p
                v-if="invalid"
                style="color: #e01515; font-size: 13px; margin: 0"
              >
                {{ invalid }}
              </p>
            </div>
          </div>

          <div v-if="enableEditPermission" class="content-total-permissions">
            <label for="total-permission" class="textTotalPermission">
              <input
                type="checkbox"
                id="total-permission"
                style="width: 18px; height: 18px"
                ref="isTotalPermission"
                @change="grantAllPermission"
              />
              Conceder permissão total
            </label>

            <label for="registro-ponto" class="textTotalPermission">
              <input
                type="checkbox"
                id="registro-ponto"
                style="width: 18px; height: 18px"
                v-model="
                  dataUser.permissoes.configuracoes.registroPonto
                    .painelPrincipal
                "
              />

              Registro de pontos
            </label>
          </div>
        </div>

        <div :class="isTablePermissionsLoading && 'contentTableLoading'">
          <b-spinner v-if="isTablePermissionsLoading" class="spinInTable" />
        </div>
      </div>

      <div
        class="rowContainer"
        style="align-items: flex-start; justify-content: space-between"
        v-else
      >
        <span class="subtitles">
          <b-icon-person-plus-fill style="color: #06c0eb" />
          <p class="m-0 p-0" style="color: #06c0eb">Cadastro de Maquininhas</p>
        </span>

        <div style="position: absolute; right: 10px">
          <b-button class="btnNewCardMachine" @click="addMoreCardMachine">
            + Adicionar
          </b-button>
        </div>

        <div
          style="
            width: 100%;
            height: auto;
            max-height: 150px;
            margin-top: 1.5rem;
            padding: 1rem 0.5rem 1rem 0;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            overflow: auto;
          "
        >
          <div
            style="width: 100%; display: flex; justify-content: space-between"
            v-for="machine in cardMachines.stone.serialMachines"
            :key="machine.idList"
          >
            <div class="inputResponsive" style="width: 70%">
              <Input
                label="Numero de serie"
                labelFixed
                ref="serialNumber"
                :dataInput.sync="machine.serialNumber"
              />
            </div>

            <div
              class="contentIconsUser"
              @click="removeCardMachine(machine.idList)"
            >
              <b-icon-x scale="2" />
            </div>
          </div>
        </div>

        <div class="contentBtnCardMachines mt-3">
          <div style="width: 100px">
            <b-button
              :class="`btnCreate ${isLoading && 'btnDisabled'}`"
              @click="updateCardMachines"
            >
              <p>Salvar</p>
              <b-spinner v-if="isLoading" class="spin" />
            </b-button>
          </div>

          <div style="width: 100px">
            <b-button
              class="btnCreate btnCancelPermissions"
              @click="clearCardMachines"
            >
              Cancelar
            </b-button>
          </div>
        </div>
      </div>

      <div class="rowContainer" style="align-items: flex-start">
        <span class="subtitles">
          <b-icon-person-lines-fill style="color: #06c0eb" />
          <p class="m-0 p-0" style="color: #06c0eb">Usuários Registrados</p>
        </span>

        <div class="contentRowContentHeader">
          <div class="rowContentHeader">
            <div class="tableActions" style="width: 100%" v-if="users.length">
              <table>
                <thead>
                  <tr>
                    <th style="width: 50%">Login</th>
                    <th style="width: 100px">Nível</th>
                    <th :class="textActions && 'textActions'">
                      {{ textActions }}
                    </th>
                  </tr>
                </thead>
                <tbody style="margin-top: 5px">
                  <tr v-for="user in users" :key="user.id">
                    <td style="width: 50%; text-align: start">
                      <p>
                        {{ user.login }}
                      </p>
                    </td>
                    <td style="width: 100px; text-align: start">
                      {{
                        user.nivel === "accountant" ? "contador" : user.nivel
                      }}
                    </td>
                    <td
                      style="
                        width: 170px;
                        display: flex;
                        justify-content: center;
                        gap: 15px;
                      "
                    >
                      <div
                        v-if="isStoneIntegration && !enableEditPermission"
                        class="contentIconsUser"
                        @click="handleEnableCardMachines(user.id)"
                        @mouseenter="handleTextActions('cardMachines')"
                        @mouseleave="handleRemoveTextAction"
                      >
                        <b-icon-tablet scale="1.5" />
                      </div>

                      <div
                        class="contentIconsUser"
                        @click="handleEnablePermissions(user.id)"
                        @mouseenter="handleTextActions('permission')"
                        @mouseleave="handleRemoveTextAction"
                      >
                        <b-icon-shield-lock scale="1.5" />
                      </div>

                      <div
                        v-if="!enableEditPermission"
                        class="contentIconsUser"
                        @click="handleFindUser(user.id)"
                        @mouseenter="handleTextActions('edit')"
                        @mouseleave="handleRemoveTextAction"
                      >
                        <b-icon-pencil-square scale="1.2" />
                      </div>

                      <div
                        v-if="!enableEditPermission"
                        class="contentIconsUser"
                        @click="deleteUser(user.id, user.idUsuarioGestao)"
                        @mouseenter="handleTextActions('delete')"
                        @mouseleave="handleRemoveTextAction"
                      >
                        <b-icon-x scale="2" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              v-if="!users.length && !isTableLoading"
              style="
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <span style="font-size: 15px"
                >Ops!... Nenhum Resultado Encontrado</span
              >
            </div>
          </div>
        </div>

        <div :class="isTableLoading && 'contentTableLoading'">
          <b-spinner v-if="isTableLoading" class="spinInTable" />
        </div>
      </div>
    </div>

    <div class="contentPermissions" v-if="enableEditPermission">
      <b-card-text
        class="w-100"
        v-if="dataUser.permissoes.configuracoes.registroPonto.painelPrincipal"
      >
        <b-navbar toggleable class="cardSales">
          <b-navbar-toggle
            target="navbar-toggle-collapse-working"
            id="dataSales"
            class="sizeCards"
          >
            <template #default="{ expanded }">
              <div class="sizeBtnOpenCard">
                <div>
                  <b-icon
                    v-if="expanded"
                    icon="dash-square-fill"
                    style="color: #00395d"
                  ></b-icon>
                  <b-icon v-else icon="plus-square-fill" style="color: #00395d">
                  </b-icon>
                </div>
                <div class="titles">Dias Trabalhados</div>
              </div>
            </template>
          </b-navbar-toggle>

          <b-collapse
            id="navbar-toggle-collapse-working"
            class="col-md-12 col-sm-12 col-lg-12 col-xl-12"
            is-nav
          >
            <b-navbar-nav class="ml-auto">
              <div style="margin-bottom: 30px">
                <div
                  style="
                    margin-top: 2rem;
                    display: flex;
                    justify-content: flex-start;
                    width: 445px;
                    padding: 0 1rem;
                  "
                >
                  <InputSelect
                    label="Funcionários"
                    :items="employees"
                    valueField="id"
                    textField="nomeFuncionario"
                    :enableIconCreate="false"
                    :dataSelect.sync="dataUser.idFuncionario"
                    ref="idFuncionario"
                  />
                </div>
                <div class="rowContent">
                  <section class="containerWorkingSchedule">
                    <div
                      class="workingSchedule"
                      v-for="(dayKey, dayIdx) in Object.keys(workingSchedule)"
                      :key="dayIdx"
                    >
                      <div class="contentItensWorking">
                        <span class="contentDaysWorking">
                          <input
                            type="checkbox"
                            :id="`${dayIdx}`"
                            v-model="dataUser.horarios[dayKey].ativo"
                          />
                          <label :for="`${dayIdx}`">{{ dayKey }}</label>
                        </span>

                        <span
                          class="contentDaysWorking"
                          style="justify-content: center"
                        >
                          <label
                            style="position: absolute; top: -28px; right: 35px"
                            v-if="dayIdx == 0"
                            >Inicio
                          </label>
                          <input
                            class="inputTime"
                            type="time"
                            v-model="dataUser.horarios[dayKey].inicio"
                            @input="
                              handleWorkload(
                                dayKey,
                                dataUser.horarios[dayKey].inicio,
                                dataUser.horarios[dayKey].intervalo,
                                dataUser.horarios[dayKey].fim
                              )
                            "
                          />
                        </span>

                        <span
                          class="contentDaysWorking"
                          style="justify-content: center"
                        >
                          <label
                            style="position: absolute; top: -28px; right: 25px"
                            v-if="dayIdx == 0"
                            >Intervalo
                          </label>
                          <input
                            class="inputTime"
                            type="time"
                            v-model="dataUser.horarios[dayKey].intervalo"
                            @input="
                              handleWorkload(
                                dayKey,
                                dataUser.horarios[dayKey].inicio,
                                dataUser.horarios[dayKey].intervalo,
                                dataUser.horarios[dayKey].fim
                              )
                            "
                          />
                        </span>

                        <span
                          class="contentDaysWorking"
                          style="justify-content: center"
                        >
                          <label
                            style="position: absolute; top: -28px; right: 42px"
                            v-if="dayIdx == 0"
                            >Fim
                          </label>
                          <input
                            class="inputTime"
                            type="time"
                            v-model="dataUser.horarios[dayKey].fim"
                            @input="
                              handleWorkload(
                                dayKey,
                                dataUser.horarios[dayKey].inicio,
                                dataUser.horarios[dayKey].intervalo,
                                dataUser.horarios[dayKey].fim
                              )
                            "
                          />
                        </span>

                        <span
                          class="contentDaysWorking"
                          style="justify-content: center"
                        >
                          <label
                            style="
                              width: 100px;
                              position: absolute;
                              top: -28px;
                              right: -5px;
                            "
                            v-if="dayIdx == 0"
                            >Carga Horaria
                          </label>

                          <span
                            >{{ dataUser.horarios[dayKey].carga_horaria }}
                            {{
                              dataUser.horarios[dayKey].carga_horaria && " Hrs"
                            }}</span
                          >
                        </span>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-card-text>

      <b-card-text class="w-100">
        <b-navbar toggleable class="cardSales">
          <b-navbar-toggle
            target="navbar-toggle-collapse-sales"
            id="dataSales"
            class="sizeCards"
          >
            <template #default="{ expanded }">
              <div class="sizeBtnOpenCard">
                <div>
                  <b-icon
                    v-if="expanded"
                    icon="dash-square-fill"
                    style="color: #00395d"
                  ></b-icon>
                  <b-icon v-else icon="plus-square-fill" style="color: #00395d">
                  </b-icon>
                </div>
                <div class="titles">Vendas</div>
              </div>
            </template>
          </b-navbar-toggle>

          <b-collapse
            id="navbar-toggle-collapse-sales"
            class="col-md-12 col-sm-12 col-lg-12 col-xl-12"
            is-nav
          >
            <b-navbar-nav class="ml-auto">
              <div class="content-check-panel-principal">
                <label for="sale-panel-general" class="sale-panel-general">
                  Painel Geral
                  <input
                    type="checkbox"
                    id="sale-panel-general"
                    v-model="dataUser.permissoes.vendas.painelPrincipal"
                  />
                </label>
              </div>
              <div class="contentModal">
                <div class="rowContainer">
                  <span class="subtitles">
                    <b-icon-dash-circle></b-icon-dash-circle> Nova Venda</span
                  >

                  <div class="rowContent">
                    <div class="content-check">
                      <label for="permission-sale-access">
                        Acessar
                        <input
                          type="checkbox"
                          id="permission-sale-access"
                          v-model="dataUser.permissoes.vendas.novaVenda.acessar"
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-sale-save">
                        Salvar
                        <input
                          type="checkbox"
                          id="permission-sale-save"
                          v-model="dataUser.permissoes.vendas.novaVenda.salvar"
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-sale-edit">
                        Editar
                        <input
                          type="checkbox"
                          id="permission-sale-edit"
                          v-model="dataUser.permissoes.vendas.novaVenda.editar"
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-sale-see">
                        Ver
                        <input
                          type="checkbox"
                          id="permission-sale-see"
                          v-model="dataUser.permissoes.vendas.novaVenda.ler"
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-sale-delete">
                        Deletar
                        <input
                          type="checkbox"
                          id="permission-sale-delete"
                          v-model="dataUser.permissoes.vendas.novaVenda.deletar"
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div class="rowContainer">
                  <span class="subtitles">
                    <b-icon-dash-circle></b-icon-dash-circle> Novo
                    Orçamento</span
                  >

                  <div class="rowContent">
                    <div class="content-check">
                      <label for="permission-budget-access">
                        Acessar
                        <input
                          type="checkbox"
                          id="permission-budget-access"
                          v-model="
                            dataUser.permissoes.vendas.novoOrcamento.acessar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-budget-save">
                        Salvar
                        <input
                          type="checkbox"
                          id="permission-budget-save"
                          v-model="
                            dataUser.permissoes.vendas.novoOrcamento.salvar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-budget-edit">
                        Editar
                        <input
                          type="checkbox"
                          id="permission-budget-edit"
                          v-model="
                            dataUser.permissoes.vendas.novoOrcamento.editar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-budget-see">
                        Ver
                        <input
                          type="checkbox"
                          id="permission-budget-see"
                          v-model="dataUser.permissoes.vendas.novoOrcamento.ler"
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-budget-delete">
                        Deletar
                        <input
                          type="checkbox"
                          id="permission-budget-delete"
                          v-model="
                            dataUser.permissoes.vendas.novoOrcamento.deletar
                          "
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div class="rowContainer">
                  <span class="subtitles">
                    <b-icon-dash-circle></b-icon-dash-circle> Frente de
                    caixa</span
                  >

                  <div class="rowContent">
                    <div class="content-check">
                      <label for="permission-box-front-access">
                        Acessar
                        <input
                          type="checkbox"
                          id="permission-box-front-access"
                          v-model="
                            dataUser.permissoes.vendas.novoFrenteDeCaixa.acessar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-box-front-save">
                        Salvar
                        <input
                          type="checkbox"
                          id="permission-box-front-save"
                          v-model="
                            dataUser.permissoes.vendas.novoFrenteDeCaixa.salvar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-box-front-edit">
                        Editar
                        <input
                          type="checkbox"
                          id="permission-box-front-edit"
                          v-model="
                            dataUser.permissoes.vendas.novoFrenteDeCaixa.editar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-box-front-see">
                        Ver
                        <input
                          type="checkbox"
                          id="permission-box-front-see"
                          v-model="
                            dataUser.permissoes.vendas.novoFrenteDeCaixa.ler
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-box-front-delete">
                        Deletar
                        <input
                          type="checkbox"
                          id="permission-box-front-delete"
                          v-model="
                            dataUser.permissoes.vendas.novoFrenteDeCaixa.deletar
                          "
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-card-text>

      <b-card-text class="w-100">
        <b-navbar toggleable class="cardSales">
          <b-navbar-toggle
            target="navbar-toggle-collapse-registers"
            id="dataSales"
            class="sizeCards"
          >
            <template #default="{ expanded }">
              <div class="sizeBtnOpenCard">
                <div>
                  <b-icon
                    v-if="expanded"
                    icon="dash-square-fill"
                    style="color: #00395d"
                  ></b-icon>
                  <b-icon v-else icon="plus-square-fill" style="color: #00395d">
                  </b-icon>
                </div>
                <div class="titles">Cadastros</div>
              </div>
            </template>
          </b-navbar-toggle>

          <b-collapse
            id="navbar-toggle-collapse-registers"
            class="col-md-12 col-sm-12 col-lg-12 col-xl-12"
            is-nav
          >
            <b-navbar-nav class="ml-auto">
              <div class="content-check-panel-principal">
                <label for="register-panel-general">
                  Painel Geral
                  <input
                    type="checkbox"
                    id="register-panel-general"
                    v-model="dataUser.permissoes.cadastros.painelPrincipal"
                  />
                </label>
              </div>
              <div class="contentModal">
                <div class="rowContainer">
                  <span class="subtitles">
                    <b-icon-dash-circle></b-icon-dash-circle> Novo Cliente</span
                  >

                  <div class="rowContent">
                    <div class="content-check">
                      <label for="permission-customer-access">
                        Acessar
                        <input
                          type="checkbox"
                          id="permission-customer-access"
                          v-model="
                            dataUser.permissoes.cadastros.novoCliente.acessar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-customer-save">
                        Salvar
                        <input
                          type="checkbox"
                          id="permission-customer-save"
                          v-model="
                            dataUser.permissoes.cadastros.novoCliente.salvar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-customer-edit">
                        Editar
                        <input
                          type="checkbox"
                          id="permission-customer-edit"
                          v-model="
                            dataUser.permissoes.cadastros.novoCliente.editar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-customer-see">
                        Ver
                        <input
                          type="checkbox"
                          id="permission-customer-see"
                          v-model="
                            dataUser.permissoes.cadastros.novoCliente.ler
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-customer-delete">
                        Deletar
                        <input
                          type="checkbox"
                          id="permission-customer-delete"
                          v-model="
                            dataUser.permissoes.cadastros.novoCliente.deletar
                          "
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div class="rowContainer">
                  <span class="subtitles">
                    <b-icon-dash-circle></b-icon-dash-circle> Novo
                    Fornecedor</span
                  >

                  <div class="rowContent">
                    <div class="content-check">
                      <label for="permission-supplier-access">
                        Acessar
                        <input
                          type="checkbox"
                          id="permission-supplier-access"
                          v-model="
                            dataUser.permissoes.cadastros.novoFornecedor.acessar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-supplier-save">
                        Salvar
                        <input
                          type="checkbox"
                          id="permission-supplier-save"
                          v-model="
                            dataUser.permissoes.cadastros.novoFornecedor.salvar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-supplier-edit">
                        Editar
                        <input
                          type="checkbox"
                          id="permission-supplier-edit"
                          v-model="
                            dataUser.permissoes.cadastros.novoFornecedor.editar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-supplier-see">
                        Ver
                        <input
                          type="checkbox"
                          id="permission-supplier-see"
                          v-model="
                            dataUser.permissoes.cadastros.novoFornecedor.ler
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-supplier-delete">
                        Deletar
                        <input
                          type="checkbox"
                          id="permission-supplier-delete"
                          v-model="
                            dataUser.permissoes.cadastros.novoFornecedor.deletar
                          "
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div class="rowContainer">
                  <span class="subtitles">
                    <b-icon-dash-circle></b-icon-dash-circle> Novo Produto</span
                  >

                  <div class="rowContent">
                    <div class="content-check">
                      <label for="permission-product-access">
                        Acessar
                        <input
                          type="checkbox"
                          id="permission-product-access"
                          v-model="
                            dataUser.permissoes.cadastros.novoProduto.acessar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-product-save">
                        Salvar
                        <input
                          type="checkbox"
                          id="permission-product-save"
                          v-model="
                            dataUser.permissoes.cadastros.novoProduto.salvar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-product-edit">
                        Editar
                        <input
                          type="checkbox"
                          id="permission-product-edit"
                          v-model="
                            dataUser.permissoes.cadastros.novoProduto.editar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-product-see">
                        Ver
                        <input
                          type="checkbox"
                          id="permission-product-see"
                          v-model="
                            dataUser.permissoes.cadastros.novoProduto.ler
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-product-delete">
                        Deletar
                        <input
                          type="checkbox"
                          id="permission-product-delete"
                          v-model="
                            dataUser.permissoes.cadastros.novoProduto.deletar
                          "
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div class="rowContainer">
                  <span class="subtitles">
                    <b-icon-dash-circle></b-icon-dash-circle> Novo
                    Funcionario</span
                  >

                  <div class="rowContent">
                    <div class="content-check">
                      <label for="permission-employee-access">
                        Acessar
                        <input
                          type="checkbox"
                          id="permission-employee-access"
                          v-model="
                            dataUser.permissoes.cadastros.novoFuncionario
                              .acessar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-employee-save">
                        Salvar
                        <input
                          type="checkbox"
                          id="permission-employee-save"
                          v-model="
                            dataUser.permissoes.cadastros.novoFuncionario.salvar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-employee-edit">
                        Editar
                        <input
                          type="checkbox"
                          id="permission-employee-edit"
                          v-model="
                            dataUser.permissoes.cadastros.novoFuncionario.editar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-employee-see">
                        Ver
                        <input
                          type="checkbox"
                          id="permission-employee-see"
                          v-model="
                            dataUser.permissoes.cadastros.novoFuncionario.ler
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-employee-delete">
                        Deletar
                        <input
                          type="checkbox"
                          id="permission-employee-delete"
                          v-model="
                            dataUser.permissoes.cadastros.novoFuncionario
                              .deletar
                          "
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div class="rowContainer">
                  <span class="subtitles">
                    <b-icon-dash-circle></b-icon-dash-circle> Novo Serviço</span
                  >

                  <div class="rowContent">
                    <div class="content-check">
                      <label for="permission-service-access">
                        Acessar
                        <input
                          type="checkbox"
                          id="permission-service-access"
                          v-model="
                            dataUser.permissoes.cadastros.novoServico.acessar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-service-save">
                        Salvar
                        <input
                          type="checkbox"
                          id="permission-service-save"
                          v-model="
                            dataUser.permissoes.cadastros.novoServico.salvar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-service-edit">
                        Editar
                        <input
                          type="checkbox"
                          id="permission-service-edit"
                          v-model="
                            dataUser.permissoes.cadastros.novoServico.editar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-service-see">
                        Ver
                        <input
                          type="checkbox"
                          id="permission-service-see"
                          v-model="
                            dataUser.permissoes.cadastros.novoServico.ler
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-service-delete">
                        Deletar
                        <input
                          type="checkbox"
                          id="permission-service-delete"
                          v-model="
                            dataUser.permissoes.cadastros.novoServico.deletar
                          "
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-card-text>

      <b-card-text class="w-100">
        <b-navbar toggleable class="cardSales">
          <b-navbar-toggle
            target="navbar-toggle-collapse-service-order"
            id="dataSales"
            class="sizeCards"
          >
            <template #default="{ expanded }">
              <div class="sizeBtnOpenCard">
                <div>
                  <b-icon
                    v-if="expanded"
                    icon="dash-square-fill"
                    style="color: #00395d"
                  ></b-icon>
                  <b-icon v-else icon="plus-square-fill" style="color: #00395d">
                  </b-icon>
                </div>
                <div class="titles">Ordem de serviço</div>
              </div>
            </template>
          </b-navbar-toggle>

          <b-collapse
            id="navbar-toggle-collapse-service-order"
            class="col-md-12 col-sm-12 col-lg-12 col-xl-12"
            is-nav
          >
            <b-navbar-nav class="ml-auto">
              <div class="content-check-panel-principal">
                <label for="order-service-panel-general">
                  Painel Geral
                  <input
                    type="checkbox"
                    id="order-service-panel-general"
                    v-model="
                      dataUser.permissoes.ordensDeServico.painelPrincipal
                    "
                  />
                </label>
              </div>
              <div class="contentModal">
                <div class="rowContainer">
                  <span class="subtitles">
                    <b-icon-dash-circle></b-icon-dash-circle> Nova ordem de
                    serviço</span
                  >

                  <div class="rowContent">
                    <div class="content-check">
                      <label for="permission-order-service-access">
                        Acessar
                        <input
                          type="checkbox"
                          id="permission-order-service-access"
                          v-model="
                            dataUser.permissoes.ordensDeServico.novaOrdem
                              .acessar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-order-service-save">
                        Salvar
                        <input
                          type="checkbox"
                          id="permission-order-service-save"
                          v-model="
                            dataUser.permissoes.ordensDeServico.novaOrdem.salvar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-order-service-edit">
                        Editar
                        <input
                          type="checkbox"
                          id="permission-order-service-edit"
                          v-model="
                            dataUser.permissoes.ordensDeServico.novaOrdem.editar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-order-service-see">
                        Ver
                        <input
                          type="checkbox"
                          id="permission-order-service-see"
                          v-model="
                            dataUser.permissoes.ordensDeServico.novaOrdem.ler
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-order-service-delete">
                        Deletar
                        <input
                          type="checkbox"
                          id="permission-order-service-delete"
                          v-model="
                            dataUser.permissoes.ordensDeServico.novaOrdem
                              .deletar
                          "
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-card-text>

      <b-card-text class="w-100">
        <b-navbar toggleable class="cardSales">
          <b-navbar-toggle
            target="navbar-toggle-collapse-purchase"
            id="dataSales"
            class="sizeCards"
          >
            <template #default="{ expanded }">
              <div class="sizeBtnOpenCard">
                <div>
                  <b-icon
                    v-if="expanded"
                    icon="dash-square-fill"
                    style="color: #00395d"
                  ></b-icon>
                  <b-icon v-else icon="plus-square-fill" style="color: #00395d">
                  </b-icon>
                </div>
                <div class="titles">Compras</div>
              </div>
            </template>
          </b-navbar-toggle>

          <b-collapse
            id="navbar-toggle-collapse-purchase"
            class="col-md-12 col-sm-12 col-lg-12 col-xl-12"
            is-nav
          >
            <b-navbar-nav class="ml-auto">
              <div class="content-check-panel-principal">
                <label for="purchase-panel-general">
                  Painel Geral
                  <input
                    type="checkbox"
                    id="purchase-panel-general"
                    v-model="dataUser.permissoes.compras.painelPrincipal"
                  />
                </label>
              </div>
              <div class="contentModal">
                <div class="rowContainer">
                  <span class="subtitles">
                    <b-icon-dash-circle></b-icon-dash-circle> Nova nota de
                    compra</span
                  >

                  <div class="rowContent">
                    <div class="content-check">
                      <label for="permission-purchase-access">
                        Acessar
                        <input
                          type="checkbox"
                          id="permission-purchase-access"
                          v-model="
                            dataUser.permissoes.compras.novaCompra.acessar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-purchase-save">
                        Salvar
                        <input
                          type="checkbox"
                          id="permission-purchase-save"
                          v-model="
                            dataUser.permissoes.compras.novaCompra.salvar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-purchase-edit">
                        Editar
                        <input
                          type="checkbox"
                          id="permission-purchase-edit"
                          v-model="
                            dataUser.permissoes.compras.novaCompra.editar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-purchase-see">
                        Ver
                        <input
                          type="checkbox"
                          id="permission-purchase-see"
                          v-model="dataUser.permissoes.compras.novaCompra.ler"
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-purchase-delete">
                        Deletar
                        <input
                          type="checkbox"
                          id="permission-purchase-delete"
                          v-model="
                            dataUser.permissoes.compras.novaCompra.deletar
                          "
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div class="rowContainer">
                  <span class="subtitles">
                    <b-icon-dash-circle></b-icon-dash-circle> Notas fiscais
                    recebidas</span
                  >

                  <div class="rowContent">
                    <div class="content-check">
                      <label for="permission-nfe-receipt-access">
                        Acessar
                        <input
                          type="checkbox"
                          id="permission-nfe-receipt-access"
                          v-model="
                            dataUser.permissoes.compras.notasRecebidas.acessar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-nfe-receipt-save">
                        Salvar
                        <input
                          type="checkbox"
                          id="permission-nfe-receipt-save"
                          v-model="
                            dataUser.permissoes.compras.notasRecebidas.salvar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-nfe-receipt-edit">
                        Editar
                        <input
                          type="checkbox"
                          id="permission-nfe-receipt-edit"
                          v-model="
                            dataUser.permissoes.compras.notasRecebidas.editar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-nfe-receipt-see">
                        Ver
                        <input
                          type="checkbox"
                          id="permission-nfe-receipt-see"
                          v-model="
                            dataUser.permissoes.compras.notasRecebidas.ler
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-nfe-receipt-delete">
                        Deletar
                        <input
                          type="checkbox"
                          id="permission-nfe-receipt-delete"
                          v-model="
                            dataUser.permissoes.compras.notasRecebidas.deletar
                          "
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-card-text>

      <b-card-text class="w-100">
        <b-navbar toggleable class="cardSales">
          <b-navbar-toggle
            target="navbar-toggle-collapse-service-financial"
            id="dataSales"
            class="sizeCards"
          >
            <template #default="{ expanded }">
              <div class="sizeBtnOpenCard">
                <div>
                  <b-icon
                    v-if="expanded"
                    icon="dash-square-fill"
                    style="color: #00395d"
                  ></b-icon>
                  <b-icon v-else icon="plus-square-fill" style="color: #00395d">
                  </b-icon>
                </div>
                <div class="titles">Financeiro</div>
              </div>
            </template>
          </b-navbar-toggle>

          <b-collapse
            id="navbar-toggle-collapse-service-financial"
            class="col-md-12 col-sm-12 col-lg-12 col-xl-12"
            is-nav
          >
            <b-navbar-nav class="ml-auto">
              <div class="content-check-panel-principal">
                <label for="financial-panel-general">
                  Painel Geral
                  <input
                    type="checkbox"
                    id="financial-panel-general"
                    v-model="dataUser.permissoes.financeiro.painelPrincipal"
                  />
                </label>
              </div>
              <div class="contentModal">
                <div class="rowContainer">
                  <span class="subtitles">
                    <b-icon-dash-circle></b-icon-dash-circle> Nova conta a
                    receber</span
                  >

                  <div class="rowContent">
                    <div class="content-check">
                      <label for="permission-to-receive-access">
                        Acessar
                        <input
                          type="checkbox"
                          id="permission-to-receive-access"
                          v-model="
                            dataUser.permissoes.financeiro.novaContaReceber
                              .acessar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-to-receive-save">
                        Salvar
                        <input
                          type="checkbox"
                          id="permission-to-receive-save"
                          v-model="
                            dataUser.permissoes.financeiro.novaContaReceber
                              .salvar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-to-receive-edit">
                        Editar
                        <input
                          type="checkbox"
                          id="permission-to-receive-edit"
                          v-model="
                            dataUser.permissoes.financeiro.novaContaReceber
                              .editar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-to-receive-see">
                        Ver
                        <input
                          type="checkbox"
                          id="permission-to-receive-see"
                          v-model="
                            dataUser.permissoes.financeiro.novaContaReceber.ler
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-to-receive-delete">
                        Deletar
                        <input
                          type="checkbox"
                          id="permission-to-receive-delete"
                          v-model="
                            dataUser.permissoes.financeiro.novaContaReceber
                              .deletar
                          "
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div class="rowContainer">
                  <span class="subtitles">
                    <b-icon-dash-circle></b-icon-dash-circle> Nova conta a
                    pagar</span
                  >

                  <div class="rowContent">
                    <div class="content-check">
                      <label for="permission-to-pay-access">
                        Acessar
                        <input
                          type="checkbox"
                          id="permission-to-pay-access"
                          v-model="
                            dataUser.permissoes.financeiro.novaContaPagar
                              .acessar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-to-pay-save">
                        Salvar
                        <input
                          type="checkbox"
                          id="permission-to-pay-save"
                          v-model="
                            dataUser.permissoes.financeiro.novaContaPagar.salvar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-to-pay-edit">
                        Editar
                        <input
                          type="checkbox"
                          id="permission-to-pay-edit"
                          v-model="
                            dataUser.permissoes.financeiro.novaContaPagar.editar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-to-pay-see">
                        Ver
                        <input
                          type="checkbox"
                          id="permission-to-pay-see"
                          v-model="
                            dataUser.permissoes.financeiro.novaContaPagar.ler
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-to-pay-delete">
                        Deletar
                        <input
                          type="checkbox"
                          id="permission-to-pay-delete"
                          v-model="
                            dataUser.permissoes.financeiro.novaContaPagar
                              .deletar
                          "
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-card-text>

      <b-card-text class="w-100">
        <b-navbar toggleable class="cardSales">
          <b-navbar-toggle
            target="navbar-toggle-collapse-fiscal"
            id="dataSales"
            class="sizeCards"
          >
            <template #default="{ expanded }">
              <div class="sizeBtnOpenCard">
                <div>
                  <b-icon
                    v-if="expanded"
                    icon="dash-square-fill"
                    style="color: #00395d"
                  ></b-icon>
                  <b-icon v-else icon="plus-square-fill" style="color: #00395d">
                  </b-icon>
                </div>
                <div class="titles">Fiscal</div>
              </div>
            </template>
          </b-navbar-toggle>

          <b-collapse
            id="navbar-toggle-collapse-fiscal"
            class="col-md-12 col-sm-12 col-lg-12 col-xl-12"
            is-nav
          >
            <b-navbar-nav class="ml-auto">
              <div class="content-check-panel-principal">
                <label for="fiscal-panel-general">
                  Painel Geral
                  <input
                    type="checkbox"
                    id="fiscal-panel-general"
                    v-model="dataUser.permissoes.emissaoFiscal.painelPrincipal"
                  />
                </label>
              </div>

              <div class="contentModal">
                <div class="rowContainer">
                  <span class="subtitles">
                    <b-icon-dash-circle></b-icon-dash-circle> NF-e</span
                  >

                  <div class="rowContent">
                    <div class="content-check">
                      <label for="permission-nfe-access">
                        Acessar
                        <input
                          type="checkbox"
                          id="permission-nfe-access"
                          v-model="
                            dataUser.permissoes.emissaoFiscal.novaNfe.acessar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-nfe-save">
                        Salvar
                        <input
                          type="checkbox"
                          id="permission-nfe-save"
                          v-model="
                            dataUser.permissoes.emissaoFiscal.novaNfe.salvar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-nfe-edit">
                        Editar
                        <input
                          type="checkbox"
                          id="permission-nfe-edit"
                          v-model="
                            dataUser.permissoes.emissaoFiscal.novaNfe.editar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-nfe-see">
                        Ver
                        <input
                          type="checkbox"
                          id="permission-nfe-see"
                          v-model="
                            dataUser.permissoes.emissaoFiscal.novaNfe.ler
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-nfe-delete">
                        Deletar
                        <input
                          type="checkbox"
                          id="permission-nfe-delete"
                          v-model="
                            dataUser.permissoes.emissaoFiscal.novaNfe.deletar
                          "
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div class="rowContainer">
                  <span class="subtitles">
                    <b-icon-dash-circle></b-icon-dash-circle> NFC-e</span
                  >

                  <div class="rowContent">
                    <div class="content-check">
                      <label for="permission-nfce-access">
                        Acessar
                        <input
                          type="checkbox"
                          id="permission-nfce-access"
                          v-model="
                            dataUser.permissoes.emissaoFiscal.novaNfce.acessar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-nfce-save">
                        Salvar
                        <input
                          type="checkbox"
                          id="permission-nfce-save"
                          v-model="
                            dataUser.permissoes.emissaoFiscal.novaNfce.salvar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-nfce-edit">
                        Editar
                        <input
                          type="checkbox"
                          id="permission-nfce-edit"
                          v-model="
                            dataUser.permissoes.emissaoFiscal.novaNfce.editar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-nfce-see">
                        Ver
                        <input
                          type="checkbox"
                          id="permission-nfce-see"
                          v-model="
                            dataUser.permissoes.emissaoFiscal.novaNfce.ler
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-nfce-delete">
                        Deletar
                        <input
                          type="checkbox"
                          id="permission-nfce-delete"
                          v-model="
                            dataUser.permissoes.emissaoFiscal.novaNfce.deletar
                          "
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div class="rowContainer">
                  <span class="subtitles">
                    <b-icon-dash-circle></b-icon-dash-circle> NFS-e</span
                  >

                  <div class="rowContent">
                    <div class="content-check">
                      <label for="permission-nfse-access">
                        Acessar
                        <input
                          type="checkbox"
                          id="permission-nfse-access"
                          v-model="
                            dataUser.permissoes.emissaoFiscal.novaNfse.acessar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-nfse-save">
                        Salvar
                        <input
                          type="checkbox"
                          id="permission-nfse-save"
                          v-model="
                            dataUser.permissoes.emissaoFiscal.novaNfse.salvar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-nfse-edit">
                        Editar
                        <input
                          type="checkbox"
                          id="permission-nfse-edit"
                          v-model="
                            dataUser.permissoes.emissaoFiscal.novaNfse.editar
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-nfse-see">
                        Ver
                        <input
                          type="checkbox"
                          id="permission-nfse-see"
                          v-model="
                            dataUser.permissoes.emissaoFiscal.novaNfse.ler
                          "
                        />
                      </label>
                    </div>

                    <div class="content-check">
                      <label for="permission-nfse-delete">
                        Deletar
                        <input
                          type="checkbox"
                          id="permission-nfse-delete"
                          v-model="
                            dataUser.permissoes.emissaoFiscal.novaNfse.deletar
                          "
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-card-text>

      <b-card-text class="w-100">
        <b-navbar toggleable class="cardSales">
          <b-navbar-toggle
            target="navbar-toggle-collapse-cashier"
            id="dataSales"
            class="sizeCards"
          >
            <template #default="{ expanded }">
              <div class="sizeBtnOpenCard">
                <div>
                  <b-icon
                    v-if="expanded"
                    icon="dash-square-fill"
                    style="color: #00395d"
                  ></b-icon>
                  <b-icon v-else icon="plus-square-fill" style="color: #00395d">
                  </b-icon>
                </div>
                <div class="titles">Caixa</div>
              </div>
            </template>
          </b-navbar-toggle>

          <b-collapse
            id="navbar-toggle-collapse-cashier"
            class="col-md-12 col-sm-12 col-lg-12 col-xl-12"
            is-nav
          >
            <b-navbar-nav class="ml-auto">
              <div class="content-check-panel-principal">
                <label for="cashier-panel-general">
                  Painel Geral
                  <input
                    type="checkbox"
                    id="cashier-panel-general"
                    v-model="dataUser.permissoes.caixa.painelPrincipal"
                  />
                </label>
              </div>

              <div class="contentModal">
                <div class="rowContainer">
                  <span class="subtitles">
                    <b-icon-dash-circle></b-icon-dash-circle> Caixa</span
                  >

                  <div class="rowContent">
                    <div class="content-check-cashier">
                      <label for="permission-open-cashier">
                        Abrir Caixa
                        <input
                          type="checkbox"
                          id="permission-open-cashier"
                          v-model="dataUser.permissoes.caixa.abrirCaixa"
                        />
                      </label>
                    </div>

                    <div class="content-check-cashier">
                      <label for="permission-close-cashier">
                        Fechar Caixa
                        <input
                          type="checkbox"
                          id="permission-close-cashier"
                          v-model="dataUser.permissoes.caixa.fecharCaixa"
                        />
                      </label>
                    </div>

                    <div class="content-check-cashier">
                      <label for="permission-cashier-sangria">
                        Sangria
                        <input
                          type="checkbox"
                          id="permission-cashier-sangria"
                          v-model="dataUser.permissoes.caixa.sangria"
                        />
                      </label>
                    </div>

                    <div class="content-check-cashier">
                      <label for="permission-cashier-suprimentos-contas">
                        Suprimentos Contas
                        <input
                          type="checkbox"
                          id="permission-cashier-suprimentos-contas"
                          v-model="dataUser.permissoes.caixa.suprimentosConta"
                        />
                      </label>
                    </div>

                    <div class="content-check-cashier">
                      <label for="permission-cashier-suprimentos-gaveta">
                        Suprimentos Gaveta
                        <input
                          type="checkbox"
                          id="permission-cashier-suprimentos-gaveta"
                          v-model="dataUser.permissoes.caixa.suprimentosGaveta"
                        />
                      </label>
                    </div>

                    <div class="content-check-cashier">
                      <label for="permission-cashier-transfer">
                        Transferência
                        <input
                          type="checkbox"
                          id="permission-cashier-transfer"
                          v-model="dataUser.permissoes.caixa.transferencia"
                        />
                      </label>
                    </div>

                    <div class="content-check-cashier">
                      <label for="permission-cashier-adjustment">
                        Ajuste de caixa
                        <input
                          type="checkbox"
                          id="permission-cashier-adjustment"
                          v-model="dataUser.permissoes.caixa.ajusteDeCaixa"
                        />
                      </label>
                    </div>

                    <div class="content-check-cashier">
                      <label for="permission-cashier-adjustment-account">
                        Ajuste de conta
                        <input
                          type="checkbox"
                          id="permission-cashier-adjustment-account"
                          v-model="dataUser.permissoes.caixa.ajusteDeConta"
                        />
                      </label>
                    </div>

                    <div class="content-check-cashier">
                      <label for="permission-cashier-automatic">
                        Caixa Automático
                        <input
                          type="checkbox"
                          id="permission-cashier-automatic"
                          v-model="dataUser.permissoes.caixa.caixaAutomatico"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-card-text>

      <b-card-text class="w-100">
        <b-navbar toggleable class="cardSales">
          <b-navbar-toggle
            target="navbar-toggle-collapse-settings"
            id="dataSales"
            class="sizeCards"
          >
            <template #default="{ expanded }">
              <div class="sizeBtnOpenCard">
                <div>
                  <b-icon
                    v-if="expanded"
                    icon="dash-square-fill"
                    style="color: #00395d"
                  ></b-icon>
                  <b-icon v-else icon="plus-square-fill" style="color: #00395d">
                  </b-icon>
                </div>
                <div class="titles">Configurações / Outros</div>
              </div>
            </template>
          </b-navbar-toggle>

          <b-collapse
            id="navbar-toggle-collapse-settings"
            class="col-md-12 col-sm-12 col-lg-12 col-xl-12"
            is-nav
          >
            <b-navbar-nav class="ml-auto">
              <div class="contentModal">
                <div style="display: flex; flex-direction: column; flex: 1">
                  <div class="content-check-panel-principal">
                    <label for="settings-panel-general">
                      Painel Geral
                      <input
                        type="checkbox"
                        id="settings-panel-general"
                        v-model="
                          dataUser.permissoes.configuracoes.painelPrincipal
                        "
                      />
                    </label>
                  </div>
                  <div class="rowContainer">
                    <span class="subtitles">
                      <b-icon-dash-circle></b-icon-dash-circle>
                      Empresa</span
                    >

                    <div class="rowContent">
                      <div class="content-check">
                        <label for="permission-settings-access">
                          Acessar
                          <input
                            type="checkbox"
                            id="permission-settings-access"
                            v-model="
                              dataUser.permissoes.configuracoes.empresa.acessar
                            "
                          />
                        </label>
                      </div>

                      <div class="content-check">
                        <label for="permission-settings-save">
                          Salvar
                          <input
                            type="checkbox"
                            id="permission-settings-save"
                            v-model="
                              dataUser.permissoes.configuracoes.empresa.salvar
                            "
                          />
                        </label>
                      </div>

                      <div class="content-check">
                        <label for="permission-settings-edit">
                          Editar
                          <input
                            type="checkbox"
                            id="permission-settings-edit"
                            v-model="
                              dataUser.permissoes.configuracoes.empresa.editar
                            "
                          />
                        </label>
                      </div>

                      <div class="content-check">
                        <label for="permission-settings-see">
                          Ver
                          <input
                            type="checkbox"
                            id="permission-settings-see"
                            v-model="
                              dataUser.permissoes.configuracoes.empresa.ler
                            "
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div style="display: flex; flex-direction: column; flex: 1">
                  <div class="content-check-panel-principal">
                    <label for="reports-panel-general">
                      Painel Geral
                      <input
                        type="checkbox"
                        id="reports-panel-general"
                        v-model="dataUser.permissoes.relatorios.painelPrincipal"
                      />
                    </label>
                  </div>
                  <div class="rowContainer">
                    <span class="subtitles">
                      <b-icon-dash-circle></b-icon-dash-circle>
                      Relatórios</span
                    >

                    <div class="rowContent">
                      <div class="content-check-cashier">
                        <label for="permission-print">
                          Imprimir
                          <input
                            type="checkbox"
                            id="permission-print"
                            v-model="dataUser.permissoes.relatorios.imprimir"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-card-text>
    </div>

    <b-modal id="modalEditPermission" hide-footer hide-header centered>
      <div style="display: flex; flex-direction: column; gap: 20px">
        <h6 class="titleModal">
          Deseja informar as permissões para esse usuário ?
        </h6>

        <section
          style="
            display: flex;
            flex-direction: column;
            gap: 28px;
            padding: 0 5px;
          "
        >
          <p class="subTitle">
            Ao clicar em (SIM) será habilitado a edição de permissões para esse
            usuário, caso clique em (NÃO), você poderá realizar esse
            procedimento a qualquer momento futuramente.
          </p>

          <div
            style="
              display: flex;
              align-items: center;
              jusitfy-content: space-between;
              width: 100%;
              margin-top: 10px;
              gap: 20px;
            "
          >
            <div style="display: flex">
              <button
                class="btnContentLeft mr-4"
                style="width: 100px"
                @click="handleCloseModalAndEnablePermissions(dataUser.id)"
              >
                SIM
              </button>
              <button
                class="btnContentRight"
                style="width: 100px"
                @click="handleClearAndCloseModal"
              >
                NÃO
              </button>
            </div>
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";
import Input from "../UI/CustomInputs/Input.vue";
import InputPassword from "../UI/CustomInputs/InputPassword.vue";
import ServiceUser from "../../services/serviceUser";
import ServiceEmployees from "../../services/serviceEmployees";
import { isValidateEmail } from "../../utils/validateEmail";
import { removeReference } from "../../utils/removeReference";
import { HandleErrors } from "../../utils/handleErrors";

import {
  defaultUserPermission,
  defaultAdmPermission,
  defaultWorkingSchedule,
} from "../../common/index";

export default {
  components: {
    InputSelect,
    Input,
    InputPassword,
  },
  data() {
    return {
      defaultUsers: [
        { nivel: "Administrador", value: "adm" },
        { nivel: "Contador", value: "accountant" },
        { nivel: "Usuário", value: "user" },
      ],
      dataUser: {
        id: null,
        nivel: null,
        permissoes: JSON.parse(JSON.stringify(defaultUserPermission)),
        idFuncionario: "",
        horarios: JSON.parse(JSON.stringify(defaultWorkingSchedule)),
        login: "",
        senha: "",
        userName: "",
      },
      confirmedPassword: "",
      users: [],
      invalid: "",
      isLoading: false,
      isTableLoading: false,
      isTablePermissionsLoading: false,
      enableEditPermission: false,
      userLevelLogged: null,
      textActions: "",
      enableCreateCardMachines: false,
      userIdToIntegrations: "",
      employees: [],
      workingSchedule: defaultWorkingSchedule,
      cardMachines: {
        stone: {
          serialMachines: [],
        },
      },
    };
  },
  methods: {
    async saveUser() {
      try {
        this.isLoading = true;
        this.isTableLoading = true;

        if (!this.verifyDataUser()) {
          return;
        }

        const id = await ServiceUser.save(this.dataUser);
        this.dataUser.id = id;

        await this.findAll();
        if (this.dataUser.nivel === "user") {
          this.$bvModal.show("modalEditPermission");
        } else {
          this.clearUpdatePermissions();
        }

        this.$toast.open({
          message: "Novo usuário registrado",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error.response.data.message ||
            "Ocorreu um erro ao salvar o novo usuário",
          type: "info",
        });
      } finally {
        this.isLoading = false;
        this.isTableLoading = false;
      }
    },

    async updateUser() {
      try {
        this.isLoading = true;
        this.isTableLoading = true;

        if (!this.verifyDataUser()) {
          return;
        }

        await ServiceUser.update(this.dataUser.id, this.dataUser);
        await this.findAll();

        this.clearUpdatePermissions();
        this.$toast.open({
          message: "Usuário atualizado",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error.response.data.message ||
            "Ocorreu um erro ao atualizar o usuário",
          type: "info",
        });
      } finally {
        this.isLoading = false;
        this.isTableLoading = false;
      }
    },

    async updatePermissions() {
      try {
        const payload = {
          permissions: this.dataUser.permissoes,
          idFuncionario: this.dataUser.idFuncionario,
          workingSchedule: this.dataUser.horarios,
        };

        await ServiceUser.updatePermissions(this.dataUser.id, payload);

        this.clearUpdatePermissions();
        this.$toast.open({
          message: "Permissões atualizadas",
          type: "success",
        });
        this.$toast.open({
          message:
            "Para as novas permissões entrarem em vigor, você deve realizar o login novamente referente ao usuário atualizado!",
          type: "info",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);
        if (typeError) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        this.$toast.open({
          message:
            error.response.data.message ||
            "Ocorreu um erro ao atualizar as informações de permissões",
          type: "info",
        });
      }
    },

    async handleSaveOrUpdate() {
      this.dataUser.id ? await this.updateUser() : await this.saveUser();
    },

    async updateCardMachines() {
      try {
        this.isLoading = true;

        const cardMachines = this.handleCardMachinesToUpdate(
          removeReference(this.cardMachines)
        );

        if (!cardMachines.stone.serialMachines.length) {
          return this.$toast.open({
            message: "Preencha o numero de serie das maquininhas",
            type: "info",
          });
        }

        await ServiceUser.updatedCardMachine(
          this.userIdToIntegrations,
          cardMachines
        );

        this.$toast.open({
          message: "Numeros de serie das maquinhas registrados",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao salvar os o numero de serie das maquininhas",
          type: "info",
        });
      } finally {
        this.isLoading = false;
        this.enableCreateCardMachines = false;
      }
    },

    handleCardMachinesToUpdate(cardMachine) {
      const newCardMachine = cardMachine;

      newCardMachine.stone.serialMachines = cardMachine.stone.serialMachines
        .map((item) => {
          Reflect.deleteProperty(item, "idList");

          return item.serialNumber;
        })
        .filter((item) => item);

      return newCardMachine;
    },

    async handleEnablePermissions(id) {
      try {
        this.enableCreateCardMachines = false;
        this.userIdToIntegrations = "";

        this.isTablePermissionsLoading = true;
        this.enableEditPermission = true;
        if (id === this.dataUser.id) return;

        const user = await this.findById(id);

        this.dataUser.id = user.id;
        this.dataUser.nivel = user.nivel;
        this.dataUser.permissoes = user.permissoes;

        this.dataUser.permissoes.configuracoes.registroPonto = user?.permissoes
          ?.configuracoes?.registroPonto || { painelPrincipal: false };

        this.dataUser.idFuncionario = user.idFuncionario;
        this.dataUser.horarios = user.horarios || defaultWorkingSchedule;

        this.dataUser.login = user.login;
        this.dataUser.userName = user.userName;
        this.dataUser.senha = "";
        this.confirmedPassword = "";
        this.$refs.userLevel.valueItems = user.nivel;
        this.$refs.userLogin.valueInput = user.login;
        this.$refs.userName.valueInput = user.userName;

        this.$nextTick(() => {
          if (this.$refs.idFuncionario) {
            this.$refs.idFuncionario.valueItems = user.idFuncionario;
          }
        });
      } catch (error) {
        this.$toast.open({
          message:
            error.response.data.message ||
            "Ocorreu um erro ao buscar as permissõesa do usuário",
          type: "info",
        });
      } finally {
        this.isTablePermissionsLoading = false;
      }
    },

    async handleEnableCardMachines(idUser) {
      try {
        const user = await this.findIntegrationsUserById(idUser);
        if (user && Object.keys(user)) {
          this.userIdToIntegrations = user.id;

          const defaultCardMachine = {
            serialNumber: "",
            idList: uuidv4(),
          };

          this.enableCreateCardMachines = true;

          const stoneMachines =
            user.integracoes.cardMachines.stone.serialMachines;

          this.cardMachines.stone.serialMachines = stoneMachines.length
            ? stoneMachines.map((item) => {
                return {
                  serialNumber: item,
                  idList: uuidv4(),
                };
              })
            : [{ ...defaultCardMachine }];

          console.log(this.cardMachines.stone.serialMachines);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async handleCloseModalAndEnablePermissions(id) {
      this.$bvModal.hide("modalEditPermission");
      await this.handleEnablePermissions(id);
    },

    async deleteUser(idUser, idUserGestao) {
      try {
        this.enableCreateCardMachines = false;
        this.userIdToIntegrations = "";

        this.isTableLoading = true;
        await ServiceUser.delete(idUser, idUserGestao);

        await this.findAll();
      } catch (error) {
        this.$toast.open({
          message:
            error.response.data.message ||
            "Ocorreu um erro ao deletar o usuário",
          type: "info",
        });
      } finally {
        this.isTableLoading = false;
      }
    },

    async findById(id) {
      const user = await ServiceUser.findById(id);
      return user;
    },

    async findIntegrationsUserById(id) {
      const user = await ServiceUser.findIntegrationsById(id);
      return user;
    },

    async findAll() {
      try {
        this.isTableLoading = true;
        const users = await ServiceUser.findAll();
        this.users = users;
      } catch (error) {
        this.$toast.open({
          message:
            error.response.data.message ||
            "Ocorreu um erro ao buscar todos usuários",
          type: "info",
        });
      } finally {
        this.isTableLoading = false;
      }
    },

    async handleFindUser(id) {
      try {
        this.enableCreateCardMachines = false;
        this.userIdToIntegrations = "";

        if (id === this.dataUser.id) return;

        this.isTablePermissionsLoading = true;
        const user = await this.findById(id);

        Object.assign(this.dataUser, user);
        this.$refs.userLevel.valueItems = user.nivel;
        this.$refs.userLogin.valueInput = user.login;
        this.$refs.userName.valueInput = user.userName;
        this.$refs.userPassword.valueInput = "";
        this.$refs.confirmedPassword.valueInput = "";
      } catch (error) {
        this.$toast.open({
          message:
            error.response.data.message ||
            "Ocorreu um erro ao buscar este usuários",
          type: "info",
        });
      } finally {
        this.isTablePermissionsLoading = false;
      }
    },

    async getEmployeesForSelectBox() {
      try {
        const result = await ServiceEmployees.getEmployeesForSelectBox();
        this.employees = result.data;
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao listar os funcionários",
          type: "info",
        });
      }
    },

    handleWorkload(key, startTime, interval, endTime) {
      if (!startTime || !endTime) {
        this.dataUser.horarios[key].carga_horaria = null;
        return;
      }

      const start = DateTime.fromFormat(startTime, "HH:mm");
      const current = DateTime.fromFormat(endTime, "HH:mm");

      let diffMinutes = start.diff(current, "minutes").minutes;
      if (interval) {
        const newInterval = DateTime.fromFormat(interval, "HH:mm");
        const newIntervalMinutes = newInterval.hour * 60 + newInterval.minute;
        diffMinutes += newIntervalMinutes;
      }

      const hours = Math.floor(Math.abs(diffMinutes) / 60)
        .toString()
        .padStart(2, "0");
      const minutes = Math.abs(diffMinutes) % 60;

      const formattedDiff = `${hours}:${minutes.toString().padStart(2, "0")}`;
      this.dataUser.horarios[key].carga_horaria = formattedDiff;
    },

    addMoreCardMachine() {
      const defaultCardMachine = {
        serialNumber: "",
        idList: uuidv4(),
      };

      this.cardMachines.stone.serialMachines = [
        ...this.cardMachines.stone.serialMachines,
        { ...defaultCardMachine },
      ];
    },

    removeCardMachine(idList) {
      if (this.cardMachines.stone.serialMachines.length === 1) {
        const defaultCardMachine = {
          serialNumber: "",
          idList: uuidv4(),
        };

        this.cardMachines.stone.serialMachines = [{ ...defaultCardMachine }];
      }

      this.cardMachines.stone.serialMachines =
        this.cardMachines.stone.serialMachines.filter(
          (item) => item.idList !== idList
        );
    },

    verifyDataUser() {
      if (!this.dataUser.nivel) {
        this.invalid = "*Nível do usuário deve ser selecionado!";

        this.$refs.userLevel.$el.children[1].focus();
        return false;
      } else if (!isValidateEmail(this.dataUser.login)) {
        this.invalid = "*Campo Email deve ser um email válido!";

        this.$refs.userLogin.$el.children[0].focus();
        return false;
      } else if (this.confirmedPassword !== this.dataUser.senha) {
        this.invalid =
          "*As senhas estão diferentes, por favor verifique e tente novamente!";

        this.$refs.userPassword.$el.children[0].focus();
        return false;
      } else if (!this.dataUser.senha || !this.confirmedPassword) {
        this.invalid = "*Campos de senhas não podem ser vazios!";

        this.$refs.userPassword.$el.children[0].focus();
        return false;
      }

      return true;
    },

    clearInvalid() {
      this.invalid = "";
    },

    clearDataUser() {
      this.dataUser = {
        id: null,
        nivel: null,
        permissoes: JSON.parse(JSON.stringify(defaultUserPermission)),
        horarios: JSON.parse(JSON.stringify(defaultWorkingSchedule)),
        login: "",
        senha: "",
        userName: "",
      };

      this.confirmedPassword = "";

      this.$refs.userLevel.valueItems = "";
      this.$refs.userLogin.valueInput = "";
      this.$refs.userName.valueInput = "";
      this.$refs.userPassword.valueInput = "";
      this.$refs.confirmedPassword.valueInput = "";
    },

    clearCardMachines() {
      this.enableCreateCardMachines = false;
      this.userIdToIntegrations = "";
    },

    clearUpdatePermissions() {
      this.enableEditPermission = false;

      this.$nextTick(() => {
        this.clearDataUser();
      });
    },

    handleTextActions(textActions) {
      if (textActions === "permission") {
        this.textActions = "Editar permissões";
      } else if (textActions === "edit") {
        this.textActions = "Editar usuário";
      } else if (textActions === "cardMachines") {
        this.textActions = "Maquininhas de cartão";
      } else {
        this.textActions = "Excluir usuário";
      }
    },

    handleRemoveTextAction() {
      this.textActions = "";
    },

    grantAllPermission() {
      if (this.$refs.isTotalPermission.checked) {
        this.dataUser.permissoes = JSON.parse(
          JSON.stringify(defaultAdmPermission)
        );

        return;
      }

      this.dataUser.permissoes = JSON.parse(
        JSON.stringify(defaultUserPermission)
      );
    },

    handleClearAndCloseModal() {
      this.clearUpdatePermissions();
      this.$bvModal.hide("modalEditPermission");
    },
  },
  computed: {
    ...mapState(["dataCompany"]),
    isStoneIntegration() {
      if (
        this.dataCompany?.liberars[0]?.integracoes &&
        Object.keys(this.dataCompany?.liberars[0]?.integracoes).length
      ) {
        const stone =
          this.dataCompany?.liberars[0]?.integracoes.cardMachines.find(
            (machine) => machine.text === "STONE"
          );

        return stone.value;
      }

      return false;
    },
  },
  created() {
    this.findAll();
    this.getEmployeesForSelectBox();
    this.userIdToIntegrations = "";
  },
};
</script>

<style scoped>
.general-container {
  width: 100%;
  padding: 1rem 5rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.cardSales {
  width: 100%;
  padding-bottom: 15px !important;
  border-radius: 5px !important;
  border: 1px solid rgba(232, 231, 231, 0.788);
  background-color: #f9f9f94d !important;
  padding: 0px 0px 0px 0px !important;
  border-radius: 5px !important;
}

#dataSales {
  border: none !important;
  box-shadow: 0 2px 2px #00000014;
}

.sizeCards {
  width: 100%;
  display: flex;
}

.sizeBtnOpenCard {
  color: black;
  font-size: 16px;
  height: 30px;
  display: flex;
  align-items: center;
}

.cardDataSales {
  border-radius: 5px !important;
  margin-top: 25px !important;
  margin-bottom: 20px;
}

.titles {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
  color: #00395d;
}

.subtitles {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
  color: #666360;
}

.rowContainer {
  position: relative;
  margin-top: 10px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 5px 10px;
  background: white;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.071);
  margin-bottom: 10px;
  gap: 5px;
}

.rowContainer span {
  cursor: context-menu;
}

.rowContent {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 0 13px 0;
  gap: 15px;
  margin-top: 15px;
}

.rowContentHeader {
  display: flex;
  width: 100%;
  gap: 15px;
}

.contentRowContentHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0 13px 0;
  margin-top: 15px;
  height: 100%;
}

.content-check {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-check label {
  width: 95px !important;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  color: #00395d;
  padding: 5px;
  border-radius: 5px;
  gap: 15px;
  background-color: whitesmoke;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.135);
  color: #00395d;
  cursor: pointer;
}

.content-check input {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
}

.content-check-cashier {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-check-cashier label {
  width: 180px !important;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  color: #00395d;
  padding: 5px;
  border-radius: 5px;
  gap: 15px;
  background-color: whitesmoke;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.135);
  color: #00395d;
  cursor: pointer;
}

.content-check-cashier input {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
}

.content-check-panel-principal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.content-check-panel-principal label {
  width: 130px !important;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  color: #00395d;
  padding: 5px;
  border-radius: 5px;
  gap: 15px;
  background-color: #39c239;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.529);
  color: white;
  cursor: pointer;
}

.content-check-panel-principal input {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
}

.contentModal {
  display: flex;
  gap: 10px;
}

.btnCreate {
  border-radius: 5px;
  background-color: rgba(84, 188, 233, 0.975);
  width: 100%;
  border: none;
  color: white;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.btnCreate:hover {
  background-color: rgba(56, 129, 160, 0.975);
}

.btnCreate:focus {
  background-color: rgba(56, 129, 160, 0.975);
  border: none;
  outline: none;
  box-shadow: none !important;
}

.btnCreate p {
  margin: 0;
}

.btnNewCardMachine {
  border-radius: 5px;
  background-color: rgba(84, 188, 233, 0.975);
  width: 100%;
  border: none;
  color: white;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.btnNewCardMachine:hover {
  background-color: rgba(56, 129, 160, 0.975);
}

.btnNewCardMachine:focus {
  background-color: rgba(56, 129, 160, 0.975);
  border: none;
  outline: none;
  box-shadow: none !important;
}

.btnNewCardMachine p {
  margin: 0;
}

.btnNewUser {
  background-color: #c1c1c1;
}

.btnNewUser:hover {
  background-color: #a8a8a8;
}

.btnNewUser:focus {
  background-color: #a8a8a8;
  border: none;
  outline: none;
  box-shadow: none !important;
}

.btnCancelPermissions {
  background-color: rgb(181, 7, 7);
}

.btnCancelPermissions:hover {
  background-color: rgb(143, 6, 6);
}

.contentIconsUser {
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(220, 219, 219);
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.26);
}

.contentIconsUser:hover {
  background-color: rgb(196, 196, 196);
}

.contentPermissions {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.textTotalPermission {
  display: flex;
  align-items: center;
  color: rgba(84, 188, 233, 0.975);
  cursor: pointer;
  margin: 0;
  gap: 0.8rem;
  background-color: whitesmoke;
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.135);
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.textTotalPermission:hover {
  color: rgba(64, 145, 179, 0.975);
}

table {
  width: 100%;
  border-radius: 5px;
}

thead,
tbody {
  width: 100%;
  display: block;
}

tr {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

table tr th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

table tr th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

thead {
  color: black;
}

tbody {
  overflow-y: auto;
  max-height: 150px;
  padding: 0 0.3rem 0 0;
}

tbody tr {
  background-color: whitesmoke;
  border-radius: 5px;
  text-align: center;
  height: 50px;
}

tr:nth-child(even) {
  background: white;
}

.tdLogin p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
}

.spin {
  height: 15px;
  width: 15px;
  color: white;
}

.spinInTable {
  height: 60px;
  width: 60px;
  color: white;
}

.btnDisabled {
  pointer-events: none;
  background-color: rgba(56, 129, 160, 0.975);
}

.contentTableLoading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.083);
  z-index: 9999;
  top: 0;
  left: 0;
  border-radius: 5px;
}

.contentBtnUsersActions {
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 15px;
}

.contentBtnCardMachines {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
}

.btnContentLeft {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.btnContentLeft:hover {
  box-shadow: 0px 0px 4px 0px #003e5f32;
}

.btnContentRight {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentRight:hover {
  background-color: #003e5f;
  box-shadow: 0px 0px 4px 0px #003e5f70;
}

.titleModal {
  font-size: 1rem;
  font-family: Poppins-Regular, sans-serif !important;
  padding: 10px;
  background-color: #00496e;
  border-radius: 5px;
  color: white;
  margin: 0;
}

.subTitle {
  color: #767676;
  font-size: 13px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  margin: 0;
}

.customToltip {
  width: 120px;
  display: none;
  text-align: start;
  padding: 0 0 0 0.3rem;
  position: absolute;
  top: 6px;
  right: 40px;
  z-index: 100;
  border-radius: 5px;
  background-color: rgba(84, 188, 233, 0.975);
  color: whitesmoke;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.422);
}

.textActions {
  width: 150px;
  text-align: center;
  border-radius: 5px;
  background-color: rgba(84, 188, 233, 0.975);
  color: whitesmoke;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.355);
  font-weight: normal;
}

.content-total-permissions {
  display: flex;
  align-items: center;
  gap: 15px;
}

p {
  margin: 0 !important;
  padding: 0 !important;
}

.containerWorkingSchedule {
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 10px;
  flex-wrap: wrap;
}

.workingSchedule {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 10px;
  padding: 0.5rem;
}

.contentItensWorking {
  display: flex;
  background-color: whitesmoke;
  padding: 0.5rem 0.3rem;
  border-radius: 10px;
  box-shadow: 0 0px 3px 1px rgba(0, 0, 0, 0.135);
}

.contentDaysWorking {
  width: 100px;
  display: flex;
  gap: 0.3rem;
  align-items: center;
  position: relative;
}

.contentDaysWorking label {
  margin: 0 !important;
  transform: none !important;
  background-color: transparent !important;
}

.inputTime {
  background-color: transparent;
  border: none;
  box-shadow: 0 0 1px 1px black;
  border-radius: 50px;
  outline: none;
  text-align: end;
  padding-left: 1rem;
}

@media screen and (max-width: 1024px) {
  .containerWorkingSchedule {
    gap: 0rem;
  }
}

@media screen and (max-width: 800px) {
  .contentItensWorking {
    justify-content: space-between;
  }
}

@media screen and (max-width: 1097px) {
  .contentModal {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 670px) {
  .tableActions {
    overflow: auto;
    max-width: 670px;
  }

  .rowContentHeader,
  .contentBtnUsersActions,
  .contentBtnCardMachines {
    flex-direction: column;
  }

  .inputResponsive {
    width: 100% !important;
  }

  .general-container {
    padding: 1.5rem;
  }
}
</style>