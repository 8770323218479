<template>
  <div class="d-flex w-100 justify-content-center container-geral animation">
    <section style="width: 90%; padding: 2rem 0">
      <div class="title">
        <span> Conferência de registros </span>
      </div>

      <div class="containerCards">
        <div style="display: flex; gap: 30px; width: 100%">
          <div class="contentCard closeSafe">
            <div class="contentHeaderCards">
              <div
                class="contentTitleAndIcon"
                style="justify-content: space-between"
              >
                <div style="display: flex; gap: 10px; align-items: center">
                  <b-icon-calculator-fill scale="1.3" class="iconCard" />
                  <span class="titleCads">Fechamento de caixa</span>
                </div>

                <div style="display: flex; gap: 10px; font-size: 14px">
                  <span> Fundo de caixa: </span>
                  <span>{{ handleValueOpening }}</span>
                </div>
              </div>
            </div>
            <section class="resultBalance" v-if="!inputsIsEnable">
              <div class="isCheckBoxDrawer">
                <div>
                  <button
                    class="btnContent btnLeft"
                    style="width: 220px"
                    @click="openModals('modalCloseCashier')"
                  >
                    Fechar Caixa
                  </button>
                </div>

                <!-- <div>
                  <button
                    class="btnContent btnRight"
                    style="width: 220px"
                    @click="checkSafe"
                  >
                    Conferencia e fechamento
                  </button>
                </div> -->
              </div>
            </section>

            <section class="resultBalance" v-else>
              <div class="containerInputsReview">
                <div class="contentInputsReview">
                  <span>
                    <Input
                      label="Caixa gaveta"
                      :labelFixed="true"
                      :isDisabled="true"
                      ref="drawerBox"
                    />
                  </span>

                  <span>
                    <Input
                      label="Valor para fechamento"
                      :labelFixed="true"
                      :dataInput.sync="declaredTotal"
                      :mask="declaredTotal"
                    />
                  </span>
                </div>

                <div class="contentInputsReview">
                  <span>
                    <button
                      class="btnContent btnCancelCheckSafe"
                      @click="cancelCheckSafe"
                    >
                      Cancelar
                    </button>
                  </span>

                  <span>
                    <button
                      class="btnContent btnConfirmCheckSafe"
                      @click="closeSafeWithConference"
                    >
                      Confirmar
                    </button>
                  </span>
                </div>
              </div>
            </section>
          </div>

          <div
            class="contentCard balanceByType"
            v-if="typePaymentsUsedGrouped.length"
          >
            <div class="contentHeaderCards">
              <div class="contentTitleAndIcon">
                <b-icon-credit-card2-back-fill scale="1.3" class="iconCard" />
                <span class="titleCads">Saldo por forma de pagamento</span>
              </div>
            </div>
            <section class="resultBalance">
              <table>
                <tbody class="tbodyBalance">
                  <tr
                    v-for="typePaymentGrouped in typePaymentsUsedGrouped"
                    :key="typePaymentGrouped.id"
                  >
                    <td>
                      <div>
                        <span>
                          {{ typePaymentGrouped.tipoMov }}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="typeResultBalance">
                        <span>{{ typePaymentGrouped.tipo }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="valueResultBalance">
                        <span
                          class="totalValue"
                          :style="
                            Math.sign(typePaymentGrouped.total) === -1 ||
                            (typePaymentGrouped.tipoMov === 'saida' &&
                              'background-color: rgb(184 72 72)')
                          "
                          >{{ maskCurrency(typePaymentGrouped.total) }}</span
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="footerCards">
                <div class="typeResultBalance">
                  <span style="font-weight: 600; font-size: 14px">TOTAL</span>
                </div>
                <div class="valueResultBalance">
                  <span
                    class="totalValue"
                    :style="
                      Math.sign(totalsPayments) === -1 &&
                      'background-color: rgb(184 72 72)'
                    "
                    >{{ maskCurrency(totalsPayments) }}</span
                  >
                </div>
              </div>
            </section>
          </div>
        </div>

        <div
          style="display: flex; justify-content: end; width: 100%"
          v-if="typePaymentsPerTypeMov.length"
        >
          <div class="contentCard conferenceByMoney">
            <div class="contentHeaderCards">
              <div class="contentTitleAndIcon">
                <b-icon-calculator-fill scale="1.3" class="iconCard" />
                <span class="titleCads">Movimentações de Entradas/saídas</span>
              </div>
            </div>
            <section
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
              "
            >
              <table style="width: 100%">
                <thead class="mb-3">
                  <tr>
                    <th>Tipo</th>
                    <th style="text-align: center">Forma de Pagamento</th>
                    <th style="text-align: end; padding-right: 15px">Totais</th>
                  </tr>
                </thead>
                <tbody class="tbodyBalancePerType">
                  <tr
                    v-for="typePayment in typePaymentsPerTypeMov"
                    :key="typePayment.id"
                  >
                    <td>
                      <div class="typeResultBalance">
                        <span>{{ typePayment.tipoMov }}</span>
                      </div>
                    </td>
                    <td style="text-align: center">
                      <div class="typeResultBalance">
                        <span>{{ typePayment.tipo }}</span>
                      </div>
                    </td>
                    <td style="text-align: end">
                      <div>
                        <span
                          class="totalValue"
                          :style="
                            Math.sign(typePayment.total) === -1 ||
                            (typePayment.tipoMov === 'saida' &&
                              'background-color: rgb(184 72 72)')
                          "
                          >{{ maskCurrency(typePayment.total) }}</span
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="footerCards">
                <div style="display: flex; gap: 10px">
                  <div class="typeResultBalance">
                    <span style="font-weight: 600; font-size: 14px"
                      >ENTRADAS:</span
                    >
                  </div>
                  <div class="valueResultBalance">
                    <span class="totalValue">{{
                      maskCurrency(totalsEntriesAndExits.entrada)
                    }}</span>
                  </div>
                </div>

                <div style="display: flex; gap: 10px">
                  <div class="typeResultBalance">
                    <span style="font-weight: 600; font-size: 14px"
                      >SAÍDAS:</span
                    >
                  </div>
                  <div class="valueResultBalance">
                    <span
                      class="totalValue"
                      style="background-color: rgb(184 72 72)"
                      >{{ maskCurrency(totalsEntriesAndExits.saida) }}</span
                    >
                  </div>
                </div>

                <div style="display: flex; gap: 10px">
                  <div class="typeResultBalance">
                    <span style="font-weight: 600; font-size: 14px"
                      >TOTAL:</span
                    >
                  </div>
                  <div class="valueResultBalance">
                    <span
                      class="totalValue"
                      :style="
                        Math.sign(totalsPayments) === -1 &&
                        'background-color: rgb(184 72 72)'
                      "
                      >{{ maskCurrency(totalsPayments) }}</span
                    >
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>

    <b-modal id="modalCloseCashier" hide-footer hide-header centered>
      <div class="modalCloseCashier">
        <div class="titleModal">
          <span> Fechamento de caixa </span>
          <img class="svgPadLockClose" :src="svgPadLock" />
        </div>
        <span class="contentModal"
          >Deseja efetuar alguma MOVIMENTAÇÃO antes do fechamento?</span
        >
        <div class="btnModal">
          <button @click="modalHeight" class="btnContentReceive">
            Realizar movimentações
          </button>
          <button
            style="background-color: #004b73; color: white"
            class="btnContentReceive"
            @click="closeSafe"
          >
            Não, fechar caixa
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Input from "../UI/CustomInputs/Input.vue";
import ServiceSafeRegister from "../../services/serviceSafeRegister";
import ServiceSafe from "../../services/serviceSafe";
import { mascaraMoeda } from "../../utils/maskMoney";
import SvgPadLock from "../../assets/padlock-locked.svg";

export default {
  props: {
    typePaymentsUsedGrouped: {
      type: Array,
    },
    typePaymentsPerTypeMov: {
      type: Array,
    },
    totalsPayments: {
      type: Number,
    },
    totalsEntriesAndExits: {
      type: Object,
    },
    idSafeRegister: {
      type: String,
    },
  },
  components: {
    Input,
  },
  data() {
    return {
      dataSafeWithMoney: [],
      svgPadLock: SvgPadLock,
      inputsIsEnable: false,
      diffValues: 0,
      declaredTotal: null,
    };
  },
  methods: {
    ...mapMutations([
      "ALTER_VALUES_MODAL_DIALOG_GENERAL",
      "SET_SAFE_REGISTER",
      "SET_SAFE_REMAINING_VALUE",
      "SET_COLAPSE_CASHIER",
      "ALTER_SIZE_MODAL_CASHIER",
      "ALTER_MODAL_CONTAINER_CASHIER",
    ]),

    async closeSafe() {
      try {
        await ServiceSafeRegister.toClose(this.idSafeRegister);

        this.modalHeight();
        this.SET_SAFE_REGISTER({ ...this.dataSafeIsOpen, safeIsOpen: false });
        this.$emit("clearValues");
        return this.$toast.open({
          message: "Caixa fechado com sucesso!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ocorreu um erro ao fechar o caixa!",
            type: "info",
          });
      }
    },

    async closeSafeWithConference() {
      try {
        await ServiceSafeRegister.closingWithConference(this.idSafeRegister, {
          declaredTotal: mascaraMoeda(this.declaredTotal),
        });

        this.modalHeight();
        this.SET_SAFE_REGISTER({ ...this.dataSafeIsOpen, safeIsOpen: false });
        this.$emit("clearValues");
        return this.$toast.open({
          message: "Caixa fechado com sucesso!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ocorreu um erro ao fechar o caixa!",
            type: "info",
          });
      }
    },

    async findRemainingDrawerBoxValue() {
      const drawerBoxValue = await ServiceSafe.findRemainingDrawerBoxValue(
        this.idSafeRegister
      );
      this.$refs.drawerBox.valueInput = parseFloat(
        drawerBoxValue || 0
      ).toLocaleString("pt-br", { style: "currency", currency: "BRL" });
    },

    // checkSafe() {
    //   this.inputsIsEnable = true;
    // },

    cancelCheckSafe() {
      this.declaredTotal = 0;
      this.inputsIsEnable = false;
      this.dataSafeWithMoney = [];
    },

    modalHeight() {
      this.SET_COLAPSE_CASHIER(!this.colapseCashier);

      this.colapseCashier
        ? this.ALTER_SIZE_MODAL_CASHIER("ModalGeneral")
        : this.ALTER_SIZE_MODAL_CASHIER("disable");

      this.modalContainerCashier
        ? this.ALTER_MODAL_CONTAINER_CASHIER("modalContainer")
        : this.ALTER_MODAL_CONTAINER_CASHIER("contentModalDisable");

      this.$bvModal.hide("modalCloseCashier");
    },

    maskCurrency(valor, locale = "pt-BR") {
      if (valor === "") {
        return "0,00";
      }

      if (isNaN(valor)) {
        return "0,00";
      }

      return parseFloat(valor).toLocaleString(locale, {
        style: "currency",
        currency: "BRL",
      });
    },

    openModals(name) {
      this.$bvModal.show(name);
    },
  },
  computed: {
    ...mapState({
      colapseCashier: (state) => state.colapseCashier,
      dataSafeIsOpen: (state) => state.dataSafeIsOpen,
    }),
    handleValueOpening() {
      return this.dataSafeIsOpen.valorInicial.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
  },
  watch: {
    inputsIsEnable() {
      if (this.inputsIsEnable) {
        this.$nextTick(() => {
          this.findRemainingDrawerBoxValue();
        });
      }
    },
  },
};
</script>

<style scoped>
.containerCards {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.title {
  width: 100%;
  text-align: start;
  font-size: 16px;
  color: #00486e;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.titleModal {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #00486e;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.titleModal span {
  font-family: Poppins-Medium, sans-serif !important;
  font-weight: 600;
}

.contentModal {
  font-size: 14px;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentCard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f9f9f94d;
  padding: 1rem;
  border: solid 1px rgb(230, 230, 230);
  border-radius: 5px;
}

.balanceByType {
  width: 50%;
  height: 300px;
  max-height: 300px !important;
}

.closeSafe {
  position: relative;
  width: 50%;
  height: 300px;
  max-height: 300px !important;
}

.conferenceByMoney {
  width: 48.9%;
  height: 380px;
  max-height: 380px !important;
}

.contentHeaderCards {
  display: flex;
  align-items: center;
  gap: 10px;
}

.titleCads {
  font-size: 14px;
}

.contentResultBalance {
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: solid 1px #b3babe;
}

.valueResultBalance {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.contentItemsResultBalance {
  display: flex;
  align-items: center;
  gap: 10px;
}

.totalValue {
  background-color: rgb(72, 184, 101);
  padding: 0px 5px;
  color: white;
  border-radius: 5px;
}

.totalsPayments {
  font-weight: 600;
}

.resultBalance {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 10px;
}

.tbodyBalance {
  width: 100% !important;
  height: auto;
  max-height: 165px !important;
  overflow-y: auto !important;
  display: block;
}

.tbodyBalancePerType {
  width: 100% !important;
  height: auto;
  max-height: 210px !important;
  overflow-y: auto !important;
  display: block;
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table {
  border-collapse: collapse;
}

tr:not(:last-child) {
  border-bottom: solid 1px #b3babe;
}

tr:last-child > td {
  height: 50px;
  vertical-align: bottom;
  padding-bottom: 5px;
}

tr > td {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 5px;
}

tr:first-child > td {
  padding-bottom: 15px;
  padding-top: 5px;
}

/* Verify Box Drawer */
.isCheckBoxDrawer {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.containerInputsReview {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.contentInputsReview {
  display: flex;
  gap: 30px;
}

.contentInputsReview span {
  cursor: default;
}

.titleCheckBoxDrawer {
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.btnCheck {
  border: 1px solid #00486e;
  border-radius: 3px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnCheck:hover {
  box-shadow: 0px 0px 4px 0px #003e5f70;
  text-decoration: underline;
}

.btnContent {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 100%;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContent:hover {
  box-shadow: 0px 0px 4px 0px #003e5f70;
  text-decoration: underline;
}

.btnLeft {
  border: 1px solid #60c7ff;
  background: #60c7ff;
  padding: 1rem 0;
}

.btnRight {
  border: 1px solid #004b73;
  background: #004b73;
  padding: 1rem 0;
}

.btnCancelCheckSafe {
  font-size: 12px;
  border: 1px solid #004b73;
  background: rgb(105, 0, 0);
  padding: 0.5rem 1rem;
}

.btnConfirmCheckSafe {
  font-size: 12px;
  border: 1px solid #004b73;
  background: rgb(0, 71, 39);
  padding: 0.5rem 1rem;
}

.notInformation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
}

.btnModal {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.btnContentReceive {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.tableCheckMoney {
  max-width: 500px;
  width: 100%;
  max-height: 100px;
  height: 100%;
}

.calcluledValues {
  pointer-events: none;
  border: solid 10px white;
}

.disabledInputs {
  background-color: #d9d9d9;
  pointer-events: none;
}

.contentTitleAndIcon {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.footerCards {
  display: flex;
  gap: 30px;
  padding: 5px;
  background-color: aliceblue;
  border-radius: 5px;
  justify-content: space-between;
}

/* INPUT */
.labelFixed {
  position: absolute;
  left: 0px;
  bottom: 30px;
  pointer-events: none;
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #00486e;
  font-size: 12px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  margin: 0px;
  padding: 0 0.2em;
}

.input {
  font-size: 12px !important;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.inputDisabled {
  background-color: #fafafa;
  pointer-events: none;
  color: #949290 !important;
}

.borderInput {
  font-size: 12px !important;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  height: 30px;
  border: 2px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  padding-top: 0;
  padding-bottom: 0;
  color: #5c5855;
}

.borderInput:focus {
  border: 2px solid #a9cff4;
}

.inputValues {
  width: 100%;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: white;
  border-radius: 50px;
}

*::-webkit-scrollbar-thumb {
  background-color: #003e5f;
  border-radius: 50px;
}
</style>