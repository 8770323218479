<template>
  <div>
    <b-modal
      id="modalCancelNota"
      size="lg"
      title="Cancelar documento fiscal"
      ok-title="Salvar"
      centered
      @hidden="clear"
    >
      <template #modal-footer>
        <div
          class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-between"
        >
          <div>
            <b-button
              :disabled="motivo.length <= 15"
              variant="info"
              size="sm"
              @click="cancelNota"
              >Prosseguir</b-button
            >
          </div>

          <div v-if="spinLoading">
            <b-spinner
              style="width: 2rem; height: 2rem"
              variant="primary"
            ></b-spinner>
          </div>
        </div>
      </template>
      <b-row class="d-flex">
        <b-form-group
          id="input-group-1"
          label="Motivo do cancelamento!"
          label-for="input-1"
          class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
        >
          <b-form-input
            placeholder="Min: 15 caracteres"
            size="md"
            v-model="motivo"
          ></b-form-input>
        </b-form-group>
      </b-row>
      <div class="cancellationNotice">
        <ul>
          <li>
            O prazo máximo para cancelamento de uma NF-e é de até 24 HORAS após
            a concessão da autorização de uso.
          </li>
          <li>
            O prazo máximo para cancelamento de uma NFC-e é de até 30 MINUTOS
            após a concessão da autorização de uso.
          </li>
        </ul>
      </div>
    </b-modal>
  </div>
</template>

<script>
import serviceNotaFiscal from "../../services/serviceNotaFiscal";
export default {
  props: {
    idNota: {
      type: Object,
    },
  },
  data() {
    return {
      motivo: "",
      spinLoading: false,
      alterValueEvent: false,
    };
  },
  methods: {
    async cancelNota() {
      try {
        const dataCancelamento = {
          uuid: this.idNota.uuidNotaWebMania,
          motivo: this.motivo,
        };

        this.spinLoading = true;
        await serviceNotaFiscal.cancelNota(
          dataCancelamento,
          this.idNota.idNota
        );

        this.$emit("getNotaAfterCanceled", !this.alterValueEvent);
        this.$toast.open({
          message: "Nota Cancelada!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message: error.response.data.message,
          type: "error",
        });
      } finally {
        this.spinLoading = false;
        this.$bvModal.hide("modalCancelNota");
      }
    },
    clear() {
      this.motivo = "";
    },
  },
};
</script>

<style scoped>
.cancellationNotice {
  color: red;
  font-size: 11px;
  margin-left: 50px;
}

p {
  margin: 0;
}
</style>