<template>
  <div class="d-flex justify-content-center container-geral-financeiro">
    <div class="container-components-financeiro" role="tablist">
      <Bill
        :isProvider="isProvider"
        :title="title"
        :type="type"
        :label="label"
      />
    </div>
  </div>
</template>

<script>
import Bill from "./Bill.vue";
export default {
  components: {
    Bill,
  },
  props: {
    title: {
      type: String,
      default: "Nova Receita",
    },
    type: {
      type: String,
      default: "entrada",
    },
    label: {
      type: String,
      default: "Conta de Recebimento",
    },
    isProvider: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.container-geral-financeiro {
  width: 100% !important;
}

.container-components-financeiro {
  transition: all 0.4s linear;
  width: 95%;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .container-components-financeiro {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>