<template>
  <div class="containerGeneralInstallments">
    <div class="contentGeneralInstallments">
      <div class="summaryForm">
        <table>
          <thead>
            <tr>
              <th class="tdAndThFistValidity"></th>
              <th class="tdAndThValue">
                <p class="mr-2 titleItensBills">Data Vencimento</p>
              </th>
              <th class="tdAndThValue">
                <p class="mr-2 titleItensBills">Vl. Restante</p>
              </th>
              <th class="tdAndThValue">
                <p class="mr-2 titleItensBills">
                  {{
                    isProvider ? "Forma de Pagamento" : "Forma de recebimento"
                  }}
                </p>
              </th>
              <th class="tdAndThValue">
                <p class="mr-2 titleItensBills">
                  {{
                    isProvider ? "Conta de pagamento" : "Conta de recebimento"
                  }}
                </p>
              </th>
              <th class="tdAndThWithInputs">
                <p class="mr-2 titleItensBills">Descrição</p>
              </th>
              <th class="tdAndThWithInputs">
                <p class="mr-2 titleItensBills">Paga?</p>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(bills, index) in dataBills" :key="index">
              <td class="tdAndThFistValidity">
                {{ index + 1 }}/{{ dataBills.length }}
              </td>
              <td class="tdAndThWithInputs">
                <div class="mr-2">
                  <div class="input-group">
                    <input
                      required
                      type="date"
                      class="input col-sm-12 col-md-12 col-lg-12 col-xl-12 borderInput text-center"
                      v-model="bills.data"
                      :disabled="bills.id !== ''"
                      @change="emitEventChangeBill"
                    />
                  </div>
                </div>
              </td>
              <td class="tdAndThValue">
                <div class="mr-2">
                  <div class="input-group">
                    <input
                      required
                      type="text"
                      class="input col-sm-12 col-md-12 col-lg-12 col-xl-12 borderInput text-center"
                      :value="maskCurrency(bills.valorRestante)"
                      @input="handleRecalculateRemainingValue($event, index)"
                      :disabled="
                        dataBills.length - 1 === index || bills.id !== '' || bills.isPaid
                      "
                      ref="refValorRestante"
                      @change="emitEventChangeBill"
                    />
                  </div>
                </div>
              </td>
              <td class="tdAndThWithInputs">
                <div class="mr-2">
                  <div class="input-group">
                    <b-form-select
                      class="input col-sm-12 col-md-12 col-lg-12 col-xl-12 borderInput shadow-none"
                      value-field="id"
                      text-field="tipo"
                      v-model="dataBills[index].idFormaPagamento"
                      :options="paymentTypes"
                      @change="emitEventChangeBill"
                    >
                    </b-form-select>
                  </div>
                </div>
              </td>
              <td class="tdAndThWithInputs">
                <div class="mr-2">
                  <div class="input-group">
                    <b-form-select
                      class="input col-sm-12 col-md-12 col-lg-12 col-xl-12 borderInput shadow-none"
                      value-field="id"
                      text-field="nome"
                      v-model="dataBills[index].idContaRecebimento"
                      @change="emitEventChangeBill"
                      :options="receiptAccounts"
                    >
                    </b-form-select>
                  </div>
                </div>
              </td>
              <td class="tdAndThWithInputs">
                <div class="mr-2">
                  <div class="input-group">
                    <input
                      required
                      type="text"
                      class="input col-sm-12 col-md-12 col-lg-12 col-xl-12 borderInput text-center"
                      v-model="dataBills[index].descricao"
                      :disabled="bills.id !== ''"
                      @change="emitEventChangeBill"
                    />
                  </div>
                </div>
              </td>
              <td class="tdAndThWithSwitch">
                <div class="content-switch">
                  <label class="switch">
                    <input
                      type="checkbox"
                      v-model="dataBills[index].isPaid"
                      @change="handlePaymentStatus(index)"
                    />
                    <div class="slider">
                      <span>Não</span>
                      <span>Sim</span>
                    </div>
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ServiceBill from "../../services/serviceBill";
import { mascaraMoeda, maskCurrency } from "../../utils/maskMoney";
import { removeReference } from "../../utils/removeReference";

export default {
  props: {
    amountBills: {
      type: Array,
    },
    paymentTypes: {
      type: Array,
    },
    receiptAccounts: {
      type: Array,
    },
    totalValue: {
      type: [String, Number],
    },
    rangeOfDays: {
      type: [String, Number],
    },
    paymentSelected: {
      type: String,
    },
    receiptBillSelected: {
      type: String,
    },
    verifyBillIsPaid: {
      type: Boolean,
    },
    saleIsCanceled: {
      type: Boolean,
    },
    confirmedInstallments: {
      type: Boolean,
    },
    idAccountReceipt: {
      type: String,
    },
    idTypePayment: {
      type: String,
    },
    isProvider: {
      type: Boolean,
    },
  },
  data() {
    return {
      dataBills: [],
      enableConfirmButton: true,
      dropBills: false,
      newTotalValue: "",
      newRangeOfDays: "",
      newPaymentSelected: "",
      newReceiptBillSelected: "",
      isRemovedBills: false,
      isChangedBill: {},
      maskCurrency: maskCurrency,
    };
  },
  methods: {
    formatMoney(event, index) {
      this.dataBills[index].valorRestante = mascaraMoeda(event.target.value);
    },
    async removeAllBills() {
      await ServiceBill.deleteByIdSale(this.dataBills[0].idVenda);
      this.dropBills = !this.dropBills;

      this.$emit("isDropBills", this.dropBills);
    },
    handleCloseModalAndClearDataBills() {
      this.clearDataBills();
    },
    clearDataBills() {
      this.dataBills = [];
      this.newPaymentSelected = "";
      this.newReceiptBillSelected = "";
      this.newTotalValue = 0;
      this.newRangeOfDays = "";
    },
    async handleRemoveOrSaveBills() {
      this.emitEventChangeBill();

      if (this.billsIsRegistred) {
        try {
          await this.removeAllBills();
        } catch (error) {
          return this.$toast.open({
            message:
              `${error.response.data.message}` ||
              "Ocorreu um erro ao remover as parcelas!",
            type: "warning",
          });
        }
      }

      this.handleCloseModalAndClearDataBills();
    },

    handleRecalculateRemainingValue(event, index = -1) {
      if (index !== -1) {
        this.isChangedBill[index] = true;

        this.formatMoney(event, index);
        this.dataBills[index].valorTotal =
          this.dataBills[index].valorRestante - this.dataBills[index].valorPago;
      }

      this.handleDivideValuesBills(index);
      this.calculateRemainingValue();

      this.$nextTick(() => {
        this.emitEventChangeBill();
      });
    },
    assingValues() {
      const totalRemainingValue = this.dataBills.reduce((oldValue, bill) => {
        parseFloat(oldValue) + parseFloat(bill.valorRestante);
      }, 0);

      this.newTotalValue = maskCurrency(totalRemainingValue);
      this.newRangeOfDays = this.rangeOfDays;
      this.newPaymentSelected = this.paymentSelected;
      this.newReceiptBillSelected = this.receiptBillSelected;
    },
    handleDivideValuesBills(index = -1) {
      if (this.dataBills.length) {
        if (index !== -1) {
          const total = this.calculateTotalValueOfChangedBills();
          const billsNotChanged = this.getBillsNotChanged();

          this.divideValuesBetweenBills(total, billsNotChanged);
        }
      }
    },
    calculateTotalValueOfChangedBills() {
      const total = this.totalValue;
      let totalValueOfChangedBills = 0;

      for (var i = 0; i < this.dataBills.length; i++) {
        if (this.isChangedBill[i]) {
          totalValueOfChangedBills += parseFloat(
            this.dataBills[i].valorRestante
          );
        }
      }

      return total - totalValueOfChangedBills;
    },
    getBillsNotChanged() {
      const billsNotChanged = Object.keys(this.isChangedBill).filter(
        (changedBill) => {
          return this.isChangedBill[parseInt(changedBill)] === false;
        }
      );

      return billsNotChanged;
    },
    divideValuesBetweenBills(total, billsNotChanged) {
      for (let i = 0; i < billsNotChanged.length - 1; i++) {
        this.dataBills[billsNotChanged[i]].valorTotal = (
          total / billsNotChanged.length
        ).toFixed(2);

        this.dataBills[billsNotChanged[i]].valorRestante = (
          this.dataBills[billsNotChanged[i]].valorTotal -
          this.dataBills[billsNotChanged[i]].valorPago
        ).toFixed(2);
      }
    },
    calculateRemainingValue() {
      const lastIndexBill = this.dataBills.length - 1;

      const totalBills = this.dataBills.reduce(
        (oldValue, bill) =>
          parseFloat(oldValue) + parseFloat(bill.valorRestante),
        0
      );

      const remainingValue = (totalBills - this.totalValue).toFixed(2);
      if (this.dataBills.length) {
        this.dataBills[lastIndexBill].valorTotal = (
          parseFloat(this.dataBills[lastIndexBill].valorTotal) -
          parseFloat(remainingValue)
        ).toFixed(2);

        this.dataBills[lastIndexBill].valorRestante = (
          this.dataBills[lastIndexBill].valorTotal -
          this.dataBills[lastIndexBill].valorPago
        ).toFixed(2);
      }
    },
    cashierEntry(index) {
      const isPaydBill = this.$refs.isPaidBill[index].checked;
      if (isPaydBill) {
        this.dataBills[index].dataPagamento = moment().format("YYYY-MM-DD");
        this.$refs.refValorRestante[index].value = "0,00";
      } else {
        this.dataBills[index].dataPagamento = null;
        this.$refs.refValorRestante[index].value = parseFloat(
          this.dataBills[index].valorRestante
        ).toLocaleString("pt-br", { minimumFractionDigits: 2 });
      }
    },

    handlePaymentStatus(index) {
      if (this.dataBills[index].isPaid) {
        this.dataBills[index].dataPagamento = moment().format("YYYY-MM-DD");
        this.$refs.refValorRestante[index].value = "0,00";
      } else {
        this.dataBills[index].dataPagamento = null;
      }
      this.emitEventChangeBill();
    },

    emitEventChangeBill() {
      this.$emit("changed", this.dataBills);
    },
  },
  watch: {
    amountBills() {
      this.dataBills = removeReference(this.amountBills);

      if (this.dataBills.length && this.dataBills[0].id === "") {
        this.calculateRemainingValue();
      }

      this.assingValues();
    },
    verifyBillIsPaid() {
      this.dataBills.forEach((bill, index) => {
        this.$nextTick(() => {
          if (bill.id) this.$refs.isPaidBill[index].disabled = true;
          if (bill.dataPagamento) {
            this.$refs.isPaidBill[index].checked = true;
            this.$refs.isPaidBill[index].disabled = true;
          }
        });
      });
    },
    saleIsCanceled() {
      this.clearDataBills();
      this.emitEventChangeBill();
    },
    confirmedInstallments() {
      this.emitEventChangeBill();
    },
    idAccountReceipt() {
      this.dataBills.forEach((bill) => {
        bill.idContaRecebimento = this.idAccountReceipt;
      });

      this.emitEventChangeBill();
    },
    idTypePayment() {
      this.dataBills.forEach((bill) => {
        bill.idFormaPagamento = this.idTypePayment;
      });

      this.emitEventChangeBill();
    },
  },
  computed: {
    billsIsRegistred() {
      return this.dataBills[0]?.id;
    },
    handleEnableConfirmOrRemoveButtom() {
      if (!this.billsIsRegistred && this.dataBills.length) {
        return "Confirmar";
      } else {
        return "Deletar";
      }
    },
  },
};
</script>

<style scoped>
.containerGeneralInstallments {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
}

.contentGeneralInstallments {
  background-color: rgb(175 173 173 / 12%);
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  overflow-x: auto;
}
.summaryForm {
  display: flex;
  flex-direction: column;
  color: #00486e;
}

.summaryForm label {
  margin-bottom: 0px;
}

#modalParcelas {
  color: #00486e;
  font-size: 17px;
}

table {
  border-collapse: separate;
  border-spacing: 0 8px;
}

th {
  text-align: center !important;
  font-weight: normal;
}

.tdAndThWithInputs {
  width: 20%;
}

.tdAndThWithSwitch {
  position: relative;
  width: 20%;
}

.slider {
  position: absolute;
  bottom: 0.25rem;
  cursor: pointer;
  background-color: #e7e6e6;
  font-weight: bold;
  color: #000000;
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.4s;
  width: 60px !important;
  height: 70%;
}

.slider:before {
  position: absolute;
  content: "Não";
  height: 80%;
  width: 45%;
  left: 5%;
  font-weight: bold;
  border-radius: 50%;
  background-color: rgb(66, 203, 66);
  color: white;
  display: grid;
  align-content: center;
  justify-content: center;
  transition: 0.4s;
}

.slider:after {
  content: "";
  position: absolute;
  z-index: -1;
  font-weight: bold;
}

.switch input:checked + .slider {
  background-color: #e7e6e6;
  color: rgb(0, 0, 0);
}

.switch input:checked + .slider:before {
  content: "Sim";
  background-color: rgb(66, 203, 66);
  transform: translateX(100%);
  color: white;
  font-weight: bold;
}

.switch input {
  display: none;
}

.content-switch {
  width: 35%;
}

.tdAndThPercentageInputs {
  width: 10%;
}

.tdAndThFistValidity {
  width: 4%;
  color: #afadad;
  font-size: 14px;

  margin-bottom: 0px;
}

.tdAndThValue {
  width: 10%;
}

.titleItensBills {
  color: #afadad;
  font-size: 12px;
  margin-bottom: 0px;
  text-align: start;
}

.launchAsPaidTitle {
  color: #afadad;
  font-size: 12px;
  margin-bottom: 0px;
}

.user-label {
  position: absolute;
  left: 5px;
  font-size: 12px;
  color: #00395d;
  bottom: 6px;
  color: #777777;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
}

.input {
  font-size: 12px !important;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.2em;
}

.selectPlus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.borderInput {
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #a9cff4;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 10px 1rem;
}

.switch {
  height: 30px;
}

/* The slider */
.slider {
  cursor: pointer;
  background-color: #cccaca;
  color: #504d4d;
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.4s;
  width: 70px !important;
  height: 33px;
  font-size: 10px;
}

.slider:before {
  position: absolute;
  content: "Não";
  height: 90%;
  width: 45%;
  left: 5%;
  border-radius: 50%;
  background-color: rgb(66, 203, 66);
  color: whitesmoke;

  display: grid;
  align-content: center;
  justify-content: center;
  transition: 0.4s;
}

.slider:after {
  content: "";
  position: absolute;
  z-index: -1;
}

.switch input:checked + .slider {
  background-color: #cccaca;
  color: #504d4d;
}

.switch input:checked + .slider:before {
  content: "Sim";
  background-color: rgb(66, 203, 66);
  transform: translateX(100%);
  color: white;
}

.switch input {
  display: none;
}
</style>