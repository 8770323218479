<template>
  <b-modal size="lg" id="modalSaleSquareMeder" hide-header>
    <template #modal-footer>
      <div style="display: flex; justify-content: space-between; width: 100%">
        <div>
          <button class="btnsSale" @click="closeModal">Voltar</button>
        </div>
        <!-- <div>
            <button class="btnsSale" @click="print">Imprimir</button>
          </div> -->
        <div>
          <button class="btnsSale btnConfirm" @click="saveOrUpdateSale(true)">
            Salvar
          </button>
        </div>
        <!-- <div>
            <button
              class="btnsSale btnsSaveOrSendNfe"
              @click="handleTranformToNfeAndCloseModal"
            >
              {{ returnTextSaveAndSendNota }}
            </button>
          </div> -->
      </div>
    </template>

    <div>
      <div class="headerSale">
        <p>
          {{
            dataSaleSquareMeter.status === salesSquareMeterType.venda
              ? "Venda"
              : "Orçamento"
          }}
          {{ numberSale || dataSaleSquareMeter.numero }}
        </p>
        <p>{{ totals.total }}</p>
      </div>

      <div style="padding: 0 10px">
        <hr style="margin-top: 0.2rem; margin-bottom: 1rem" />
      </div>
      <div @click="closeModal" class="closeModal">
        <b-icon-x scale="1.3"></b-icon-x>
      </div>
    </div>

    <div class="contentSections">
      <section class="dataCabecalho">
        <div class="contentItens" style="padding: 0 0 0 5px">
          <p class="titleItens">Cliente</p>
          <p class="itens">
            {{ squareMeterForModal.customerSelected.nome }}
          </p>
        </div>

        <div class="contentItens" style="align-items: center">
          <p class="titleItens">Data da Venda</p>
          <p class="itens">
            {{ squareMeterForModal.data | moment }}
          </p>
        </div>

        <div class="contentItens" style="align-items: flex-end">
          <p class="titleItens">Funcionário</p>
          <p class="itens">
            {{ squareMeterForModal.employeeSelected.nomeFuncionario }}
          </p>
        </div>
      </section>

      <section class="dataSaleItems responsiveTable">
        <table>
          <thead>
            <tr>
              <th>Produto/Serviço</th>
              <th>Observação</th>
              <th style="text-align: start">Quantid.</th>
              <th style="text-align: start">MT²</th>
              <th style="text-align: center">Valor</th>
              <th style="text-align: end">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(
                product, indice
              ) in squareMeterForModal.formattedProductsSale"
              :key="indice"
            >
              <td>{{ product.nome }}</td>
              <td>{{ product.dadosAdicionais }}</td>
              <td style="text-align: start">{{ product.quantidade }}</td>
              <td style="text-align: start">
                {{ maskAmount(product.metragem) }}
              </td>
              <td style="text-align: center">R$ {{ product.valorUnitario }}</td>
              <td style="text-align: end">
                {{ maskCurrency(product.valorTotal) }}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <b-row style="margin: 30px 0">
        <div class="contentDataTotals">
          <div class="contentTotals">
            <span class="boxTotals">
              <p>Subtotal (R$)</p>
              <p class="colorItens">{{ totals.totalItems }}</p>
            </span>

            <span class="boxTotals">
              <p>Desconto (R$)</p>
              <p class="colorItens">{{ totals.totalDiscount }}</p>
            </span>

            <span class="dashed"></span>

            <span class="boxTotals contentFreteTotals">
              <p>Frete (R$)</p>
              <p class="frete">{{ totals.additionalValues }}</p>
            </span>

            <span class="boxTotals contentFreteTotals">
              <p>Outros (R$)</p>
              <p class="frete">{{ totals.otherValues }}</p>
            </span>

            <span class="boxTotals totals">
              <p>Total da Venda (R$)</p>
              <p class="colorItens">{{ totals.total }}</p>
            </span>
          </div>
        </div>
      </b-row>

      <hr style="margin: 0" v-if="dataSaleSquareMeter.bills.length" />
      <div class="generalContent" v-if="dataSaleSquareMeter.bills.length">
        <div class="subTitle">
          <p>Informações Financeiras</p>
        </div>

        <div class="contentTitlesFinancial">
          <div>
            <p class="titleFinancial">Forma de Pagamento</p>
            <p class="subTitleFinancial">{{ paymentsDataInfo.typePayment }}</p>
          </div>

          <div>
            <p class="titleFinancial">Conta de Recebimento</p>
            <p class="subTitleFinancial">
              {{ paymentsDataInfo.receiptAccount }}
            </p>
          </div>

          <div>
            <p class="titleFinancial">Intervalo de Parcelas (dias)</p>
            <p class="subTitleFinancial">
              {{ paymentsDataInfo.installmentsInterval }}
            </p>
          </div>

          <div>
            <p class="titleFinancial">Número de Parcelas</p>
            <p class="subTitleFinancial">
              {{ paymentsDataInfo.installmentsNumber }}
            </p>
          </div>

          <div>
            <p class="titleFinancial">Venc. Primeira Parcela</p>
            <p class="subTitleFinancial">{{ paymentsDataInfo.expiredFirst }}</p>
          </div>
        </div>
      </div>

      <div class="generalContent mt-3" v-if="dataSaleSquareMeter.bills.length">
        <div class="subTitle">
          <p>Parcelas</p>
        </div>

        <div
          style="
            background-color: whitesmoke;
            padding: 10px 5px;
            border-radius: 5px;
          "
        >
          <table style="text-align: center">
            <thead>
              <tr>
                <th style="width: 60px"></th>
                <th style="width: 150px">Data de vencimento</th>
                <th>Valor restante</th>
                <th>Valor pago</th>
                <th>Descrição</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(bill, index) in dataSaleSquareMeter.bills"
                :key="index"
              >
                <td>{{ index + 1 }}º Venc.</td>
                <td>
                  {{ bill.data.split(" ")[0].split("-").reverse().join("/") }}
                </td>
                <td>
                  {{
                    bill.dataPagamento
                      ? maskCurrency(0)
                      : maskCurrency(bill.valorRestante)
                  }}
                </td>
                <td>
                  {{
                    bill.dataPagamento && !bill.valorPago
                      ? maskCurrency(bill.valorRestante)
                      : maskCurrency(bill.valorPago)
                  }}
                </td>
                <td>{{ bill.descricao }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <hr style="margin: 20px 0" v-if="dataSaleSquareMeter.dadosAdicionais" />

      <div class="generalContent m-0">
        <div v-if="dataSaleSquareMeter.dadosAdicionais != ''">
          <p class="titleObs">Observações Gerais</p>
          <div class="descriptions">
            {{ dataSaleSquareMeter.dadosAdicionais }}
          </div>
        </div>

        <div v-if="dataSaleSquareMeter.observacoesNf != ''">
          <p class="titleObs">Observação de Nota Fiscal</p>
          <div class="descriptions">
            {{ dataSaleSquareMeter.observacoesNf }}
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-center-sale-squared"
      hide-footer
      hide-header
      centered
      title="Emitir"
      @hidden="closePrint"
    >
      <div>
        <h6 class="titleModal">
          {{ !isOtherPrints ? "Deseja imprimir ?" : "Modelos para impressão" }}
        </h6>
        <div class="btnModal" v-if="!isOtherPrints">
          <button class="btnContentReceive" @click="showOtherPrints">
            Impressões
          </button>
          <button
            class="btnContent"
            @click="handleTranformToNfeAndCloseModal"
            v-if="dataSaleSquareMeter.status === salesSquareMeterType.venda"
          >
            {{ returnTextSaveAndSendNota }}
          </button>
          <button
            class="btnContent"
            @click="handleValidateDataAndGenerateSaleSquareMeter"
            v-else
          >
            Transformar em Venda MT2
          </button>
        </div>

        <div class="rowContainer" v-else>
          <span>
            <b-icon-calendar-week></b-icon-calendar-week> Selecione um
            modelo:</span
          >
          <div class="rowContent mt-3">
            <div
              class="containerRadio"
              v-for="(modelPrint, idx) in modelsPrints"
              :key="idx"
            >
              <label @click="selectTypeReport(modelPrint.type)">
                <input type="radio" name="radio" :checked="modelPrint.value" />
                <span>{{ modelPrint.text }}</span>
              </label>
            </div>
          </div>

          <div class="rowContent" style="justify-content: flex-end; padding: 0">
            <button class="btnPrint" @click="printReportSelect">
              Imprimir
            </button>
          </div>
        </div>

        <div v-if="isOtherPrints" class="rowContent mt-3" style="padding: 0">
          <span class="contentClosePrint" @click="closePrint">
            <b-icon-arrow-left scale="1.5" />
            <p>Voltar</p>
          </span>
        </div>

        <a
          class="rejectModal"
          v-if="!spinLoading && !isOtherPrints"
          @click="$bvModal.hide('modal-center-sale-squared')"
          >Não, Obrigado!</a
        >
      </div>
      <Loading v-if="spinLoading" class="spin" />
    </b-modal>
  </b-modal>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import moment from "moment";

import Loading from "../Loading/Loading.vue";

import ServiceSaleSquareMeter from "../../services/serviceSaleSquareMeter";
import ServiceSituation from "../../services/serviceSituation";

import { ValidadeSquareMeterBudget } from "../../validators/squareMeterBudget";
import { HandleErrors } from "../../utils/handleErrors";
import { TypeErrors } from "../../utils/enums/TypeErrors";
import toastAlertErros from "../../utils/toastAlertErros";
import { maskAmount } from "../../utils/maskMoney";
import { SalesSquareMeterType } from "../../utils/enums/SalesSquareMeterType";
import { SquareMeterSituationType } from "../../utils/enums/SquareMeterSituationType";
import { E_TypesReports } from "../../utils/enums/reports/SaleSquareMeter";
import { formatObjectToSaveAndUpdate } from "../../helpers/squareMeterSaleAndBudget";

import DefaultLogo from "../../assets/LogoTeste.jpeg";
import print from "../../reports/saleSquareMeter";

export default {
  components: {
    Loading,
  },
  props: {
    dataSaleSquareMeter: {
      type: Object,
    },
    totals: {
      type: Object,
    },
    squareMeterForModal: {
      type: Object,
    },
    paymentsDataInfo: {
      type: Object,
    },
  },
  data() {
    return {
      spinLoading: false,
      defaultLogo: DefaultLogo,
      productsSale: {},
      numberSale: "",
      maskAmount: maskAmount,
      salesSquareMeterType: SalesSquareMeterType,
      isOtherPrints: false,
      modelPrintDefault: "1",
      typeReportSelected: E_TypesReports.simple,
      modelsPrints: [
        { text: "Padrão", value: true, type: E_TypesReports.simple },
        {
          text: "Por Ambiente",
          value: false,
          type: E_TypesReports.rooms,
        },
        {
          text: "romaneio",
          value: false,
          type: E_TypesReports.deliveryManifest,
        },
      ],
    };
  },
  methods: {
    ...mapMutations([
      "SET_DATA_NFE",
      "ALTER_VALUES_MODAL_DIALOG_GENERAL",
      "ALTER_TYPE_DOCUMENT",
    ]),
    async saveOrUpdateSale(showToast) {
      if (!this.dataSaleSquareMeter.id) return await this.saveSale(showToast);
      await this.updateCompleted(showToast);
    },

    async saveSale(showToast = true) {
      try {
        const dataSaleFormatted = formatObjectToSaveAndUpdate(
          this.dataSaleSquareMeter
        );
        const result = await ServiceSaleSquareMeter.createNewCompleted(
          dataSaleFormatted
        );

        this.numberSale = result.number;
        if (result.billsOfSaleSquareMeter) {
          result.billsOfSaleSquareMeter.forEach((bill) => {
            if (bill.dataPagamento) {
              bill.valorPago = bill.valorTotal;
              bill.valorRestante = 0;
            }
          });
        }

        this.$emit("saleSquareMeterSaved", result);
        this.openModals("modal-center-sale-squared");

        if (showToast) {
          return this.$toast.open({
            message: `${
              this.dataSaleSquareMeter.status === "Orçamento"
                ? "Orçamento salvo com Sucesso"
                : "Venda salva com Sucesso"
            }`,
            type: "success",
          });
        }
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (!this.dataConfig.caixa_automatico.utilizar) {
          if (
            typeError &&
            Object.keys(typeError).length &&
            TypeErrors.NO_SAFE_REGISTER_OPEN.includes(typeError?.nameError)
          ) {
            return this.handleModalSafeOpening();
          }
        }

        if (error.response.status === 422) {
          return this.$toast.open({
            message:
              typeError?.message ||
              "Verifique se os dados do cliente foram informados",
            type: "info",
          });
        } else {
          return this.$toast.open({
            message:
              error?.response?.data?.message ||
              "Verifique se os dados do cliente foram informados",
            type: "info",
          });
        }
      }
    },

    async updateCompleted(showToast = true) {
      try {
        const dataSaleFormatted = formatObjectToSaveAndUpdate(
          this.dataSaleSquareMeter
        );

        const result = await ServiceSaleSquareMeter.updateCompleted(
          dataSaleFormatted
        );

        if (result.billsOfSale) {
          result.billsOfSale.forEach((bill) => {
            if (bill.dataPagamento) {
              bill.valorPago = bill.valorTotal;
              bill.valorRestante = 0;
            }
          });
        }

        this.$emit("saleSquareMeterSaved", result);
        this.openModals("modal-center-sale-squared");

        if (showToast) {
          return this.$toast.open({
            message: `${
              this.dataSaleSquareMeter.status === "Orçamento"
                ? "Orçamento salvo com Sucesso"
                : "Venda salva com Sucesso"
            }`,
            type: "success",
          });
        }
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (!this.dataConfig.caixa_automatico.utilizar) {
          if (
            typeError &&
            Object.keys(typeError).length &&
            TypeErrors.NO_SAFE_REGISTER_OPEN.includes(typeError?.nameError)
          ) {
            return this.handleModalSafeOpening();
          }
        }

        if (showToast) {
          return this.$toast.open({
            message:
              typeError?.message ||
              error.response.data.message ||
              "Verifique se os dados do cliente foram informados",
            type: "info",
          });
        }
      } finally {
        this.openModals("modal-center-sale-squared");
      }
    },

    printReportSelect() {
      if (!this.dataSaleSquareMeter.id) return;
      print(this.typeReportSelected, this.dataSaleSquareMeter.id);
    },

    showOtherPrints() {
      this.isOtherPrints = !this.isOtherPrints;
    },

    async handleTranformToNfeAndCloseModal() {
      const showToast = false;
      if (!this.dataSaleSquareMeter.id) await this.saveSale(showToast);

      this.closeModal();
      this.transformToNfe();
    },

    async transformToNfe() {
      try {
        const dataNFe = await ServiceSaleSquareMeter.transformWithNota(
          this.dataSaleSquareMeter.id
        );

        this.SET_DATA_NFE(dataNFe);
        this.$toast.open({
          message: "Venda transformada em nota fiscal!",
          type: "success",
        });

        this.$router.push({
          path: `/fiscal`,
        });
      } catch (error) {
        if (error?.response?.data?.erros)
          return this.$toast.open({
            message:
              error?.response?.data?.erros ||
              "Ocorreu um problema ao transformar a venda MT2 em NF-e",
            type: "info",
          });

        return toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async handleValidateDataAndGenerateSaleSquareMeter() {
      const situation = await ServiceSituation.findById(
        this.dataSaleSquareMeter.idSituacao
      );

      if (situation.nome === SquareMeterSituationType.cancelado) {
        return this.$toast.open({
          message:
            "Não é possível gerar uma venda a partir de um orçamento cancelado",
          type: "info",
        });
      }

      const isNotValid = ValidadeSquareMeterBudget(this.dataSaleSquareMeter);
      if (isNotValid) {
        return this.$toast.open({
          message: isNotValid,
          type: "info",
        });
      }

      this.generateSaleSquareMeter();
    },

    generateSaleSquareMeter() {
      this.$emit("transformToSale");
      this.dataSaleSquareMeter.status = "Venda";
      this.ALTER_TYPE_DOCUMENT("vendaMtq");
      this.$bvModal.hide("modal-center-sale-squared");
      this.closeModal();
    },

    maskCurrency(valor, locale = "pt-BR") {
      if (!valor) {
        valor = 0;
      }
      return parseFloat(valor).toLocaleString(locale, {
        style: "currency",
        currency: "BRL",
      });
    },

    handleModalSafeOpening() {
      this.ALTER_VALUES_MODAL_DIALOG_GENERAL({
        nameModal: "modalDialogSafeOpening",
        title: "Caixa ainda não aberto!",
        subTitle:
          "Verificamos que você ainda não iniciou o caixa, para realizar qualquer lançamento, é necessario realizar a abertura do caixa!",
        footerTitle: "Deseja abrir o caixa agora ?",
        leftButtonText: "Não",
        rightButtonText: "Sim",
      });

      this.$nextTick(() => {
        this.openModals(this.dataModalDialogGeneral.nameModal);
      });
    },

    selectTypeReport(typeReport) {
      this.typeReportSelected = typeReport;
    },

    closePrint() {
      this.isOtherPrints = false;
    },

    openModals(name) {
      this.$bvModal.show(name);
    },

    closeModal() {
      this.$bvModal.hide("modalSaleSquareMeder");
    },
  },
  computed: {
    ...mapState({
      dataCompany: (state) => state.dataCompany,
      dataModalDialogGeneral: (state) => state.dataModalDialogGeneral,
      dataSafeIsOpen: (state) => state.dataSafeIsOpen,
      dataConfig: (state) => state.dataConfig,
    }),
    returnTextSaveAndSendNota() {
      if (!this.dataSaleSquareMeter.id) {
        return "Salvar e Emitir NF-e";
      } else {
        return "Emitir NF-e";
      }
    },
    safeIsOpen() {
      return this.dataSafeIsOpen.safeIsOpen;
    },
  },
  watch: {
    safeIsOpen() {
      this.saveOrUpdateSale();
    },
  },
  filters: {
    moment(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.footerBtn {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

thead th {
  border-bottom: 1px solid #d5d3d3;
  color: #afadad;
  font-size: 12px;
  font-weight: normal;
}

td {
  color: black;
  font-size: 12px;
}

.dataSaleItems {
  font-size: 14px;
  margin-top: 25px;
}

table {
  border-collapse: separate;
  border-spacing: 0 4px;
  width: 100%;
}

.responsiveTable {
  max-height: 200px;
  overflow-x: auto;
}

.btnsActions {
  width: 150px;
  font-size: 18px;
  margin-top: 0;
  background-color: #0fbf4d;
}

.btnSaveAndSendLeaf {
  width: 200px;
  font-size: 18px;
  margin-top: 0;
  background-color: #00395d;
}

.summaryTitle h5 {
  font-family: Arial, Helvetica, sans-serif;

  color: #00486e;
}

.contentSummary {
  display: flex;
  flex-direction: column;
}

.contentSummary p {
  margin-bottom: 5px;
}

.itensSummary {
  display: flex;
  justify-content: space-between;
}

.company p {
  font-family: Arial, Helvetica, sans-serif;
  color: #00486e;
  font-size: 15px;
}

.contentDataCompany {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dataCompany {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dataCompany img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: solid 1px rgb(162, 144, 144);
}

.contentLogo {
  display: flex;
  justify-content: center;
}

.contentTotals {
  width: 370px;
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  font-size: 14px;
  gap: 5px;
}

.boxTotals {
  display: flex;
  align-items: center;
  gap: 30px;
  color: #666360;
}

.dashed {
  border-bottom: 1px dashed #b1aaa5;
  width: 100%;
  margin: 10px 0;
  cursor: auto;
}

.boxTotals {
  display: flex;
  align-items: center;
  cursor: auto;
}

.boxTotals .colorItens {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  outline: none;
  padding-right: 5px;
  background-color: #f2f3f3;
  border-radius: 5px;
  height: 30px;
  width: 177px;
}

.totals {
  font-size: 13px !important;
  color: #666360 !important;
  font-weight: 600;
}

.contentFreteTotals .frete {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  outline: none;
  padding-right: 5px;
  background-color: #f2f3f3;
  border-radius: 5px;
  height: 30px;
  width: 177px;
}

.dataCabecalho {
  width: 100%;
  display: flex;
  background-color: #f7f7f7;
  gap: 20px;
  border-radius: 4px;
}

.contentItens {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.titleItens {
  color: #afadad;
  font-size: 12px;
}

.itens {
  color: black;
  font-size: 14px;
}

.contentSections {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.titleModal {
  font-size: 12px;
  border-bottom: 1px solid #e6e7e8;
}

.btnModal {
  margin-top: 10px;
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.btnContent {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentReceive {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.btnPrint {
  font-size: 11px;
  border: 1px solid #0fbf4d;
  border-radius: 3px;
  color: #ffffff;
  background: #0fbf4d;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.185);
  transition: ease-in all 0.1s;
}

.btnPrint:hover {
  transition: ease-in all 0.1s;
  background: #0ea241;
}

.contentClosePrint {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0.3rem;
  font-size: 11px;
  border-radius: 3px;
  color: #00486e;
  transition: ease-in all 0.1s;
  font-weight: 600;
  cursor: pointer;
}

.contentClosePrint:hover {
  transition: ease-in all 0.1s;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.321);
}

.rejectModal {
  cursor: pointer;
  color: black;
  display: flex;
  justify-content: center;
  font-size: 11px;
  font-style: oblique;
  text-decoration: underline;
}

.spin {
  width: 480px;
  height: 100%;
  top: 0;
}

.headerSale {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 0 10px;
}

.headerSale p {
  font-size: 14px !important;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  color: #666360 !important;
}

.closeModal {
  position: absolute;
  top: 0;
  right: 0;
  color: rgb(255, 153, 153);
  cursor: pointer;
  border: 1px solid rgba(245, 245, 245, 0.476);
  padding: 1px 2px;
  border-radius: 4px;
}

.closeModal:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}

.contentDataTotals {
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.btnsSale {
  border-radius: 5px;
  background-color: transparent;
  width: 200px;
  height: 28px;
  border: none;
  border: solid 1px black;
  color: black;
  font-size: 12px;
}

.btnConfirm {
  background-color: whitesmoke;
  border: solid 1px #0fbf4d;
  color: #0fbf4d;
}

.btnsSaveOrSendNfe {
  width: 135px !important;
}

.titleObs {
  color: black;
}

.descriptions {
  outline: none;
  padding: 5px;
  background-color: #f2f3f3;
  border-radius: 5px;
  min-height: 30px;
  height: auto;
  width: 100%;
  font-size: 12px;
}

.generalContent {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
}

.generalContent p {
  font-size: 14px;
}

tr:nth-child(even) {
  background: #f2f3f3;
}

.contentFinancialObservations p {
  color: black;
  font-size: 14px;
}

.contentTitlesFinancial {
  width: 100%;
  display: flex;
  gap: 30px;
  text-align: center;
  margin: 10px 0;
  background-color: whitesmoke;
  padding: 10px 5px;
  border-radius: 5px;
}

.titleFinancial {
  color: #afadad !important;

  font-size: 12px !important;
}

.subTitleFinancial {
  font-size: 12px !important;
}

p {
  margin: 0 !important;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #00486e;
  border-radius: 2px;
}

.modal-footer {
  padding: 0.25rem;
}

.subTitle p {
  font-size: 14px;
}

.rowContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
  background: #f9f9f94d;
  margin-top: 20px;
}

.rowContainer span {
  font-weight: 600;
  font-size: 11px;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  gap: 10px;
}

/* INPUT RADIO */

.containerRadio label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
}

.containerRadio label input {
  position: absolute;
  left: -9999px;
}

.containerRadio label input:checked + span {
  background-color: #f3f2f2;
  color: rgb(0, 0, 0);
}

.containerRadio label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4375em #00486e;
}

.containerRadio label span {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 2px;
  transition: 0.25s ease;
}

.containerRadio label span:hover {
  background-color: #e6e6e6;
  border-radius: 2px;
}

.containerRadio label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.1em #00486e;
}

/* INPUT RADIO */

@media (min-width: 768px) and (max-width: 1200px) {
  .contentTotals {
    width: 351px;
    font-size: 11px;
    gap: 0px;
  }
}
</style>