<template>
  <b-modal size="lg" id="modalCreateProduct" hide-footer hide-header>
    <div>
      <button class="closeModal" @click="$bvModal.hide('modalCreateProduct')">
        <b-icon-x scale="1.3" />
      </button>

      <Products
        @getAllProducts="createdNewproduct = $event"
        :idProductFromSearch="idProductFromSearch"
      />
    </div>
  </b-modal>
</template>

<script>
import Products from "../Products/Products.vue";
export default {
  props: {
    idProductFromSearch: {
      type: String,
    },
  },
  data() {
    return {
      createdNewproduct: null,
    };
  },
  components: {
    Products,
  },
  methods: {},
  watch: {
    createdNewproduct() {
      this.$emit("getAllNewProduct", this.createdNewproduct);
      this.createdNewproduct = null;
    },
  },
  computed: {},
};
</script>

<style scoped>
.btnBack {
  border-radius: 5px;
  background-color: #00395d;
  border: none;
  margin-top: 30px;
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  width: 150px;
  font-size: 18px;
  margin-top: 0;
}

.closeModal {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
  color: rgb(255, 153, 153);
  cursor: pointer;
  border: 1px solid rgb(238, 238, 238);
  padding: 2px 3px;
  border-radius: 4px;
}

.closeModal:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}
</style>