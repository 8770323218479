import Joi from "joi";

export const ValidateBillsWhenGenerating = (data) => {
  const schema = Joi.object({
    idCliente: Joi.string().guid({ version: "uuidv4" }).required().messages({
      "string.empty": "(Cliente) deve ser selecionado para prosseguir"
    }),
    idFormaDePagamento: Joi.string().guid({ version: "uuidv4" }).required().messages({
      "string.empty": "(Forma de pagamento) deve ser selecionada para prosseguir"
    }),
    idContaRecebimento: Joi.string().guid({ version: "uuidv4" }).required().messages({
      "string.empty": "(Conta de recebimento) deve ser selecionada para prosseguir"
    }),
    intervaloDias: Joi.number().required().messages({
      "number.base": "(Interval. de pagamento) deve ser preenchido e deve ser um campo numérico para prosseguir"
    }),
    vencimento: Joi.date().required().messages({
      'date.base': '(Venc. 1º parcela) deve ser um formato valido',
      'date.format': '(Venc. 1º parcela) deve ser um formato valido'
    }),
    quantidadeParcelas: Joi.number().required().messages({
      "number.base": "(Parcelas) deve ser preenchido e deve ser um campo numérico para prosseguir"
    }),
  })

  const { error } = schema.validate(data, {
    allowUnknown: true,
  })

  return error?.details[0]?.message
}

export const ValidateBillsWhenLaunchValue = (data) => {
  const schema = Joi.object({
    idTypePayment: Joi.string().guid({ version: "uuidv4" }).required().messages({
      "string.empty": "(Forma de pagamento) deve ser selecionada para prosseguir"
    }),
    idReceiptAccount: Joi.string().guid({ version: "uuidv4" }).required().messages({
      "string.empty": "(Conta) deve ser selecionada para prosseguir"
    }),
    price: Joi.number().required().messages({
      "number.base": "(Valor) deve ser informado e não pode ser zerado para prosseguir"
    }),
  })

  const { error } = schema.validate(data, {
    allowUnknown: true,
  })

  return error?.details[0]?.message
}
