import api from "./axios";

class ServiceCompany {
  async findCompany() {
    const { data } = await api.get("/company");
    return data
  }

  async uploadImage(dataImg) {
    const { data } = await api.post("/company", dataImg);
    return data
  }

  async update(dataCompany) {
    await api.post(`/company/sendGestao`, dataCompany);
  }
}

export default new ServiceCompany()