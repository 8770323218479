<template>
  <div style="position: relative">
    <div
      :class="`iconSelectInfinit ${isDisabled && 'iconDisabledInfinit'}`"
      v-if="enableIconCreate"
      @click="createModal"
    >
      <div role="button">
        <b-icon-plus scale="2"></b-icon-plus>
      </div>
    </div>

    <label v-if="defaultLabel" class="floatLabel"> {{ label }} </label>
    <label v-else class="defaultLabel"> {{ label }}: </label>

    <v-select
      :value="selected"
      :label="text"
      :options="dataList"
      :filterable="false"
      :loading="loading ? true : false"
      :custom-filter="customFilter"
      @input="setSelected"
      @open="onOpen"
      @close="onClose"
      @search="searchByWriting"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          Loading more options...
        </li>
      </template>

      <template #no-options>
        <div>Resultado não encontrado!</div>
      </template>

      <template #spinner="{ loading }">
        <div
          v-if="loading"
          style="border-left-color: rgba(88, 151, 251, 0.71)"
          class="vs__spinner"
        ></div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { debounce } from "../../../utils/debounce";

export default {
  name: "InfiniteScroll",
  props: {
    dataInput: {
      type: String,
      default: "",
    },
    isEdit: {
      type: String,
    },
    text: {
      type: String,
      default: "nome",
    },
    value: {
      type: String,
      default: "id",
    },
    onSearch: {
      type: Function,
    },
    label: {
      type: String,
      default: "label",
    },
    enableIconCreate: {
      type: Boolean,
      default: true,
    },
    openCreateModal: {
      type: Function,
    },
    isDisabled: {
      type: Boolean,
    },
    findAll: {
      type: Boolean,
      default: false,
    },
    defaultLabel: {
      type: Boolean,
      default: true,
    },
    clearSelected: {
      type: Boolean,
      default: false,
    },
    searchFields: {
      typeof: Array,
    },
  },
  data() {
    return {
      observer: null,
      limit: 10,
      dataList: [],
      selected: null,
      hasNextPage: false,
      debouncedChanged: debounce(this.search),
      loading: false,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
    this.removeClearButtonTitle();
  },
  created() {
    this.findInitial();
  },
  methods: {
    async search(search = "") {
      this.loading = true;

      if (this.onSearch) {
        this.dataList = await this.onSearch("", search);
      }

      this.loading = false;
    },
    async findInitial() {
      this.loading = true;

      if (this.dataInput) {
        const result = await this.onSearch(this.dataInput, "");
        this.selected = result.find(
          (item) => item[this.value] === this.dataInput
        );

        this.dataList = result;
      } else {
        this.dataList = await this.onSearch("", "");
      }

      this.loading = false;
    },
    async findToEdit() {
      this.loading = true;

      const result = await this.onSearch(this.dataInput, "");
      this.selected = result.find(
        (item) => item[this.value] === this.dataInput
      );

      this.$emit("selectItem", this.selected);
      this.dataList = result;

      this.loading = false;
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    searchByWriting(search = "") {
      this.debouncedChanged(search);
    },
    setSelected(itemSelect) {
      this.selected = itemSelect;

      if (itemSelect) {
        this.$emit("update:dataInput", itemSelect[this.value]);
        this.$emit("selectItem", itemSelect);
      } else {
        this.$emit("update:dataInput", "");
        this.$emit("selectItem", "");
      }
    },

    onClose() {
      this.observer.disconnect();
    },

    removeClearButtonTitle() {
      this.$nextTick(() => {
        const clearButton = document.querySelector(".vs__clear");
        if (clearButton) {
          clearButton.title = "Remover seleção";
        }
      });
    },

    createModal() {
      if (this.openCreateModal) return this.openCreateModal();

      return;
    },
    customFilter(option, label, search) {
      const searchLower = search.toLowerCase();

      if (this.searchFields && this.searchFields.length) {
        return this.searchFields.some((field) =>
          String(option[field]).toLowerCase().includes(searchLower)
        );
      }

      return option[this.text].toLowerCase().includes(searchLower);
    },
  },
  watch: {
    clearSelected() {
      this.selected = "";
    },
    isEdit() {
      this.findToEdit();
    },
    findAll() {
      this.findInitial();
    },
  },
};
</script>

<style>
.loader {
  text-align: center;
  color: #bbbbbb;
  height: 30px !important;
}

.vs__dropdown-toggle {
  height: 30px;
}

.vs__search {
  font-size: 12px !important;
  color: #495057 !important;
}

.vs__dropdown-menu {
  font-size: 12px;
}

.vs__selected {
  font-size: 12px !important;
}

.vs__dropdown-menu {
  padding: 0 0 5px 0 !important;
}

.floatLabel {
  position: absolute;
  left: 3px;
  font-size: 8.5px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  top: -9px;
  left: 7px;
  pointer-events: none;
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  height: 12px;
  background-color: #ffff;
  padding: 0 0.2em;
  z-index: 9999;
}

.defaultLabel {
  position: absolute;
  margin-bottom: 0px;
  font-size: 11px;
  bottom: 30px;
  left: 0px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: transparent;
  font-weight: 600;
}

.iconSelectInfinit {
  position: absolute;
  right: -18px !important;
  top: 0 !important;
  z-index: 10;
  background-color: #c1c1c1;
  color: whitesmoke;
  height: 100%;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0 0.25rem 0.25rem 0;
}

.iconDisabledInfinit {
  pointer-events: none !important;
}
</style>